.element-image {
    width: 100%;
    aspect-ratio: 21.5 / 12;
    object-fit: fill;
}
.course-two__item {
    border-radius: 0;
}

.tutor-accordion-item-header::after {
    content: '';
}

.course-details__title {
    margin: 0;
}

.course-details__sidebar__item {
    margin-left: 0;
}
