.header__menu {
    position: fixed !important;
    z-index: 100;
}
.sub-menu {
    min-width: 300px !important;
}
.page-header__breadcrumb li:not(:last-of-type)::after {
    color: white !important;
}

.display-none {
    display: none !important;
}
.display-grid {
    display: grid;
}
.main-menu .main-menu__list li:hover > ul,
.stricky-header .main-menu__list li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: none !important;
}
.main-menu .main-menu__list li ul,
.stricky-header .main-menu__list li ul {
    opacity: 1;
    visibility: visible;
    transform: none;
    transition: none;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list ul {
    display: flex;
}

.main-menu .main-menu__list li ul li > a::after,
.stricky-header .main-menu__list li ul li > a::after {
    content: '' !important;
}

@media (min-width: 991px) {
    .main-menu__list {
        display: flex;
    }
}
.mobile-nav__toggler {
    display: none;
}
@media (max-width: 991px) {
    .main-menu .main-menu__list > li,
    .stricky-header .main-menu__list > li {
        width: 100% !important;
        text-align: start;
        padding-bottom: 5px;
        padding-left: 15px;
    }
    .main-menu .main-menu__list,
    .main-menu .main-menu__list ul,
    .stricky-header .main-menu__list,
    .stricky-header .main-menu__list ul {
        padding: 0 0 35px 15px;
    }
    .main-menu .main-menu__list li ul li,
    .stricky-header .main-menu__list li ul li {
        flex: none;
    }
    .sub-menu {
        position: static !important;
        height: fit-content !important;
    }
    .sub-menu {
        background-color: transparent !important;
    }
    .main-menu .main-menu__list,
    .main-menu .main-menu__list ul,
    .stricky-header .main-menu__list,
    .stricky-header .main-menu__list ul {
        height: calc(100vh - 100px);
        overflow: auto;
    }

    .menu-item-link {
        justify-content: start;
    }
    .main-menu .main-menu__list li ul,
    .stricky-header .main-menu__list li ul {
        position: static;
    }
    .main-menu__list {
        position: fixed;
        width: 60%;
        left: 0;
        justify-content: center;
        background-color: #2a254d;
        top: 138px;
        max-width: 350px;
    }
    .mobile-nav__toggler {
        display: block;
    }
    .main-menu .main-menu__list,
    .main-menu .main-menu__list ul,
    .stricky-header .main-menu__list,
    .stricky-header .main-menu__list ul {
        display: flex;
        flex-flow: column;
        justify-content: start;
    }
    .main-menu .main-menu__list li ul,
    .stricky-header .main-menu__list li ul {
        top: 40%;
        left: 211px;
    }
    .main-menu .main-menu__list > li + li,
    .stricky-header .main-menu__list > li + li {
        margin-left: 0;
        text-align: st;
    }
}
@media (max-width: 768px) {
    .main-menu .main-menu__list,
    .main-menu .main-menu__list ul,
    .stricky-header .main-menu__list,
    .stricky-header .main-menu__list ul {
        top: 124px;
    }
}
@media (max-width: 600px) {
    .main-menu .main-menu__list,
    .main-menu .main-menu__list ul,
    .stricky-header .main-menu__list,
    .stricky-header .main-menu__list ul {
        width: 100%;
        max-width: 100%;
    }
    .main-menu .main-menu__list li ul,
    .stricky-header .main-menu__list li ul {
        top: 72%;
        left: -114px;
    }
}
