.testimonial-wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
}
.service-card-wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px;
}
.service-card {
    max-width: fit-content;
    margin: auto;
    min-height: 360px;
}
.service-one__item {
    min-height: 360px;
}
.testimonial-one__author {
    margin-top: 20px;
}
.testimonial-one__area {
    padding: 10px 25px;
}
.testimonial-one__quote {
    font-size: 17px;
}
.elementor img {
    height: inherit;
}
.clamp-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
}
.service-one__wrapper {
    height: 360px;
}
.cirtification-container {
    padding: 15px 10px !important;
}
.testimonial-one {
    padding: 0 0 218px;
}
.hero-banner {
    padding: 250px 0 92px;
}

@media (min-width: 951px) {
    .hero-banner__thumb {
        width: auto;
        height: auto;
    }
}

@media (max-width: 1000px) {
    .service-card-wrapper {
        grid-template-columns: auto auto auto;
    }
    .testimonial-wrapper {
        grid-template-columns: auto auto;
    }
}
@media (max-width: 850px) {
    .service-card-wrapper {
        grid-template-columns: auto auto;
    }
    .testimonial-one {
        padding: 0 0 50px;
    }
}
@media (max-width: 770px) {
    .testimonial-one .section-title {
        margin-top: 35px;
    }
    .testimonial-wrapper {
        grid-template-columns: auto;
    }
}
@media (max-width: 600px) {
    .service-card-wrapper {
        grid-template-columns: auto;
    }
}
@media (max-width: 370px) {
    .hero-banner__btn {
        display: grid;
        justify-content: start;
        width: 100%;
        gap: 10px;
    }
    .hero-banner__btn > a {
        width: 100%;
    }
    .hero-banner__btn .eduact-btn + .eduact-btn {
        margin-left: 0;
    }
}
