.contact-info ul {
    align-items: normal;
}
.contact-info ul li.active .contact-info__title,
.contact-info ul li:hover .contact-info__title {
    color: white !important;
}
.contact-info__text a:hover {
    color: white !important;
}
.contact-info__text:hover {
    color: white !important;
}
