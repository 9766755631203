.text-white {
    color: white !important;
}
.text-black {
    color: black !important;
}
.text-gray {
    color: #6e7a89 !important;
}
.text-orange {
    color: #f57005 !important;
}
.display-flex {
    display: flex;
}
.page-header {
    padding: 50px 0;
}
.cursor-pointer {
    cursor: pointer;
}
.active {
    color:#f57005 !important;
}
/*!
Theme Name: Eduact
Theme URI: https://bracketweb.com/eduactwp/
Author: bracket-web
Author URI: https://themeforest.net/user/bracket-web
Description: EduAct is a modern education & online courses WordPress Theme. It is specially designed for LMS, online courses, education, learning management, e-learning, online training, zoom classes, and all other education related websites and businesses.
Version: 1.0.1
Tested up to: 7.4
Requires PHP: 7.4
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: eduact
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned.

eduact is based on Underscores https://underscores.me/, (C) 2012-2020 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
	- Normalize
	- Box sizing
# Base
	- Typography
	- Elements
	- Links
	- Forms
## Layouts
# Components
	- Navigation
	- Posts and pages
	- Comments
	- Widgets
	- Media
	- Captions
	- Galleries
# plugins
	- Jetpack infinite scroll
# Utilities
	- Accessibility
	- Alignments
# Templates
	- Common
	- Navigations One
	- Navigations Two
	- Animations
	- Zoom Fade Css
	- Mobile Nav
	- Search Popup
	- Banner One
	- Main Slider Two
	- Welcome One
	- Counter One
	- We Change
	- We Make
	- Services One
	- Portfolio One
	- Brand One
	- Testimonials Two
	- Two Boxes
	- Video One
	- Reasons One
	- Blog One
	- CTA One
	- Site Footer
	- Areas Of Practice
	- Build Business
	- Web Solutions
	- Team One
	- Watch Video
	- Core Features
	- Blog Two
	- Google Map
	- Get In Touch
	- CTA Two
	- Portfolio Two
	- Page Header
	- Service Details
	- Blog Sidebar
	- Sidebar
	- Blog Details
		- Comments
	- Portfolio Page
	- Portfolio Details
	- Similar Work
	- Portfolio Details Full
	- Reasons
	- Error Page
	- Contact Page
	- Contact Page Google Map
--------------------------------------------------------------*/
:root {
    --eduact-font: 'Urbanist', sans-serif;
    --heading-font: 'Urbanist', sans-serif;
    --eduact-text: #697585;
    --eduact-text-rgb: 105, 117, 133;
    --eduact-text2: #b3ade1;
    --eduact-text2-rgb: 179, 173, 225;
    --eduact-grey: #b7b0b4;
    --eduact-grey-rgb: 183, 176, 180;
    --eduact-soft: #f1f2fd;
    --eduact-soft-rgb: 241, 242, 253;
    --eduact-soft2: #f6f5f5;
    --eduact-soft2-rgb: 246, 245, 245;
    --eduact-soft3: #e0e3fd;
    --eduact-soft3-rgb: 224, 227, 253;
    --eduact-soft4: #e4e4e4;
    --eduact-soft4-rgb: 228, 228, 228;
    --eduact-soft5: #f6f6f6;
    --eduact-soft5-rgb: 246, 246, 246;
    --eduact-base: #4f5de4;
    --eduact-base-rgb: 79, 93, 228;
    --eduact-secondary: #f57005;
    --eduact-secondary-rgb: 245, 112, 5;
    --eduact-black: #2a254d;
    --eduact-black-rgb: 42, 37, 77;
    --eduact-black2: #000000;
    --eduact-black2-rgb: 0, 0, 0;
    --eduact-white: #ffffff;
    --eduact-white-rgb: 255, 255, 255;
}

/*--------------------------------------------------------------
  # Base
  --------------------------------------------------------------*/
/* Typography
  --------------------------------------------- */
body,
button,
input,
select,
optgroup,
textarea {
    color: var(--eduact-black, #2a254d);
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
    font-size: 1rem;
    line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
}

p {
    margin-bottom: 1.5em;
}

dfn,
cite,
em,
i {
    font-style: italic;
}

blockquote {
    margin: 0 1.5em;
}

address {
    margin: 0 0 1.5em;
}

pre {
    background: #eee;
    font-family: 'Courier 10 Pitch', courier, monospace;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1.6em;
}

code,
kbd,
tt,
var {
    font-family: monaco, consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
}

abbr,
acronym {
    border-bottom: 1px dotted var(--eduact-grey, #b7b0b4);
    cursor: help;
}

mark,
ins {
    background: #eceae0;
    text-decoration: none;
}

big {
    font-size: 125%;
}

b,
strong {
    color: var(--eduact-black, #2a254d);
}

/* Elements
  --------------------------------------------- */
body {
    background: #fff;
}

.has-fixed-footer,
.has-fixed-footer body {
    height: 100%;
    overflow: unset;
}
.has-fixed-footer .page-wrapper {
    min-height: 100%;
    margin-bottom: -100px;
}
.has-fixed-footer .default.error-page,
.has-fixed-footer .blog-page,
.has-fixed-footer .blog-single,
.has-fixed-footer .product-details,
.has-fixed-footer .shop-one.product.news-sidebar,
.has-fixed-footer .woocommerce-cart .woocommerce,
.has-fixed-footer .woocommerce-checkout .woocommerce {
    padding-bottom: 220px;
}
.has-fixed-footer .blog-details {
    padding-bottom: 220px;
}
.has-fixed-footer .tutor-dashboard {
    padding-bottom: 170px;
}
.has-fixed-footer
    .tutor-wrap.tutor-wrap-parent.tutor-full-width-student-profile,
.has-fixed-footer .tutor-course-details-page {
    padding: 100px;
}
.has-fixed-footer .default-footer .site-footer__bottom {
    padding: 34.5px 0;
}
.has-fixed-footer .page-template-default.woocommerce-checkout .woocommerce {
    padding-top: 0;
    padding-bottom: 50px;
}
.has-fixed-footer .woocommerce-cart .woocommerce,
.has-fixed-footer .woocommerce-checkout .woocommerce {
    padding-top: 0;
    margin-top: -15px;
    margin-bottom: -27px;
    padding-bottom: 0;
}
.has-fixed-footer .woocommerce-checkout .woocommerce {
    margin-top: 0;
    margin-bottom: -30px;
}
.has-fixed-footer .page-template-default.woocommerce-cart .woocommerce {
    margin-bottom: -10px;
}
.has-fixed-footer .copyright {
    padding: 37.5px 0;
}

hr {
    background-color: #eceae0;
    border: 0;
    height: 1px;
    margin-bottom: 1.5em;
}

ul,
ol {
    margin: 0 0 0.5em 3em;
    padding-left: 1em;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li > ul,
li > ol {
    margin-bottom: 0;
    margin-left: 1.5em;
}

dt {
    font-weight: 700;
}

dd {
    margin: 0 1.5em 1.5em;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
    max-width: 100%;
}

img {
    height: auto;
    max-width: 100%;
}

figure {
    margin: 1em 0;
}

.logo-retina img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

table {
    margin: 0 0 1.5em;
    width: 100%;
    max-width: 100%;
}

tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

table > tbody > tr > td,
table > tbody > tr > th,
table > tfoot > tr > td,
table > tfoot > tr > th,
table > thead > tr > td,
table > thead > tr > th {
    padding: 8px;
    vertical-align: middle;
    border: 1px solid #eceae0;
    border-top: 1px solid #eceae0;
}

table > tbody > tr > td,
table > tbody > tr > th,
table > tfoot > tr > td,
table > tfoot > tr > th,
table > thead > tr > td,
table > thead > tr > th {
    padding: 8px;
    vertical-align: middle;
    border: 1px solid #eceae0;
    border-top: 1px solid #eceae0;
}

.sidebar__single .calendar_wrap .wp-calendar-table thead {
    background-color: var(--eduact-primary, #f1cf69);
}

.sidebar__single .calendar_wrap .wp-calendar-table thead th {
    text-align: center;
    line-height: 40px;
    color: var(--eduact-base, #ffffff);
    font-weight: 600;
    font-size: 18px;
    padding-top: 4px;
}

.wp-block-calendar .wp-calendar-table caption {
    padding-bottom: 0;
    line-height: 1;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
}

.wp-block-calendar .wp-calendar-nav {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wp-block-calendar .wp-calendar-nav a {
    text-decoration: none;
    line-height: 1;
    color: var(--moniz-black, #1e1e22);
    text-transform: uppercase;
    font-weight: 600;
    transition: all 500ms ease;
}

/* Links
  --------------------------------------------- */
a {
    color: var(--eduact-black, #2a254d);
}
a:hover,
a:focus,
a:active {
    color: var(--eduact-base, #4f5de4);
}
a:focus {
    outline: none;
}
a:hover,
a:active {
    outline: none;
}

/* Forms
  --------------------------------------------- */
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
    border: 1px solid;
    border-color: #eceae0 #eceae0 #eceae0;
    background: #eceae0;
    color: rgba(0, 0, 0, 0.8);
    line-height: 1;
    padding: 0.6em 1em 0.4em;
}
button:hover,
input[type='button']:hover,
input[type='reset']:hover,
input[type='submit']:hover {
    border-color: #eceae0 #eceae0 #eceae0;
}
button:active,
button:focus,
input[type='button']:active,
input[type='button']:focus,
input[type='reset']:active,
input[type='reset']:focus,
input[type='submit']:active,
input[type='submit']:focus {
    border-color: #eceae0 #eceae0 #eceae0;
}

.wp-block-button:not(.wp-block-button.align-left) {
    margin-bottom: 20px;
}

.news-details__left .wp-block-button a:hover {
    background-color: var(--eduact-grey, #b7b0b4);
    border-color: var(--eduact-grey, #b7b0b4);
    color: var(--eduact-base, #4f5de4);
}

.news-details__left .wp-block-button a {
    transition: all 500ms ease;
}
.news-details__left .wp-block-button a:hover {
    background-color: var(--eduact-grey, #b7b0b4);
    border-color: var(--eduact-grey, #b7b0b4);
    color: #fff;
}

.news-details__left .wp-block-button.is-style-squared a {
    border-radius: 0;
}

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search'],
input[type='number'],
input[type='tel'],
input[type='range'],
input[type='date'],
input[type='month'],
input[type='week'],
input[type='time'],
input[type='datetime'],
input[type='datetime-local'],
input[type='color'],
textarea {
    color: var(--eduact-black, #2a254d);
    border: 1px solid #eceae0;
    border-radius: 0px;
    padding: 3px;
}
input[type='text']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='number']:focus,
input[type='tel']:focus,
input[type='range']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='week']:focus,
input[type='time']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='color']:focus,
textarea:focus {
    color: var(--eduact-black, #2a254d);
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
}

select {
    border: 1px solid #eceae0;
}

textarea {
    width: 100%;
}

/*--------------------------------------------------------------
  # Layouts
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Components
  --------------------------------------------------------------*/
/* Navigation
  --------------------------------------------- */
.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
    margin: 0 0 1.5em;
}
.comment-navigation .nav-links,
.posts-navigation .nav-links,
.post-navigation .nav-links {
    display: flex;
}
.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
    flex: 1 0 50%;
}
.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
    text-align: end;
    flex: 1 0 50%;
}

/* Posts and pages
  --------------------------------------------- */
.sticky {
    display: block;
}

.post,
.page {
    margin: 0 0 0em;
}

.updated:not(.published) {
    display: none;
}

.page-content,
.entry-content,
.entry-summary {
    margin: 1.5em 0 0;
}

.page-links {
    clear: both;
    margin: 0 0 1.5em;
}

/* Comments
  --------------------------------------------- */
.comment-content a {
    word-wrap: break-word;
    font-weight: 500;
}

.bypostauthor {
    display: block;
}

.comments-area {
    position: relative;
    display: block;
    border-top: 1px solid #eeede5;
    padding-top: 52px;
}

.comments-area .comment-one__title {
    font-size: 24px;
    margin-bottom: 30px;
    text-transform: capitalize;
}

.comments-area .comment-form__title {
    margin-bottom: 45px;
    font-size: 24px;
}

.comments-area .comment-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.comments-area .comment-list ul.children {
    margin: 0;
    list-style: none;
    padding-left: 1em;
}
@media (min-width: 992px) {
    .comments-area .comment-list ul.children {
        padding-left: 3em;
    }
}

.comments-area .comment-box {
    position: relative;
}

.comments-area .comment {
    position: relative;
    min-height: 90px;
}

.comments-area .comment-box .author-thumb {
    position: relative;
    left: 0px;
    top: 0px;
    width: 165px;
    height: 165px;
    border-radius: 50%;
    margin-bottom: 20px;
    overflow: hidden;
}

.comments-area .comment-box .author-thumb img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 50%;
}

.comments-area .comment-box .info {
    position: relative;
    margin-bottom: 25px;
    line-height: 24px;
}

.comments-area .comment-box .info .name {
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
    color: var(--eduact-grey, #b7b0b4);
    text-transform: uppercase;
}

.comments-area .comment-box .info .date {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: var(--eduact-grey, #b7b0b4);
}

.comments-area .comment-box .text {
    position: relative;
    display: block;
    color: var(--eduact-grey, #b7b0b4);
    font-weight: 300;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    margin-bottom: 30px;
}

.comments-area .comment-box .reply-btn {
    position: relative;
    display: block;
}

.comments-area .comment-box .theme-btn .btn-title {
    padding: 6px 30px 4px;
    line-height: 30px;
}

.comments-area .comment-body .says {
    display: none;
}

.comments-area .comment-body {
    width: 100%;
    position: relative;
    padding-left: 120px;
    margin-bottom: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #eceae0;
}
@media (max-width: 991px) {
    .comments-area .comment-body {
        padding-left: 0;
    }
}

.comments-area .trackback .comment-body,
.comments-area .pingback .comment-body {
    padding-left: 0;
}

.comments-area .comment-body .avatar {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
}
@media (max-width: 991px) {
    .comments-area .comment-body .avatar {
        position: relative;
        margin-bottom: 40px;
    }
}

.comments-area .fn a,
.comments-area .fn {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    color: var(--eduact-black);
    font-family: var(--heading-font);
    text-decoration: none;
    text-transform: capitalize;
}

.comments-area .comment-body .comment-metadata {
    margin-bottom: 25px;
}

.comments-area .comment-body .comment-metadata a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    color: var(--eduact-base, #4f5de4);
    text-decoration: none;
}

.comments-area .comment-body .comment-metadata .edit-link a::before {
    content: '.';
    margin-left: 8px;
    margin-right: 10px;
}

.comments-area .comment-content p {
    position: relative;
    display: block;
    color: var(--eduact-text, #697585);
    line-height: 1.875em;
    margin-bottom: 1em;
    font-size: 16px;
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
    letter-spacing: var(--eduact-letter-spacing-two);
    font-weight: 600;
}

.comments-area .comment-content table a {
    color: var(--eduact-black, #2a254d);
    text-decoration: none;
}

.comments-area .comment-content table a:hover {
    color: var(--eduact-grey, #b7b0b4);
}

.comments-area .comment-content p:last-of-type {
    margin-bottom: 0;
}

.comments-area .reply a {
    padding: 3px 24px;
    position: absolute;
    top: -15px;
    right: 0;
    font-size: 16px;
    font-weight: 700;
    color: var(--eduact-text);
    background-color: var(--eduact-soft5);
    overflow: hidden;
    transition: all 0.5s linear;
    z-index: 1;
    appearance: none;
    outline: none !important;
    border: none;
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
    font-family: var(--heading-font);
}

.comments-area .reply a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

@media (min-width: 992px) {
    .comments-area .reply a {
        margin-top: 0;
        position: absolute;
        top: 0;
        right: 0;
    }
}
.comments-area .blog-pagination a,
.comments-area .blog-pagination span {
    margin-bottom: 45px;
}

.comment-respond .blog-details__content-title {
    margin-bottom: 50px;
}

.reply-form .logged-in-as {
    display: flex;
    color: var(--eduact-base, #4f5de4);
    margin-bottom: 24px;
    margin-top: -30px;
    font-weight: 500;
}

.reply-form .logged-in-as a {
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
    color: var(--eduact-black);
}

.reply-form .logged-in-as a:hover {
    color: var(--eduact-secondary);
}

.comment-form__title small {
    font-size: 20px;
}

.comment-form__title small a {
    text-decoration: none;
    color: var(--eduact-base, #4f5de4);
    text-decoration: underline;
}

.comment-form__title small a:hover {
    color: var(--eduact-black);
    opacity: 0.5;
}

.comment-list .comment-respond {
    margin-bottom: 30px;
}

.reply-form.row {
    --bs-gutter-x: 20px;
}

.comment-respond .comment-form__input-box input[type='text'],
.comment-respond .comment-form__input-box input[type='url'],
.comment-respond .comment-form__input-box input[type='email'] {
    margin-bottom: 20px;
}
.comment-respond .form-submit {
    margin-bottom: 0 !important;
}

.comment-list .comment-respond .form-submit {
    margin-bottom: 30px !important;
}

/* Widgets
  --------------------------------------------- */
.widget {
    margin: 0 0 1.5em;
}
.widget select {
    max-width: 100%;
}

.sidebar .widget ul,
.sidebar__single ul {
    margin-left: 0;
    padding-left: 0;
}
.sidebar .widget ul ul,
.sidebar__single ul ul {
    padding-left: 0.5em;
    margin-top: 15px;
    box-shadow: none;
}

.sidebar .widget select,
.sidebar__single select {
    width: 100%;
    height: 68px;
    background-color: var(--eduact-white, #ffffff);
    font-weight: 400;
    outline: none;
    color: var(--eduact-gray, #717178);
    font-size: 16px;
    padding-left: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-family: var(--eduact-font, 'Kumbh Sans', sans-serif);
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path fill="currentColor" d="M540.1 776.847l472.32-473.63c15.466-15.518 15.44-40.642-0.080-56.134-15.518-15.48-40.656-15.44-56.134 0.080l-444.208 445.438-444.206-445.454c-15.48-15.518-40.602-15.558-56.122-0.080-7.78 7.766-11.67 17.94-11.67 28.114 0 10.148 3.864 20.282 11.59 28.034l472.308 473.632c7.436 7.474 17.556 11.668 28.1 11.668s20.652-4.206 28.102-11.668z"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 30px top 50%;
    background-size: 0.65em auto;
    border-radius: 0;
    border: 1px solid #eceae0;
}

.sidebar .widget,
.sidebar__single {
    border-radius: 14px;
    background-color: var(--eduact-white);
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    padding: 37px 30px 42px;
}

.sidebar__single.widget_search {
    padding: 35px 30px;
}

.sidebar .widget ul li,
.sidebar__single ul li {
    position: relative;
}

.sidebar .widget ul li,
.sidebar__single ul li {
    font-weight: 600;
}
.sidebar .widget ul li a::after,
.sidebar__single ul li a::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
    font-weight: 900;
    position: absolute;
    font-size: 16px;
    top: -1px;
    right: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}
.sidebar .widget ul li a,
.sidebar__single ul li a {
    transition: all 500ms ease;
    font-weight: 600;
    color: var(--eduact-text);
}
.sidebar .widget ul li a:hover,
.sidebar__single ul li a:hover {
    color: var(--eduact-black, #2a254d);
}

.sidebar .widget.widget_rss,
.sidebar__single.widget_rss {
    padding-bottom: 40px;
}

.sidebar .widget.widget_rss ul,
.sidebar__single.widget_rss ul {
    list-style: none;
}

.sidebar .widget.widget_rss ul li,
.sidebar__single.widget_rss ul li {
    position: relative;
}
.sidebar .widget.widget_rss ul li ::after,
.sidebar__single.widget_rss ul li ::after {
    content: '';
}
.sidebar .widget.widget_rss ul li + li,
.sidebar__single.widget_rss ul li + li {
    margin-top: 10px;
}

.sidebar .widget.widget_rss li .rsswidget,
.sidebar__single.widget_rss li .rsswidget {
    line-height: 1.5em;
    display: block;
    color: var(--eduact-black, #2a254d);
    font-weight: 700;
    font-size: 20px;
    text-transform: capitalize;
}

.sidebar .widget p,
.sidebar__single p,
.sidebar .widget.widget_rss li .rssSummary,
.sidebar__single.widget_rss li .rssSummary {
    font-size: 16px;
    line-height: 32px;
}

.sidebar .widget p,
.sidebar__single p,
.sidebar .widget.widget_rss li .rssSummary {
    font-weight: 600;
}

.sidebar .widget.widget_rss li .rss-date,
.sidebar__single.widget_rss li .rss-date {
    display: flex;
    align-items: center;
    color: var(--eduact-text, #697585);
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 10px;
    line-height: 1;
    font-weight: 500;
}

.sidebar .widget.widget_rss li .rss-date::before,
.sidebar__single.widget_rss li .rss-date::before {
    content: '\f017';
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    font-size: 14px;
    color: var(--eduact-secondary, #f57005);
    margin-right: 10px;
}

.sidebar .widget.widget_rss cite,
.sidebar__single.widget_rss cite {
    color: var(--eduact-black, #2a254d);
    font-size: 14px;
}

.sidebar .widget .tagcloud,
.sidebar__single .tagcloud {
    margin-bottom: -10px;
    margin-left: -5px;
}

.sidebar .widget .tagcloud a,
.sidebar__single .tagcloud a,
.sidebar__single .wp-block-tag-cloud a {
    font-size: 16px !important;
    color: var(--eduact-text);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: var(--eduact-soft5);
    display: inline-block;
    padding: 7px 20px;
    margin-left: 5px;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 13px;
    font-family: var(--heading-font, 'Urbanist', sans-serif);
    margin-right: 5px;
    border-radius: 5px;
}
.sidebar .widget .tagcloud a:hover,
.sidebar__single .tagcloud a:hover,
.sidebar__single .wp-block-tag-cloud a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.sidebar .widget .wp-block-search input[type='search'],
.sidebar__single .wp-block-search input[type='search'] {
    background-color: var(--eduact-white, #ffffff);
    height: 70px;
    padding-left: 25px;
    padding-right: 15px;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 400;
    color: var(--eduact-text, #697585);
    border: 1px solid #eceae0;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
}

.sidebar .widget .wp-block-search .wp-block-search__button,
.sidebar__single .wp-block-search .wp-block-search__button {
    padding: 15px 20px;
    text-transform: capitalize;
    color: var(--eduact-white, #ffffff);
    background-color: var(--eduact-black, #2a254d);
    line-height: 1;
    fill: var(--eduact-white, #ffffff);
    border-radius: 0;
    margin-left: 0;
    transition: all 500ms ease;
    font-size: 14px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    font-weight: 700;
    text-transform: capitalize;
}

.sidebar .widget .wp-block-search .wp-block-search__button:hover,
.sidebar__single .wp-block-search .wp-block-search__button:hover,
.wp-block-search .wp-block-search__button:hover {
    background-color: var(--eduact-secondary, #f57005);
    color: var(--eduact-white, #ffffff);
    fill: var(--eduact-white, #ffffff);
}

.sidebar .widget:last-child,
.sidebar__single.widget:last-child {
    margin-bottom: 0;
}

.wp-calendar-table {
    margin-bottom: 0;
}
.wp-calendar-table th,
.wp-calendar-table td {
    text-align: center;
}
.wp-calendar-table caption {
    padding: 0;
    text-align: center;
    color: var(--eduact-black, #2a254d);
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;
}

.wp-calendar-nav {
    line-height: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.wp-calendar-nav a {
    color: var(--eduact-black, #2a254d);
    transition: all 500ms ease;
    font-weight: 500;
}
.wp-calendar-nav a:hover {
    color: var(--eduact-grey, #b7b0b4);
}

.sidebar .widget .calendar_wrap .wp-calendar-table thead,
.sidebar__single .calendar_wrap .wp-calendar-table thead {
    background-color: var(--eduact-black, #2a254d);
}

.sidebar .widget .calendar_wrap .wp-calendar-table thead th,
.sidebar__single .calendar_wrap .wp-calendar-table thead th {
    text-align: center;
    color: var(--eduact-white, #ffffff);
    font-weight: 400;
    font-size: 18px;
    border: none;
}

.wp-block-calendar .wp-calendar-table caption {
    padding-bottom: 0;
    line-height: 1;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    padding-top: 10px;
}

.wp-block-calendar .wp-calendar-nav {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.widget-area .wp-block-calendar .wp-calendar-nav {
    margin-bottom: -5px;
}

.wp-block-calendar .wp-calendar-nav a {
    text-decoration: none;
    line-height: 1;
    color: var(--eduact-black, #2a254d);
    text-transform: uppercase;
    font-weight: 400;
    transition: all 500ms ease;
}

tbody {
    background-color: var(--eduact-white, #ffffff);
}

.sidebar .widget ul,
.sidebar__single ul {
    list-style: none;
}
.sidebar .widget .widget-title,
.sidebar__single .widget-title {
    display: flex;
    align-items: center;
}
.sidebar .widget .widget-title .rss-widget-icon,
.sidebar__single .widget-title .rss-widget-icon {
    position: relative;
    top: -2px;
    margin-right: 5px;
}
.sidebar .widget .widget-title a,
.sidebar__single .widget-title a {
    color: inherit;
    transition: all 500ms ease;
}
.sidebar .widget .widget-title a:hover,
.sidebar__single .widget-title a:hover {
    color: var(--eduact-grey, #b7b0b4);
}
.sidebar .widget.widget_nav_menu > ul,
.sidebar .widget.widget_meta > ul,
.sidebar .widget.widget_pages > ul,
.sidebar .widget.widget_archive > ul,
.sidebar__single.widget_nav_menu > ul,
.sidebar__single.widget_meta > ul,
.sidebar__single.widget_pages > ul,
.sidebar__single.widget_archive > ul {
    margin-top: -8px;
    padding-left: 0;
}
.sidebar .widget.widget_nav_menu > ul ul,
.sidebar .widget.widget_meta > ul ul,
.sidebar .widget.widget_pages > ul ul,
.sidebar .widget.widget_archive > ul ul,
.sidebar__single.widget_nav_menu > ul ul,
.sidebar__single.widget_meta > ul ul,
.sidebar__single.widget_pages > ul ul,
.sidebar__single.widget_archive > ul ul {
    margin-top: 15px;
    padding-left: 0.7em;
}
.sidebar .widget.widget_nav_menu li,
.sidebar .widget.widget_meta li,
.sidebar .widget.widget_pages li,
.sidebar .widget.widget_archive li,
.sidebar__single.widget_nav_menu li,
.sidebar__single.widget_meta li,
.sidebar__single.widget_pages li,
.sidebar__single.widget_archive li {
    font-size: 16px;
}
.sidebar .widget.widget_nav_menu li + li,
.sidebar .widget.widget_meta li + li,
.sidebar .widget.widget_pages li + li,
.sidebar .widget.widget_archive li + li,
.sidebar__single.widget_nav_menu li + li,
.sidebar__single.widget_meta li + li,
.sidebar__single.widget_pages li + li,
.sidebar__single.widget_archive li + li {
    margin-top: 15px;
}
.sidebar .widget.widget_recent_entries ul,
.sidebar .widget.widget_recent_comments ul,
.sidebar__single.widget_recent_entries ul,
.sidebar__single.widget_recent_comments ul {
    padding: 0;
    margin-top: 7px;
}
.sidebar .widget.widget_recent_entries ul li ::after,
.sidebar .widget.widget_recent_comments ul li ::after,
.sidebar__single.widget_recent_entries ul li ::after,
.sidebar__single.widget_recent_comments ul li ::after {
    display: none;
}
.sidebar .widget.widget_recent_entries ul li,
.sidebar .widget.widget_recent_comments ul li,
.sidebar__single.widget_recent_entries ul li,
.sidebar__single.widget_recent_comments ul li {
    padding-left: 65px;
    font-size: 20px;
    margin: 0;
    line-height: 26px;
    color: var(--eduact-black, #2a254d);
    font-weight: 700;
    text-transform: capitalize;
    min-height: 45px;
}
.sidebar .widget.widget_recent_entries ul li + li,
.sidebar .widget.widget_recent_comments ul li + li,
.sidebar__single.widget_recent_entries ul li + li,
.sidebar__single.widget_recent_comments ul li + li {
    margin-top: 23px;
}
.sidebar .widget.widget_recent_entries ul li a,
.sidebar .widget.widget_recent_comments ul li a,
.sidebar__single.widget_recent_entries ul li a,
.sidebar__single.widget_recent_comments ul li a {
    color: var(--eduact-black, #2a254d);
    font-weight: 700;
    font-size: 20px;
    transition: all 500ms ease;
}
.sidebar .widget.widget_recent_entries ul li a:hover,
.sidebar .widget.widget_recent_comments ul li a:hover,
.sidebar__single.widget_recent_entries ul li a:hover,
.sidebar__single.widget_recent_comments ul li a:hover {
    color: var(--eduact-secondary, #f57005);
}
.sidebar .widget.widget_recent_entries ul li::before,
.sidebar .widget.widget_recent_comments ul li::before,
.sidebar__single.widget_recent_entries ul li::before,
.sidebar__single.widget_recent_comments ul li::before {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    font-size: 22px;
    color: var(--eduact-black, #2a254d);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0;
    transition: all 0.4s ease;
    content: '\e926';
    font-family: 'icomoon';
    font-weight: 200;
    background-color: var(--eduact-soft5);
}
.sidebar .widget.widget_recent_entries ul li:hover::before,
.sidebar .widget.widget_recent_comments ul li:hover::before,
.sidebar__single.widget_recent_entries ul li:hover::before,
.sidebar__single.widget_recent_comments ul li:hover::before {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}
.sidebar .widget.widget_recent_entries li,
.sidebar__single.widget_recent_entries li {
    min-height: 45px;
    display: flex;
    align-items: center;
}
.sidebar .widget.widget_recent_entries ul li::before,
.sidebar__single.widget_recent_entries ul li::before {
    content: '\f044';
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    font-size: 15px;
}
.sidebar .widget.widget_rss ul,
.sidebar__single.widget_rss ul {
    padding: 0;
    margin-top: -5px;
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul,
.sidebar__single.recent-posts-widget-with-thumbnails ul {
    margin: -20px !important;
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul li ::after,
.sidebar__single.recent-posts-widget-with-thumbnails ul li ::after {
    display: none;
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul li,
.sidebar__single.recent-posts-widget-with-thumbnails ul li {
    display: flex;
    flex-direction: column-reverse;
    padding: 20px;
    padding-bottom: 30px;
    padding-left: 105px;
    position: relative;
    margin-bottom: 0;
    transition: all 500ms ease;
    border-radius: var(--eduact-bdr-radius);
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul li:hover,
.sidebar__single.recent-posts-widget-with-thumbnails ul li:hover {
    background-color: #fff;
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul li img,
.sidebar__single.recent-posts-widget-with-thumbnails ul li img {
    position: absolute;
    top: 22px;
    left: 20px;
    width: 70px;
    height: auto;
    background-color: var(--eduact-black, #2a254d);
}
.sidebar
    .widget.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-date::before,
.sidebar__single.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-date::before {
    content: '\f017';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;
    color: var(--eduact-base, #4f5de4);
}
.sidebar
    .widget.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-author::before,
.sidebar__single.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-author::before {
    content: '\f2bd';
    font-weight: 400;
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;
    color: var(--eduact-base, #4f5de4);
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul li .rpwwt-post-author,
.sidebar__single.recent-posts-widget-with-thumbnails ul li .rpwwt-post-author {
    font-size: 12px;
    font-weight: 500;
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul li .rpwwt-post-title,
.sidebar__single.recent-posts-widget-with-thumbnails ul li .rpwwt-post-title {
    font-size: 16px;
    color: var(--eduact-black, #2a254d);
    transition: all 500ms ease;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: var(--eduact-letter-space-small);
    line-height: 28px;
    display: block;
}
.sidebar
    .widget.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-title:hover,
.sidebar__single.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-title:hover {
    color: var(--eduact-base, #4f5de4);
}
.sidebar .widget.recent-posts-widget-with-thumbnails ul li .rpwwt-post-date,
.sidebar__single.recent-posts-widget-with-thumbnails ul li .rpwwt-post-date {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--eduact-gray);
    margin-bottom: 0px;
    line-height: 1;
    color: var(--eduact-gray);
    font-size: 14px;
    text-transform: capitalize;
}

.news-one .blog-pagination {
    justify-content: center;
    margin-top: 30px;
}

.sidebar .widget .wp-block-categories,
.sidebar__single .wp-block-categories {
    padding-left: 0;
}

.sidebar .widget .wp-block-tag-cloud,
.sidebar__single .wp-block-tag-cloud {
    margin-bottom: -10px;
}
.sidebar .widget .wp-block-latest-comments__comment-meta,
.sidebar__single .wp-block-latest-comments__comment-meta {
    font-size: 16px;
    line-height: 26px;
    color: var(--eduact-grey, #b7b0b4);
}
.sidebar .widget .wp-block-categories,
.sidebar__single .wp-block-categories {
    margin-top: -8px;
    margin-bottom: -8px;
}
.sidebar .widget .wp-block-categories li + li,
.sidebar__single .wp-block-categories li + li {
    margin-top: 15px;
}
.sidebar .widget ul li,
.sidebar__single ul li {
    font-size: 16px;
}
.sidebar .widget.recent-posts-widget-with-thumbnails,
.sidebar__single.recent-posts-widget-with-thumbnails {
    padding-bottom: 55px;
}
.sidebar .widget .wp-block-latest-comments:not(.has-avatar):not(.has-dates) li,
.sidebar__single .wp-block-latest-comments:not(.has-avatar):not(.has-dates) li {
    margin-bottom: 0;
}
.sidebar
    .widget
    .wp-block-latest-comments:not(.has-avatar):not(.has-dates)
    li
    + li,
.sidebar__single
    .wp-block-latest-comments:not(.has-avatar):not(.has-dates)
    li
    + li {
    margin-top: 20px;
}

.widget_nav_menu ul.menu {
    margin-top: -10px;
    margin-bottom: -6px;
}

.widget_recent_entries > ul {
    margin-top: -7px;
}

.sidebar .widget.widget_recent_comments ul li:hover::before,
.sidebar__single.widget_recent_comments ul li:hover::before {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white, #ffffff);
}

.sidebar
    .widget.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-comments-number::before,
.sidebar__single.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-comments-number::before {
    content: '\f086';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    margin-right: 5px;
    color: var(--eduact-base, #976147);
}

.sidebar
    .widget.recent-posts-widget-with-thumbnails
    ul
    li
    .rpwwt-post-comments-number {
    font-size: 13px;
    padding-bottom: 5px;
}

.sidebar .widget.recent-posts-widget-with-thumbnails ul li .rpwwt-post-title,
.sidebar__single.recent-posts-widget-with-thumbnails ul li .rpwwt-post-title {
    color: var(--eduact-black, #2a254d);
    transition: all 500ms ease;
    margin-top: -5px;
    display: block;
    font-family: var(--heading-font);
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 26px;
}

.sidebar .widget input[type='email'],
.sidebar .widget input[type='search'],
.sidebar .widget input[type='password'],
.sidebar .widget input[type='text'] {
    width: 100%;
    display: block;
    height: 68px;
    background-color: #fff;
    padding-left: 30px;
    padding-right: 30px;
    color: var(--eduact-text, #697585);
    outline: none;
    border-radius: 0;
    border: 1px solid #eceae0;
}
.sidebar .widget input[type='search']::placeholder {
    color: var(--eduact-text, #697585);
}
.sidebar .widget .textwidget > p:first-child {
    margin-top: -10px;
}
.sidebar .widget .wp-block-tag-cloud {
    margin-bottom: -10px;
    margin-left: -5px;
}
.sidebar .widget .wp-block-tag-cloud a {
    font-size: 16px !important;
    color: var(--eduact-text);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    background: var(--eduact-soft5);
    display: inline-block;
    padding: 4px 20px;
    margin-left: 5px;
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 13px;
    font-family: var(--heading-font, 'Urbanist', sans-serif);
    margin-right: 5px;
    border-radius: 5px;
}
.sidebar .widget .wp-block-tag-cloud a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}
.sidebar .widget .wp-calendar-table td {
    padding: 3px 0px;
    font-size: 14px;
}
.sidebar .widget .wp-block-calendar thead th {
    background-color: var(--eduact-black, #2a254d);
    color: #fff;
    border: none;
    font-weight: 400;
}
.sidebar .widget .wp-calendar-table td {
    color: var(--eduact-black, #2a254d);
}
.sidebar .widget .wp-block-archives {
    margin-top: -10px;
    margin-bottom: -10px;
}
.sidebar .widget .wp-block-archives li + li {
    margin-top: 10px;
}
.sidebar .widget .wp-block-archives li a::after {
    display: none;
}
.sidebar .widget .wp-block-categories-dropdown,
.sidebar .widget .wp-block-archives-dropdown {
    margin-bottom: 0;
}
.sidebar .widget .wp-block-calendar .wp-calendar-table caption {
    padding-top: 15px;
    padding-bottom: 15px;
    color: var(--eduact-black, #2a254d);
    font-weight: 500;
}
.sidebar .widget .wp-block-calendar .wp-calendar-nav a {
    text-transform: capitalize;
    font-weight: 500;
}
.sidebar .widget .wp-block-latest-comments__comment-meta {
    color: var(--eduact-black, #2a254d);
}
.sidebar .widget .wp-block-latest-comments__comment-meta a:hover {
    color: var(--eduact-secondary, #f57005);
}
.sidebar .widget .wp-block-latest-comments__comment-date {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
}
.sidebar .widget .wp-block-latest-comments__comment-date::before {
    content: '\f017';
    margin-right: 5px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    color: var(--eduact-secondary, #f57005);
}
.sidebar .widget .has-avatar .wp-block-latest-comments__comment-date {
    margin-left: 5px;
}
.sidebar .widget .wp-block-latest-comments__comment:last-child {
    margin-bottom: 0;
}
.sidebar .widget .wp-block-latest-comments__comment-excerpt p:last-child {
    margin-bottom: 0;
}
.sidebar .widget .has-excerpts.wp-block-latest-comments {
    margin-bottom: -10px;
}
.sidebar .widget .wp-block-latest-posts__list li a::after {
    display: none;
}
.sidebar .widget .wp-block-latest-posts__list.has-dates li {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
}
.sidebar .widget .wp-block-latest-posts__list.has-dates li::before {
    display: none;
}
.sidebar .widget .wp-block-latest-posts__list.has-dates li a::after {
    display: none;
}
.sidebar .widget .wp-block-latest-posts__list {
    margin-bottom: -10px;
}
.sidebar .widget .wp-block-latest-posts__list li > a,
.sidebar
    .widget
    .wp-block-latest-posts__list
    .wp-block-latest-posts__post-excerpt {
    flex: 0 0 100%;
}
.sidebar
    .widget
    .wp-block-latest-posts__list
    .wp-block-latest-posts__post-excerpt {
    font-weight: 600;
    margin-bottom: 0;
    color: var(--eduact-text, #697585);
    font-size: 16px;
    line-height: 32px;
}
.sidebar .widget .wp-block-latest-posts__list li + li {
    margin-top: 15px;
}
.sidebar .widget .wp-block-latest-posts__list li > a {
    color: var(--eduact-black, #2a254d) !important;
    font-weight: 400;
}
.sidebar
    .widget
    .wp-block-latest-posts__list
    .wp-block-latest-posts__post-author {
    margin-right: 5px;
}
.sidebar
    .widget
    .wp-block-latest-posts__list
    .wp-block-latest-posts__post-author::before {
    color: var(--eduact-secondary, #f57005);
    font-weight: 400;
    font-family: 'Font Awesome 5 Free';
    content: '\f2bd';
    margin-right: 5px;
}
.sidebar
    .widget
    .wp-block-latest-posts__list
    .wp-block-latest-posts__post-date::before {
    content: '\f017';
    margin-right: 5px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    color: var(--eduact-secondary, #f57005);
}
.sidebar
    .widget
    .wp-block-latest-posts__list
    .wp-block-latest-posts__post-author,
.sidebar
    .widget
    .wp-block-latest-posts__list
    .wp-block-latest-posts__post-date {
    font-weight: 600;
    font-size: 14px;
    color: var(--eduact-text, #697585);
}
.sidebar .widget .wp-block-page-list {
    margin-top: -10px;
    margin-bottom: -10px;
}
.sidebar .widget .wp-block-page-list li + li {
    margin-top: 15px;
}
.sidebar .widget .wp-block-rss__item {
    margin-bottom: -10px;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
}
.sidebar .widget .wp-block-rss__item::before {
    display: none;
}
.sidebar .widget .wp-block-rss__item a::after {
    display: none;
}
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-title,
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-excerpt {
    flex: 0 0 100%;
}
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-excerpt {
    font-weight: 600;
    margin-bottom: 0;
    color: var(--eduact-text, #697585);
    line-height: 2;
}
.sidebar .widget .wp-block-rss__item li + li {
    margin-top: 15px;
}
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-title {
    color: var(--eduact-black, #2a254d);
    font-weight: 600;
}
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-author {
    margin-right: 5px;
}
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-author {
    margin-left: 10px;
}
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-author::before {
    color: var(--eduact-secondary, #f57005);
    font-weight: 400;
    font-family: 'Font Awesome 5 Free';
    content: '\f2bd';
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.sidebar .widget .wp-block-rss__item .wp-block-rss__item-publish-date::before {
    content: '\f017';
    margin-right: 5px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    color: var(--eduact-secondary, #f57005);
}
.sidebar .widget .wp-block-rss__item-author,
.sidebar .widget .wp-block-rss__item-publish-date,
.sidebar .widget .wp-block-archives-dropdown label {
    font-weight: 600;
}
.sidebar .widget .wp-block-rss__item-author,
.sidebar .widget .wp-block-rss__item-publish-date {
    font-size: 14px;
    color: var(--eduact-gray, 117, 120, 115);
    margin-top: 5px;
    margin-bottom: 5px;
}
.sidebar
    .widget
    .has-avatars
    .wp-block-latest-comments__comment
    .wp-block-latest-comments__comment-excerpt,
.sidebar
    .widget
    .has-avatars
    .wp-block-latest-comments__comment
    .wp-block-latest-comments__comment-meta {
    margin-left: 60px;
}
.sidebar .widget #loginform {
    margin-top: -10px;
}
.sidebar .widget #loginform input[type='text'],
.sidebar .widget #loginform input[type='password'] {
    margin-bottom: 10px;
}
.sidebar .widget #loginform p {
    margin: 0;
}
.sidebar .widget #loginform input[type='checkbox'] {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.sidebar .widget #loginform .login-submit .button {
    padding: 25px 39px;
    color: #fff;
    font-size: 16px;
    background-color: var(--eduact-black, #2a254d);
    text-transform: uppercase;
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
    transition: all 500ms ease;
    border: none;
    border-radius: 0px;
}
.sidebar .widget #loginform .login-submit .button:hover {
    color: #fff;
    background-color: var(--eduact-base, #4f5de4);
}
.sidebar
    .widget
    .wp-block-latest-comments:not(.has-dates):not(.has-author)
    .wp-block-latest-comments__comment-meta {
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
    font-size: 16px;
    text-transform: lowercase;
}
.sidebar
    .widget
    .wp-block-latest-comments:not(.has-dates):not(.has-author)
    .wp-block-latest-comments__comment-meta
    a {
    color: var(--eduact-black, #161617);
}
.sidebar
    .widget
    .wp-block-latest-comments:not(.has-dates):not(.has-author)
    .wp-block-latest-comments__comment-meta
    a:hover {
    color: var(--eduact-base, #4f5de4);
}
.sidebar
    .widget
    .wp-block-latest-comments:not(.has-dates):not(.has-author)
    .wp-block-latest-comments__comment-meta:first-letter {
    text-transform: uppercase;
}

.widget.widget_block h2,
.widget .sidebar__title,
.sidebar .widget .title h2 {
    position: relative;
    font-size: 24px;
    line-height: 26px;
    text-transform: capitalize;
    border-bottom: 1px dashed var(--eduact-secondary);
    margin: 0 0 30px;
    padding-bottom: 25px;
}

h2 .rss-widget-title {
    color: var(--eduact-black);
}

.sidebar .widget ul.wp-block-social-links li a::after {
    content: '';
}

.sidebar .widget.widget_categories > ul,
.sidebar .widget.widget_pages > ul,
.sidebar .widget.widget_meta > ul,
.sidebar .widget.widget_recent_comments > ul,
.sidebar .widget.widget_archive > ul {
    margin-bottom: -10px;
}

.sidebar .widget ::placeholder {
    opacity: 1;
    color: var(--eduact-text, #697585);
}

.sidebar .widget .wp-block-latest-posts__list.has-dates li a,
.sidebar .widget .wp-block-rss li a {
    color: var(--eduact-black, #2a254d);
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
}

.wp-block-latest-posts__post-excerpt a {
    display: block;
}

.sidebar
    .widget
    .wp-block-latest-comments
    .wp-block-latest-comments__comment-meta {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
}

.widget.widget_archive ul li a::after,
.sidebar .widget ul.wp-block-categories li a::after,
.sidebar .widget.widget_categories ul li a::after {
    content: '';
}

.shop-one__sidebar.sidebar .widget ul.wp-block-categories li a::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f105';
    font-weight: 900;
}

.widget.widget_archive ul li,
.sidebar .widget .wp-block-categories li,
.sidebar .widget_categories ul li,
.sidebar__single .widget_categories ul li,
.sidebar__single .wp-block-categories li {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sidebar .widget .wp-block-categories li ul.children,
.sidebar .widget_categories ul li ul.children,
.sidebar__single .widget_categories ul li ul.children,
.sidebar__single .wp-block-categories li ul.children {
    flex: 0 0 100%;
}

.sidebar
    .widget
    .wp-block-search.wp-block-search__button-inside
    .wp-block-search__inside-wrapper {
    border-radius: 0;
    border-color: #fff;
    padding: 0;
}

.sidebar .widget ul li a:hover,
.sidebar__single ul li a:hover,
.sidebar .widget.widget_rss li .rsswidget:hover,
.sidebar .widget .wp-block-latest-posts__list.has-dates li a:hover {
    color: var(--eduact-secondary, #f57005) !important;
}

.sidebar .widget .wp-block-tag-cloud a:last-child {
    margin-bottom: 5px;
}

.sidebar .widget.widget_categories ul li {
    margin-bottom: 0px;
}

/* Media
  --------------------------------------------- */
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
    border: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
    display: inline-block;
}

/* Captions
  --------------------------------------------- */
.wp-caption {
    margin-bottom: 1.5em;
    max-width: 100%;
}
.wp-caption img[class*='wp-image-'] {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.wp-caption .wp-caption-text {
    margin: 0.8075em 0;
}

.wp-caption-text {
    text-align: center;
}

/* Galleries
  --------------------------------------------- */
.gallery {
    margin-bottom: 1.5em;
    display: grid;
    grid-gap: 1.5em;
}

.gallery-item {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.gallery-columns-2 {
    grid-template-columns: repeat(2, 1fr);
}

.gallery-columns-3 {
    grid-template-columns: repeat(3, 1fr);
}

.gallery-columns-4 {
    grid-template-columns: repeat(4, 1fr);
}

.gallery-columns-5 {
    grid-template-columns: repeat(5, 1fr);
}

.gallery-columns-6 {
    grid-template-columns: repeat(6, 1fr);
}

.gallery-columns-7 {
    grid-template-columns: repeat(7, 1fr);
}

.gallery-columns-8 {
    grid-template-columns: repeat(8, 1fr);
}

.gallery-columns-9 {
    grid-template-columns: repeat(9, 1fr);
}

.gallery-caption {
    display: block;
}

/* Accessibility
  --------------------------------------------- */
/* Text meant only for screen readers. */
.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}
.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    clip-path: none;
    color: var(--eduact-grey, #b7b0b4);
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
}

/* Do not show the outline on the skip link target. */
#primary[tabindex='-1']:focus {
    outline: 0;
}

/* Alignments
  --------------------------------------------- */
.alignleft {
    /*rtl:ignore*/
    float: left;
    /*rtl:ignore*/
    margin-right: 1.5em;
    margin-bottom: 1.5em;
}

.alignright {
    /*rtl:ignore*/
    float: right;
    /*rtl:ignore*/
    margin-left: 1.5em;
    margin-bottom: 1.5em;
}

.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5em;
}

/* Custom Animations
  --------------------------------------------- */
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-moz-keyframes rotateme {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-o-keyframes rotateme {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
    }
}
@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}
@keyframes shine {
    100% {
        left: 125%;
    }
}
.rotate-me {
    animation-name: rotateme;
    animation-duration: 24s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 24s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme;
    -moz-animation-duration: 24s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme;
    -ms-animation-duration: 24s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme;
    -o-animation-duration: 24s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes float-bob {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
}
@keyframes float-bob {
    0% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
    50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
}
.float-bob {
    animation-name: float-bob;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: float-bob;
    -webkit-animation-duration: 7s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: float-bob;
    -moz-animation-duration: 7s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: float-bob;
    -ms-animation-duration: 7s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: float-bob;
    -o-animation-duration: 7s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

/***
  =============================================
      circle Css
  =============================================
  ***/
@-webkit-keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}
@keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}
/***
  =============================================
      Zoom Fade Css
  =============================================
  ***/
.zoom-fade {
    animation-name: zoom-fade;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: zoom-fade;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: zoom-fade;
    -moz-animation-duration: 5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: zoom-fade;
    -ms-animation-duration: 5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: zoom-fade;
    -o-animation-duration: 5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}
@keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}
/***
  =============================================
      Zoom In Out Css
  =============================================
  ***/
.zoominout {
    animation-name: zoomInOut;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    -webkit-animation-name: zoomInOut;
    -webkit-animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-name: zoomInOut;
    -moz-animation-duration: 3s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -ms-animation-name: zoomInOut;
    -ms-animation-duration: 3s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: ease-in-out;
    -o-animation-name: zoomInOut;
    -o-animation-duration: 3s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: ease-in-out;
}

@keyframes zoomInOut {
    0% {
        -webkit-transform: rotate(0deg) scale(0.7);
        transform: rotate(0deg) scale(0.7);
        opacity: 0;
    }
    50% {
        -webkit-transform: rotate(180deg) scale(1);
        transform: rotate(180deg) scale(1);
        opacity: 1;
    }
    100% {
        -webkit-transform: rotate(360deg) scale(0.7);
        transform: rotate(360deg) scale(0.7);
        opacity: 0;
    }
}
@-webkit-keyframes bgSlideReverse {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: 100px 0;
    }
    40% {
        background-position: 200px 0;
    }
    60% {
        background-position: 150px 0;
    }
    80% {
        background-position: 100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@keyframes bgSlideReverse {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: 100px 0;
    }
    40% {
        background-position: 200px 0;
    }
    60% {
        background-position: 150px 0;
    }
    80% {
        background-position: 100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@-webkit-keyframes bgSlideReverse2 {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: -100px 0;
    }
    40% {
        background-position: -200px 0;
    }
    60% {
        background-position: -150px 0;
    }
    80% {
        background-position: -100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@keyframes bgSlideReverse2 {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: -100px 0;
    }
    40% {
        background-position: -200px 0;
    }
    60% {
        background-position: -150px 0;
    }
    80% {
        background-position: -100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@-webkit-keyframes banner-animate {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
    }
    50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
    }
}
@keyframes banner-animate {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
    }
    50% {
        -webkit-transform: translateX(-10px);
        transform: translateX(-10px);
    }
    100% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
    }
}
.banner-animate {
    animation-name: banner-animate;
    animation-duration: 70s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: banner-animate;
    -webkit-animation-duration: 70s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: banner-animate;
    -moz-animation-duration: 70s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: banner-animate;
    -ms-animation-duration: 70s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: banner-animate;
    -o-animation-duration: 70s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes ripple {
    70% {
        -webkit-box-shadow: 0 0 0 20px 0 0 20px 0 #fff;
        box-shadow: 0 0 0 20px 0 0 20px 0 #fff;
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 #fff;
        box-shadow: 0 0 0 0 #fff;
    }
}
@keyframes ripple {
    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
        box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.2);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
/***
  =============================================
      Animation1 Css
  =============================================
  ***/
@-webkit-keyframes animation1 {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(5deg) translateX(15px);
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        -webkit-transform: rotate(10deg) translateX(30px);
        transform: rotate(10deg) translateX(30px);
    }
}
@keyframes animation1 {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(5deg) translateX(15px);
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        -webkit-transform: rotate(10deg) translateX(30px);
        transform: rotate(10deg) translateX(30px);
    }
}
/***
  =============================================
      Animation2 Css
  =============================================
  ***/
@keyframes animation2 {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@-moz-keyframes animation2 {
    0% {
        -moz-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -moz-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@-o-keyframes animation2 {
    0% {
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@keyframes animation2 {
    0% {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@-webkit-keyframes bgSlide {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: -100px 0;
    }
    40% {
        background-position: -200px 0;
    }
    60% {
        background-position: -150px 0;
    }
    80% {
        background-position: -100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@keyframes bgSlide {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: -100px 0;
    }
    40% {
        background-position: -200px 0;
    }
    60% {
        background-position: -150px 0;
    }
    80% {
        background-position: -100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@-webkit-keyframes bgSlideReverse {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: 100px 0;
    }
    40% {
        background-position: 200px 0;
    }
    60% {
        background-position: 150px 0;
    }
    80% {
        background-position: 100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@keyframes bgSlideReverse {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: 100px 0;
    }
    40% {
        background-position: 200px 0;
    }
    60% {
        background-position: 150px 0;
    }
    80% {
        background-position: 100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@-webkit-keyframes ribbonRotate {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    50% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
}
@keyframes ribbonRotate {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }
    50% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
}
@-webkit-keyframes footerFish {
    0%,
    100% {
        -webkit-transform: translateX(0) translateY(0) rotate(0);
        transform: translateX(0) translateY(0) rotate(0);
    }
    25%,
    75% {
        -webkit-transform: translateX(20px) translateY(30px) rotate(25deg);
        transform: translateX(20px) translateY(30px) rotate(25deg);
    }
    50% {
        -webkit-transform: translateX(40px) translateY(60px) rotate(45deg);
        transform: translateX(40px) translateY(60px) rotate(45deg);
    }
}
@keyframes footerFish {
    0%,
    100% {
        -webkit-transform: translateX(0) translateY(0) rotate(0);
        transform: translateX(0) translateY(0) rotate(0);
    }
    25%,
    75% {
        -webkit-transform: translateX(20px) translateY(30px) rotate(25deg);
        transform: translateX(20px) translateY(30px) rotate(25deg);
    }
    50% {
        -webkit-transform: translateX(40px) translateY(60px) rotate(45deg);
        transform: translateX(40px) translateY(60px) rotate(45deg);
    }
}
@-webkit-keyframes contactSwimmer {
    0%,
    100% {
        -webkit-transform: translateX(0) translateY(0) rotate(0);
        transform: translateX(0) translateY(0) rotate(0);
    }
    25%,
    75% {
        -webkit-transform: translateX(15px) translateY(20px) rotate(10deg);
        transform: translateX(15px) translateY(20px) rotate(10deg);
    }
    50% {
        -webkit-transform: translateX(60px) translateY(35px) rotate(15deg);
        transform: translateX(60px) translateY(35px) rotate(15deg);
    }
}
@keyframes contactSwimmer {
    0%,
    100% {
        -webkit-transform: translateX(0) translateY(0) rotate(0);
        transform: translateX(0) translateY(0) rotate(0);
    }
    25%,
    75% {
        -webkit-transform: translateX(15px) translateY(20px) rotate(10deg);
        transform: translateX(15px) translateY(20px) rotate(10deg);
    }
    50% {
        -webkit-transform: translateX(60px) translateY(35px) rotate(15deg);
        transform: translateX(60px) translateY(35px) rotate(15deg);
    }
}
/***
  =============================================
      Float Bob Y Animation Css
  =============================================
  ***/
@-webkit-keyframes float-bob-y {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(-20px);
    }
}
@keyframes float-bob-y {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.float-bob-y {
    -webkit-animation-name: float-bob-y;
    animation-name: float-bob-y;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes footerTree {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(5deg) translateX(15px);
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        -webkit-transform: rotate(10deg) translateX(30px);
        transform: rotate(10deg) translateX(30px);
    }
}
@keyframes footerTree {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(5deg) translateX(15px);
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        -webkit-transform: rotate(10deg) translateX(30px);
        transform: rotate(10deg) translateX(30px);
    }
}
.footertree {
    -webkit-animation: footerTree 5s ease-in infinite;
    animation: footerTree 5s ease-in infinite;
}

@keyframes service_hexagon_2 {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@-moz-keyframes service_hexagon_2 {
    0% {
        -moz-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -moz-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@-o-keyframes service_hexagon_2 {
    0% {
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@keyframes service_hexagon_2 {
    0% {
        -webkit-transform: rotateY(0deg);
        -moz-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(360deg);
        -moz-transform: rotateY(360deg);
        -o-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}
@keyframes bgSlide {
    0% {
        background-position: 0 0;
    }
    20% {
        background-position: -100px 0;
    }
    40% {
        background-position: -200px 0;
    }
    60% {
        background-position: -150px 0;
    }
    80% {
        background-position: -100px 0;
    }
    100% {
        background-position: 0px 0;
    }
}
@keyframes rotateScale {
    from {
        transform: rotate(0deg) scale(1);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rotateScale {
    from {
        transform: rotate(0deg) scale(1);
    }
    to {
        transform: rotate(360deg);
    }
}
@-moz-keyframes rotateScale {
    from {
        transform: rotate(0deg) scale(1);
    }
    to {
        transform: rotate(360deg);
    }
}
@-o-keyframes rotateScale {
    from {
        transform: rotate(0deg) scale(1);
    }
    to {
        transform: rotate(360deg);
    }
}
.rotatescale {
    -webkit-animation-name: rotateScale;
    -webkit-animation-duration: 20s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateScale;
    -moz-animation-duration: 20s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateScale;
    -ms-animation-duration: 20s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    animation-name: rotateScale;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

/*--------------------------------------------------------------
  # Animations
  --------------------------------------------------------------*/
@-webkit-keyframes bubbleMover {
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }
    30% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: center center;
    }
    50% {
        transform: translateY(50px) translateX(100px) rotate(45deg);
        transform-origin: right bottom;
    }
    80% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: left top;
    }
    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
}
@keyframes bubbleMover {
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }
    30% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: center center;
    }
    50% {
        transform: translateY(50px) translateX(100px) rotate(45deg);
        transform-origin: right bottom;
    }
    80% {
        transform: translateY(30px) translateX(50px) rotate(15deg);
        transform-origin: left top;
    }
    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
}
@-webkit-keyframes shapeMover {
    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px)
            translateX(0);
    }
    50% {
        transform: perspective(400px) rotate(0deg) translateZ(20px)
            translateY(20px) translateX(20px);
    }
}
@keyframes shapeMover {
    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px)
            translateX(0);
    }
    50% {
        transform: perspective(400px) rotate(0deg) translateZ(20px)
            translateY(20px) translateX(20px);
    }
}
@-webkit-keyframes banner3Shake {
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }
    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }
    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
}
@keyframes banner3Shake {
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }
    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }
    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }
    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
}
@-webkit-keyframes squareMover {
    0%,
    100% {
        transform: translate(0, 0) rotate(0);
    }
    20%,
    60% {
        transform: translate(20px, 40px) rotate(180deg);
    }
    30%,
    80% {
        transform: translate(40px, 60px) rotate(0deg);
    }
}
@keyframes squareMover {
    0%,
    100% {
        transform: translate(0, 0) rotate(0);
    }
    20%,
    60% {
        transform: translate(20px, 40px) rotate(180deg);
    }
    30%,
    80% {
        transform: translate(40px, 60px) rotate(0deg);
    }
}
@-webkit-keyframes treeMove {
    0%,
    100% {
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        transform: rotate(10deg) translateX(30px);
    }
}
@keyframes treeMove {
    0%,
    100% {
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        transform: rotate(10deg) translateX(30px);
    }
}
/*--------------------------------------------------------------
  #     animate BubbleCss
  --------------------------------------------------------------*/
@-webkit-keyframes animateBubble {
    0% {
        margin-top: 20%;
    }
    100% {
        margin-top: -30%;
    }
}
@-moz-keyframes animateBubble {
    0% {
        margin-top: 20%;
    }
    100% {
        margin-top: -30%;
    }
}
@keyframes animateBubble {
    0% {
        margin-top: 20%;
    }
    100% {
        margin-top: -30%;
    }
}
@-webkit-keyframes sideWays {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: 25px;
    }
}
@-moz-keyframes sideWays {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: 25px;
    }
}
@keyframes sideWays {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: 25px;
    }
}
/*--------------------------------------------------------------
  #     Zoom Fade Css
  --------------------------------------------------------------*/
.zoom-fade {
    animation-name: zoom-fade;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: zoom-fade;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: zoom-fade;
    -moz-animation-duration: 5s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: zoom-fade;
    -ms-animation-duration: 5s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: zoom-fade;
    -o-animation-duration: 5s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}
@keyframes zoom-fade {
    0% {
        transform: scale(0.9);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.9);
    }
}
.zoomInDown {
    -webkit-animation-name: zoomInDown;
    animation-name: zoomInDown;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes sunMove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes sunMove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    75% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@-webkit-keyframes planeMove {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    25% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }
    75% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
@keyframes planeMove {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    25% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }
    75% {
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
@-webkit-keyframes bridMove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    50% {
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
    }
    75% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes bridMove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    50% {
        -webkit-transform: rotate(20deg);
        transform: rotate(20deg);
    }
    75% {
        -webkit-transform: rotate(10deg);
        transform: rotate(10deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
/** squares **/
@keyframes squares {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
    20% {
        -webkit-transform: scale(1.24);
        transform: scale(1.24);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(2.1);
        transform: scale(2.1);
        opacity: 0;
    }
}
@-moz-keyframes squares {
    0% {
        -moz-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
    20% {
        -moz-transform: scale(1.24);
        transform: scale(1.24);
        opacity: 1;
    }
    100% {
        -moz-transform: scale(2.1);
        transform: scale(2.1);
        opacity: 0;
    }
}
@-o-keyframes squares {
    0% {
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
    20% {
        -o-transform: scale(1.24);
        transform: scale(1.24);
        opacity: 1;
    }
    100% {
        -o-transform: scale(2.1);
        transform: scale(2.1);
        opacity: 0;
    }
}
@keyframes squares {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
    20% {
        -webkit-transform: scale(1.24);
        -moz-transform: scale(1.24);
        -o-transform: scale(1.24);
        transform: scale(1.24);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(2.1);
        -moz-transform: scale(2.1);
        -o-transform: scale(2.1);
        transform: scale(2.1);
        opacity: 0;
    }
}
/* Templates
  --------------------------------------------- */
/*--------------------------------------------------------------
  # Variables
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  # Utility
  --------------------------------------------------------------*/
.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-60 {
    margin-top: 60px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-120 {
    margin-top: 120px;
}

.mt--60 {
    margin-top: -60px;
}

.mt--120 {
    margin-top: -120px;
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-80 {
    margin-bottom: 80px;
}

.mb-120 {
    margin-bottom: 120px;
}

.mb--60 {
    margin-bottom: -60px;
}

.mb--120 {
    margin-bottom: -120px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-40 {
    padding-top: 40px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-80 {
    padding-top: 80px;
}

.pt-110 {
    padding-top: 110px;
}

.pt-115 {
    padding-top: 115px;
}

.pt-120 {
    padding-top: 120px;
}

.pt-142 {
    padding-top: 142px;
}

.pb-20 {
    padding-bottom: 20px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pb-50 {
    padding-bottom: 50px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pb-90 {
    padding-bottom: 90px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-115 {
    padding-bottom: 115px;
}

.pb-120 {
    padding-bottom: 120px;
}

.pl-5 {
    padding-left: 5px;
}

.pl-10 {
    padding-left: 10px;
}

.pl-15 {
    padding-left: 15px;
}

.pl-20 {
    padding-left: 20px;
}

.pl-30 {
    padding-left: 30px;
}

.pr-5 {
    padding-right: 5px;
}

.pr-10 {
    padding-right: 10px;
}

.pr-15 {
    padding-right: 15px;
}

.pr-20 {
    padding-right: 20px;
}

.pr-30 {
    padding-right: 30px;
}

/*--------------------------------------------------------------
  # Cards
  --------------------------------------------------------------*/
.video-one {
    position: relative;
}

.video-one__bg {
    position: relative;
    border-radius: 0 0 14px 14px;
    overflow: hidden;
    padding: 74px 110px 80px 113px;
}

@media (max-width: 1199px) {
    .video-one__bg {
        padding: 74px 90px 80px 55px;
    }
}
@media (max-width: 991px) {
    .video-one__bg {
        padding: 74px 30px 80px 30px;
    }
}
@media (max-width: 767px) {
    .video-one__bg {
        padding: 60px 30px;
    }
}
.video-one__bg::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(36, 36, 36, 0.87);
}

.video-one .row {
    position: relative;
    z-index: 2;
}

.video-one__shape {
    position: absolute;
    right: 113px;
    top: 0;
    width: 485px;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    -webkit-animation: blinker 3s infinite linear;
    animation: blinker 3s infinite linear;
}

@media (max-width: 1399px) {
    .video-one__shape {
        right: 20px;
    }
}
@media (max-width: 991px) {
    .video-one__shape {
        display: none;
    }
}
.video-one__shape2 {
    position: absolute;
    right: 27px;
    left: 0;
    margin: auto;
    top: 0;
    width: 331px;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    -webkit-animation: movebounce2 2s linear infinite;
    animation: movebounce2 2s linear infinite;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .video-one__shape2 {
        right: 250px;
    }
}
@media (max-width: 767px) {
    .video-one__shape2 {
        display: none;
    }
}
.video-one__title {
    color: var(--eduact-white);
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 34px;
}

@media (min-width: 1400px) {
    .video-one__title {
        margin-right: -15px;
    }
}
@media (max-width: 991px) {
    .video-one__title {
        font-size: 35px;
    }
}
.video-one__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 165px;
    height: 167px;
    border-radius: 50%;
    background-color: var(--eduact-secondary);
    padding: 15px;
    margin: auto;
    left: 20px;
    animation: shadows 1s linear infinite;
    -moz-animation: shadows 1s linear infinite;
    -webkit-animation: shadows 1s linear infinite;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .video-one__btn {
        left: 65px;
    }
}
@media (max-width: 767px) {
    .video-one__btn {
        margin: 50px 0 0;
    }
}
.video-one__btn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.video-one__btn .video-popup {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    width: 53px;
    height: 53px;
    background-color: var(--eduact-white);
    border-radius: 50%;
    font-size: 13px;
    color: var(--eduact-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-one__btn .video-popup:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.video-two {
    position: relative;
    padding: 74px 0;
}

.video-two__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
}

.video-two__bg::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: rgba(54, 48, 93, 0.8);
}

.video-two__shape {
    position: absolute;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right center;
    width: 100%;
    height: 100%;
}

@media (max-width: 1199px) {
    .video-two__shape {
        display: none;
    }
}
@media (max-width: 1499px) {
    .video-two__shape {
        right: -200px;
    }
}
.video-two__title {
    color: var(--eduact-white);
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 34px;
}

@media (max-width: 991px) {
    .video-two__title {
        font-size: 35px;
    }
    .video-two__title br {
        display: none;
    }
}
.video-two__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 256px;
    height: 256px;
    border-radius: 50%;
    background-color: rgba(var(--eduact-white-rgb), 0.1);
    margin-left: auto;
    z-index: 2;
    animation: shadows2 1s linear infinite;
    -moz-animation: shadows2 1s linear infinite;
    -webkit-animation: shadows2 1s linear infinite;
}

@media (max-width: 767px) {
    .video-two__btn {
        margin: 40px 0 0;
    }
}
.video-two__btn .video-popup {
    font-size: 55px;
    color: var(--eduact-secondary);
    position: relative;
    display: block;
    left: 9px;
}

.video-two__btn .video-popup:hover {
    color: var(--eduact-base);
}

.team-one {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: var(--eduact-soft);
    padding: 117px 0 85px;
}

@media (max-width: 767px) {
    .team-one {
        padding: 77px 0 45px;
    }
}
.team-one .section-title__tagline svg {
    width: 170px;
}

.team-one .section-title__tagline svg path {
    fill: var(--eduact-white);
}

.team-one__item {
    position: relative;
    padding: 17px 0 0 13px;
    margin: 0 0 36px;
}

.team-one__item::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 170px;
    height: 199px;
    border-radius: 20px;
    content: '';
    background-color: var(--eduact-base);
    -webkit-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.team-one__item:hover::before {
    background-color: var(--eduact-secondary);
}

.team-one__item:hover .team-one__image::after {
    visibility: visible;
    opacity: 1;
}

.team-one__item:hover .team-one__title {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.team-one__item:hover .team-one__designation {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.team-one__item:hover .team-one__social a {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.team-one__image {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.team-one__image::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(var(--eduact-black-rgb), 0.83);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.team-one__image img {
    width: 100%;
    height: auto;
}

.team-one__content {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.team-one__title {
    font-size: 32px;
    color: var(--eduact-white);
    line-height: 1;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
    transform: translate3d(-100px, 0, 0);
    -moz-transform: translate3d(-100px, 0, 0);
    -webkit-transform: translate3d(-100px, 0, 0);
}

.team-one__title a {
    color: inherit;
}

.team-one__title a:hover {
    color: var(--eduact-base);
}

.team-one__designation {
    font-size: 20px;
    color: var(--eduact-white);
    line-height: 1;
    display: block;
    margin: 5px 0 22px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
    transform: translate3d(100px, 0, 0);
    -moz-transform: translate3d(100px, 0, 0);
    -webkit-transform: translate3d(100px, 0, 0);
}

.team-one__social {
    position: relative;
}

.team-one__social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--eduact-base);
    color: var(--eduact-white);
    font-size: 14px;
    text-align: center;
    line-height: 29px;
    margin: 0 4px;
    visibility: hidden;
    opacity: 0;
}

.team-one__social a:hover {
    background-color: var(--eduact-base);
}

.team-one__social a:nth-child(1) {
    -webkit-transition: all 600ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 600ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 600ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-one__social a:nth-child(2) {
    -webkit-transition: all 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-one__social a:nth-child(3) {
    -webkit-transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-one__social a:nth-child(4) {
    -webkit-transition: all 900ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 900ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 900ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-two {
    position: relative;
    background-position: bottom center;
    background-repeat: no-repeat;
    padding: 115px 0 85px;
}

@media (max-width: 767px) {
    .team-two {
        padding: 75px 0 45px;
    }
}
.team-two--about {
    border-top: 1px solid var(--eduact-soft4);
    margin-bottom: 120px;
}

@media (max-width: 767px) {
    .team-two--about {
        margin-bottom: 80px;
    }
}
.team-two__item {
    position: relative;
    padding: 0 0 35px;
    margin-bottom: 36px;
    z-index: 2;
}

@media (max-width: 1199px) {
    .team-two__item {
        padding-bottom: 28px;
    }
}
.team-two__item::before {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--eduact-secondary);
    content: '';
    border-radius: 0 0 200px 200px;
    width: 100%;
    height: 309px;
    z-index: -1;
}

.team-two__item::after {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: var(--eduact-base);
    content: '';
    border-radius: 0 0 200px 200px;
    width: 100%;
    height: 200px;
    z-index: -1;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s linear;
    -o-transition: all 0.4s linear;
    transition: all 0.4s linear;
}

.team-two__item:hover::after {
    height: 309px;
    visibility: visible;
    opacity: 1;
}

.team-two__item:hover .team-two__image::after {
    visibility: visible;
    opacity: 1;
}

.team-two__item:hover .team-two__title {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.team-two__item:hover .team-two__designation {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.team-two__item:hover .team-two__social a {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
}

.team-two__image {
    position: relative;
    overflow: hidden;
    border-radius: 200px;
    margin: 0 31.5px;
}

@media (max-width: 1199px) {
    .team-two__image {
        margin: 0 25.5px;
    }
}
.team-two__image::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(24, 24, 24, 0.69);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.team-two__image img {
    width: 100%;
    height: auto;
}

.team-two__content {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.team-two__title {
    font-size: 32px;
    color: var(--eduact-white);
    line-height: 1;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
    transform: translate3d(-100px, 0, 0);
    -moz-transform: translate3d(-100px, 0, 0);
    -webkit-transform: translate3d(-100px, 0, 0);
}

.team-two__title a {
    color: inherit;
}

.team-two__title a:hover {
    color: var(--eduact-base);
}

.team-two__designation {
    font-size: 20px;
    color: var(--eduact-white);
    line-height: 1;
    display: block;
    margin: 5px 0 22px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
    transform: translate3d(100px, 0, 0);
    -moz-transform: translate3d(100px, 0, 0);
    -webkit-transform: translate3d(100px, 0, 0);
}

.team-two__social {
    position: relative;
}

.team-two__social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--eduact-base);
    color: var(--eduact-white);
    font-size: 14px;
    text-align: center;
    line-height: 29px;
    margin: 0 4px;
    visibility: hidden;
    opacity: 0;
}

.team-two__social a:hover {
    background-color: var(--eduact-base);
}

.team-two__social a:nth-child(1) {
    -webkit-transition: all 600ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 600ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 600ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-two__social a:nth-child(2) {
    -webkit-transition: all 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 700ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-two__social a:nth-child(3) {
    -webkit-transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 800ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-two__social a:nth-child(4) {
    -webkit-transition: all 900ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -moz-transition: all 900ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transition: all 900ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    transform: translate3d(0, 70px, 0);
    -moz-transform: translate3d(0, 70px, 0);
    -webkit-transform: translate3d(0, 70px, 0);
}

.team-three {
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 116px 0 85px;
}

@media (max-width: 767px) {
    .team-three {
        padding: 76px 0 45px;
    }
}
.team-three__item {
    position: relative;
    margin-bottom: 36px;
    z-index: 2;
}

.team-three__item:hover .team-three__image::after {
    visibility: visible;
    opacity: 1;
}

.team-three__item:hover svg {
    height: 318px;
    visibility: visible;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .team-three__item:hover svg {
        height: 252px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .team-three__item:hover svg {
        height: 285px;
    }
}
.team-three__item:hover .team-three__rm {
    visibility: visible;
    opacity: 1;
    animation-delay: 0.8s;
    animation-name: fadeInDown;
}

.team-three__item:hover .team-three__title {
    visibility: visible;
    opacity: 1;
    animation-delay: 0.5s;
    animation-name: fadeInUp;
}

.team-three__item:hover .team-three__designation {
    visibility: visible;
    opacity: 1;
    animation-delay: 0.6s;
    animation-name: fadeInUp;
}

.team-three__item:hover .team-three__social a:nth-child(1) {
    visibility: visible;
    opacity: 1;
    animation-delay: 0.7s;
    animation-name: fadeInUp;
}

.team-three__item:hover .team-three__social a:nth-child(2) {
    visibility: visible;
    opacity: 1;
    animation-delay: 0.75s;
    animation-name: fadeInUp;
}

.team-three__item:hover .team-three__social a:nth-child(3) {
    visibility: visible;
    opacity: 1;
    animation-delay: 0.8s;
    animation-name: fadeInUp;
}

.team-three__item:hover .team-three__social a:nth-child(4) {
    visibility: visible;
    opacity: 1;
    animation-delay: 0.85s;
    animation-name: fadeInUp;
}

.team-three__image {
    position: relative;
    overflow: hidden;
    border-radius: 200px;
    margin: 0;
}

.team-three__image::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(var(--eduact-black2-rgb), 0.2);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.team-three__image img {
    width: 100%;
    height: auto;
}

.team-three svg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 53px;
    fill: rgba(var(--eduact-secondary-rgb), 0.91);
    width: 314px;
    height: 0;
    margin: auto;
    visibility: hidden;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    transition: all ease 0.5s;
}

@media (max-width: 1399px) {
    .team-three svg {
        bottom: 35px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .team-three svg {
        bottom: 25px;
        width: 250px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .team-three svg {
        width: 280px;
    }
}
.team-three__content {
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    text-align: center;
    z-index: 3;
    width: 314px;
    bottom: 128px;
}

@media (max-width: 1399px) {
    .team-three__content {
        bottom: 90px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .team-three__content {
        bottom: 55px;
        width: 100%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .team-three__content {
        bottom: 75px;
    }
}
.team-three__rm {
    position: relative;
    margin: 0 33px 27px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--eduact-soft2);
    font-size: 18px;
    color: var(--eduact-secondary);
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .team-three__rm {
        margin: 0 45px 20px auto;
    }
}
.team-three__rm span {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.team-three__rm:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.team-three__rm:hover span {
    animation: iconTranslateX 0.4s forwards;
}

.team-three__title {
    font-size: 32px;
    color: var(--eduact-white);
    line-height: 1;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .team-three__title {
        font-size: 27px;
    }
}
.team-three__title a {
    color: inherit;
}

.team-three__title a:hover {
    color: var(--eduact-base);
}

.team-three__designation {
    font-size: 20px;
    color: var(--eduact-white);
    line-height: 1;
    display: block;
    margin: 6px 0 23px;
    visibility: hidden;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .team-three__designation {
        margin: 6px 0 18px;
    }
}
.team-three__social {
    position: relative;
}

.team-three__social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    background-color: rgba(var(--eduact-white-rgb), 0.2);
    color: var(--eduact-white);
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    margin: 0 4px;
    visibility: hidden;
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.team-three__social a:hover {
    background-color: var(--eduact-base);
}

/*-- Team Page --*/
.team-page {
    position: relative;
    padding: 120px 0 70px;
}

@media (max-width: 767px) {
    .team-page {
        padding: 80px 0 50px;
    }
}
.team-page .team-two__item {
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .team-page .team-two__item {
        margin-bottom: 30px;
    }
}
.team-page__carousel {
    position: relative;
    padding-bottom: 50px;
}

@media (max-width: 767px) {
    .team-page__carousel {
        padding-bottom: 30px;
    }
}
/*-- Team Details --*/
.team-details {
    position: relative;
    padding: 120px 0;
    overflow: hidden;
    border-bottom: 2px solid var(--eduact-soft4);
}

@media (max-width: 767px) {
    .team-details {
        padding: 80px 0;
    }
}
.team-details .col-lg-5 {
    flex: 0 0 auto;
    width: 47.3%;
}

@media (max-width: 991px) {
    .team-details .col-lg-5 {
        width: 100%;
    }
}
.team-details .col-lg-7 {
    flex: 0 0 auto;
    width: 52.7%;
}

@media (max-width: 991px) {
    .team-details .col-lg-7 {
        width: 100%;
    }
}
.team-details__image {
    position: relative;
    z-index: 2;
    padding: 0 0;
}

@media (min-width: 1400px) {
    .team-details__image {
        padding-right: 40px;
    }
}
.team-details__image > img {
    width: 100%;
    height: auto;
    border-radius: 346px;
}

.team-details__image__bg-shape {
    position: absolute;
    left: -112px;
    top: -147px;
    z-index: -1;
}

.team-details__image__bg-shape img {
    max-width: 100%;
    -webkit-animation: movebounce2 2s linear infinite;
    animation: movebounce2 2s linear infinite;
}

.team-details__image__shape-bottom {
    position: absolute;
    left: -5px;
    bottom: 6px;
}

.team-details__image__shape-bottom img {
    max-width: 100%;
    -webkit-animation: rotated 15s infinite linear;
    animation: rotated 15s infinite linear;
}

.team-details__image__svg-shape {
    position: absolute;
    left: -160px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    max-height: 218px;
    width: 135px;
}

@media (max-width: 1300px) {
    .team-details__image__svg-shape {
        display: none;
    }
}
.team-details__image__svg-shape__one {
    width: 69px;
    height: 80px;
    fill: var(--eduact-base);
    display: block;
    margin-left: auto;
    margin-bottom: -21px;
    animation: tm-shape1 3s ease infinite;
}

@keyframes tm-shape1 {
    0% {
        fill: var(--eduact-base);
    }
    50% {
        fill: var(--eduact-secondary);
    }
    100% {
        fill: var(--eduact-base);
    }
}
.team-details__image__svg-shape__two {
    width: 135px;
    height: 157px;
    fill: var(--eduact-secondary);
    display: block;
    animation: tm-shape2 3s ease infinite;
}

@keyframes tm-shape2 {
    0% {
        fill: var(--eduact-secondary);
    }
    50% {
        fill: var(--eduact-base);
    }
    100% {
        fill: var(--eduact-secondary);
    }
}
.team-details__content {
    position: relative;
    padding: 36px 0 0 0;
}

@media (max-width: 1399px) {
    .team-details__content {
        padding: 0 0 0;
    }
}
@media (max-width: 991px) {
    .team-details__content {
        padding: 50px 0 0;
    }
}
.team-details__title {
    font-size: 32px;
    margin: 0 0 6px;
}

@media (max-width: 767px) {
    .team-details__title {
        font-size: 30px;
    }
}
.team-details__designation {
    display: block;
    color: var(--eduact-secondary);
    font-size: 20px;
    margin-bottom: 14px;
}

.team-details__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin-bottom: 29px;
}

.team-details__progress {
    position: relative;
    margin: 35px 0 40px;
}

.team-details__progress__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 17px;
}

.team-details__progress__bar {
    position: relative;
    width: 100%;
    height: 8px;
    display: block;
    border-radius: 16px;
    background-color: var(--eduact-soft4);
}

.team-details__progress__inner {
    position: relative;
    display: block;
    width: 0px;
    height: 8px;
    border-radius: 16px;
    background-color: var(--eduact-base);
    -webkit-transition: all 1500ms ease;
    -ms-transition: all 1500ms ease;
    -o-transition: all 1500ms ease;
    -moz-transition: all 1500ms ease;
    transition: all 1500ms ease;
}

.team-details__progress__number {
    position: absolute;
    right: -18px;
    bottom: 24px;
    line-height: 26px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    opacity: 0;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
}

.team-details__progress .counted .count-text {
    opacity: 1;
}

.team-details__social {
    position: relative;
    margin: 0 0 50px;
}

.team-details__social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: var(--eduact-soft5);
    border-radius: 50%;
    text-align: center;
    color: var(--eduact-black);
    font-size: 16px;
    line-height: 40px;
}

.team-details__social a:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.team-details__social a + a {
    margin-left: 7px;
}

.become-team {
    position: relative;
    padding: 120px 0 70px;
    overflow: hidden;
    border-bottom: 2px solid var(--eduact-soft4);
}

@media (max-width: 991px) {
    .become-team {
        padding-bottom: 120px;
    }
}
@media (max-width: 767px) {
    .become-team {
        padding: 80px 0;
    }
}
.become-team__content {
    position: relative;
    margin: -6px 0 0;
}

.become-team__title {
    font-size: 32px;
    margin: 0 0 11px;
}

@media (max-width: 767px) {
    .become-team__title {
        font-size: 30px;
    }
}
.become-team__list {
    margin: 0 0 41px;
    padding: 0;
    list-style: none;
}

.become-team__list li {
    list-style: none;
    position: relative;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.16px;
    padding-left: 22px;
    margin: 0 0 9px;
}

.become-team__list li::after {
    position: absolute;
    left: 0;
    top: 12px;
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--eduact-secondary);
    border-radius: 50%;
}

.become-team__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin-bottom: 41px;
}

@media (min-width: 1400px) {
    .become-team__text {
        padding-right: 10px;
    }
}
.become-team__form-box {
    position: relative;
    display: block;
    padding: 0;
    border-radius: 14px;
    background: var(--eduact-white);
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    padding: 30px;
}

.become-team__form-box__title {
    display: block;
    font-size: 24px;
    line-height: 26px;
    border-bottom: 1px dashed var(--eduact-secondary);
    padding-bottom: 25px;
    margin: 0 0 30px;
}

.become-team__input-box {
    position: relative;
    display: block;
    margin-bottom: 20px;
}

.become-team__input-box input[type='text'],
.become-team__input-box input[type='email'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    font-family: var(--eduact-font);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.become-team__input-box textarea {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    height: 154px;
    width: 100%;
    background-color: var(--eduact-soft5);
    font-family: var(--eduact-font);
    padding: 24px 30px 30px;
    border: none;
    outline: none;
    border-radius: 4px;
    margin-bottom: 0px;
}

.become-team__input-box.text-message-box {
    height: 154px;
    margin-bottom: 30px;
}

.become-team .eduact-btn {
    text-transform: inherit;
}

.blog-one {
    position: relative;
    padding: 117px 0 142px;
    background-color: var(--eduact-white);
}

@media (max-width: 767px) {
    .blog-one {
        padding: 77px 0 80px;
    }
}
.blog-one .col-xl-8 {
    flex: 0 0 auto;
    width: 64%;
}

@media (max-width: 1199px) {
    .blog-one .col-xl-8 {
        width: 100%;
    }
}
.blog-one .col-xl-4 {
    flex: 0 0 auto;
    width: 36%;
}

@media (max-width: 1199px) {
    .blog-one .col-xl-4 {
        width: 452px;
    }
}
@media (max-width: 767px) {
    .blog-one .col-xl-4 {
        width: 100%;
    }
}
.blog-one__item {
    position: relative;
    border-radius: 14px;
    border: 1px solid var(--eduact-soft3);
    background: var(--eduact-white);
    box-shadow: 11px 15px 30px 0px rgba(128, 137, 225, 0.1);
    margin: 0 0 36px;
}

.blog-one__item .col-md-5 {
    flex: 0 0 auto;
    width: 44.6%;
}

@media (max-width: 767px) {
    .blog-one__item .col-md-5 {
        width: 100%;
    }
}
.blog-one__item .col-md-7 {
    flex: 0 0 auto;
    width: 55.4%;
}

@media (max-width: 767px) {
    .blog-one__item .col-md-7 {
        width: 100%;
    }
}
.blog-one__item:hover .blog-one__image a {
    opacity: 1;
    transform: translateY(0);
}

.blog-one__item:hover .blog-one__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.blog-one__image {
    position: relative;
    overflow: hidden;
    border-radius: 14px 0 0 14px;
}

@media (max-width: 767px) {
    .blog-one__image {
        border-radius: 14px 14px 0 0;
    }
}
.blog-one__image img {
    width: 100%;
    height: auto;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .blog-one__image img {
        height: 374px;
        object-fit: cover;
    }
}
.blog-one__image a {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--eduact-black-rgb), 0.4);
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-20%);
    transition:
        opacity 500ms ease,
        transform 500ms ease;
}

.blog-one__image a::after,
.blog-one__image a::before {
    content: '';
    width: 32px;
    height: 2px;
    background-color: var(--eduact-white);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blog-one__image a::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.blog-one__content {
    position: relative;
    padding: 0 29px 0 0;
    margin-left: -6px;
}

@media (max-width: 767px) {
    .blog-one__content {
        margin: 0;
        padding: 30px 29px;
    }
}
@media (max-width: 1399px) {
    .blog-one__content {
        margin: 0;
    }
}
.blog-one__cats {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 24px;
}

@media (max-width: 991px) {
    .blog-one__cats {
        margin-bottom: 15px;
    }
}
.blog-one__cats a {
    display: inline-block;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    height: 24px;
    line-height: 24px;
    padding: 0 11px;
    margin: 0 5px 5px 0;
}

.blog-one__cats a:hover {
    background-color: var(--eduact-secondary);
}

.blog-one__title {
    font-size: 40px;
    text-transform: capitalize;
    margin: 0 0 31px;
}

@media (max-width: 991px) {
    .blog-one__title {
        font-size: 32px;
        margin-bottom: 22px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .blog-one__title {
        font-size: 36px;
    }
}
.blog-one__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.blog-one__title a:hover {
    color: var(--eduact-base);
}

.blog-one__meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 12px 20px;
    background-color: var(--eduact-soft);
    border-radius: 6px;
}

.blog-one__meta__author {
    position: relative;
    padding: 6px 0 0 55px;
    min-height: 45px;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
}

@media (max-width: 400px) {
    .blog-one__meta__author {
        padding-left: 0;
    }
}
.blog-one__meta__author img {
    width: 44px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

@media (max-width: 400px) {
    .blog-one__meta__author img {
        display: none;
    }
}
.blog-one__meta__author a {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
    color: var(--eduact-black);
}

.blog-one__meta__author a:hover {
    color: var(--eduact-base);
}

.blog-one__meta__date {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: var(--eduact-secondary);
}

.blog-one__cta {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 14px;
    box-shadow: 11px 15px 30px 0px rgba(128, 137, 225, 0.1);
    padding: 157px 50px 35px;
    text-align: center;
}

@media (max-width: 767px) {
    .blog-one__cta {
        padding: 80px 40px 35px;
    }
}
.blog-one__cta::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(var(--eduact-black-rgb), 0.83);
    border-radius: 14px;
}

.blog-one__cta__icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: var(--eduact-white);
    background-color: rgba(var(--eduact-base-rgb), 0.44);
    position: relative;
    z-index: 2;
    margin: 0 auto 29px;
}

.blog-one__cta__title {
    color: var(--eduact-white);
    position: relative;
    z-index: 2;
    font-size: 32px;
    margin: 0 0 20px;
}

.blog-one__cta__email-box {
    position: relative;
    z-index: 2;
    margin: 188px 0 15px;
}

@media (max-width: 767px) {
    .blog-one__cta__email-box {
        margin-top: 50px;
    }
}
.blog-one__cta__email-input-box {
    position: relative;
    margin: 0 0 12px;
}

.blog-one__cta__email-input-box input[type='email'] {
    width: 100%;
    height: 56px;
    border: none;
    border-radius: 6px;
    outline: none;
    background-color: var(--eduact-white);
    font-size: 16px;
    font-family: var(--eduact-font);
    color: var(--eduact-text);
    padding: 0 24px;
    font-weight: 500;
}

.blog-one__cta .eduact-btn {
    height: 56px;
    padding: 0 20px;
    width: 100%;
}

.blog-one__cta .eduact-btn__curve {
    opacity: 0;
}

.blog-one__cta .eduact-btn:hover .eduact-btn__curve {
    opacity: 0.2;
}

.blog-one__cta__text {
    position: relative;
    z-index: 2;
    color: var(--eduact-white);
    font-size: 18px;
    margin: 0;
}

.blog-one__cta svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 341px;
    z-index: 1;
    border-radius: 0 0 14px 14px;
}

.blog-two {
    position: relative;
    padding: 115px 0 85px;
    border-top: 1px solid var(--eduact-soft4);
}

@media (max-width: 767px) {
    .blog-two {
        padding: 80px 0 45px;
    }
}
.blog-two__item {
    position: relative;
    margin: 0 0 36px;
}

.blog-two__item:hover .blog-two__image a {
    opacity: 1;
    transform: translateY(0);
}

.blog-two__item:hover .blog-two__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.blog-two__image {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
}

.blog-two__image img {
    width: 100%;
    height: auto;
}

.blog-two__image a {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--eduact-black-rgb), 0.4);
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-20%);
    transition:
        opacity 500ms ease,
        transform 500ms ease;
}

.blog-two__image a::after,
.blog-two__image a::before {
    content: '';
    width: 32px;
    height: 2px;
    background-color: var(--eduact-white);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blog-two__image a::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.blog-two__content {
    position: relative;
    background-color: var(--eduact-white);
    margin: -158px 30px 0;
    padding: 30px 30px 27px;
    border-radius: 10px 10px 50px 50px;
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
}

@media (max-width: 991px) {
    .blog-two__content {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }
}
@media (max-width: 767px) {
    .blog-two__content {
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .blog-two__content {
        margin-left: 20px;
        margin-right: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }
}
.blog-two__top-meta {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 19px;
}

.blog-two__cats {
    display: flex;
    flex-wrap: wrap;
}

.blog-two__cats a {
    display: inline-block;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    height: 24px;
    line-height: 24px;
    padding: 0 11px;
    margin: 0 5px 5px 0;
}

.blog-two__cats a:hover {
    background-color: var(--eduact-secondary);
}

.blog-two__date {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: var(--eduact-secondary);
}

.blog-two__title {
    font-size: 24px;
    margin: 0 0 24px;
}

.blog-two__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.blog-two__title a:hover {
    color: var(--eduact-base);
}

.blog-two__meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
}

.blog-two__meta__author {
    position: relative;
    padding: 6px 0 0 55px;
    min-height: 45px;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
}

.blog-two__meta__author img {
    width: 44px !important;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.blog-two__meta__author a {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
    color: var(--eduact-black);
}

.blog-two__meta__author a:hover {
    color: var(--eduact-base);
}

.blog-two__rm {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--eduact-secondary);
    font-size: 18px;
    color: var(--eduact-text);
    overflow: hidden;
}

.blog-two__rm span {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.blog-two__rm:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.blog-two__rm:hover span {
    animation: iconTranslateX 0.4s forwards;
}

.blog-two__text {
    font-weight: 600;
    margin: 0 0 23px;
}

.blog-two__item--list {
    position: relative;
}

.blog-two__item--list .blog-two__content {
    margin: -45px 40px 0;
    padding: 40px 40px 40px;
}

@media (max-width: 1199px) {
    .blog-two__item--list .blog-two__content {
        margin-left: 30px;
        margin-right: 30px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (max-width: 767px) {
    .blog-two__item--list .blog-two__content {
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.blog-two__item--list .blog-two__top-meta {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
}

.blog-two__item--list .blog-two__date {
    color: var(--eduact-text);
    display: flex;
    align-items: center;
    margin-left: 15px;
    line-height: 1;
    margin-top: -5px;
}

.blog-two__item--list .blog-two__date i {
    display: inline-block;
    font-size: 24px;
    color: var(--eduact-secondary);
    margin-right: 12px;
}

.blog-two__item--list .blog-two__title {
    font-size: 32px;
    margin-bottom: 15px;
}

@media (max-width: 767px) {
    .blog-two__item--list .blog-two__title {
        font-size: 28px;
    }
}
.blog-two__item--list .blog-two__meta__author a {
    font-size: 18px;
}

.blog-two__item--list .blog-two__rm {
    width: 50px;
    height: 50px;
}

.blog-three {
    position: relative;
    padding: 115px 0 85px;
    background-position: bottom center;
    background-repeat: no-repeat;
    border-top: 1px solid var(--eduact-soft4);
}

@media (max-width: 767px) {
    .blog-three {
        padding: 80px 0 45px;
    }
}
.blog-three__item {
    position: relative;
    background-color: var(--eduact-white);
    border-radius: 20px;
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    margin: 0 0 36px;
}

.blog-three__item:hover .blog-three__image a {
    opacity: 1;
    transform: translateY(0);
}

.blog-three__item:hover .blog-three__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.blog-three__image {
    position: relative;
    overflow: hidden;
    border-radius: 20px 20px 0 0;
}

.blog-three__image img {
    width: 100%;
    height: auto;
}

.blog-three__image a {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--eduact-black-rgb), 0.4);
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-20%);
    transition:
        opacity 500ms ease,
        transform 500ms ease;
}

.blog-three__image a::after,
.blog-three__image a::before {
    content: '';
    width: 32px;
    height: 2px;
    background-color: var(--eduact-white);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.blog-three__image a::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.blog-three__content {
    position: relative;
    padding: 30px 30px 28px;
}

@media (max-width: 991px) {
    .blog-three__content {
        padding-left: 25px;
        padding-right: 25px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .blog-three__content {
        padding-left: 25px;
        padding-right: 25px;
    }
}
.blog-three__top-meta {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 19px;
}

.blog-three__cats {
    display: flex;
    flex-wrap: wrap;
}

.blog-three__cats a {
    display: inline-block;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    height: 24px;
    line-height: 24px;
    padding: 0 11px;
    margin: 0 5px 5px 0;
}

.blog-three__cats a:hover {
    background-color: var(--eduact-secondary);
}

.blog-three__date {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: var(--eduact-secondary);
}

.blog-three__title {
    font-size: 24px;
    margin: 0 0 24px;
}

.blog-three__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.blog-three__title a:hover {
    color: var(--eduact-base);
}

.blog-three__meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
}

.blog-three__meta__author {
    position: relative;
    padding: 6px 0 0 55px;
    min-height: 45px;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
}

.blog-three__meta__author img {
    width: 44px;
    height: 45px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.blog-three__meta__author a {
    display: block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-transform: capitalize;
    color: var(--eduact-black);
}

.blog-three__meta__author a:hover {
    color: var(--eduact-base);
}

.blog-three__rm {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid var(--eduact-secondary);
    font-size: 18px;
    color: var(--eduact-text);
    overflow: hidden;
}

.blog-three__rm span {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.blog-three__rm:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.blog-three__rm:hover span {
    animation: iconTranslateX 0.4s forwards;
}

/*-- Blog Page --*/
.blog-page {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .blog-page {
        padding: 80px 0;
    }
}
.blog-page__pagination {
    margin: 26px 0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

@media (max-width: 767px) {
    .blog-page__pagination {
        margin: 5px 0 0;
    }
}
.blog-page__pagination.text-left {
    justify-content: flex-start;
}

.blog-page__pagination.text-end {
    justify-content: flex-end;
}

.blog-page__pagination li {
    display: inline-block;
}

.blog-page__pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid var(--eduact-soft4);
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    color: var(--eduact-black);
}

.blog-page__pagination li a:hover,
.blog-page__pagination li a.active {
    background-color: var(--eduact-secondary);
    border-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.blog-page__pagination li.prev a,
.blog-page__pagination li.next a {
    font-size: 12px;
}

.blog-page__pagination li + li {
    margin-left: 16px;
}

/*-- Sidebar --*/
.sidebar {
    position: relative;
}

@media (max-width: 991px) {
    .sidebar {
        margin: 60px 0 0;
    }
    .sidebar--left {
        margin: 0 0 60px;
    }
}
.sidebar__single {
    position: relative;
    border-radius: 14px;
    background-color: var(--eduact-white);
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    padding: 24px 30px 30px;
}

.sidebar__single + .sidebar__single {
    margin-top: 36px;
}

.sidebar__search {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
}

.sidebar__search-form {
    position: relative;
}

.sidebar__search-form input[type='search'] {
    display: block;
    border: none;
    outline: none;
    background-color: var(--eduact-secondary);
    box-shadow: none;
    color: var(--eduact-white);
    font-size: 16px;
    font-weight: 400;
    padding-left: 60px;
    border-radius: 7px;
    height: 80px;
    width: 100%;
}

.sidebar__search-form input[type='search']::-webkit-input-placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.sidebar__search-form input[type='search']:-ms-input-placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.sidebar__search-form input[type='search']::-ms-input-placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.sidebar__search-form input[type='search']::placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.sidebar__search-form button[type='submit'] {
    background-color: transparent;
    color: var(--eduact-white);
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 30px;
    bottom: 0;
    width: auto;
    outline: none;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.sidebar__search-form button[type='submit']:hover {
    color: var(--eduact-base);
}

.sidebar__title {
    position: relative;
    font-size: 24px;
    line-height: 26px;
    text-transform: capitalize;
    border-bottom: 1px dashed var(--eduact-secondary);
    margin: 0 0 30px;
    padding-bottom: 25px;
}

.sidebar__post {
    position: relative;
}

.sidebar__post__list {
    margin: 0;
    padding: 0;
}

.sidebar__post__list li {
    list-style: none;
    position: relative;
    padding: 3px 0 31px 109px;
    margin-bottom: 30px;
    border-bottom: 1px solid var(--eduact-soft4);
    min-height: 89px;
}

.sidebar__post__list li:last-child {
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.sidebar__post__list li:hover .sidebar__post__content__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.sidebar__post__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 89px;
    height: 89px;
    border-radius: 6px;
    overflow: hidden;
}

.sidebar__post__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sidebar__post__content {
    position: relative;
}

.sidebar__post__content__meta {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin: -3px 0 15px;
}

.sidebar__post__content__meta i {
    display: inline-block;
    font-size: 24px;
    color: var(--eduact-secondary);
    margin-right: 12px;
}

.sidebar__post__content__title {
    font-size: 20px;
    max-width: 280px;
    margin: 0;
}

@media (max-width: 400px) {
    .sidebar__post__content__title {
        font-size: 19px;
    }
}
.sidebar__post__content__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.sidebar__post__content__title a:hover {
    color: var(--eduact-secondary);
}

.sidebar__category {
    position: relative;
    padding-bottom: 2px;
}

.sidebar__category .sidebar__title {
    margin-bottom: 0;
}

.sidebar__category-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar__category-list li {
    position: relative;
    display: block;
}

.sidebar__category-list li a {
    position: relative;
    z-index: 2;
    display: block;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    color: var(--eduact-text);
    border-bottom: 1px solid var(--eduact-soft4);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    padding: 29px 0 29px 33px;
}

.sidebar__category-list li a::after {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '\e92b';
    font-size: 22px;
    color: var(--eduact-secondary);
    font-family: 'icomoon' !important;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.sidebar__category-list li:last-child a {
    border-bottom: none;
}

.sidebar__category-list li:hover a,
.sidebar__category-list li.active a {
    color: var(--eduact-secondary);
    padding-left: 40px;
}

.sidebar__tags {
    position: relative;
}

.sidebar__tags-list {
    margin-bottom: -5px;
}

.sidebar__tags-list a {
    font-size: 16px;
    line-height: 40px;
    color: var(--eduact-text);
    font-weight: 600;
    background-color: var(--eduact-soft5);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    display: inline-block;
    padding: 0 20px;
    height: 40px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-right: 3px;
    margin-bottom: 10px;
}

.sidebar__tags-list a:last-child {
    margin-right: 0;
}

.sidebar__tags-list a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.sidebar__comments {
    position: relative;
}

.sidebar__comments-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar__comments-list li {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 62px;
    min-height: 48px;
}

.sidebar__comments-list li:hover .sidebar__comments-icon {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.sidebar__comments-list li + li {
    margin-top: 28px;
}

.sidebar__comments-icon {
    height: 48px;
    width: 48px;
    background-color: var(--eduact-soft5);
    border-radius: 50%;
    font-size: 22px;
    color: var(--eduact-black);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    left: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.sidebar__comments-text-box p {
    font-weight: 600;
    margin: 0;
}

.sidebar__comments-text-box p a {
    color: var(--eduact-black);
}

.sidebar__comments-text-box p a:hover {
    color: var(--eduact-secondary);
}

/*-- Blog Details --*/
.blog-details {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .blog-details {
        padding: 80px 0;
    }
}
.blog-details__content {
    position: relative;
}

.blog-details__img {
    position: relative;
    margin: 0 0 40px;
}

.blog-details__img img {
    width: 100%;
    height: auto;
    border-radius: 14px;
}

.blog-details__meta {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.blog-details__meta__cats {
    display: flex;
    flex-wrap: wrap;
}

.blog-details__meta a {
    display: inline-block;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    height: 24px;
    line-height: 24px;
    padding: 0 11px;
    margin: 0 5px 5px 0;
}

.blog-details__meta a:hover {
    background-color: var(--eduact-secondary);
}

.blog-details__meta__date {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    display: flex;
    align-items: center;
    margin-left: 15px;
    line-height: 1;
    margin-top: -5px;
}

.blog-details__meta__date i {
    display: inline-block;
    font-size: 24px;
    color: var(--eduact-secondary);
    margin-right: 12px;
}

.blog-details__title {
    font-size: 32px;
    margin: 0 0 11px;
}

@media (max-width: 767px) {
    .blog-details__title {
        font-size: 28px;
    }
}
.blog-details__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0 0 30px;
}

.blog-details__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 0 40px;
    margin-top: 39px;
    border-bottom: 1px solid var(--eduact-soft4);
    border-top: 1px solid var(--eduact-soft4);
}

@media (max-width: 767px) {
    .blog-details__bottom {
        display: block;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .blog-details__bottom {
        display: block;
    }
}
.blog-details__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

@media (max-width: 767px) {
    .blog-details__tags {
        display: block;
    }
}
.blog-details__tags__title {
    font-size: 24px;
    margin: 0 21px 0 0;
}

@media (max-width: 767px) {
    .blog-details__tags__title {
        margin: 0 0 15px;
    }
}
.blog-details__tags a {
    font-size: 16px;
    line-height: 40px;
    font-weight: 600;
    color: var(--eduact-text);
    background-color: var(--eduact-soft5);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    display: inline-block;
    padding: 0 20px;
    height: 40px;
    font-weight: 700;
    border-radius: 8px;
    text-transform: capitalize;
    margin-right: 8px;
}

@media (max-width: 767px) {
    .blog-details__tags a {
        margin-bottom: 10px;
    }
}
.blog-details__tags a:last-child {
    margin-right: 0;
}

.blog-details__tags a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.blog-details__social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .blog-details__social {
        margin: 30px 0 0;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .blog-details__social {
        margin-top: 30px;
    }
}
.blog-details__social a {
    font-size: 16px;
    color: var(--eduact-black);
    background-color: var(--eduact-soft5);
    width: 40px;
    height: 40px;
    line-height: 41px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    margin-left: 21px;
}

.blog-details__social a:first-child {
    margin-left: 0;
}

.blog-details__social a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.blog-details__comment {
    position: relative;
    margin: 45px 0 0;
}

.blog-details__comment__title {
    font-size: 24px;
    margin: 0 0 49px;
}

@media (max-width: 767px) {
    .blog-details__comment__title {
        margin: 0 0 30px;
    }
}
.blog-details__comment__item {
    position: relative;
    border-bottom: 1px solid var(--eduact-soft4);
    min-height: 165px;
    padding: 0 0 59px 196px;
    margin-bottom: 54px;
}

@media (max-width: 767px) {
    .blog-details__comment__item {
        padding-left: 0;
    }
}
.blog-details__comment__image {
    position: absolute;
    left: 0;
    top: 6px;
    width: 165px;
    height: 165px;
    border-radius: 50%;
    overflow: hidden;
}

@media (max-width: 767px) {
    .blog-details__comment__image {
        position: relative;
        margin: 0 0 25px;
    }
}
.blog-details__comment__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-details__comment__content {
    position: relative;
}

.blog-details__comment__name {
    font-size: 24px;
    text-transform: capitalize;
    margin: 0 0 3px;
}

.blog-details__comment__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0 0 20px;
}

.blog-details__comment .eduact-btn {
    height: 31px;
    background-color: var(--eduact-soft5);
    border-radius: 5px;
    font-size: 16px;
    color: var(--eduact-text);
    padding: 2px 24px;
}

.blog-details__comment .eduact-btn__curve {
    opacity: 0;
    background-color: var(--eduact-secondary);
    z-index: -1;
}

.blog-details__comment .eduact-btn:hover {
    color: var(--eduact-white);
}

.blog-details__comment .eduact-btn:hover .eduact-btn__curve {
    opacity: 1;
}

.blog-details__comment-form {
    position: relative;
    margin: 40px 0 0;
}

.blog-details__comment-form .row {
    --bs-gutter-x: 20px;
}

.blog-details__comment-form__title {
    font-size: 24px;
    margin: 0 0 34px;
}

.blog-details__comment-form__input-box {
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 20px;
}

.blog-details__comment-form__input-box input[type='text'],
.blog-details__comment-form__input-box input[type='email'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    font-family: var(--eduact-font);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.blog-details__comment-form__input-box textarea {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    height: 211px;
    width: 100%;
    background-color: var(--eduact-soft5);
    font-family: var(--eduact-font);
    padding: 25px 30px 30px;
    border: none;
    outline: none;
    border-radius: 4px;
    margin-bottom: 0px;
}

.blog-details__comment-form .eduact-btn {
    margin-top: 20px;
}

/*--------------------------------------------------------------
  # Common
  --------------------------------------------------------------*/
body {
    font-family: var(--eduact-font);
    color: var(--eduact-text);
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
}

body.locked {
    overflow: hidden;
}

a {
    color: var(--eduact-base);
    transition: all 500ms ease;
}

a,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
}

::placeholder {
    color: inherit;
    opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--heading-font);
    color: var(--eduact-black);
    font-weight: 700;
}

@media (max-width: 575px) {
    h1 br,
    h2 br,
    h3 br,
    h4 br,
    h5 br,
    h6 br {
        display: none;
    }
}
@media (max-width: 575px) {
    p br {
        display: none;
    }
}
::placeholder {
    color: inherit;
    opacity: 1;
}

.background-base {
    background-color: var(--eduact-base);
}

.background-black {
    background-color: var(--eduact-black);
}

.eduact-text-dark {
    color: var(--eduact-black);
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
    transition: filter 0.5s ease;
}

@media (min-width: 1400px) {
    .container {
        padding-left: 18px;
        padding-right: 18px;
        max-width: 1356px;
    }
    .row {
        --bs-gutter-x: 36px;
    }
}
.eduact-btn {
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    outline: none !important;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    font-size: 18px;
    font-weight: 700;
    border-radius: 6px;
    text-transform: capitalize;
    padding: 16px 32px 15px;
    overflow: hidden;
    z-index: 2;
    position: relative;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

.eduact-btn-second {
    background-color: var(--eduact-secondary);
}

.eduact-btn__text {
    position: relative;
    z-index: 2;
}

.eduact-btn__curve {
    position: absolute;
    right: -15px;
    top: 0;
    width: 33px;
    height: 100%;
    background: var(--eduact-soft2);
    opacity: 0.2;
    z-index: 0;
    -webkit-transform: skewX(-22deg);
    transform: skewX(-22deg);
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.eduact-btn i {
    display: inline-block;
    margin-left: 11px;
    position: relative;
    top: 2px;
}

.eduact-btn:hover {
    color: var(--eduact-white);
}

.eduact-btn:hover .eduact-btn__curve {
    right: 0;
    width: 100%;
    -webkit-transform: skewX(0deg);
    transform: skewX(0deg);
}

.bootstrap-select .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    border: none;
}

.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
    background-color: var(--eduact-base);
}

.ul-list-one {
    margin-bottom: 0;
}

.ul-list-one li {
    position: relative;
    padding-left: 45px;
    font-size: 16px;
    font-weight: 500;
    color: var(--eduact-black);
}

@media (min-width: 481px) {
    .ul-list-one li {
        font-size: 20px;
    }
}
.ul-list-one li::before {
    content: '\e907';
    color: var(--eduact-base);
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-family: 'azino-icon';
}

.preloader {
    position: fixed;
    background-color: var(--eduact-black);
    background-position: center center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9991;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
}

.preloader__image {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100px auto;
    width: 100%;
    height: 100%;
}

/* scroll to top */
.scroll-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 50px;
    width: 50px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px RGBA(var(--eduact-black2-rgb), 0.3);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.scroll-top--active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.scroll-top::after {
    position: absolute;
    content: '\e904';
    font-family: 'icomoon' !important;
    text-align: center;
    line-height: 50px;
    font-size: 17px;
    color: var(--eduact-base);
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

.scroll-top__circle path {
    stroke: var(--eduact-base);
    stroke-width: 4;
    fill: none;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
}

/* post paginations */
.post-pagination {
    margin-bottom: 0;
    margin-top: 0px;
}

@media (min-width: 992px) {
    .post-pagination {
        margin-top: 0px;
    }
}
.post-pagination a {
    display: flex;
    width: 45px;
    height: 45px;
    background-color: #eff2f6;
    align-items: center;
    justify-content: center;
    color: var(--eduact-text);
    font-size: 16px;
    font-weight: 500;
    border-radius: 50%;
    transition: 500ms ease;
}

@media (min-width: 992px) {
    .post-pagination a {
        width: 60px;
        height: 60px;
        font-size: 18px;
    }
}
.post-pagination a:hover {
    background-color: var(--eduact-base);
    color: #fff;
}

.post-pagination li:first-child a {
    background-color: var(--eduact-base);
    color: #fff;
}

.post-pagination li:last-child a {
    background-color: var(--eduact-black);
    color: #fff;
}

.post-pagination li + li {
    margin-left: 10px;
}

/* owl carousel overfllow */
.owl-with-shadow .owl-stage-outer {
    overflow: visible;
}

.owl-with-shadow .owl-stage-outer .owl-item {
    opacity: 0;
    visibility: hidden;
    -webkit-transition:
        opacity 500ms ease,
        visibility 500ms ease,
        -webkit-transform 500ms ease;
    transition:
        opacity 500ms ease,
        visibility 500ms ease,
        -webkit-transform 500ms ease;
    transition:
        opacity 500ms ease,
        visibility 500ms ease,
        transform 500ms ease;
    transition:
        opacity 500ms ease,
        visibility 500ms ease,
        transform 500ms ease,
        -webkit-transform 500ms ease;
}

.owl-with-shadow .owl-stage-outer .owl-item.active {
    opacity: 1;
    visibility: visible;
}

/* owl carousel Dots */
.eduact-owl__dots.owl-carousel {
    position: relative;
}

.eduact-owl__dots.owl-carousel.owl-theme .owl-dots,
.eduact-owl__dots.owl-carousel .owl-dots {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eduact-owl__dots.owl-carousel.owl-theme .owl-dots .owl-dot,
.eduact-owl__dots.owl-carousel .owl-dots .owl-dot {
    height: 10px;
    border-radius: 50%;
    width: 10px;
    background-color: rgba(var(--eduact-base-rgb), 0.27);
    margin: 0 11px;
    position: relative;
    top: 0;
    z-index: 2;
}

.eduact-owl__dots.owl-carousel.owl-theme .owl-dots .owl-dot::after,
.eduact-owl__dots.owl-carousel .owl-dots .owl-dot::after {
    position: absolute;
    left: -5px;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    content: '';
    border-radius: 50%;
    border: 1px solid var(--eduact-base);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.eduact-owl__dots.owl-carousel.owl-theme .owl-dots .owl-dot:hover,
.eduact-owl__dots.owl-carousel.owl-theme .owl-dots .owl-dot.active,
.eduact-owl__dots.owl-carousel .owl-dots .owl-dot:hover,
.eduact-owl__dots.owl-carousel .owl-dots .owl-dot.active {
    background-color: var(--eduact-secondary);
}

.eduact-owl__dots.owl-carousel.owl-theme .owl-dots .owl-dot:hover::after,
.eduact-owl__dots.owl-carousel.owl-theme .owl-dots .owl-dot.active::after,
.eduact-owl__dots.owl-carousel .owl-dots .owl-dot:hover::after,
.eduact-owl__dots.owl-carousel .owl-dots .owl-dot.active::after {
    visibility: visible;
    opacity: 1;
}

.eduact-owl__dots.owl-carousel.owl-theme .owl-dots .owl-dot span,
.eduact-owl__dots.owl-carousel .owl-dots .owl-dot span {
    display: none;
}

/* section title */
.section-title {
    position: relative;
    display: block;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .section-title {
        margin-bottom: 40px;
    }
}
.section-title.text-center .section-title__tagline svg {
    right: 0;
    margin: auto;
}

.section-title.text-end .section-title__tagline svg {
    right: 0;
    left: auto;
}

.section-title__tagline {
    font-size: 24px;
    line-height: 26px;
    text-transform: capitalize;
    color: var(--eduact-base);
    margin: 0 0 11px;
    position: relative;
    z-index: 2;
    padding: 0 20px;
}

.section-title__tagline svg {
    position: absolute;
    left: 0;
    bottom: -1px;
    z-index: -1;
    width: 133px;
    height: 13px;
}

.section-title__tagline svg.arrow-svg {
    position: relative;
    display: inline-block;
    width: 55px;
    overflow: hidden;
    margin: 0 0 0 9px !important;
    fill: var(--eduact-secondary);
}

.section-title__title {
    font-size: 40px;
    line-height: 47px;
    color: var(--eduact-black);
    margin: 0;
}

@media (max-width: 767px) {
    .section-title__title {
        font-size: 30px;
        line-height: 40px;
    }
    .section-title__title br {
        display: none;
    }
}
/*--------------------------------------------------------------
  # Form
  --------------------------------------------------------------*/
.form-one .form-group {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin: 0;
}

@media (min-width: 576px) {
    .form-one .form-group {
        grid-template-columns: 1fr 1fr;
    }
}
.form-one .form-control {
    border: none;
    width: auto;
    height: auto;
    border-radius: 0;
    padding: 0;
}

.form-one .form-control-full {
    grid-column-start: 1;
    grid-column-end: -1;
}

.form-one input[type='text'],
.form-one input[type='email'],
.form-one textarea {
    display: block;
    width: 100%;
    height: 73px;
    border-radius: 36.5px;
    background-color: #f1f1f1;
    color: #7e7e7e;
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    padding-left: 30px;
}

.form-one textarea {
    border-radius: 30px;
    height: 195px;
    padding-top: 20px;
}

.form-one .thm-btn {
    padding: 24.5px 57.5px;
}

.form-one .thm-btn:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

/*--------------------------------------------------------------
  # Navigations
  --------------------------------------------------------------*/
.main-menu .container {
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
}

.main-menu {
    background-color: transparent;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 91;
    padding: 0;
}

.main-menu__logo {
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 0;
}
.main-menu__logo > a > img {
    height: 60px;
}
@media (max-width: 767px) {
    .main-menu__logo {
        padding: 23px 0;
    }
}
.main-menu__nav {
    margin-left: auto;
    margin-right: auto;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list ul,
.stricky-header .main-menu__list,
.stricky-header .main-menu__list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    align-items: center;
    display: none;
}

@media (min-width: 1200px) {
    .main-menu .main-menu__list,
    .main-menu .main-menu__list ul,
    .stricky-header .main-menu__list,
    .stricky-header .main-menu__list ul {
        display: flex;
    }
}
.main-menu .main-menu__list > li,
.stricky-header .main-menu__list > li {
    padding-top: 39px;
    padding-bottom: 44px;
    position: relative;
}

.main-menu .main-menu__list > li.dropdown > a,
.stricky-header .main-menu__list > li.dropdown > a {
    position: relative;
}

.main-menu .main-menu__list > li.dropdown > a::after,
.stricky-header .main-menu__list > li.dropdown > a::after {
    position: relative;
    right: 0;
    top: 0;
    border-radius: 0;
    font-size: 11px;
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    content: '\f078';
    color: inherit;
    line-height: 10px;
    margin-left: 5px;
}

.main-menu .main-menu__list > li + li,
.stricky-header .main-menu__list > li + li {
    margin-left: 55px;
}

@media (max-width: 1400px) {
    .main-menu .main-menu__list > li + li,
    .stricky-header .main-menu__list > li + li {
        margin-left: 44px;
    }
}
@media (min-width: 1200px) and (max-width: 1300px) {
    .main-menu .main-menu__list > li + li,
    .stricky-header .main-menu__list > li + li {
        margin-left: 38px;
    }
}
.main-menu .main-menu__list > li > a,
.stricky-header .main-menu__list > li > a {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-family: var(--heading-font);
    color: var(--eduact-white);
    font-weight: 600;
    text-transform: capitalize;
    letter-spacing: 0.48px;
    position: relative;
    transition: all 500ms ease;
}

.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a,
.stricky-header .main-menu__list > li.current > a,
.stricky-header .main-menu__list > li:hover > a {
    color: var(--eduact-secondary);
}

.main-menu .main-menu__list li ul,
.stricky-header .main-menu__list li ul {
    position: absolute;
    top: 100%;
    left: -25px;
    min-width: 270px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transform-origin: top center;
    transform: scaleY(0) translateZ(100px);
    transition:
        opacity 500ms ease,
        visibility 500ms ease,
        transform 700ms ease;
    z-index: 99;
    background-color: var(--eduact-white);
    box-shadow: 0px 10px 60px 0px RGBA(var(--eduact-white-rgb), 0.07);
    padding: 15px 20px 10px;
    border-radius: 14px;
}

.main-menu .main-menu__list li:hover > ul,
.stricky-header .main-menu__list li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1) translateZ(0px);
}

.main-menu .main-menu__list > .megamenu,
.stricky-header .main-menu__list > .megamenu {
    position: static;
}

.main-menu .main-menu__list > .megamenu > ul,
.stricky-header .main-menu__list > .megamenu > ul {
    top: 100% !important;
    left: 0 !important;
    right: 0 !important;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
}

.main-menu .main-menu__list li ul li,
.stricky-header .main-menu__list li ul li {
    flex: 1 1 100%;
    width: 100%;
    position: relative;
}

.main-menu .main-menu__list li ul li > a,
.stricky-header .main-menu__list li ul li > a {
    font-size: 16px;
    line-height: 26px;
    color: var(--eduact-text);
    font-weight: 500;
    font-family: var(--heading-font);
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    transition: 400ms;
    margin-bottom: 4px;
}

.main-menu .main-menu__list li ul li > a::after,
.stricky-header .main-menu__list li ul li > a::after {
    position: absolute;
    right: 20px;
    top: 7px;
    border-radius: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Font Awesome 5 Free';
    content: '\f0da';
    color: var(--eduact-base);
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease;
    transform: scale(0);
}

.main-menu .main-menu__list li ul li.current > a,
.main-menu .main-menu__list li ul li:hover > a,
.stricky-header .main-menu__list li ul li.current > a,
.stricky-header .main-menu__list li ul li:hover > a {
    background-color: var(--eduact-black);
    color: var(--eduact-white);
}

.main-menu .main-menu__list li ul li.current > a::after,
.main-menu .main-menu__list li ul li:hover > a::after,
.stricky-header .main-menu__list li ul li.current > a::after,
.stricky-header .main-menu__list li ul li:hover > a::after {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

.main-menu .main-menu__list li ul li > ul,
.stricky-header .main-menu__list li ul li > ul {
    top: 0;
    left: calc(100% + 20px);
}

.main-menu .main-menu__list li ul li > ul.right-align,
.stricky-header .main-menu__list li ul li > ul.right-align {
    top: 0;
    left: auto;
    right: 100%;
}

.main-menu .main-menu__list li ul li > ul ul,
.stricky-header .main-menu__list li ul li > ul ul {
    display: none;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .main-menu__list li:nth-last-child(1) ul li > ul,
    .main-menu__list li:nth-last-child(2) ul li > ul {
        left: auto;
        right: calc(100% + 20px);
    }
}
.main-menu__right {
    display: flex;
    align-items: center;
}

.main-menu__right .eduact-btn {
    background-color: var(--eduact-secondary);
    font-size: 16px;
    margin-left: 19px;
    font-weight: 600;
    padding: 7px 20.5px;
    height: 40px;
}

.main-menu__right .eduact-btn:hover .eduact-btn__curve {
    opacity: 0.2;
}

@media (max-width: 1199px) {
    .main-menu__right .eduact-btn {
        display: none;
    }
}
.main-menu__right .eduact-btn__curve {
    opacity: 0;
}

.main-menu__right .mobile-nav__toggler {
    margin-right: 18px;
}

.main-menu__login,
.main-menu__search {
    position: relative;
    font-size: 20px;
    color: var(--eduact-white);
    display: flex;
    align-items: center;
    height: 50px;
    transition: all 500ms ease;
}

.main-menu__login:hover,
.main-menu__search:hover {
    color: var(--eduact-secondary);
}

.main-menu__login__count,
.main-menu__search__count {
    font-size: 10px;
    font-weight: 600;
    color: var(--eduact-black);
    font-family: var(--heading-font);
    text-align: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: var(--eduact-base);
    border-radius: 16px;
    position: absolute;
    top: 10px;
    right: -6px;
    opacity: 0;
    transition: all 300ms linear 0ms;
}

.main-menu__login:hover .main-menu__login__count,
.main-menu__search:hover .main-menu__login__count,
.main-menu__login:hover .main-menu__search__count,
.main-menu__search:hover .main-menu__search__count {
    opacity: 1;
}

.main-menu__login {
    font-size: 24px;
    margin-left: 11px;
}

.stricky-header {
    position: fixed;
    z-index: 991;
    top: 0;
    left: 0;
    background-color: var(--eduact-black);
    box-shadow: 0px 10px 60px 0px RGBA(var(--eduact-black-rgb), 0.07);
    width: 100%;
    transform: translateY(-120%);
    transition: transform 500ms ease;
}

@media (max-width: 1199px) {
    .stricky-header {
        display: none;
    }
}
.stricky-header.stricky-fixed {
    transform: translateY(0);
}

/*--------------------------------------------------------------
  # Home Showcase
  --------------------------------------------------------------*/
.home-showcase {
    margin-top: 0;
    margin-bottom: -20px;
}

.home-showcase .row {
    --bs-gutter-x: 40px;
    --bs-gutter-y: 20px;
}

.home-showcase__inner {
    padding: 40px 40px;
    background-color: var(--eduact-white);
    box-shadow: 0px 10px 60px 0px RGBA(var(--eduact-white-rgb), 0.07);
    border-radius: 14px;
}

.home-showcase__image {
    position: relative;
    background-color: var(--eduact-black);
    overflow: hidden;
}

.home-showcase__image > img {
    width: 100%;
    transition: 500ms ease;
    transform: scale(1);
}

.home-showcase__image:hover > img {
    opacity: 0.75;
}

.home-showcase__image:hover .home-showcase__buttons {
    transform: scale(1, 1);
    opacity: 1;
    visibility: visible;
}

.home-showcase__buttons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: scale(1, 0);
    opacity: 0;
    visibility: hidden;
    transform-origin: bottom center;
    transition: 500ms ease;
}

.home-showcase__buttons__item {
    padding: 11px 20px 12px;
    width: 150px;
    text-align: center;
}

.home-showcase__buttons__item + .home-showcase__buttons__item {
    margin-top: 10px;
}

.home-showcase__title {
    margin: 0;
    text-align: center;
    color: var(--eduact-black2);
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    margin-top: 17px;
}

.mobile-nav__buttons {
    display: flex;
    margin-left: auto;
    margin-right: 10px;
}

@media (min-width: 1200px) {
    .mobile-nav__buttons {
        display: none;
    }
}
.mobile-nav__buttons a {
    font-size: 20px;
    color: var(--eduact-white);
    cursor: pointer;
}

.mobile-nav__buttons a + a {
    margin-left: 10px;
}

.mobile-nav__buttons a:hover {
    color: var(--eduact-base);
}

.mobile-nav__toggler {
    font-size: 24px;
    color: var(--eduact-white);
    cursor: pointer;
    transition: 500ms;
}

.mobile-nav__toggler:hover {
    color: var(--eduact-base);
}

@media (min-width: 1200px) {
    .mobile-nav__toggler {
        display: none;
    }
}
/*-- header two --*/
.main-header-two .main-menu {
    position: relative;
    background: var(--eduact-white);
    box-shadow: 0px 6px 30px 0px rgba(var(--eduact-black2-rgb), 0.08);
}

.main-header-two .main-menu__logo {
    padding: 26px 0;
}

.main-header-two .main-menu .main-menu__list > li > a {
    color: var(--eduact-black);
}

.main-header-two .main-menu__list > li.current > a,
.main-header-two .main-menu__list > li:hover > a {
    color: var(--eduact-base);
}

.main-header-two .main-menu .main-menu__list > li {
    padding-top: 35px;
    padding-bottom: 40px;
}

.main-menu .main-menu__list > li,
.stricky-header .main-menu__list > li {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
}

.main-header-two.stricky-header {
    background: var(--eduact-white);
    box-shadow: 0px 6px 30px 0px rgba(var(--eduact-black2-rgb), 0.08);
}

.main-header-two.stricky-header .main-menu__list > li > a {
    color: var(--eduact-black);
}

.main-header-two.stricky-header .main-menu__list > li {
    padding-top: 35px;
    padding-bottom: 40px;
}

.main-header-two.stricky-header .main-menu__list > li.current > a,
.main-header-two.stricky-header .main-menu__list > li:hover > a {
    color: var(--eduact-base);
}

.main-header-two .main-menu__login,
.main-header-two .mobile-nav__toggler,
.main-header-two .main-menu__search {
    color: var(--eduact-black);
}

.main-header-two .main-menu__login:hover,
.main-header-two .mobile-nav__toggler:hover,
.main-header-two .main-menu__search:hover {
    color: var(--eduact-base);
}

.main-header-two .main-menu__nav {
    margin-right: 50px;
}

@media (max-width: 1399px) {
    .main-header-two .main-menu__nav {
        margin-right: 20px;
    }
}
/*-- header three --*/
.main-menu-with-bg {
    background: rgba(246, 246, 246, 0.42);
}

.main-menu-with-bg.stricky-header {
    background: var(--eduact-white);
}

.main-menu-with-bg .main-menu__logo {
    padding: 26px 0;
}

.main-menu-with-bg.main-menu .main-menu__list > li,
.main-menu-with-bg.stricky-header .main-menu__list > li {
    padding-top: 35px;
    padding-bottom: 40px;
}

.main-menu-with-bg.main-menu .main-menu__list > li > a,
.main-menu-with-bg.stricky-header .main-menu__list > li > a {
    color: var(--eduact-black);
}

.main-menu-with-bg.main-menu .main-menu__list > li.current > a,
.main-menu-with-bg.main-menu .main-menu__list > li:hover > a,
.main-menu-with-bg.stricky-header .main-menu__list > li.current > a,
.main-menu-with-bg.stricky-header .main-menu__list > li:hover > a {
    color: var(--eduact-base);
}

.main-menu-with-bg .main-menu__login,
.main-menu-with-bg .mobile-nav__toggler,
.main-menu-with-bg .main-menu__search {
    color: var(--eduact-black);
}

.main-menu-with-bg .main-menu__login:hover,
.main-menu-with-bg .mobile-nav__toggler:hover,
.main-menu-with-bg .main-menu__search:hover {
    color: var(--eduact-base);
}

@media (max-width: 1399px) {
    .main-header-two .main-menu .main-menu__list > li + li,
    .main-header-two.stricky-header .main-menu__list > li + li {
        margin-left: 38px;
    }
}
.main-menu__info {
    position: relative;
    background-color: var(--eduact-base);
    border-radius: 60px 0px 0px 60px;
    padding: 24px 49px 16px 85px;
    max-width: 256px;
    font-size: 14px;
    color: var(--eduact-white);
    font-weight: 600;
    line-height: 23px;
    min-height: 84px;
    margin-left: 49px;
}

@media (max-width: 1399px) {
    .main-menu__info {
        margin-left: 15px;
        max-width: 232px;
        padding-right: 25px;
    }
}
@media (max-width: 767px) {
    .main-menu__info {
        display: none;
    }
}
.main-menu__info span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    background-color: var(--eduact-white);
    font-size: 28px;
    color: var(--eduact-base);
    position: absolute;
    left: 15px;
    bottom: 0;
    top: 0;
    margin: auto;
}

.main-menu__info a {
    display: block;
    font-size: 18px;
    color: inherit;
    line-height: 1;
    margin-bottom: 1px;
}

.main-menu__info a:hover {
    color: var(--eduact-secondary);
}

/*--------------------------------------------------------------
  # Megamenu Popup
  --------------------------------------------------------------*/
.mobile-nav__container .main-menu__list > .megamenu.megamenu-clickable > ul,
.main-menu .main-menu__list > .megamenu.megamenu-clickable > ul,
.stricky-header .main-menu__list > .megamenu.megamenu-clickable > ul {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    height: 100vh;
    visibility: visible;
    overflow-y: scroll;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    transition:
        transform 0.7s ease,
        opacity 0.7s ease,
        visibility 0.7s ease;
    z-index: 999999;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    padding: 0;
    background-color: var(--eduact-white);
    display: block !important;
    margin: 0;
}

.main-menu__list > li.megamenu-clickable > ul::-webkit-scrollbar {
    display: none;
}

.mobile-nav__container
    .main-menu__list
    > .megamenu.megamenu-clickable
    > ul.megamenu-clickable--active,
.main-menu
    .main-menu__list
    > .megamenu.megamenu-clickable
    > ul.megamenu-clickable--active,
.stricky-header
    .main-menu__list
    > .megamenu.megamenu-clickable
    > ul.megamenu-clickable--active {
    -webkit-transform-origin: top center;
    transform-origin: top center;
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
    visibility: visible;
    transition:
        transform 0.7s ease,
        opacity 0.7s ease,
        visibility 0.7s ease;
}

body.megamenu-popup-active {
    overflow: hidden;
}

body.megamenu-popup-active .stricky-header {
    bottom: 0;
}

body.megamenu-popup-active .mobile-nav__content {
    overflow: unset;
}

.mobile-nav__content .demos-one .container {
    padding-left: 15px;
    padding-right: 15px;
}

.megamenu-popup {
    position: relative;
}

.megamenu-popup .megamenu-clickable--close {
    position: absolute;
    top: 18px;
    right: 20px;
    display: block;
    font-size: 20px;
    color: var(--eduact-black2);
}

@media (min-width: 1300px) {
    .megamenu-popup .megamenu-clickable--close {
        top: 38px;
        right: 40px;
        font-size: 24px;
    }
}
.megamenu-popup .megamenu-clickable--close:hover {
    color: var(--eduact-base);
}

.demos-one {
    padding-top: 90px;
    padding-bottom: 60px;
}

@media (min-width: 1200px) {
    .demos-one {
        padding-top: 120px;
        padding-bottom: 90px;
    }
}
.demos-one__single {
    background-color: var(--eduact-white);
    margin: 0 0 36px;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(var(--eduact-black2-rgb), 0.1);
    box-shadow: 0px 10px 60px 0px rgba(var(--eduact-black2-rgb), 0.1);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    position: relative;
}

.demos-one__single:hover {
    -webkit-box-shadow: 0px 10px 60px 0px rgba(var(--eduact-black2-rgb), 0.12);
    box-shadow: 0px 10px 60px 0px rgba(var(--eduact-black2-rgb), 0.2);
}

.demos-one__single:hover .demos-one__image img {
    opacity: 0.7;
}

.demos-one__single:hover .demos-one__buttons {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.demos-one__image {
    overflow: hidden;
    position: relative;
    background-color: var(--eduact-black2);
}

.demos-one__image img {
    width: 100%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.demos-one__buttons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    -webkit-transform: translateY(10%);
    transform: translateY(10%);
    -webkit-transition:
        opacity 500ms ease,
        -webkit-transform 500ms ease;
    transition:
        opacity 500ms ease,
        -webkit-transform 500ms ease;
    transition:
        opacity 500ms ease,
        transform 500ms ease;
    transition:
        opacity 500ms ease,
        transform 500ms ease,
        -webkit-transform 500ms ease;
}

.demos-one__buttons .eduact-btn {
    padding: 11px 20px 12px;
    width: 145px;
    text-align: center;
}

@media (max-width: 1199px) {
    .demos-one__buttons .eduact-btn {
        width: 115px;
        font-size: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
.demos-one__buttons .eduact-btn + .eduact-btn {
    margin-left: 15px;
}

@media (max-width: 1199px) {
    .demos-one__buttons .eduact-btn + .eduact-btn {
        margin-left: 10px;
    }
}
.demos-one__text {
    text-align: center;
    padding-top: 34px;
    padding-bottom: 33px;
}

.demos-one__text__title {
    color: var(--eduact-black2);
    font-size: 20px;
    font-weight: 800;
    margin: 0;
}

/*--------------------------------------------------------------
  # Animations
  --------------------------------------------------------------*/
@keyframes bubbleMover {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
    }
    30% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
    50% {
        -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
        transform: translateY(50px) translateX(100px) rotate(45deg);
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
    }
    80% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
    }
    100% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}
@keyframes treeMove {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(5deg) translateX(15px);
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        -webkit-transform: rotate(10deg) translateX(30px);
        transform: rotate(10deg) translateX(30px);
    }
}
@keyframes footerTree {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(5deg) translateX(15px);
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        -webkit-transform: rotate(10deg) translateX(30px);
        transform: rotate(10deg) translateX(30px);
    }
}
@keyframes shapeMover2 {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    20%,
    60% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    30%,
    80% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes shapeMover3 {
    0%,
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    20%,
    60% {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-79deg);
    }
    30%,
    80% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes shapeMover {
    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px)
            translateX(0);
    }
    50% {
        transform: perspective(400px) rotate(-45deg) translateZ(20px)
            translateY(20px) translateX(20px);
    }
}
@keyframes banner3Shake {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg);
    }
    30% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }
    60% {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }
    80% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }
    100% {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg);
    }
}
@keyframes squareMover {
    0%,
    100% {
        -webkit-transform: translate(0, 0) rotate(0);
        transform: translate(0, 0) rotate(0);
    }
    20%,
    60% {
        -webkit-transform: translate(20px, 40px) rotate(180deg);
        transform: translate(20px, 40px) rotate(180deg);
    }
    30%,
    80% {
        -webkit-transform: translate(40px, 60px) rotate(0deg);
        transform: translate(40px, 60px) rotate(0deg);
    }
}
@keyframes treeMove {
    0%,
    100% {
        -webkit-transform: rotate(0deg) translateX(0);
        transform: rotate(0deg) translateX(0);
    }
    25%,
    75% {
        -webkit-transform: rotate(5deg) translateX(15px);
        transform: rotate(5deg) translateX(15px);
    }
    50% {
        -webkit-transform: rotate(10deg) translateX(30px);
        transform: rotate(10deg) translateX(30px);
    }
}
@-webkit-keyframes float-bob-y-2 {
    0% {
        -webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
        transform: translateY(0px) translateX(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(10px) translateX(10px) rotate(5deg);
        transform: translateY(10px) translateX(10px) rotate(5deg);
    }
    100% {
        -webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
        transform: translateY(0px) translateX(0px) rotate(0deg);
    }
}
@keyframes float-bob-y-2 {
    0% {
        -webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
        transform: translateY(0px) translateX(0px) rotate(0deg);
    }
    50% {
        -webkit-transform: translateY(10px) translateX(10px) rotate(5deg);
        transform: translateY(10px) translateX(10px) rotate(5deg);
    }
    100% {
        -webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
        transform: translateY(0px) translateX(0px) rotate(0deg);
    }
}
@keyframes ripple {
    70% {
        -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
@keyframes blinker {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.03, 1.03);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes blinker2 {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(0.6, 0.6);
    }
    100% {
        transform: scale(1, 1);
    }
}
@keyframes sunMove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    25% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    50% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    75% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}
@keyframes circle {
    0% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(450deg);
    }
}
@keyframes zoomBig {
    0% {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 1;
        border-width: 3px;
    }
    40% {
        opacity: 0.5;
        border-width: 2px;
    }
    65% {
        border-width: 1px;
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
        border-width: 1px;
    }
}
@keyframes rotated {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotated2 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    50% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
}
@keyframes iconTranslateX {
    49% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}
@keyframes iconTranslateY {
    49% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    51% {
        opacity: 1;
    }
}
@keyframes animationFrames {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(0.9);
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1);
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2);
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1);
    }
}
@keyframes movebounce {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    50% {
        transform: translateX(-10px) translateY(-10px);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}
@keyframes movebounce2 {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes movebounce3 {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0px);
    }
}
@keyframes movebounce4 {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0px);
    }
}
@keyframes shadows {
    0% {
        box-shadow:
            0 0 0 0 rgba(var(--eduact-white-rgb), 0.21),
            0 0 0 30px rgba(var(--eduact-white-rgb), 0.21),
            0 0 0 50px rgba(var(--eduact-white-rgb), 0.21);
    }
    100% {
        box-shadow:
            0 0 0 30px rgba(var(--eduact-white-rgb), 0.21),
            0 0 0 50px rgba(var(--eduact-white-rgb), 0.21),
            0 0 0 70px rgba(var(--eduact-white-rgb), 0);
    }
}
@keyframes shadows2 {
    0% {
        box-shadow:
            0 0 0 0 rgba(var(--eduact-white-rgb), 0.05),
            0 0 0 30px rgba(var(--eduact-white-rgb), 0.05),
            0 0 0 50px rgba(var(--eduact-white-rgb), 0.05);
    }
    100% {
        box-shadow:
            0 0 0 30px rgba(var(--eduact-white-rgb), 0.05),
            0 0 0 50px rgba(var(--eduact-white-rgb), 0.05),
            0 0 0 70px rgba(var(--eduact-white-rgb), 0);
    }
}
/*--------------------------------------------------------------
  # Mobile Nav
  --------------------------------------------------------------*/
.mobile-nav__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    transform: translateX(-100%);
    transform-origin: left center;
    transition:
        transform 500ms ease 500ms,
        visibility 500ms ease 500ms;
    visibility: hidden;
    position: fixed;
}

.mobile-nav__wrapper .container {
    padding-left: 0;
    padding-right: 0;
}

.mobile-nav__wrapper .home-showcase .row [class*='col-'] {
    flex: 0 0 100%;
}

.mobile-nav__wrapper .home-showcase {
    margin-bottom: -1px;
    margin-top: 0;
    border-bottom: 1px solid RGBA(var(--eduact-white-rgb), 0.1);
}

.mobile-nav__wrapper .home-showcase__inner {
    padding: 15px 0px;
    background-color: transparent;
    box-shadow: none;
}

.mobile-nav__wrapper .home-showcase__title {
    color: var(--eduact-white);
}

.mobile-nav__wrapper.expanded {
    opacity: 1;
    transform: translateX(0%);
    visibility: visible;
    transition:
        transform 500ms ease 0ms,
        visibility 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition:
        opacity 500ms ease 500ms,
        visibility 500ms ease 500ms,
        transform 500ms ease 500ms;
}

.mobile-nav__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--eduact-black);
    opacity: 0.3;
}

.mobile-nav__content {
    width: 300px;
    background-color: var(--eduact-black2);
    z-index: 10;
    position: relative;
    height: 100%;
    overflow-y: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition:
        opacity 500ms ease 0ms,
        visibility 500ms ease 0ms,
        transform 500ms ease 0ms;
}

.mobile-nav__content .main-menu__nav {
    display: block;
    padding: 0;
}

.mobile-nav__content .logo-box {
    margin-bottom: 40px;
    display: flex;
}

.mobile-nav__close {
    position: absolute;
    top: 20px;
    right: 15px;
    font-size: 18px;
    color: var(--eduact-white);
    cursor: pointer;
}

.mobile-nav__close:hover {
    color: var(--eduact-base);
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
    display: none;
    border-top: 1px solid RGBA(var(--eduact-white-rgb), 0.1);
}

.mobile-nav__content .main-menu__list ul li > a {
    padding-left: 1em;
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
    border-bottom: 1px solid RGBA(var(--eduact-white-rgb), 0.1);
}

.mobile-nav__content .main-menu__list li > a {
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    color: var(--eduact-white);
    font-size: 16px;
    font-family: var(--heading-font);
    text-transform: capitalize;
    font-weight: 600;
    height: 46px;
    letter-spacing: 0.48px;
    align-items: center;
    transition: 500ms;
}

.mobile-nav__content .main-menu__list li a.expanded {
    color: var(--eduact-base);
}

.mobile-nav__content .main-menu__list li a button {
    width: 30px;
    height: 30px;
    background-color: var(--eduact-base);
    border: none;
    outline: none;
    color: var(--eduact-white);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform: rotate(-90deg);
    transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
    transform: rotate(0deg);
    background-color: var(--eduact-white);
    color: var(--eduact-black);
}

.mobile-nav__social {
    display: flex;
    align-items: center;
}

.mobile-nav__social a {
    font-size: 16px;
    color: var(--eduact-white);
    transition: 500ms;
}

.mobile-nav__social a + a {
    margin-left: 20px;
}

.mobile-nav__social a:hover {
    color: var(--eduact-base);
}

.mobile-nav__contact {
    margin-bottom: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.mobile-nav__contact li {
    color: var(--eduact-white);
    font-size: 14px;
    font-weight: 500;
    position: relative;
    display: flex;
    align-items: center;
}

.mobile-nav__contact li + li {
    margin-top: 15px;
}

.mobile-nav__contact li a {
    color: inherit;
    transition: 500ms;
}

.mobile-nav__contact li a:hover {
    color: var(--eduact-base);
}

.mobile-nav__contact li > i {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--eduact-base);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    margin-right: 10px;
    color: var(--eduact-white);
}

.mobile-nav__container .main-menu__logo,
.mobile-nav__container .main-menu__right {
    display: none;
}

/*--------------------------------------------------------------
  # Search Popup
  --------------------------------------------------------------*/
.search-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    -webkit-transition: all 1s ease;
    -khtml-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.search-popup__overlay {
    position: fixed;
    width: 224vw;
    height: 224vw;
    top: calc(90px - 112vw);
    right: calc(50% - 112vw);
    z-index: 3;
    display: block;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -khtml-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transition: transform 0.8s ease-in-out;
    -khtml-transition: transform 0.8s ease-in-out;
    -moz-transition: transform 0.8s ease-in-out;
    -ms-transition: transform 0.8s ease-in-out;
    -o-transition: transform 0.8s ease-in-out;
    transition: transform 0.8s ease-in-out;
    transition-delay: 0s;
    transition-delay: 0.3s;
    -webkit-transition-delay: 0.3s;
    background-color: var(--eduact-black);
    opacity: 0.8;
}

@media (max-width: 767px) {
    .search-popup__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        width: 100%;
        height: 100%;
        border-radius: 0;
        transform: translateY(-110%);
    }
}
.search-popup__content {
    position: fixed;
    width: 0;
    max-width: 560px;
    padding: 30px 15px;
    left: 50%;
    top: 50%;
    opacity: 0;
    z-index: 3;
    -webkit-transform: translate(-50%, -50%);
    -khtml-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition:
        opacity 0.5s 0s,
        width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01),
        transform 0.2s 0s;
    -khtml-transition:
        opacity 0.5s 0s,
        width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01),
        transform 0.2s 0s;
    -moz-transition:
        opacity 0.5s 0s,
        width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01),
        transform 0.2s 0s;
    -ms-transition:
        opacity 0.5s 0s,
        width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01),
        transform 0.2s 0s;
    -o-transition:
        opacity 0.5s 0s,
        width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01),
        transform 0.2s 0s;
    transition:
        opacity 0.5s 0s,
        width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01),
        transform 0.2s 0s;
    transition-delay: 0s, 0.8s, 0s;
    transition-delay: 0s, 0.4s, 0s;
    transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
}

.search-popup__form {
    position: relative;
}

.search-popup__form input[type='search'],
.search-popup__form input[type='text'] {
    width: 100%;
    background-color: var(--eduact-white);
    font-size: 15px;
    color: var(--eduact-text);
    border: none;
    outline: none;
    height: 66px;
    padding-left: 30px;
}

.search-popup__form .eduact-btn {
    padding: 0;
    width: 66px;
    height: 66px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 0;
    right: -1px;
    border-radius: 0;
}

.search-popup__form .eduact-btn i {
    margin: 0;
}

.search-popup__form .eduact-btn::after {
    background-color: var(--eduact-black);
}

.search-popup.active {
    z-index: 9999;
}

.search-popup.active .search-popup__overlay {
    top: auto;
    bottom: calc(90px - 112vw);
    -webkit-transform: scale(1);
    -khtml-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    opacity: 0.8;
    -webkit-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -khtml-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -moz-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -ms-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    -o-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
    transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
}

@media (max-width: 767px) {
    .search-popup.active .search-popup__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
        width: 100%;
        height: 100%;
        border-radius: 0;
        transform: translateY(0%);
    }
}
.search-popup.active .search-popup__content {
    width: 100%;
    opacity: 1;
    transition-delay: 0.7s;
    -webkit-transition-delay: 0.7s;
}

/*--------------------------------------------------------------
  # Hero Banner
  --------------------------------------------------------------*/
.hero-banner {
    position: relative;
    background-color: var(--eduact-base);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right bottom;
    padding: 166px 0 92px;
    overflow: hidden;
    z-index: 2;
}

.hero-banner__bg-shape1 {
    position: absolute;
    left: -180px;
    top: -55px;
    width: 759px;
    height: 759px;
    border-radius: 50%;
    background: linear-gradient(
        180deg,
        rgba(var(--eduact-white-rgb), 0.09) 0%,
        rgba(var(--eduact-white-rgb), 0) 100%
    );
    box-shadow: 54px 24px 39px 0px rgba(var(--eduact-black2-rgb), 0.02);
}

@media (max-width: 1199px) {
    .hero-banner__bg-shape1 {
        width: 640px;
        height: 640px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hero-banner__bg-shape1 {
        width: 759px;
        height: 759px;
    }
}
.hero-banner__bg-shape1::after {
    position: absolute;
    left: -140px;
    top: -140px;
    content: '';
    width: calc(100% + 280px);
    height: calc(100% + 280px);
    border-radius: 50%;
    background: linear-gradient(
        180deg,
        rgba(var(--eduact-white-rgb), 0.09) 0%,
        rgba(var(--eduact-white-rgb), 0) 100%
    );
    box-shadow: 54px 24px 39px 0px rgba(var(--eduact-black2-rgb), 0.02);
    animation-fill-mode: both;
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite;
}

.hero-banner__bg-round {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    animation: circle 15s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

.hero-banner__bg-round-border {
    width: 33px;
    height: 33px;
    border-radius: 100%;
    border: 1px solid #d9d9d9;
    position: absolute;
    right: 250px;
    top: 0;
}

.hero-banner__content {
    position: relative;
    z-index: 4;
    padding: 90px 0 0;
}

@media (max-width: 1199px) {
    .hero-banner__content {
        padding: 45px 0 0;
    }
}
@media (max-width: 991px) {
    .hero-banner__content {
        padding: 0;
    }
}
.hero-banner__title {
    font-size: 70px;
    line-height: 85px;
    font-weight: 800;
    color: var(--eduact-white);
    margin: 0 0 12px;
}

@media (max-width: 1400px) {
    .hero-banner__title {
        font-size: 65px;
        line-height: 80px;
    }
}
@media (max-width: 1199px) {
    .hero-banner__title {
        font-size: 55px;
        line-height: 65px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .hero-banner__title {
        max-width: 510px;
    }
}
@media (max-width: 767px) {
    .hero-banner__title {
        font-size: 40px;
        line-height: 48px;
    }
}
.hero-banner__text {
    line-height: 32px;
    color: var(--eduact-white);
    position: relative;
    margin: 0 0 30px;
}

.hero-banner__text img {
    position: absolute;
    right: 109px;
    top: 17px;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@media (max-width: 1199px) {
    .hero-banner__text img {
        display: none;
    }
}
.hero-banner__btn {
    display: flex;
}

.hero-banner__btn .eduact-btn + .eduact-btn {
    margin-left: 20px;
    background-color: transparent;
    border: 1px solid var(--eduact-white);
}

@media (max-width: 500px) {
    .hero-banner__btn i {
        display: none;
    }
    .hero-banner__btn .eduact-btn {
        padding-left: 25px;
        padding-right: 25px;
    }
}
.hero-banner__thumb {
    position: relative;
    z-index: 4;
    width: 642px;
    height: 642px;
    border-radius: 50%;
    padding: 34px;
    background: linear-gradient(147deg, #ff7200 0%, #6a78ff 100%);
    box-shadow: 27px 27px 52px 0px rgba(0, 0, 0, 0.08);
    margin: 0 0 0 95px;
}

@media (max-width: 1500px) {
    .hero-banner__thumb {
        width: 600px;
        height: 600px;
        margin-left: 20px;
    }
}
@media (max-width: 1300px) {
    .hero-banner__thumb {
        width: 515px;
        height: 515px;
        margin-left: 20px;
    }
}
@media (max-width: 1199px) {
    .hero-banner__thumb {
        width: 455px;
        height: 455px;
        margin-left: 0;
    }
}
@media (max-width: 991px) {
    .hero-banner__thumb {
        width: 100%;
        height: auto;
        margin: 40px 0 0;
    }
}
.hero-banner__thumb > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.hero-banner__cap {
    position: absolute;
    left: -46px;
    top: 50px;
}

.hero-banner__cap img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@media (max-width: 1199px) {
    .hero-banner__cap {
        display: none;
    }
}
.hero-banner__star {
    position: absolute;
    left: 146px;
    right: 0;
    margin: auto;
    text-align: center;
    top: -54px;
}

.hero-banner__star img {
    -webkit-animation: blinker2 3s infinite linear;
    animation: blinker2 3s infinite linear;
}

@media (max-width: 1199px) {
    .hero-banner__star {
        display: none;
    }
}
.hero-banner__map {
    position: absolute;
    right: 95px;
    top: -12px;
}

.hero-banner__map img {
    -webkit-animation: rotated 20s infinite linear;
    animation: rotated 20s infinite linear;
}

@media (max-width: 1199px) {
    .hero-banner__map {
        display: none;
    }
}
.hero-banner__book {
    position: absolute;
    right: 35px;
    bottom: 22px;
}

.hero-banner__book img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@media (max-width: 1199px) {
    .hero-banner__book {
        display: none;
    }
}
.hero-banner__star2 {
    position: absolute;
    left: -166px;
    right: 0;
    margin: auto;
    text-align: center;
    bottom: -6px;
}

.hero-banner__star2 img {
    -webkit-animation: blinker2 3s infinite linear;
    animation: blinker2 3s infinite linear;
}

@media (max-width: 1199px) {
    .hero-banner__star2 {
        display: none;
    }
}
.hero-banner__video {
    width: 126px;
    height: 126px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -35px;
    top: 154px;
}

@media (max-width: 1199px) {
    .hero-banner__video {
        left: 0;
        right: 0;
        margin: auto;
        top: auto;
        bottom: 55px;
    }
}
.hero-banner__video .video-popup {
    width: 49px;
    height: 49px;
    background-color: var(--eduact-white);
    border-radius: 50%;
    font-size: 20px;
    color: var(--eduact-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -6px;
}

.hero-banner__video .video-popup:hover {
    background-color: var(--eduact-black);
    color: var(--eduact-white);
}

.hero-banner__video .video-popup::before {
    content: '';
    border: 1px solid rgba(var(--eduact-white-rgb), 0.47);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: calc(100% + 107px);
    height: calc(100% + 107px);
    border-radius: 50%;
    animation: zoomBig 3.25s linear infinite;
    animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-delay: 0.55s;
    animation-delay: 0.55s;
}

.hero-banner__video .video-popup::after {
    content: '';
    border: 1px solid rgba(var(--eduact-white-rgb), 0.47);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: calc(100% + 107px);
    height: calc(100% + 107px);
    border-radius: 50%;
    animation: zoomBig 3s linear infinite;
    animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.hero-banner__cart {
    position: absolute;
    left: -73px;
    bottom: 58px;
}

@media (max-width: 1199px) {
    .hero-banner__cart {
        display: none;
    }
}
.hero-banner__cart__thumb {
    width: 67px;
    height: 67px;
    background-color: var(--eduact-base);
    border-radius: 50%;
    padding: 7px;
    margin: 0 0 -22px;
    z-index: 2;
    position: relative;
}

.hero-banner__cart__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-banner__cart__content {
    position: relative;
    width: 210px;
}

.hero-banner__cart__content-inner {
    position: relative;
    overflow: hidden;
    background-color: var(--eduact-white);
    border-radius: 12px;
    padding: 26px 28px 20px;
}

.hero-banner__cart__content::after {
    position: absolute;
    left: 18px;
    top: 18px;
    width: 100%;
    height: 100%;
    content: '';
    border: 0.8px solid rgba(var(--eduact-white-rgb), 0.47);
    border-radius: 7px;
    transition: all 500ms ease;
    z-index: -1;
}

.hero-banner__cart__content:hover::after {
    left: 0;
    top: 0;
    border-radius: 13px;
}

.hero-banner__cart__content svg {
    position: absolute;
    right: 0;
    bottom: 0;
    fill: var(--eduact-base);
    width: 88px;
    height: 38px;
}

.hero-banner__cart__title {
    font-size: 15.776px;
    color: var(--eduact-black2);
    font-weight: 600;
    margin-bottom: 2px;
}

.hero-banner__cart__text {
    font-size: 12.27px;
    color: var(--eduact-grey);
    font-weight: 600;
    margin-bottom: 9px;
}

.hero-banner__cart .eduact-btn {
    height: 34px;
    font-size: 15.776px;
    background-color: var(--eduact-secondary);
    border-radius: 6px;
    padding: 5px 14.5px;
}

.hero-banner__cart .eduact-btn i {
    margin-left: 9px;
}

.hero-banner__cart .eduact-btn__curve {
    width: 0;
}

.hero-banner__border {
    border: 1px solid #d9d9d9;
    width: 33px;
    height: 33px;
    position: absolute;
    left: 56px;
    bottom: 203px;
    border-radius: 50%;
    animation: zoomBig 3s linear infinite;
    animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

@media (max-width: 1300px) {
    .hero-banner__border {
        display: none;
    }
}
.hero-banner-two {
    position: relative;
    background-color: var(--eduact-soft2);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 102px 0 110px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .hero-banner-two {
        padding-bottom: 90px;
    }
}
.hero-banner-two__shape1 {
    position: absolute;
    left: 90px;
    top: 140px;
}

@media (max-width: 1300px) {
    .hero-banner-two__shape1 {
        display: none;
    }
}
@media (max-width: 1499px) {
    .hero-banner-two__shape1 {
        left: 20px;
    }
}
.hero-banner-two__shape1 svg {
    width: 96px;
    height: auto;
}

.hero-banner-two__shape1 svg path {
    animation: change-background 4s ease infinite;
}

.hero-banner-two__shape2 {
    position: absolute;
    left: 135px;
    bottom: 84px;
}

@media (max-width: 1499px) {
    .hero-banner-two__shape2 {
        left: 30px;
    }
}
@media (max-width: 1300px) {
    .hero-banner-two__shape2 {
        display: none;
    }
}
.hero-banner-two__shape2 img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.hero-banner-two__shape3 {
    width: 180px;
    position: absolute;
    left: -198px;
    right: 0;
    margin: auto;
    bottom: 110px;
}

@media (max-width: 1300px) {
    .hero-banner-two__shape3 {
        display: none;
    }
}
.hero-banner-two__shape3 img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.hero-banner-two__shape4 {
    top: 236px;
    width: 129px;
    position: absolute;
    left: 165px;
    right: 0;
    margin: auto;
}

@media (max-width: 1499px) {
    .hero-banner-two__shape4 {
        left: 120px;
    }
}
@media (max-width: 1300px) {
    .hero-banner-two__shape4 {
        display: none;
    }
}
.hero-banner-two__shape4 img {
    -webkit-animation: rotated 20s infinite linear;
    animation: rotated 20s infinite linear;
}

.hero-banner-two__shape5 {
    top: 129px;
    width: 59px;
    position: absolute;
    left: 20.5%;
    right: 0;
    margin: auto;
    z-index: 2;
}

@media (max-width: 1300px) {
    .hero-banner-two__shape5 {
        display: none;
    }
}
.hero-banner-two__shape5 img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.hero-banner-two__shape6 {
    bottom: 56px;
    position: absolute;
    right: 20%;
    z-index: 2;
}

@media (max-width: 1300px) {
    .hero-banner-two__shape6 {
        display: none;
    }
}
.hero-banner-two__shape6 img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.hero-banner-two__content {
    position: relative;
    z-index: 2;
}

.hero-banner-two__title {
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -1.4px;
    margin: 0 0 19px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hero-banner-two__title {
        font-size: 62px;
        line-height: 65px;
    }
}
@media (max-width: 767px) {
    .hero-banner-two__title {
        font-size: 45px;
        line-height: 52px;
        letter-spacing: 0;
    }
}
.hero-banner-two__title span {
    position: relative;
    z-index: 2;
}

.hero-banner-two__title svg {
    width: 237px;
    height: 79px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hero-banner-two__title svg {
        width: 215px;
        height: auto;
    }
}
@media (max-width: 767px) {
    .hero-banner-two__title svg {
        width: 165px;
        height: auto;
    }
}
.hero-banner-two__title svg path {
    animation: change-background 4s ease infinite;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1200;
        stroke-width: 5;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-width: 10;
    }
}
.hero-banner-two__text {
    line-height: 32px;
    margin: 0 0 30px;
}

.hero-banner-two__btn {
    display: flex;
}

.hero-banner-two__btn .eduact-btn {
    text-transform: none;
}

.hero-banner-two__stretch-image {
    position: relative;
    text-align: right;
    border-radius: 285px 0px 0px 285px;
    border: 17px solid var(--eduact-white);
    border-right: none;
    overflow: hidden;
    max-width: 886px;
    margin-left: auto;
}

@media (max-width: 991px) {
    .hero-banner-two__stretch-image {
        margin-top: 50px;
    }
}
.hero-banner-two__stretch-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.hero-banner-two__bg-shape1 {
    position: absolute;
    right: 124px;
    top: 25px;
}

@media (max-width: 1399px) {
    .hero-banner-two__bg-shape1 {
        right: 50px;
    }
}
@media (max-width: 1300px) {
    .hero-banner-two__bg-shape1 {
        right: 10px;
    }
}
@media (max-width: 991px) {
    .hero-banner-two__bg-shape1 {
        right: 0;
        top: auto;
        bottom: 0;
    }
}
.hero-banner-two__bg-shape1 svg {
    width: 729px;
    height: auto;
}

@media (max-width: 1399px) {
    .hero-banner-two__bg-shape1 svg {
        width: 600px;
    }
}
@media (max-width: 1300px) {
    .hero-banner-two__bg-shape1 svg {
        width: 500px;
    }
}
@media (max-width: 767px) {
    .hero-banner-two__bg-shape1 svg {
        width: 400px;
    }
}
.hero-banner-two__bg-shape1 svg path {
    animation: change-background 4s ease infinite;
}

@keyframes change-background {
    0% {
        fill: var(--eduact-base);
    }
    50% {
        fill: var(--eduact-secondary);
    }
    100% {
        fill: var(--eduact-base);
    }
}
/*--------------------------------------------------------------
  # Service
  --------------------------------------------------------------*/
.service-one {
    position: relative;
    background-color: var(--eduact-white);
    padding: 120px 0 0;
}

@media (max-width: 767px) {
    .service-one {
        padding-top: 80px;
    }
}
.service-one .container {
    position: relative;
    z-index: 2;
}

.service-one__bg {
    position: absolute;
    top: -490px;
    right: 0;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right top;
    width: 100%;
    height: 1882px;
    z-index: 1;
}

.service-one__item {
    position: relative;
    border-radius: 14px;
    margin: 0 0 30px;
    box-shadow: 11px 15px 30px 0px rgba(128, 137, 225, 0.1);
}

.service-one__item:hover .service-one__icon {
    background-color: var(--eduact-base);
}

.service-one__item:hover .service-one__icon span {
    transform: scale(0.9);
}

.service-one__item:hover svg path {
    fill: var(--eduact-base);
}

.service-one__item svg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 118px;
    height: 129px;
}

.service-one__item svg path {
    transition: 300ms ease;
}

.service-one__wrapper {
    overflow: hidden;
    padding: 39px 29px 33px;
    position: relative;
    z-index: 2;
    border: 1px solid var(--eduact-soft3);
    background-color: var(--eduact-white);
    border-radius: 14px;
}

.service-one__icon {
    width: 76px;
    height: 76px;
    background-color: var(--eduact-secondary);
    font-size: 38px;
    line-height: 1;
    position: relative;
    z-index: 3;
    color: var(--eduact-white);
    margin: 0 0 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    transition: 500ms ease;
}

.service-one__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transition-delay: 0.1s;
    transform: scale(1);
}

.service-one__title {
    font-size: 18px;
    margin: 0 0 17px;
}

.service-one__title a {
    color: inherit;
}

.service-one__title a:hover {
    color: var(--eduact-base);
}

.service-one__text {
    font-size: 14px;
    margin: 0 0 15px;
}

.service-one__rm {
    display: flex;
    align-items: center;
    color: var(--eduact-secondary);
    font-weight: 700;
    font-size: 16px;
}

.service-one__rm span {
    color: var(--eduact-base);
    font-size: 10px;
    position: relative;
    top: 2px;
    padding-left: 10px;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.service-one__rm:hover {
    color: var(--eduact-base);
}

.service-one__rm:hover span {
    animation: iconTranslateX 0.4s forwards;
}

.service-two {
    position: relative;
    background-color: var(--eduact-soft5);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    padding: 120px 0 85px;
}

@media (max-width: 767px) {
    .service-two {
        padding: 80px 0 55px;
    }
}
.service-two__item {
    position: relative;
    border-radius: 10px;
    margin: 0 0 36px;
    box-shadow: 0px 0px 60px 0px rgba(var(--eduact-black2-rgb), 0.07);
}

.service-two__item:hover .service-two__icon {
    background-color: var(--eduact-base);
}

.service-two__item:hover .service-two__icon span {
    transform: scale(0.9);
}

.service-two__item:hover .service-two__rm {
    color: var(--eduact-white);
}

.service-two__item:hover .service-two__rm::before {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
}

.service-two__item:hover .service-two__rm::after {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
}

.service-two__item svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    max-height: 117px;
    transition: 300ms ease;
    fill: var(--eduact-soft5);
}

.service-two__wrapper {
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 2;
    background-color: var(--eduact-white);
    border-radius: 10px;
}

.service-two__icon {
    width: 166px;
    height: 166px;
    background-color: var(--eduact-secondary);
    font-size: 60px;
    line-height: 1;
    position: relative;
    z-index: 3;
    color: var(--eduact-white);
    margin: -66px auto 35px;
    display: flex;
    align-items: end;
    justify-content: center;
    border-radius: 50%;
    transition: 500ms ease;
    padding: 0 0 22px;
}

.service-two__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transition-delay: 0.1s;
    transform: scale(1);
}

.service-two__title {
    font-size: 20px;
    margin: 0 25px 18px;
}

.service-two__title a {
    color: inherit;
}

.service-two__title a:hover {
    color: var(--eduact-base);
}

.service-two__text {
    font-weight: 500;
    max-width: 230px;
    margin: 0 auto 32px;
}

.service-two__rm {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    border-top: 1px solid var(--eduact-soft4);
    color: var(--eduact-black);
    font-weight: 700;
    font-size: 16px;
    z-index: 2;
}

.service-two__rm span {
    color: var(--eduact-secondary);
    font-size: 10px;
    position: relative;
    top: 1px;
    padding-left: 10px;
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.service-two__rm:hover {
    color: var(--eduact-secondary);
    border-color: transparent;
}

.service-two__rm:hover span {
    animation: iconTranslateX 0.4s forwards;
}

.service-two__rm::after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(var(--eduact-black2-rgb), 0.46);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease;
    transform: scale(1.1);
    z-index: -1;
}

.service-two__rm::before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    content: '';
    transition: 500ms ease;
    visibility: hidden;
    opacity: 0;
    transition: all 500ms ease;
    z-index: -1;
    transform: scale(1.1);
}

.service-three {
    position: relative;
    background-color: var(--eduact-soft5);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 120px 0 85px;
}

@media (max-width: 767px) {
    .service-three {
        padding: 80px 0 55px;
    }
}
.service-three .section-title__tagline {
    padding: 0;
}

@media (max-width: 991px) {
    .service-three .section-title {
        margin-bottom: 40px;
    }
}
@media (max-width: 1199px) {
    .service-three .section-title br {
        display: none;
    }
}
.service-three__section-text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 18px 0 0;
    padding: 10px 0 0;
    border-top: 2px solid var(--eduact-secondary);
}

@media (max-width: 991px) {
    .service-three__section-text {
        margin: 0 0 35px;
    }
}
.service-three__item {
    position: relative;
    border-radius: 10px;
    margin: 0 0 36px;
    box-shadow: 0px 0px 60px 0px rgba(var(--eduact-black2-rgb), 0.07);
}

.service-three__item:hover .service-three__icon span {
    transform: scale(0.9);
}

.service-three__item:hover .service-three__hover {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.service-three__item:hover .service-three__title {
    color: var(--eduact-white);
}

.service-three__item:hover .service-three__text {
    color: var(--eduact-white);
}

.service-three__item:hover .service-three__br {
    background-color: var(--eduact-base);
}

.service-three__item:hover .service-three__rm {
    background-color: var(--eduact-base);
}

.service-three__item:hover .service-three__rm::after,
.service-three__item:hover .service-three__rm::before {
    background-color: var(--eduact-white);
}

.service-three__wrapper {
    overflow: hidden;
    padding: 0;
    position: relative;
    z-index: 2;
    background-color: var(--eduact-white);
    border-radius: 10px;
}

.service-three__hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: 500ms ease;
    transform: scale(1.3);
    z-index: -1;
}

.service-three__hover::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(54, 48, 93, 0.92);
}

.service-three__icon {
    font-size: 68px;
    line-height: 68px;
    position: relative;
    z-index: 3;
    color: var(--eduact-secondary);
    display: block;
    margin: 40px 0 20px;
    transition: 500ms ease;
}

.service-three__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transition-delay: 0.1s;
    transform: scale(1);
}

.service-three__title {
    font-size: 20px;
    margin: 0 25px 18px;
    transition: 500ms ease;
}

.service-three__title a {
    color: inherit;
}

.service-three__title a:hover {
    color: var(--eduact-secondary);
}

.service-three__text {
    font-weight: 500;
    max-width: 230px;
    margin: 0 auto 32px;
    transition: 500ms ease;
}

.service-three__br {
    width: 100%;
    height: 1px;
    display: block;
    background-color: var(--eduact-soft4);
    transition: 500ms ease;
}

.service-three__rm {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 88px;
    background-color: var(--eduact-soft2);
    color: var(--eduact-black);
    font-weight: 700;
    font-size: 16px;
    z-index: 2;
    margin: auto;
}

.service-three__rm:hover {
    background-color: var(--eduact-secondary) !important;
}

.service-three__rm::after,
.service-three__rm::before {
    content: '';
    width: 22px;
    height: 2px;
    background-color: var(--eduact-black);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 400ms linear;
}

.service-three__rm::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about-one {
    position: relative;
    padding: 90px 0 120px;
    background-color: var(--eduact-white);
}

@media (max-width: 767px) {
    .about-one {
        padding: 50px 0 80px;
    }
}
.about-one .container {
    position: relative;
    z-index: 2;
}

.about-one__thumb {
    position: relative;
    margin: 0 14px 0 -33px;
}

@media (max-width: 1199px) {
    .about-one__thumb {
        margin: 0;
        max-width: 661px;
    }
}
.about-one__thumb__one {
    display: block;
    position: relative;
    z-index: 2;
}

.about-one__thumb__one img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.about-one__thumb__one::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: 70%;
    height: 70%;
    content: '';
    border-radius: 362.021px;
    background: linear-gradient(
        147deg,
        rgba(255, 114, 0, 0.39) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 27px 27px 52px 0px rgba(0, 0, 0, 0.08);
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite;
}

.about-one__thumb__shape1 {
    position: absolute;
    left: 0;
    top: 36px;
}

.about-one__thumb__shape1 img {
    -webkit-animation: rotated 20s infinite linear;
    animation: rotated 20s infinite linear;
}

@media (max-width: 767px) {
    .about-one__thumb__shape1 {
        display: none;
    }
}
.about-one__thumb__shape2 {
    position: absolute;
    z-index: 2;
    right: 36px;
    bottom: 25px;
}

.about-one__thumb__shape2 img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-timing-function: linear;
}

@media (max-width: 767px) {
    .about-one__thumb__shape2 {
        display: none;
    }
}
.about-one__thumb__box {
    position: absolute;
    z-index: 2;
    left: -24px;
    bottom: 37px;
    width: 350px;
    border-radius: 14px;
    background: var(--eduact-white);
    box-shadow: -18px 11px 52px 0px rgba(var(--eduact-black2-rgb), 0.08);
    padding: 28px 30px 33px 59px;
}

@media (max-width: 1300px) {
    .about-one__thumb__box {
        left: 38px;
    }
}
@media (max-width: 1199px) {
    .about-one__thumb__box {
        left: 34px;
    }
}
@media (max-width: 767px) {
    .about-one__thumb__box {
        bottom: 0;
        width: 310px;
    }
}
.about-one__thumb__box__icon {
    width: 76px;
    height: 76px;
    background-color: var(--eduact-secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -38px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 38px;
    color: var(--eduact-white);
    overflow: hidden;
}

.about-one__thumb__box__icon::after {
    position: absolute;
    content: '';
    right: -50%;
    bottom: -40px;
    width: 100%;
    height: 100%;
    background: var(--eduact-soft2);
    border-radius: 50%;
    opacity: 0.2;
    z-index: 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.about-one__thumb__box__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transform: scale(1);
}

.about-one__thumb__box:hover .about-one__thumb__box__icon span {
    transform: scale(0.9);
}

.about-one__thumb__box:hover .about-one__thumb__box__icon::after {
    right: 0;
    bottom: 0;
}

.about-one__thumb__box__title {
    font-size: 16px;
    font-weight: 500;
    color: var(--eduact-text);
    line-height: 26px;
    margin: 0 0 9px;
}

.about-one__thumb__box__text {
    font-size: 24px;
    color: var(--eduact-base);
    font-weight: 700;
    letter-spacing: 0.72px;
    margin: 0;
}

.about-one__thumb__box__text a {
    color: inherit;
}

.about-one__thumb__box__text a:hover {
    color: var(--eduact-secondary);
}

.about-one .section-title__tagline {
    margin-bottom: 21px;
}

.about-one .section-title {
    margin-bottom: 24px;
}

.about-one__content {
    position: relative;
    padding: 45px 0 0;
}

.about-one__content__text {
    line-height: 26px;
    margin: 0 0 33px;
}

.about-one__content .eduact-btn {
    margin-top: 20px;
}

.about-one__box {
    position: relative;
    padding: 1px;
    background: linear-gradient(
        180deg,
        rgba(var(--eduact-secondary-rgb), 1) 0%,
        rgba(var(--eduact-secondary-rgb), 0.15) 100%
    );
    border-radius: 24px;
    margin: 0 0 30px;
}

.about-one__box__wrapper {
    border-radius: 23px;
    padding: 18px 32px 23px 130px;
    width: 100%;
    background-color: var(--eduact-white);
}

@media (max-width: 575px) {
    .about-one__box__wrapper {
        padding-left: 32px;
    }
}
.about-one__box__icon {
    display: flex;
    width: 76px;
    height: 76px;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    background: rgba(128, 137, 225, 0.12);
    font-size: 38px;
    color: var(--eduact-base);
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
}

@media (max-width: 575px) {
    .about-one__box__icon {
        position: relative;
        left: 0;
        margin: 15px 0 25px;
    }
}
.about-one__box__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transition-delay: 0.1s;
    transform: scale(1);
}

.about-one__box:hover .about-one__box__icon span {
    transform: scale(0.9);
}

.about-one__box__title {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 7px;
}

.about-one__box__text {
    font-size: 14px;
    margin: 0;
}

.about-two {
    position: relative;
    overflow: hidden;
    padding: 120px 0 168px;
}

@media (max-width: 1199px) {
    .about-two {
        padding-bottom: 120px;
    }
}
@media (max-width: 767px) {
    .about-two {
        padding: 80px 0;
    }
}
.about-two--about {
    padding-bottom: 65px;
}

@media (max-width: 1199px) {
    .about-two--about {
        padding-bottom: 0;
    }
}
.about-two__thumb {
    position: relative;
}

@media (max-width: 1199px) {
    .about-two__thumb {
        max-width: 613px;
        margin: auto;
    }
}
@media (min-width: 1400px) {
    .about-two__thumb {
        margin-right: 29px;
    }
}
.about-two__thumb__one {
    position: relative;
    text-align: right;
}

@media (max-width: 767px) {
    .about-two__thumb__one {
        text-align: left;
    }
}
.about-two__thumb__one img {
    border-radius: 500px;
    max-width: 100%;
    height: auto;
}

.about-two__thumb__two {
    border-radius: 500px;
    border: 10px solid var(--eduact-white);
    position: absolute;
    left: -41px;
    bottom: 8px;
    max-width: 305px;
}

@media (max-width: 767px) {
    .about-two__thumb__two {
        display: none;
    }
}
.about-two__thumb__two img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 500px;
}

.about-two__thumb__two svg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 212px;
    height: auto;
}

.about-two__thumb__two-icon {
    width: 70px;
    height: 70px;
    background-color: var(--eduact-base);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--eduact-white);
    font-size: 44px;
    z-index: 2;
    position: absolute;
    right: -5px;
    bottom: 12px;
}

.about-two__thumb__two-icon span {
    display: inline-block;
}

.about-two__thumb__shape1 {
    position: absolute;
    left: 149px;
    top: 9px;
    z-index: -1;
}

@media (max-width: 767px) {
    .about-two__thumb__shape1 {
        display: none;
    }
}
.about-two__thumb__shape1 img {
    -webkit-animation: rotated 20s infinite linear;
    animation: rotated 20s infinite linear;
}

.about-two__thumb__shape2 {
    position: absolute;
    left: -84px;
    top: 117px;
    z-index: -1;
}

@media (max-width: 767px) {
    .about-two__thumb__shape2 {
        display: none;
    }
}
.about-two__thumb__shape2 img {
    -webkit-animation: rotated 15s infinite linear;
    animation: rotated 15s infinite linear;
}

.about-two__thumb__shape3 {
    position: absolute;
    left: -54px;
    bottom: 2px;
    z-index: -1;
}

@media (max-width: 767px) {
    .about-two__thumb__shape3 {
        display: none;
    }
}
.about-two__thumb__shape3 img {
    -webkit-animation: rotated 15s infinite linear;
    animation: rotated 15s infinite linear;
}

.about-two__thumb__shape4 {
    position: absolute;
    right: 88px;
    bottom: -54px;
}

@media (max-width: 767px) {
    .about-two__thumb__shape4 {
        display: none;
    }
}
.about-two__thumb__shape4 img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.about-two__fact {
    position: absolute;
    left: -32px;
    top: 9px;
    width: 267px;
}

@media (max-width: 767px) {
    .about-two__fact {
        position: relative;
        left: 0;
        top: 0;
        margin: -70px 0 -20px;
    }
}
.about-two__fact__icon {
    width: 70px;
    height: 70px;
    background-color: var(--eduact-base);
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--eduact-white);
    font-size: 44px;
    margin: 0 0 -34px 30px;
    z-index: 2;
    position: relative;
}

.about-two__fact__icon span {
    display: inline-block;
}

.about-two__fact:hover .about-two__fact__icon span {
    animation: bounceIn 1s linear;
    animation-duration: 1s;
    animation-duration: 1s;
    animation-duration: 2s;
}

.about-two__fact__content {
    position: relative;
    background-color: var(--eduact-white);
    border-radius: 10px;
    box-shadow: 0px 0px 60px 0px rgba(var(--eduact-black2-rgb), 0.07);
    padding: 49px 25px 27px;
    text-align: center;
}

.about-two__fact__count {
    display: block;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    color: var(--eduact-secondary);
    margin: 0;
    position: relative;
    z-index: 2;
}

.about-two__fact__count .count-box {
    display: inline-flex;
}

.about-two__fact__title {
    line-height: 1;
    font-size: 20px;
    color: var(--eduact-text);
    font-weight: 600;
    margin: 0;
}

.about-two__content {
    position: relative;
}

@media (max-width: 1199px) {
    .about-two__content {
        padding-top: 70px;
    }
}
@media (min-width: 1400px) {
    .about-two__content {
        padding-top: 50px;
    }
}
.about-two__content .section-title__tagline {
    padding: 0;
}

.about-two__content .section-title {
    margin-bottom: 25px;
}

.about-two__content__text {
    font-weight: 600;
    margin: 0 0 33px;
}

.about-two__box {
    position: relative;
    background-color: var(--eduact-soft2);
    border-radius: 11px;
    padding: 5px 20px 24px 122px;
    margin: 0 0 40px;
}

@media (max-width: 767px) {
    .about-two__box {
        padding-left: 108px;
    }
}
.about-two__box svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: var(--eduact-secondary);
    width: 295px;
    height: auto;
}

.about-two__box__icon {
    font-size: 48px;
    line-height: 48px;
    color: var(--eduact-white);
    position: absolute;
    left: 21px;
    top: 53px;
    display: flex;
}

.about-two__box__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transform: scale(1);
}

.about-two__box:hover .about-two__box__icon span {
    transform: scale(0.9);
}

.about-two__box__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    color: var(--eduact-white);
    margin: 0 0 20px;
    position: relative;
}

.about-two__box__text {
    font-weight: 600;
    margin: 0;
    position: relative;
}

.about-two__lists {
    margin: 0 0 32px;
    padding: 0;
    list-style: none;
    clear: both;
}

.about-two__lists li {
    list-style: none;
    padding: 4px 0 0 49px;
    position: relative;
    font-size: 20px;
    color: var(--eduact-black);
    font-weight: 700;
    line-height: 26px;
    min-height: 32px;
    width: 50%;
    float: left;
    margin: 0 0 18px;
}

@media (max-width: 767px) {
    .about-two__lists li {
        width: 100%;
        float: none;
    }
}
.about-two__lists li span {
    position: absolute;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    background-color: var(--eduact-base);
    border-radius: 50%;
    text-align: center;
    color: var(--eduact-white);
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 400ms linear;
}

.about-two__lists li span::before {
    display: inline-block;
}

.about-two__lists li:hover span::before {
    animation: bounceIn 1s linear;
    animation-duration: 1s;
    animation-duration: 1s;
    animation-duration: 1s;
}

.about-two__lists li:hover span {
    background-color: var(--eduact-secondary);
}

.about-two__about-box {
    position: relative;
    margin-bottom: 46px;
}

.about-two__about-box:hover .about-two__about-box__icon span {
    transform: rotateY(360deg);
}

.about-two__about-box__top {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.about-two__about-box__icon {
    font-size: 50px;
    line-height: 50px;
    color: var(--eduact-base);
    margin-right: 29px;
}

.about-two__about-box__icon span {
    display: block;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.about-two__about-box__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
}

.about-two__about-box__text {
    font-weight: 600;
    margin: 0;
}

.about-three {
    position: relative;
    overflow: hidden;
    padding: 116px 0 120px;
}

@media (max-width: 767px) {
    .about-three {
        padding: 76px 0 80px;
    }
}
.about-three__content {
    position: relative;
}

.about-three__content .section-title__tagline {
    padding: 0;
}

.about-three__content .section-title {
    margin-bottom: 20px;
}

.about-three__content__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0 0 29px;
}

.about-three__box {
    position: relative;
    background-color: var(--eduact-soft2);
    border-radius: 8px;
    padding: 21px 20px 18px 130px;
    margin: 0 0 40px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .about-three__box {
        padding-left: 108px;
    }
}
.about-three__box::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 66px;
    height: 100%;
    background-color: var(--eduact-base);
    content: '';
}

.about-three__box__icon {
    width: 76px;
    height: 76px;
    background-color: var(--eduact-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid var(--eduact-white);
    font-size: 38px;
    color: var(--eduact-white);
    position: absolute;
    left: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
}

.about-three__box__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transform: scale(1);
}

.about-three__box:hover .about-three__box__icon span {
    transform: scale(0.9);
}

.about-three__box__title {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 7px;
    position: relative;
}

.about-three__box__text {
    font-weight: 600;
    letter-spacing: 0.32px;
    line-height: 26px;
    margin: 0;
    position: relative;
}

.about-three__author {
    position: relative;
    padding: 9px 0 0 92px;
    min-height: 76px;
    max-width: 286px;
}

.about-three__author::after {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 1px;
    height: 62px;
    background-color: var(--eduact-soft4);
}

@media (max-width: 767px) {
    .about-three__author::after {
        display: none;
    }
}
.about-three__author > img {
    max-width: 100%;
}

@media (max-width: 1399px) {
    .about-three__author > img {
        max-width: 90%;
    }
}
.about-three__author__thumb {
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 2px solid var(--eduact-secondary);
    padding: 5px;
    position: absolute;
    left: 0;
    top: 0;
}

.about-three__author__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.about-three__author__text {
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.32px;
    margin: 1px 0 0;
}

.about-three__br {
    border-bottom: 1px solid var(--eduact-soft4);
    margin: 39px 0 40px;
}

.about-three__info {
    position: relative;
    padding: 0 0 0 68px;
    margin-left: -14px;
    margin-top: 9px;
}

@media (max-width: 1399px) {
    .about-three__info {
        margin-left: -8px;
    }
}
@media (max-width: 767px) {
    .about-three__info {
        margin: 15px 0 0;
    }
}
.about-three__info__icon {
    width: 53px;
    height: 53px;
    border: 2px solid var(--eduact-soft4);
    border-radius: 50%;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--eduact-secondary);
    position: absolute;
    left: 0;
    top: 2px;
}

.about-three__info__text {
    font-weight: 500;
    margin-bottom: 2px;
}

.about-three__info__title {
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.72px;
    color: var(--eduact-base);
    margin: 0;
}

.about-three__info__title a {
    color: inherit;
}

.about-three__info__title a:hover {
    color: var(--eduact-secondary);
}

.about-three__thumb {
    position: relative;
    z-index: 2;
}

@media (max-width: 1199px) {
    .about-three__thumb {
        max-width: 642px;
    }
}
.about-three__thumb__one {
    position: relative;
    padding: 82px 0 0 124px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .about-three__thumb__one {
        padding-left: 70px;
    }
}
@media (max-width: 767px) {
    .about-three__thumb__one {
        padding: 40px 0 0;
    }
}
.about-three__thumb__one img {
    border-radius: 33px;
    max-width: 100%;
    height: auto;
}

.about-three__thumb__shape-one {
    position: absolute;
    top: 36px;
    left: 76px;
    width: 202px;
    height: 172px;
    border: 4px solid var(--eduact-secondary);
    animation: border-background 2s ease infinite;
    border-radius: 16px;
    z-index: -1;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .about-three__thumb__shape-one {
        left: 25px;
    }
}
@media (max-width: 767px) {
    .about-three__thumb__shape-one {
        display: none;
    }
}
@keyframes border-background {
    0% {
        border-color: var(--eduact-secondary);
        transform: translateX(0px) translateY(0px);
    }
    50% {
        border-color: var(--eduact-base);
        transform: translateX(10px) translateY(10px);
    }
    100% {
        border-color: var(--eduact-secondary);
        transform: translateX(0px) translateY(0px);
    }
}
.about-three__thumb__shape-two {
    position: absolute;
    top: 23px;
    right: 93px;
    width: 115px;
    height: 168px;
    background-color: var(--eduact-secondary);
    animation: bg2-background 3s ease infinite;
    border-radius: 9px;
}

@media (max-width: 767px) {
    .about-three__thumb__shape-two {
        display: none;
    }
}
@keyframes bg2-background {
    0% {
        background-color: var(--eduact-secondary);
        transform: translateY(0);
    }
    50% {
        background-color: var(--eduact-base);
    }
    100% {
        background-color: var(--eduact-secondary);
        transform: translateY(-20px);
    }
}
.about-three__thumb__shape-three {
    position: absolute;
    top: 89px;
    right: 2px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .about-three__thumb__shape-three {
        right: -15px;
    }
}
@media (max-width: 767px) {
    .about-three__thumb__shape-three {
        display: none;
    }
}
.about-three__thumb__shape-three span {
    width: 13.43px;
    height: 13.43px;
    border: 2px solid var(--eduact-base);
    display: block;
    border-radius: 50%;
    margin: 0 0 45px;
    -webkit-animation: movebounce3 2s linear infinite;
    animation: movebounce3 2s linear infinite;
    margin: 0 0 45px;
}

@keyframes border2-background {
    0% {
        border-color: var(--eduact-base);
    }
    50% {
        border-color: var(--eduact-secondary);
    }
    100% {
        border-color: var(--eduact-base);
    }
}
.about-three__thumb__shape-four {
    position: absolute;
    bottom: 93px;
    left: 32px;
    z-index: -1;
}

@media (max-width: 767px) {
    .about-three__thumb__shape-four {
        display: none;
    }
}
.about-three__thumb__shape-five {
    position: absolute;
    bottom: 10px;
    left: 77px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .about-three__thumb__shape-five {
        left: 40px;
    }
}
@media (max-width: 767px) {
    .about-three__thumb__shape-five {
        display: none;
    }
}
.about-three__thumb__shape-five span {
    width: 13.73px;
    height: 13.73px;
    border: 1.7px solid var(--eduact-secondary);
    display: inline-block;
    border-radius: 50%;
    margin: 0 45px 0 0;
}

@keyframes ball-top {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(0);
    }
}
.about-three__thumb__shape-six {
    position: absolute;
    bottom: -40px;
    left: 77px;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .about-three__thumb__shape-six {
        left: 40px;
    }
}
@media (max-width: 767px) {
    .about-three__thumb__shape-six {
        display: none;
    }
}
.about-three__thumb__shape-six span {
    width: 13.73px;
    height: 13.73px;
    border: 1.7px solid var(--eduact-secondary);
    display: inline-block;
    border-radius: 50%;
    margin: 0 45px 0 0;
}

@keyframes ball-bottom {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}
.about-three__thumb__shape-seven {
    position: absolute;
    bottom: -6px;
    right: -15px;
    width: 215px;
    height: 135px;
    border: 16px solid var(--eduact-soft4);
    border-radius: 76px;
    z-index: -1;
    animation: br-translate 3s ease infinite;
}

@media (max-width: 767px) {
    .about-three__thumb__shape-seven {
        display: none;
    }
}
@keyframes br-translate {
    0% {
        transform: translateX(0px) translateY(0px);
    }
    50% {
        transform: translateX(10px) translateY(10px);
    }
    100% {
        transform: translateX(0px) translateY(0px);
    }
}
/*--------------------------------------------------------------
  # Category
  --------------------------------------------------------------*/
.category-one {
    position: relative;
    padding: 117px 0 135px;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: var(--eduact-white);
}

@media (max-width: 767px) {
    .category-one {
        padding: 77px 0 80px;
    }
}
.category-one .section-title__tagline svg path {
    fill: var(--eduact-white);
}

.category-one__slider {
    position: relative;
}

.category-one__slider.owl-carousel .owl-dots {
    max-width: 878px;
    margin: 62px auto 0 !important;
    background-color: var(--eduact-soft);
    border-radius: 19px;
    line-height: 0;
    height: 3px;
}

.category-one__slider.owl-carousel .owl-dots.disabled {
    display: none;
}

@media (max-width: 767px) {
    .category-one__slider.owl-carousel .owl-dots {
        margin-top: 15px !important;
    }
}
.category-one__slider.owl-carousel .owl-dots .owl-dot {
    height: 10px;
    border-radius: 50%;
    width: 10px;
    background-color: rgba(var(--eduact-base-rgb), 0.27);
    margin: 0 11px;
    position: relative;
    top: 0;
    z-index: 2;
}

.category-one__slider.owl-carousel .owl-dots .owl-dot::after {
    position: absolute;
    left: -5px;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    content: '';
    border-radius: 50%;
    border: 1px solid var(--eduact-base);
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.category-one__slider.owl-carousel .owl-dots .owl-dot:hover,
.category-one__slider.owl-carousel .owl-dots .owl-dot.active {
    background-color: var(--eduact-secondary);
}

.category-one__slider.owl-carousel .owl-dots .owl-dot:hover::after,
.category-one__slider.owl-carousel .owl-dots .owl-dot.active::after {
    visibility: visible;
    opacity: 1;
}

.category-one__slider.owl-carousel .owl-dots .owl-dot span {
    display: none;
}

.category-one__slider.owl-carousel .owl-dots::after {
    width: 18.4%;
    height: 3px;
    background-color: var(--eduact-white);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    content: '';
}

@media (max-width: 1399px) {
    .category-one__slider.owl-carousel .owl-dots::after {
        width: 28%;
    }
}
.category-one__slider.owl-carousel .owl-nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

.category-one__slider.owl-carousel .owl-nav.disabled {
    display: none;
}

.category-one__slider.owl-carousel .owl-nav button {
    width: 50px;
    height: 50px;
    display: inline-block;
    line-height: 53px;
    text-align: center;
    font-size: 18px;
    color: var(--eduact-base);
    border-radius: 50%;
    background-color: var(--eduact-soft);
    margin: 0 5px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.category-one__slider.owl-carousel .owl-nav button:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.category-one__item {
    position: relative;
    border-radius: 14px;
    box-shadow: 11px 15px 30px 0px rgba(128, 137, 225, 0.1);
    margin: 0 0 30px;
}

.category-one__item:hover .category-one__hover {
    transform: scaleY(1);
}

.category-one__wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 14px;
    border: 1px solid var(--eduact-soft3);
    background: var(--eduact-white);
}

.category-one__thumb {
    position: relative;
    padding-bottom: 44px;
}

.category-one__thumb img {
    -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303 334" fill="none"> <path d="M308 179.977C134.62 179.977 30.4249 281.999 0 333.01V0.00976562H308V179.977Z" fill="black"/> </svg>');
    mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303 334" fill="none"> <path d="M308 179.977C134.62 179.977 30.4249 281.999 0 333.01V0.00976562H308V179.977Z" fill="black"/> </svg>');
    mask-repeat: repeat;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.category-one__content {
    position: absolute;
    left: 0;
    bottom: 18px;
    height: 195px;
    width: 100%;
    text-align: center;
}

.category-one__icon {
    position: relative;
    width: 100px;
    height: 100px;
    background-color: var(--eduact-secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: var(--eduact-white);
    overflow: hidden;
    margin: 0 auto 24px;
}

.category-one__icon::after {
    position: absolute;
    content: '';
    right: -29px;
    bottom: -39px;
    width: 77px;
    height: 99px;
    transform: rotate(-146.877deg);
    background: linear-gradient(
        177deg,
        rgba(var(--eduact-white-rgb), 0.53) 0%,
        rgba(var(--eduact-white-rgb), 0) 100%
    );
    border-radius: 50%;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.category-one__title {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 4px;
}

.category-one__text {
    font-size: 20px;
    color: var(--eduact-secondary);
    margin: 0;
}

.category-one__hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition-delay: 0.1s;
    transition-timing-function: ease-in-out;
    transition-duration: 0.5s;
    transition-property: all;
    transform-origin: top;
    transform-style: preserve-3d;
    transform: scaleY(0);
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.category-one__hover__thumb {
    overflow: hidden;
    border-radius: 14px;
    position: relative;
    height: 100%;
}

.category-one__hover__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.category-one__hover__thumb::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    content: '';
    background: rgba(30, 31, 37, 0.5);
}

.category-one__hover__content {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.category-one__hover__icon {
    position: relative;
    width: 100px;
    height: 100px;
    background-color: var(--eduact-base);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: var(--eduact-white);
    overflow: hidden;
    margin: 0 auto 28px;
}

.category-one__hover__icon::after {
    position: absolute;
    content: '';
    right: -29px;
    bottom: -39px;
    width: 77px;
    height: 99px;
    transform: rotate(-146.877deg);
    background: linear-gradient(
        177deg,
        rgba(var(--eduact-white-rgb), 0.53) 0%,
        rgba(var(--eduact-white-rgb), 0) 100%
    );
    border-radius: 50%;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.category-one__hover__title {
    font-size: 32px;
    line-height: 26px;
    color: var(--eduact-white);
    margin: 0 0 10px;
}

.category-one__hover__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.category-one__hover__title a:hover {
    color: var(--eduact-secondary);
    background-size:
        0% 1px,
        100% 1px;
}

.category-one__hover__text {
    font-size: 20px;
    color: var(--eduact-white);
    margin: 0;
}

.category-two {
    position: relative;
    padding: 117px 0 120px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--eduact-base);
}

@media (max-width: 767px) {
    .category-two {
        padding: 77px 0 80px;
    }
}
.category-two .section-title__tagline {
    color: var(--eduact-white);
}

.category-two .section-title__tagline svg.arrow-svg {
    fill: var(--eduact-white);
}

.category-two .section-title__title {
    color: var(--eduact-white);
}

.category-two__slider {
    position: relative;
    border-radius: 14px;
    overflow: hidden;
}

.category-two__item {
    position: relative;
    background-color: var(--eduact-white);
    text-align: center;
    display: block;
    z-index: 2;
    padding: 56px 20px 50px;
    overflow: hidden;
    box-shadow: 0px 0px 30px 0px rgba(var(--eduact-black2-rgb), 0.15);
}

.category-two__item::after {
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    position: absolute;
    width: 200%;
    height: 200%;
    content: '';
    top: 110%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
    background-color: var(--eduact-secondary);
}

.category-two__item:hover .category-two__title,
.category-two__item:hover .category-two__icon {
    color: var(--eduact-white);
}

.category-two__item:hover .category-two__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.category-two__item:hover::after {
    top: -40%;
}

.category-two__item:hover .category-two__icon span {
    transform: rotateY(360deg);
}

.category-two__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    line-height: 60px;
    color: var(--eduact-secondary);
    margin: 0 auto 20px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.category-two__icon span {
    display: block;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.category-two__title {
    font-size: 24px;
    line-height: 30px;
    max-width: 170px;
    margin: 0 auto;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.category-two__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.category-two__title a:hover {
    color: var(--eduact-base);
}

.category-three {
    position: relative;
    padding: 116px 0 90px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--eduact-base);
}

@media (max-width: 767px) {
    .category-three {
        padding: 76px 0 50px;
    }
}
.category-three__item {
    position: relative;
    background-color: var(--eduact-white);
    text-align: center;
    display: block;
    z-index: 2;
    padding: 56px 20px 50px;
    margin: 0 0 30px;
    box-shadow: 0px 0px 30px 0px rgba(var(--eduact-black2-rgb), 0.15);
}

.category-three__item-two {
    border-radius: 14px 0 0 14px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .category-three__item-two {
        border-radius: 14px 14px 0 0;
    }
}
.category-three__item-four {
    border-radius: 0 14px 14px 0;
    overflow: hidden;
}

@media (max-width: 767px) {
    .category-three__item-four {
        border-radius: 0 0 14px 14px;
    }
}
.category-three__item:hover .category-three__thumb img {
    transform: scale(1.05);
}

.category-three__item:hover .category-three__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.category-three__item:hover .category-three__icon span {
    transform: rotateY(360deg);
}

.category-three__thumb {
    overflow: hidden;
    min-height: 246px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.category-three__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.category-three__thumb::after {
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    top: 0;
    left: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background: rgba(54, 48, 93, 0.8);
}

.category-three__icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    line-height: 60px;
    color: var(--eduact-secondary);
    margin: 0 auto 20px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.category-three__icon span {
    display: block;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.category-three__title {
    position: relative;
    font-size: 24px;
    line-height: 30px;
    color: var(--eduact-white);
    max-width: 170px;
    margin: 0 auto;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.category-three__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.category-three__title a:hover {
    color: var(--eduact-secondary);
}

/*--------------------------------------------------------------
  # Course
  --------------------------------------------------------------*/
.course-one {
    position: relative;
    background-color: var(--eduact-soft);
    padding: 117px 0 85px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    z-index: 2;
}

@media (max-width: 767px) {
    .course-one {
        padding: 77px 0 45px;
    }
}
.course-one .section-title__tagline svg path {
    fill: var(--eduact-white);
}

.course-one__item {
    position: relative;
    margin: 0 0 36px;
}

.course-one__item:hover .course-one__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.course-one__item:hover .course-one__content {
    border-color: var(--eduact-base);
}

.course-one__thumb {
    width: 246px;
    height: 248px;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background: linear-gradient(
        147deg,
        var(--eduact-soft4) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    margin: 0 0 0 auto;
    padding: 10px;
}

.course-one__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course-one__like {
    position: absolute;
    left: -8px;
    top: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
    font-size: 20px;
}

.course-one__like:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.course-one__content {
    background-color: var(--eduact-white);
    border-radius: 14px;
    border: 1px solid var(--eduact-soft3);
    padding: 28px 30px 30px;
    box-shadow: 11px 15px 30px 0px rgba(128, 137, 225, 0.1);
    transition: 500ms ease;
    margin: -110px 0 0;
}

@media (max-width: 767px) {
    .course-one__content {
        margin-top: -80px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .course-one__content {
        margin-top: -80px;
    }
}
.course-one__time {
    height: 24px;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    padding: 0 10.5px;
    margin: 0 0 25px;
}

.course-one__ratings {
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1px;
    color: var(--eduact-secondary);
    margin: 0 0 7px;
}

.course-one__ratings__reviews {
    font-size: 14px;
    color: var(--eduact-black);
    margin-left: 5px;
    letter-spacing: 0;
}

.course-one__title {
    font-size: 20px;
    line-height: 29px;
    margin: 0 0 22px;
    max-width: 300px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .course-one__title {
        max-width: 320px;
    }
    .course-one__title br {
        display: none;
    }
}
.course-one__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.course-one__title a:hover {
    color: var(--eduact-base);
}

.course-one__bottom {
    position: relative;
    background-color: var(--eduact-soft);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 44px;
    padding: 14px 19px 15px 19px;
}

.course-one__author {
    position: relative;
    padding: 0 0 0 54px;
}

@media (max-width: 400px) {
    .course-one__author {
        padding-left: 0;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .course-one__author {
        padding-left: 0;
    }
}
.course-one__author img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@media (max-width: 400px) {
    .course-one__author img {
        display: none;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .course-one__author img {
        display: none;
    }
}
.course-one__author__name {
    font-size: 16px;
    line-height: 1;
    margin: 0 0 6px;
}

.course-one__author__designation {
    display: block;
    letter-spacing: 0.48px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.course-one__meta {
    position: relative;
}

.course-one__meta__price {
    margin: 0 0 5px;
    font-size: 20px;
    color: var(--eduact-base);
    line-height: 1;
}

.course-one__meta__class {
    margin: 0;
    font-size: 14px;
    color: var(--eduact-secondary);
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
}

.course-two {
    position: relative;
    padding: 116px 0 85px;
}

@media (max-width: 767px) {
    .course-two {
        padding: 76px 0 80px;
    }
}
.course-two .section-title__tagline {
    padding: 0;
}

.course-two--carousel {
    padding: 120px 0;
}

@media (max-width: 767px) {
    .course-two--carousel {
        padding: 80px 0;
    }
}
.course-two--page {
    padding: 120px 0 85px;
}

@media (max-width: 767px) {
    .course-two--page {
        padding: 80px 0 45px;
    }
}
.course-two__shape-top {
    position: absolute;
    right: 50px;
    top: 85px;
    display: none;
}

.course-two__shape-top img {
    -webkit-animation: movebounce3 2s linear infinite;
    animation: movebounce3 2s linear infinite;
}

@media (min-width: 992px) {
    .course-two__shape-top {
        display: block;
    }
}
.course-two__shape-bottom {
    position: absolute;
    left: 70px;
    bottom: 135px;
    display: none;
}

.course-two__shape-bottom img {
    -webkit-animation: movebounce2 2s linear infinite;
    animation: movebounce2 2s linear infinite;
}

@media (min-width: 1500px) {
    .course-two__shape-bottom {
        display: block;
    }
}
.course-two__slider {
    position: relative;
}

.course-two__slider.owl-carousel .owl-nav {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: -80px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .course-two__slider.owl-carousel .owl-nav {
        position: relative;
        top: 0;
        justify-content: center;
    }
}
.course-two__slider.owl-carousel .owl-nav.disabled {
    display: none;
}

.course-two__slider.owl-carousel .owl-nav button {
    margin: 0 0 0 12px;
    padding: 0;
    width: 40px;
    height: 40px;
    background-color: var(--eduact-soft4);
    border-radius: 4px;
    font-size: 14px;
    line-height: 42px;
    display: inline-block;
    color: var(--eduact-base);
    transition: 500ms ease;
}

.course-two__slider.owl-carousel .owl-nav button span {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.course-two__slider.owl-carousel .owl-nav button:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.course-two__slider.owl-carousel .owl-nav button:hover span {
    animation: iconTranslateX 0.4s forwards;
}

.course-two__slider.owl-carousel .owl-nav button.owl-next {
    margin-left: 12px;
}

.course-two__slider.owl-carousel .owl-nav button.owl-prev:hover span {
    animation: iconTranslateY 0.4s forwards;
}

.course-two__item {
    position: relative;
    background-color: var(--eduact-white);
    border-radius: 14px;
    overflow: hidden;
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    margin: 0 0 36px;
}

.course-two__item:hover .course-two__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.course-two__item:hover .course-two__thumb svg {
    fill: var(--eduact-base);
}

.course-two__thumb {
    position: relative;
    z-index: 2;
    margin: 0;
    padding: 0;
}

.course-two__thumb img {
    -webkit-mask: url('data:image/svg+xml;utf8,<svg viewBox="0 0 416 276" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="208" cy="-2" rx="279" ry="278" fill="black"/></svg>');
    mask: url('data:image/svg+xml;utf8,<svg viewBox="0 0 416 276" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="208" cy="-2" rx="279" ry="278" fill="black"/></svg>');
    mask-repeat: repeat;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 100%;
}

.course-two__thumb svg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: auto;
    fill: var(--eduact-secondary);
    transition: 500ms ease;
    z-index: -1;
    width: calc(100% - 63px);
}

.course-two__like {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
    font-size: 20px;
}

.course-two__like:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.course-two__content {
    position: relative;
    padding: 42px 30px 30px;
}

.course-two__time {
    height: 24px;
    background-color: var(--eduact-black);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    padding: 0 10.5px;
    margin: 0 0 14px;
}

.course-two__ratings {
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1px;
    color: var(--eduact-secondary);
    margin: 0 0 8px;
}

.course-two__ratings__reviews {
    font-size: 14px;
    color: var(--eduact-black);
    margin-left: 5px;
    letter-spacing: 0;
}

.course-two__title {
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 25px;
}

.course-two__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.course-two__title a:hover {
    color: var(--eduact-base);
}

.course-two__bottom {
    position: relative;
    background-color: var(--eduact-soft2);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 44px;
    padding: 14px 19px 15px 19px;
}

.course-two__author {
    position: relative;
    padding: 0 0 0 54px;
}

@media (max-width: 991px) {
    .course-two__author {
        padding-left: 0;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .course-two__author {
        padding-left: 0;
    }
}
.course-two__author img {
    width: 44px !important;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@media (max-width: 991px) {
    .course-two__author img {
        display: none !important;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .course-two__author img {
        display: none !important;
    }
}
.course-two__author__name {
    font-size: 16px;
    line-height: 1;
    margin: 0 0 6px;
}

.course-two__author__designation {
    display: block;
    letter-spacing: 0.48px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.course-two__meta {
    position: relative;
}

.course-two__meta__price {
    margin: 0 0 5px;
    font-size: 20px;
    color: var(--eduact-black);
    line-height: 1;
}

.course-two__meta__class {
    margin: 0;
    font-size: 14px;
    color: var(--eduact-text);
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
}

.course-three {
    position: relative;
    padding: 116px 0 85px;
    background-position: left center;
    background-repeat: no-repeat;
}

@media (max-width: 767px) {
    .course-three {
        padding: 76px 0 45px;
    }
}
.course-three__item {
    position: relative;
    background-color: var(--eduact-white);
    border-radius: 14px;
    overflow: hidden;
    min-height: 283px;
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    margin: 0 0 36px;
    padding: 0 30px 0 256px;
    display: flex;
    align-items: center;
}

@media (max-width: 767px) {
    .course-three__item {
        padding: 0;
        display: block;
    }
}
.course-three__item::after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
    border: 2px solid var(--eduact-secondary);
    border-radius: 14px;
    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
}

.course-three__item:hover::after {
    visibility: visible;
    opacity: 1;
}

.course-three__item:hover .course-three__title a {
    background-size:
        0% 1px,
        100% 1px;
}

.course-three__thumb {
    position: absolute;
    left: 0;
    top: 0;
    width: 226px;
    height: 283px;
    margin: 0;
    padding: 0;
}

@media (max-width: 767px) {
    .course-three__thumb {
        position: relative;
        width: 100%;
    }
}
.course-three__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course-three__content {
    position: relative;
    z-index: 1;
    padding: 0 0 0;
}

@media (max-width: 767px) {
    .course-three__content {
        padding: 30px;
    }
}
.course-three__time {
    height: 24px;
    background-color: var(--eduact-black);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    padding: 0 10.5px;
    margin: 0 0 15px;
}

.course-three__ratings {
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 1px;
    color: var(--eduact-secondary);
    margin: 0 0 8px;
}

.course-three__ratings__reviews {
    font-size: 14px;
    color: var(--eduact-black);
    margin-left: 5px;
    letter-spacing: 0;
}

.course-three__title {
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 25px;
}

.course-three__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.course-three__title a:hover {
    color: var(--eduact-base);
}

.course-three__bottom {
    position: relative;
    background-color: var(--eduact-soft2);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 44px;
    padding: 14px 19px 15px 19px;
}

.course-three__author {
    position: relative;
    padding: 0 0 0 54px;
}

@media (max-width: 400px) {
    .course-three__author {
        padding-left: 0;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .course-three__author {
        padding-left: 0;
    }
}
.course-three__author img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

@media (max-width: 400px) {
    .course-three__author img {
        display: none !important;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .course-three__author img {
        display: none !important;
    }
}
.course-three__author__name {
    font-size: 16px;
    line-height: 1;
    margin: 0 0 6px;
}

.course-three__author__designation {
    display: block;
    letter-spacing: 0.48px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.course-three__meta {
    position: relative;
}

.course-three__meta__price {
    margin: 0 0 5px;
    font-size: 20px;
    color: var(--eduact-black);
    line-height: 1;
}

.course-three__meta__class {
    margin: 0;
    font-size: 14px;
    color: var(--eduact-text);
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;
}

/*--------------------------------------------------------------
  # Course Details
  --------------------------------------------------------------*/
.course-details {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .course-details {
        padding: 80px 0;
    }
}
.course-details__thumb {
    position: relative;
    margin: 0 0 40px;
}

.course-details__thumb img {
    width: 100%;
    height: auto;
    border-radius: 14px;
}

.course-details__title {
    font-size: 32px;
    margin: 0 0 32px;
}

.course-details__meta {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0 0 22px;
}

@media (max-width: 767px) {
    .course-details__meta {
        display: block;
    }
}
.course-details__meta__author {
    position: relative;
    padding: 6px 0 0 55px;
    min-height: 44px;
}

.course-details__meta__author img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.course-details__meta__name {
    font-size: 16px;
    line-height: 1;
    margin: 0 0 6px;
}

.course-details__meta__designation {
    display: block;
    letter-spacing: 0.48px;
    line-height: 1;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.course-details__meta__cats {
    position: relative;
    margin-left: 41px;
}

@media (max-width: 767px) {
    .course-details__meta__cats {
        margin: 15px 0 0;
    }
}
.course-details__meta__cats a {
    display: inline-block;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    height: 24px;
    line-height: 24px;
    padding: 0 10.5px;
    margin: 0;
}

.course-details__meta__cats a:hover {
    background-color: var(--eduact-secondary);
}

.course-details__meta__ratings {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--eduact-secondary);
    letter-spacing: 2px;
    margin: 0 0 0 17px;
}

@media (max-width: 767px) {
    .course-details__meta__ratings {
        margin: 15px 0;
    }
}
.course-details__meta__ratings__reviews {
    font-size: 14px;
    color: var(--eduact-black);
    margin-left: 2px;
    letter-spacing: 0;
}

.course-details__meta__price {
    color: var(--eduact-secondary);
    font-weight: 700;
    font-size: 32px;
    margin-left: auto;
}

.course-details__tabs {
    position: relative;
}

.course-details__tabs__lists {
    margin: 0 0 35px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background-color: var(--eduact-soft5);
    border-radius: 14px;
}

.course-details__tabs__lists li {
    list-style: none;
    display: inline-block;
    margin-right: 20px;
}

.course-details__tabs__lists li:last-child {
    margin-right: 0;
}

@media (max-width: 767px) {
    .course-details__tabs__lists li {
        margin-bottom: 15px;
    }
}
.course-details__tabs__lists li span {
    background-color: var(--eduact-white);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 17px;
    display: block;
    height: 50px;
    color: var(--eduact-black);
    cursor: pointer;
    overflow: hidden;
    transition: all 500ms ease;
    position: relative;
    z-index: 1;
}

.course-details__tabs__lists li span::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0%;
    background-color: var(--eduact-secondary);
    transition: all 0.3s ease;
    z-index: -1;
}

.course-details__tabs__lists li.active-btn span,
.course-details__tabs__lists li:hover span {
    color: var(--eduact-white);
}

.course-details__tabs__lists li.active-btn span::after,
.course-details__tabs__lists li:hover span::after {
    height: 100%;
}

.course-details .tabs-content {
    position: relative;
    display: block;
}

.course-details .tabs-content .tab {
    position: relative;
    display: none;
    -webkit-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    transition: all 600ms ease;
    z-index: 10;
}

.course-details .tabs-content .tab.active-tab {
    display: block;
}

.course-details__overview {
    position: relative;
}

.course-details__overview__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0 0 19px;
}

.course-details__overview__lists {
    margin: 27px 0 0;
    padding: 0;
}

.course-details__overview__lists li {
    display: block;
    position: relative;
    padding: 0 0 0 38px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: var(--eduact-black);
    margin: 0 0 14px;
}

.course-details__overview__lists li span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 24px;
    height: 24px;
    background-color: var(--eduact-secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--eduact-white);
}

.course-details__curriculum {
    position: relative;
    margin: 44px 0 0;
}

.course-details__curriculum__title {
    font-size: 24px;
    margin: 0 0 14px;
}

.course-details__curriculum__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0 0 39px;
}

.course-details__curriculum__lists {
    margin: 27px 0 0;
    padding: 0;
}

.course-details__curriculum__lists li {
    display: block;
    position: relative;
    padding: 0 0 29px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: var(--eduact-black);
    margin: 0 0 30px;
    border-bottom: 1px solid var(--eduact-soft4);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

@media (max-width: 767px) {
    .course-details__curriculum__lists li {
        display: block;
    }
}
.course-details__curriculum__lists li i {
    display: inline-block;
    font-size: 30px;
    color: var(--eduact-secondary);
    margin-right: 16px;
}

@media (max-width: 767px) {
    .course-details__curriculum__lists li i {
        display: block;
        margin-bottom: 15px;
    }
}
.course-details__curriculum__lists__time {
    margin-left: auto;
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
}

@media (max-width: 767px) {
    .course-details__curriculum__lists__time {
        display: block;
        margin: 12px 0 0;
    }
}
.course-details__curriculum__lists__preview {
    height: 28px;
    background-color: var(--eduact-soft5);
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    padding: 0 17px;
    margin-left: 30px;
}

.course-details__comment {
    position: relative;
    margin: 44px 0 0;
}

.course-details__review-title {
    font-size: 24px;
    margin-bottom: 54px;
}

@media (max-width: 767px) {
    .course-details__review-title {
        margin-bottom: 34px;
    }
}
.course-details__comment-box {
    position: relative;
    padding: 19px 0 75px 196px;
    margin-bottom: 60px;
    min-height: 165px;
    border-bottom: 1px solid var(--eduact-soft4);
}

@media (max-width: 767px) {
    .course-details__comment-box {
        padding-left: 0;
    }
}
.course-details__comment-box__thumb {
    width: 165px;
    height: 165px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    margin: 0;
    border: none;
}

@media (max-width: 767px) {
    .course-details__comment-box__thumb {
        position: relative;
        margin: 0 0 20px;
    }
}
.course-details__comment-box__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.course-details__comment-box__meta {
    font-size: 24px;
    text-transform: capitalize;
    margin: 0 0 5px;
}

.course-details__comment-box__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0;
}

.course-details__comment-box__ratings {
    position: absolute;
    right: 0;
    top: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 6px;
    font-size: 16px;
    color: var(--eduact-secondary);
}

@media (max-width: 767px) {
    .course-details__comment-box__ratings {
        position: relative;
        top: 0;
        margin: 12px 0 22px;
    }
}
.course-details__form {
    position: relative;
    margin: 0 0 0;
}

.course-details__form .row {
    --bs-gutter-x: 20px;
}

.course-details__form-title {
    font-size: 24px;
    margin-bottom: 37px;
}

.course-details__form-ratings {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    letter-spacing: 9px;
    font-size: 24px;
    color: var(--eduact-secondary);
    margin: 0 0 44px;
}

.course-details__form-ratings__label {
    display: inline-block;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 600;
    color: var(--eduact-text);
    margin: 0 18px 0 0;
}

.course-details .review-form {
    position: relative;
}

.course-details .review-form__input-box {
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 20px;
}

.course-details .review-form__input-box input[type='text'],
.course-details .review-form__input-box input[type='email'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.course-details .review-form__input-box textarea {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    height: 211px;
    width: 100%;
    background-color: var(--eduact-soft5);
    font-family: var(--eduact-font);
    padding: 24px 30px 30px;
    border: none;
    outline: none;
    margin-bottom: 0px;
    border-radius: 4px;
}

.course-details .review-form .eduact-btn {
    text-transform: none;
    margin-top: 20px;
}

.course-details__instructor {
    position: relative;
    padding: 6px 0 0 196px;
    margin-top: 60px;
    min-height: 165px;
}

@media (max-width: 767px) {
    .course-details__instructor {
        padding-left: 0;
    }
}
.course-details__instructor__thumb {
    width: 165px;
    height: 165px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    margin: 0;
    border: none;
}

@media (max-width: 767px) {
    .course-details__instructor__thumb {
        position: relative;
        margin: 0 0 20px;
    }
}
.course-details__instructor__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.course-details__instructor__name {
    font-size: 24px;
    text-transform: capitalize;
    margin: 0 0 2px;
}

.course-details__instructor__designation {
    display: block;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    margin: 0 0 4px;
}

.course-details__instructor__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0;
}

.course-details__sidebar {
    position: relative;
}

@media (max-width: 1199px) {
    .course-details__sidebar {
        margin-top: 60px;
    }
}
.course-details__sidebar__item {
    position: relative;
    background-color: var(--eduact-white);
    border-radius: 14px;
    padding: 25px 30px 30px;
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
}

.course-details__sidebar__item + .course-details__sidebar__item {
    margin-top: 36px;
}

.course-details__sidebar__title {
    position: relative;
    font-size: 24px;
    line-height: 26px;
    text-transform: capitalize;
    border-bottom: 1px dashed var(--eduact-secondary);
    margin: 0 0 30px;
    padding-bottom: 25px;
}

.course-details__sidebar__lists {
    margin: 0;
    padding: 0;
    list-style: none;
}

.course-details__sidebar__lists li {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-transform: capitalize;
    border-bottom: 1px solid var(--eduact-soft4);
    padding: 0 0 30px;
    margin: 29px 0 0;
}

.course-details__sidebar__lists li i {
    color: var(--eduact-secondary);
    display: inline-block;
    margin-right: 11px;
}

.course-details__sidebar__lists li span {
    margin-left: auto;
    font-weight: 600;
    color: var(--eduact-black);
}

.course-details__sidebar .eduact-btn {
    margin: 30px 0 0;
    width: 100%;
    text-align: center;
}

.course-details__sidebar__post {
    margin: 0;
    padding: 0;
    list-style: none;
}

.course-details__sidebar__post li {
    list-style: none;
    min-height: 89px;
    position: relative;
    border-bottom: 1px solid var(--eduact-soft4);
    padding: 0 0 29px 110px;
    margin: 0 0 29px;
}

.course-details__sidebar__post
    li:hover
    .course-details__sidebar__post__title
    a {
    background-size:
        0% 1px,
        100% 1px;
}

.course-details__sidebar__post li:last-child {
    margin-bottom: 0;
}

.course-details__sidebar__post__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 89px;
    height: 89px;
    border-radius: 6px;
    overflow: hidden;
}

.course-details__sidebar__post__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.course-details__sidebar__post__content {
    position: relative;
}

.course-details__sidebar__post__meta {
    font-weight: 600;
    display: block;
    line-height: 1.2;
    margin: 0 0 10px;
}

.course-details__sidebar__post__meta a {
    color: var(--eduact-secondary);
}

.course-details__sidebar__post__meta a:hover {
    color: var(--eduact-base);
}

.course-details__sidebar__post__title {
    margin: 0 0 10px;
    font-size: 20px;
}

.course-details__sidebar__post__title a {
    color: inherit;
    background-image: linear-gradient(currentcolor, currentcolor),
        linear-gradient(currentcolor, currentcolor);
    display: inline;
    background-size:
        0% 1px,
        0 1px;
    background-position:
        100% 100%,
        0 100%;
    background-repeat: no-repeat;
    transition: all 0.4s ease;
}

.course-details__sidebar__post__title a:hover {
    color: var(--eduact-secondary);
}

.course-details__sidebar__post__ratings {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: var(--eduact-secondary);
    letter-spacing: 4px;
}

.course-details__sidebar__post__ratings__reviews {
    color: var(--eduact-black);
    letter-spacing: 0;
    font-size: 14px;
    font-weight: 500;
}

/*--------------------------------------------------------------
  # Counter
  --------------------------------------------------------------*/
.counter-one {
    position: relative;
    background-color: var(--eduact-base);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 22px 0 59px;
    z-index: 1;
}

.counter-one__left {
    position: relative;
    padding: 62px 0 0;
}

@media (min-width: 1400px) {
    .counter-one__left {
        margin-right: 67px;
    }
}
.counter-one__left__title {
    color: var(--eduact-white);
    font-size: 40px;
    margin: 0 0 30px;
}

@media (max-width: 767px) {
    .counter-one__left__title {
        font-size: 30px;
    }
}
@media (min-width: 991px) and (max-width: 1199px) {
    .counter-one__left__title {
        font-size: 36px;
    }
}
.counter-one__left__content {
    border-radius: 12px;
    background: rgba(255, 252, 252, 0.12);
    font-size: 20px;
    line-height: 30px;
    color: var(--eduact-white);
    padding: 31px 29px;
    margin: 0 0 40px;
    overflow: hidden;
    position: relative;
}

.counter-one__left__content::after {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 90px 52px;
    border-color: transparent transparent var(--eduact-secondary) transparent;
}

.counter-one__left img {
    position: absolute;
    right: 23px;
    bottom: -42px;
    -webkit-animation: rotated 20s infinite linear;
    animation: rotated 20s infinite linear;
}

.counter-one__area {
    position: relative;
    width: 392px;
    height: 392px;
    background-color: var(--eduact-white);
    border-radius: 50%;
    margin: 0 101px 0 auto;
    z-index: 2;
    text-align: center;
    padding: 55px 40px;
}

@media (max-width: 991px) {
    .counter-one__area {
        margin: 35px auto 0;
    }
}
@media (max-width: 767px) {
    .counter-one__area {
        max-width: 100%;
        height: auto;
    }
}
@media (min-width: 991px) and (max-width: 1199px) {
    .counter-one__area {
        margin-right: 50px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .counter-one__area {
        margin-right: 50px;
    }
}
.counter-one__title {
    font-size: 24px;
    margin: 0 0 0;
}

.counter-one__title br {
    display: inherit;
}

.counter-one__title span {
    color: var(--eduact-secondary);
}

.counter-one__shapes {
    position: relative;
}

.counter-one__shapes svg {
    position: absolute;
    top: -90px;
    right: 0;
    width: 581px;
    height: 596px;
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite;
}

@media (min-width: 991px) and (max-width: 1199px) {
    .counter-one__shapes svg {
        width: 501px;
        height: auto;
        right: -20px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .counter-one__shapes svg {
        right: -50px;
    }
}
.counter-one__shapes__path-one {
    fill: linear-gradient(
        147deg,
        rgba(79, 93, 228, 0) 0%,
        rgba(158, 166, 240, 0.55) 26.94%,
        rgba(255, 255, 255, 0) 100%
    );
}

.counter-one__shapes__path-two {
    fill: linear-gradient(147deg, #ff7200 0%, rgba(255, 255, 255, 0) 100%);
}

@media (max-width: 767px) {
    .counter-one__shapes {
        display: none;
    }
}
.counter-one__list {
    margin: 0 auto;
    padding: 0;
    max-width: 200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
}

.counter-one__list li {
    list-style: none;
    width: 78px;
    height: 78px;
    border-radius: 10px;
    background: var(--eduact-soft);
    font-size: 26.343px;
    font-weight: 700;
    color: var(--eduact-base);
    padding: 18px 15px 15px;
    margin-top: 25px;
}

.counter-one__list li i {
    display: block;
    font-size: 14.583px;
    font-weight: 400;
    color: var(--eduact-black);
    font-style: normal;
}

.counter-one__list::after {
    position: absolute;
    left: 0;
    bottom: 90px;
    margin: auto;
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--eduact-soft);
}

.counter-one__list::before {
    position: absolute;
    left: 97px;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: calc(100% - 25px);
    content: '';
    background-color: var(--eduact-soft);
}

/*--------------------------------------------------------------
  # Testimonial
  --------------------------------------------------------------*/
.testimonial-one {
    position: relative;
    overflow: hidden;
    padding: 115px 0 218px;
    background-color: var(--eduact-white);
}

@media (max-width: 767px) {
    .testimonial-one {
        padding-top: 80px;
    }
}
.testimonial-one .section-title {
    margin-bottom: 135px;
}

@media (max-width: 767px) {
    .testimonial-one .section-title {
        margin-bottom: 70px;
    }
}
.testimonial-one__area {
    position: relative;
    border-radius: 40px;
    border: 1px solid var(--eduact-soft3);
    background: var(--eduact-white);
    box-shadow: 11px 15px 30px 0px rgba(128, 137, 225, 0.1);
    padding: 0 50px 48px 60px;
    max-width: 1197px;
}

@media (max-width: 767px) {
    .testimonial-one__area {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one__area {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.testimonial-one__carousel {
    position: relative;
    max-width: 670px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one__carousel {
        max-width: 520px;
    }
}
.testimonial-one__carousel.owl-carousel .owl-nav {
    display: flex;
    align-items: center;
    margin: 0;
    position: absolute;
    right: 0;
    bottom: -9px;
}

.testimonial-one__carousel.owl-carousel .owl-nav button {
    width: 50px;
    height: 50px;
    display: inline-block;
    line-height: 53px;
    text-align: center;
    font-size: 18px;
    color: var(--eduact-base);
    border-radius: 50%;
    background-color: var(--eduact-soft);
    margin: 0 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.testimonial-one__carousel.owl-carousel .owl-nav button span {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.testimonial-one__carousel.owl-carousel .owl-nav button:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.testimonial-one__carousel.owl-carousel .owl-nav button:hover span {
    animation: iconTranslateX 0.4s forwards;
}

.testimonial-one__carousel.owl-carousel .owl-nav button.owl-next {
    margin-left: 16px;
}

.testimonial-one__carousel.owl-carousel .owl-nav button.owl-prev:hover span {
    animation: iconTranslateY 0.4s forwards;
}

.testimonial-one__item {
    position: relative;
}

.testimonial-one__author {
    width: 137px;
    height: 137px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: -36px;
    margin-bottom: 27px;
}

.testimonial-one__author img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.testimonial-one__content {
    position: relative;
}

.testimonial-one__quote {
    font-size: 20px;
    line-height: 37px;
    color: var(--eduact-black);
    margin-bottom: 28px;
}

.testimonial-one__title {
    font-size: 20px;
    line-height: 1;
    margin-bottom: -1px;
}

.testimonial-one__designation {
    font-size: 12px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
}

.testimonial-one__thumb {
    display: inline-block;
    position: absolute;
    right: -160px;
    top: -35px;
    z-index: 2;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .testimonial-one__thumb {
        right: -145px;
    }
}
@media (max-width: 991px) {
    .testimonial-one__thumb {
        position: relative;
        right: 0;
        top: 0;
        margin: 75px auto -100px;
        left: 0;
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 767px) {
    .testimonial-one__thumb {
        position: relative;
        right: 0;
        top: 0;
        margin: 35px -32px -165px;
    }
}
.testimonial-one__thumb > img {
    border-radius: 50%;
    max-width: 100%;
    height: auto;
}

.testimonial-one__thumb svg {
    width: 612px;
    height: 563px;
    position: absolute;
    right: -57px;
    top: -72px;
    z-index: -1;
}

@media (max-width: 767px) {
    .testimonial-one__thumb svg {
        display: none;
    }
}
@media (max-width: 991px) {
    .testimonial-one__thumb svg {
        right: 0;
    }
}
.testimonial-one__thumb-pen {
    position: absolute;
    right: -54px;
    top: -75px;
    z-index: 2;
}

@media (max-width: 991px) {
    .testimonial-one__thumb-pen {
        right: 25px;
        top: 0;
        max-width: 45%;
    }
}
.testimonial-one__thumb-pen img {
    max-width: 100%;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.testimonial-two {
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 115px 0 120px;
}

@media (max-width: 767px) {
    .testimonial-two {
        padding: 75px 0 80px;
    }
}
.testimonial-two__carousel {
    position: relative;
    padding: 50px 0 0;
}

.testimonial-two__carousel.owl-carousel .owl-nav {
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;
}

.testimonial-two__carousel.owl-carousel .owl-nav button {
    width: 50px;
    height: 50px;
    display: inline-block;
    line-height: 53px;
    text-align: center;
    font-size: 18px;
    color: var(--eduact-white);
    border-radius: 5px;
    background-color: var(--eduact-base);
    margin: 0 8px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.testimonial-two__carousel.owl-carousel .owl-nav button:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.testimonial-two__carousel .active.center .testimonial-two__item {
    transform: translateY(-50px);
}

.testimonial-two__item {
    position: relative;
    background-color: var(--eduact-white);
    border-radius: 10px 10px 50px 50px;
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    transition: transform 0.4s ease;
    transform: translateY(0);
}

.testimonial-two__item-inner {
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 50px 50px;
    background-position: top left;
    background-repeat: no-repeat;
    padding: 40px 35px 56px 40px;
}

.testimonial-two__item svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    fill: var(--eduact-base);
}

.testimonial-two__ratings {
    display: flex;
    color: var(--eduact-secondary);
    font-size: 18px;
    letter-spacing: 4px;
    margin: 0 0 21px;
}

.testimonial-two__quote {
    font-size: 18px;
    line-height: 37px;
    font-weight: 500;
    position: relative;
    z-index: 2;
    margin: 0 0 18px;
}

.testimonial-two__meta {
    position: relative;
    z-index: 2;
    min-height: 50px;
    padding: 5px 0 0 66px;
}

.testimonial-two__meta img {
    width: 50px !important;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.testimonial-two__title {
    font-size: 20px;
    line-height: 1;
    margin-bottom: -1px;
}

.testimonial-two__designation {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
}

.testimonial-three {
    position: relative;
    overflow: hidden;
    padding: 120px 0 120px;
    background-color: var(--eduact-white);
}

@media (max-width: 767px) {
    .testimonial-three {
        padding: 80px 0;
    }
}
.testimonial-three .section-title {
    margin-bottom: 36px;
}

.testimonial-three .section-title__tagline {
    padding: 0;
}

.testimonial-three__wrapper {
    position: relative;
    border-radius: 11px;
    border: 1px solid var(--eduact-soft4);
    background: var(--eduact-white);
    padding: 39px 39px;
}

@media (max-width: 767px) {
    .testimonial-three__wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three__wrapper {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.testimonial-three__carousel {
    position: relative;
}

.testimonial-three__carousel.owl-carousel .owl-nav {
    display: flex;
    align-items: center;
    margin: 0;
    position: absolute;
    right: 0;
    bottom: 0;
}

.testimonial-three__carousel.owl-carousel .owl-nav button {
    width: 50px;
    height: 50px;
    display: inline-block;
    line-height: 53px;
    text-align: center;
    font-size: 18px;
    color: var(--eduact-secondary);
    border-radius: 50%;
    background-color: var(--eduact-soft2);
    margin: 0 0;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.testimonial-three__carousel.owl-carousel .owl-nav button span {
    display: inline-block;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.testimonial-three__carousel.owl-carousel .owl-nav button:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.testimonial-three__carousel.owl-carousel .owl-nav button:hover span {
    animation: iconTranslateX 0.4s forwards;
}

.testimonial-three__carousel.owl-carousel .owl-nav button.owl-next {
    margin-left: 16px;
}

.testimonial-three__carousel.owl-carousel .owl-nav button.owl-prev:hover span {
    animation: iconTranslateY 0.4s forwards;
}

.testimonial-three__carousel__thumb {
    position: absolute;
    right: 0;
    top: -117px;
    max-width: 302px;
}

@media (max-width: 991px) {
    .testimonial-three__carousel__thumb {
        position: relative;
        top: 0;
        margin: 0 0 22px;
    }
}
.testimonial-three__carousel__thumb a {
    display: block;
    width: 76px;
    height: 76px;
    padding: 5px;
    border-radius: 50%;
    border: 2px solid #eeeeee;
    overflow: hidden;
}

.testimonial-three__carousel__thumb a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.testimonial-three__carousel__thumb .active.center a {
    border-color: var(--eduact-secondary);
}

.testimonial-three__item {
    position: relative;
    max-width: 1020px;
    min-height: 338px;
    padding: 0 0 0 344px;
}

@media (max-width: 991px) {
    .testimonial-three__item {
        padding: 0;
    }
}
.testimonial-three__author {
    position: absolute;
    left: 0;
    top: 0;
    width: 303px;
    height: 338px;
    border-radius: 14px;
    overflow: hidden;
}

@media (max-width: 991px) {
    .testimonial-three__author {
        position: relative;
        margin: 0 0 22px;
    }
}
@media (max-width: 767px) {
    .testimonial-three__author {
        width: auto;
        max-width: 303px;
    }
}
.testimonial-three__author img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
}

.testimonial-three__content {
    position: relative;
}

.testimonial-three__quote {
    font-size: 20px;
    line-height: 37px;
    color: var(--eduact-black);
    margin: -4px 0 22px;
}

.testimonial-three__average-ratings {
    font-weight: 600;
    letter-spacing: 0.32px;
    margin-bottom: 13px;
}

.testimonial-three__ratings {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
}

.testimonial-three__ratings span {
    width: 50px;
    height: 50px;
    border: 1px solid var(--eduact-soft4);
    border-radius: 6px;
    text-align: center;
    display: inline-block;
    color: var(--eduact-secondary);
    font-size: 24px;
    line-height: 48px;
    margin-right: 16px;
}

.testimonial-three__ratings span:last-child {
    margin-right: 0;
}

.testimonial-three__title {
    font-size: 24px;
    line-height: 1;
    margin-bottom: -2px;
}

.testimonial-three__designation {
    font-size: 12px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
}

.testimonial-three__thumb {
    display: inline-block;
    position: absolute;
    right: -160px;
    top: -35px;
    z-index: 2;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .testimonial-three__thumb {
        right: -145px;
    }
}
@media (max-width: 991px) {
    .testimonial-three__thumb {
        position: relative;
        right: 0;
        top: 0;
        margin: 75px auto -100px;
        left: 0;
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 767px) {
    .testimonial-three__thumb {
        position: relative;
        right: 0;
        top: 0;
        margin: 35px -32px -165px;
    }
}
.testimonial-three__thumb > img {
    border-radius: 50%;
    max-width: 100%;
    height: auto;
}

.testimonial-three__thumb svg {
    width: 612px;
    height: 563px;
    position: absolute;
    right: -57px;
    top: -72px;
    z-index: -1;
}

@media (max-width: 767px) {
    .testimonial-three__thumb svg {
        display: none;
    }
}
@media (max-width: 991px) {
    .testimonial-three__thumb svg {
        right: 0;
    }
}
.testimonial-three__thumb-pen {
    position: absolute;
    right: -54px;
    top: -75px;
    z-index: 2;
}

@media (max-width: 991px) {
    .testimonial-three__thumb-pen {
        right: 25px;
        top: 0;
        max-width: 45%;
    }
}
.testimonial-three__thumb-pen img {
    max-width: 100%;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

/*--------------------------------------------------------------
  # Call To Action
  --------------------------------------------------------------*/
.cta-one {
    padding: 0;
    position: relative;
    background-color: var(--eduact-white);
}

.cta-one__bg {
    display: inline-block;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 1628px;
    border-radius: 23px;
    margin: auto;
}

.cta-one__left {
    position: relative;
    z-index: 3;
    padding: 96px 0;
}

@media (max-width: 767px) {
    .cta-one__left {
        padding: 80px 0 30px;
    }
}
.cta-one__left img {
    margin-left: 102px;
}

@media (max-width: 767px) {
    .cta-one__left img {
        margin-left: 72px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .cta-one__left img {
        margin-left: 42px;
    }
}
.cta-one__left__title {
    color: var(--eduact-white);
    letter-spacing: -0.96px;
    font-size: 48px;
    line-height: 50px;
    margin: -14px 0 32px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .cta-one__left__title {
        font-size: 39px;
    }
}
@media (max-width: 767px) {
    .cta-one__left__title {
        font-size: 40px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .cta-one__left__title {
        font-size: 44px;
    }
}
.cta-one__svgshape {
    position: relative;
    overflow: hidden;
    margin: 35px 0 0 -65px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .cta-one__svgshape {
        margin-left: -45px;
    }
}
@media (max-width: 991px) {
    .cta-one__svgshape {
        margin-left: -5px;
    }
}
@media (max-width: 767px) {
    .cta-one__svgshape {
        margin: 0;
    }
}
.cta-one__svgshape svg {
    width: 284px;
    height: 403px;
}

.cta-one__svgshape svg path {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    animation: dash 2.5s ease-in-out forwards;
    opacity: 0.36;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1200;
        stroke-width: 1;
    }
    100% {
        stroke-dashoffset: 0;
        stroke-width: 1;
    }
}
.cta-one__thumb__area {
    position: relative;
    z-index: 3;
    margin: -24px 0 0;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .cta-one__thumb__area {
        text-align: right;
        margin-right: -50px;
    }
}
@media (max-width: 991px) {
    .cta-one__thumb__area {
        margin-bottom: 80px;
    }
}
@media (max-width: 767px) {
    .cta-one__thumb__area {
        margin: 0 0 70px;
    }
}
.cta-one__thumb__area__dark {
    position: absolute;
    left: -35px;
    top: 32px;
    width: 139px;
    height: 139px;
    background-color: rgba(var(--eduact-black-rgb), 0.46);
    border-radius: 50%;
    animation: blinker 1.5s ease-in-out infinite;
    -webkit-animation: blinker 1.5s ease-in-out infinite;
    -moz-animation: blinker 1.5s ease-in-out infinite;
    z-index: -1;
}

.cta-one__thumb__area__shape {
    width: 166px;
    height: 146px;
    position: absolute;
    right: 58px;
    bottom: -29px;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.cta-one__thumb__area svg {
    width: 611px;
    height: 556px;
    position: absolute;
    right: 6px;
    top: -39px;
    z-index: -1;
    -webkit-animation: movebounce3 2s linear infinite;
    animation: movebounce3 2s linear infinite;
}

@media (max-width: 767px) {
    .cta-one__thumb__area svg {
        display: none;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .cta-one__thumb__area svg {
        width: 400px;
        height: auto;
        right: -12px;
        top: -20px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .cta-one__thumb__area svg {
        right: -50px;
    }
}
.cta-one__thumb {
    position: relative;
}

.cta-one__thumb img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
}

.cta-one-space {
    height: 120px;
    width: 100%;
    background-color: var(--eduact-white);
}

@media (max-width: 767px) {
    .cta-one-space {
        height: 80px;
    }
}
.cta-two {
    padding: 0;
    position: relative;
}

.cta-two__bg {
    position: relative;
    padding: 89px 77px 91px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px 14px 0 0;
}

@media (max-width: 1399px) {
    .cta-two__bg {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (max-width: 1199px) {
    .cta-two__bg {
        padding: 60px 40px;
    }
}
@media (max-width: 991px) {
    .cta-two__bg {
        padding: 50px 40px;
    }
}
@media (max-width: 767px) {
    .cta-two__bg {
        padding: 50px 30px;
    }
}
.cta-two__title {
    color: var(--eduact-white);
    font-size: 40px;
    margin: 0;
}

@media (max-width: 767px) {
    .cta-two__title {
        font-size: 32px;
        margin-bottom: 15px;
    }
}
.cta-two__mail {
    position: relative;
}

.cta-two__email-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin: 20px 0 0;
}

@media (max-width: 991px) {
    .cta-two__email-box {
        justify-content: flex-start;
    }
}
@media (max-width: 767px) {
    .cta-two__email-box {
        display: block;
    }
}
.cta-two__email-box .eduact-btn {
    margin-left: 12px;
    height: 56px;
    padding: 0 50px;
    background-color: var(--eduact-secondary);
}

@media (max-width: 1399px) {
    .cta-two__email-box .eduact-btn {
        padding-left: 35px;
        padding-right: 35px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .cta-two__email-box .eduact-btn {
        margin: 10px 0 0;
    }
}
@media (max-width: 767px) {
    .cta-two__email-box .eduact-btn {
        margin: 10px 0 0;
    }
}
.cta-two__email-box .eduact-btn__curve {
    opacity: 0;
}

.cta-two__email-box .eduact-btn:hover .eduact-btn__curve {
    opacity: 0.2;
}

.cta-two__email-input-box {
    position: relative;
}

.cta-two__email-input-box input[type='email'] {
    width: 352px;
    height: 56px;
    border: none;
    border-radius: 6px;
    outline: none;
    background-color: var(--eduact-white);
    font-size: 16px;
    font-family: var(--eduact-font);
    color: var(--eduact-text);
    padding: 0 24px;
    font-weight: 500;
}

@media (max-width: 1399px) {
    .cta-two__email-input-box input[type='email'] {
        width: 320px;
    }
}
@media (max-width: 767px) {
    .cta-two__email-input-box input[type='email'] {
        width: 100%;
    }
}
.cta-three {
    position: relative;
    background-color: var(--eduact-black);
    padding: 100px 0 90px;
}

.cta-three__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
}

.cta-three__bg::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    background: rgba(54, 48, 93, 0.7);
    width: 100%;
    height: 100%;
}

.cta-three .container {
    position: relative;
}

.cta-three__thumb {
    position: relative;
    margin: 0 0 -6px;
}

.cta-three__thumb img {
    max-width: 100%;
    height: auto;
}

.cta-three__title {
    color: var(--eduact-white);
    font-size: 73px;
    line-height: 87px;
    letter-spacing: -1.452px;
    margin: 0;
}

@media (max-width: 1199px) {
    .cta-three__title {
        font-size: 62px;
        line-height: 75px;
    }
}
@media (max-width: 991px) {
    .cta-three__title {
        font-size: 45px;
        line-height: 55px;
        letter-spacing: 0;
    }
}
.cta-three__title span:first-child {
    font-weight: 400;
}

.cta-three__title span:last-child {
    color: var(--eduact-secondary);
}

.cta-three__btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 360px;
    height: 360px;
    border-radius: 50%;
    background-color: rgba(var(--eduact-white-rgb), 0.06);
}

@media (min-width: 1400px) {
    .cta-three__btn {
        right: -52px;
    }
}
@media (max-width: 767px) {
    .cta-three__btn {
        position: relative;
    }
}
@media (max-width: 400px) {
    .cta-three__btn {
        width: 300px;
        height: 300px;
    }
}
.cta-three__btn .eduact-btn {
    background-color: var(--eduact-secondary);
    padding: 17px 59px 18px;
}

.cta-three__btn .eduact-btn__curve {
    opacity: 0;
}

.cta-three__btn .eduact-btn:hover .eduact-btn__curve {
    opacity: 0.2;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.main-footer {
    background-color: var(--eduact-black);
    position: relative;
    padding: 132px 0 120px;
}

@media (max-width: 767px) {
    .main-footer {
        padding: 80px 0;
    }
}
.main-footer__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
}

.main-footer__about {
    position: relative;
}

.main-footer__about__text {
    color: var(--eduact-text2);
    font-size: 18px;
    line-height: 29.4px;
    margin: 0 0 20px;
}

@media (min-width: 1400px) {
    .main-footer__about__text {
        padding-right: 40px;
    }
}
.main-footer__logo {
    display: block;
    margin: 0 0 20px;
}

.main-footer__social {
    position: relative;
}

.main-footer__social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: rgba(var(--eduact-base-rgb), 0.17);
    color: var(--eduact-white);
    line-height: 30px;
    border-radius: 50%;
    font-size: 15px;
    text-align: center;
}

.main-footer__social a:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.main-footer__social a + a {
    margin-left: 10px;
}

.main-footer__title {
    font-size: 23.526px;
    font-style: normal;
    font-weight: 600;
    line-height: 29.407px;
    text-transform: capitalize;
    color: var(--eduact-white);
    padding-bottom: 11px;
    margin: 0 0 22px;
    overflow: hidden;
    position: relative;
}

.main-footer__title::after {
    position: absolute;
    left: 0;
    bottom: 1px;
    content: '';
    width: 85px;
    height: 1px;
    background-color: var(--eduact-secondary);
}

.main-footer__title::before {
    position: absolute;
    left: -10px;
    bottom: -1px;
    content: '';
    width: 52px;
    height: 4px;
    background-color: var(--eduact-base);
    z-index: 2;
    -webkit-animation: movebounce2 2s linear infinite;
    animation: movebounce2 2s linear infinite;
}

@media (min-width: 1200px) {
    .main-footer__widget01 {
        padding-left: 30px;
    }
}
@media (min-width: 1400px) {
    .main-footer__widget01 {
        padding-left: 42px;
    }
    .main-footer__widget02 {
        margin-left: -41px;
    }
}
.main-footer__navmenu {
    position: relative;
}

@media (max-width: 767px) {
    .main-footer__navmenu {
        margin-top: 30px;
    }
}
.main-footer__navmenu ul {
    margin: 0;
    padding: 0;
}

.main-footer__navmenu ul li {
    list-style: none;
    display: block;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
    color: var(--eduact-text2);
    text-transform: capitalize;
}

.main-footer__navmenu ul li a {
    color: inherit;
    display: inline-block;
}

.main-footer__navmenu ul li a:hover {
    color: var(--eduact-secondary);
    padding-left: 5px;
}

.main-footer__info-list {
    margin: 27px 0 35px;
    padding: 0;
    list-style: none;
}

.main-footer__info-list li {
    list-style: none;
    display: block;
    position: relative;
    padding: 0 0 0 40px;
    font-size: 18px;
    color: var(--eduact-text2);
    line-height: 30px;
    margin: 0 0 16px;
}

.main-footer__info-list li a {
    color: inherit;
}

.main-footer__info-list li a:hover {
    color: var(--eduact-secondary);
}

.main-footer__info-list li span {
    position: absolute;
    left: 0;
    top: 0;
    color: var(--eduact-secondary);
    font-size: 24px;
    line-height: inherit;
}

.main-footer__newsletter {
    position: relative;
}

@media (max-width: 767px) {
    .main-footer__newsletter {
        margin-top: 30px;
    }
}
@media (min-width: 1400px) {
    .main-footer__newsletter {
        margin-left: -15px;
        margin-right: 20px;
    }
}
.main-footer__newsletter .eduact-btn {
    position: absolute;
    right: 0;
    top: 0;
    height: 55px;
    padding: 0 22.3px;
    background-color: var(--eduact-secondary);
}

.main-footer__newsletter .eduact-btn__curve {
    opacity: 0;
}

.main-footer__newsletter .eduact-btn:hover .eduact-btn__curve {
    opacity: 0.2;
}

.main-footer__email-box {
    position: relative;
}

.main-footer__email-input-box {
    position: relative;
}

.main-footer__email-input-box input[type='email'] {
    width: 100%;
    height: 55px;
    border: 1px solid rgba(var(--eduact-base-rgb), 0.56);
    border-radius: 6px;
    outline: none;
    background-color: transparent;
    font-size: 18px;
    font-family: var(--eduact-font);
    color: var(--eduact-text2);
    padding: 0 130px 0 19px;
    font-weight: 400;
}

/*-- Copyright --*/
.copyright {
    position: relative;
    background-color: #1f1944;
    padding: 27px 0 28px;
}

.copyright__text {
    color: var(--eduact-text2);
    margin: 0;
}

.copyright__text a {
    color: inherit;
}

.copyright__text a:hover {
    color: var(--eduact-secondary);
}

/*-- Footer Two --*/
.main-footer-two {
    background-color: var(--eduact-black);
    position: relative;
    padding: 120px 0 115px;
}

@media (max-width: 767px) {
    .main-footer-two {
        padding: 80px 0 70px;
    }
}
.main-footer-two__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: top left;
    width: 100%;
    height: 100%;
    -webkit-animation: movebounce3 2s linear infinite;
    animation: movebounce3 2s linear infinite;
}

.main-footer-two__about {
    position: relative;
}

.main-footer-two__logo {
    display: block;
    margin: 0 0 20px;
}

.main-footer-two__info-list {
    margin: 27px 0 26px;
    padding: 0;
    list-style: none;
}

.main-footer-two__info-list li {
    list-style: none;
    display: block;
    position: relative;
    padding: 0 0 0 40px;
    font-size: 18px;
    color: var(--eduact-text2);
    line-height: 30px;
    margin: 0 0 17px;
}

.main-footer-two__info-list li a {
    color: inherit;
}

.main-footer-two__info-list li a:hover {
    color: var(--eduact-secondary);
}

.main-footer-two__info-list li span {
    position: absolute;
    left: 0;
    top: 0;
    color: var(--eduact-secondary);
    font-size: 24px;
    line-height: inherit;
}

.main-footer-two__social {
    position: relative;
}

.main-footer-two__social a {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 1px solid var(--eduact-base);
    color: var(--eduact-white);
    line-height: 30px;
    border-radius: 50%;
    font-size: 15px;
    text-align: center;
}

.main-footer-two__social a:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.main-footer-two__social a + a {
    margin-left: 10px;
}

.main-footer-two__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 29.407px;
    text-transform: capitalize;
    color: var(--eduact-white);
    margin: 9px 0 26px;
    position: relative;
}

@media (min-width: 1400px) {
    .main-footer-two__widget01 {
        padding-left: 7px;
    }
    .main-footer-two__widget02 {
        margin-left: -52px;
    }
}
@media (min-width: 1200px) and (max-width: 1300px) {
    .main-footer-two__widget02 {
        margin-left: -45px;
    }
}
.main-footer-two__navmenu {
    position: relative;
}

@media (max-width: 767px) {
    .main-footer-two__navmenu {
        margin-top: 30px;
    }
}
.main-footer-two__navmenu ul {
    margin: 0;
    padding: 0;
}

.main-footer-two__navmenu ul li {
    list-style: none;
    display: block;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 5px;
    color: var(--eduact-text2);
    text-transform: capitalize;
}

.main-footer-two__navmenu ul li a {
    color: inherit;
    display: inline-block;
}

.main-footer-two__navmenu ul li a:hover {
    color: var(--eduact-secondary);
    padding-left: 5px;
}

.main-footer-two__gallery {
    position: relative;
}

@media (max-width: 1199px) {
    .main-footer-two__gallery {
        margin-top: 30px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .main-footer-two__gallery {
        margin-right: -45px;
    }
}
@media (min-width: 1200px) and (max-width: 1300px) {
    .main-footer-two__gallery {
        margin-left: -35px;
    }
}
.main-footer-two__galleries {
    position: relative;
}

.main-footer-two__galleries a {
    width: 93px;
    height: 93px;
    display: inline-block;
    position: relative;
    margin: 0 8px 12px 0;
}

.main-footer-two__galleries a::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    -ms-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    background-color: rgba(var(--eduact-base-rgb), 0.7);
}

.main-footer-two__galleries a::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: '+';
    margin: auto;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    font-weight: 400;
    color: var(--eduact-white);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    transition-delay: 0s;
    z-index: 2;
}

@media (min-width: 1200px) {
    .main-footer-two__galleries a:nth-child(3),
    .main-footer-two__galleries a:nth-child(6),
    .main-footer-two__galleries a:last-child {
        margin-right: 0;
    }
}
.main-footer-two__galleries a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-footer-two__galleries a:hover::after {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
}

.main-footer-two__galleries a:hover::before {
    -webkit-transform: scale(1);
    transform: scale(1);
    transition-delay: 400ms;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Client Carousel
  --------------------------------------------------------------*/
.client-carousel {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .client-carousel {
        padding: 80px 0;
    }
}
.client-carousel__one {
    position: relative;
}

.client-carousel__one .client-carousel__one__item {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 500ms;
    position: relative;
}

.client-carousel__one
    .client-carousel__one__item:hover
    .client-carousel__hover {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    visibility: visible;
    -webkit-transform: translateY(0%);
    -moz-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -o-transform: translateY(0%);
    transform: translateY(0%);
}

.client-carousel__one
    .client-carousel__one__item:hover
    .client-carousel__normal {
    display: block;
    visibility: hidden;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transform: translateY(100%);
}

.client-carousel__one .client-carousel__one__item img {
    transition: 500ms;
    max-width: 100%;
    width: auto;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
}

.client-carousel__one .client-carousel__one__item .client-carousel__hover {
    transition: all 0.3s ease-in-out;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    visibility: hidden;
}

/*--------------------------------------------------------------
  # Funfact
  --------------------------------------------------------------*/
.fact-one {
    position: relative;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 120px 0;
    border-bottom: 1px solid var(--eduact-soft4);
}

@media (max-width: 991px) {
    .fact-one {
        padding-bottom: 85px;
    }
}
@media (max-width: 767px) {
    .fact-one {
        padding: 80px 0 55px;
    }
}
.fact-one__item {
    position: relative;
    border-radius: 14px;
    background: var(--eduact-white);
    box-shadow: -1px 3px 60px 0px rgba(var(--eduact-black2-rgb), 0.07);
    padding: 50px 25px 53px;
    transition: 500ms ease;
}

@media (max-width: 991px) {
    .fact-one__item {
        max-width: 303px;
        margin: 0 auto 36px;
    }
}
@media (min-width: 991px) and (max-width: 1199px) {
    .fact-one__item {
        padding: 26px 25px 27px;
    }
}
@media (min-width: 1200px) and (max-width: 1399px) {
    .fact-one__item {
        padding: 38px 25px 40px;
    }
}
.fact-one__item svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.fact-one__item svg rect {
    stroke: var(--eduact-base);
    transition: 500ms ease;
}

.fact-one__item:hover {
    box-shadow: -1px 3px 60px 0px rgba(var(--eduact-black2-rgb), 0.12);
}

.fact-one__item:hover svg rect {
    stroke: var(--eduact-secondary);
}

.fact-one__count {
    display: block;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    color: var(--eduact-secondary);
    margin: 0 0 9px;
    position: relative;
    z-index: 2;
}

.fact-one__count .count-box {
    display: inline-flex;
}

.fact-one__title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    color: var(--eduact-text);
    position: relative;
    z-index: 2;
}

.fact-two {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 0 0 120px;
    border-bottom: 1px solid var(--eduact-soft4);
}

@media (max-width: 767px) {
    .fact-two {
        padding: 0 0 80px;
    }
}
.fact-two__inner {
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: var(--eduact-base);
    border-radius: 0 0 40px 40px;
    padding: 50px 25px;
}

.fact-two__item {
    position: relative;
    padding: 25px 0 18px;
    transition: 500ms ease;
}

@media (max-width: 767px) {
    .fact-two__item {
        padding-bottom: 25px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .fact-two__item--smnone::after {
        display: none;
    }
}
.fact-two__item--noborder::after {
    display: none;
}

.fact-two__item::after {
    position: absolute;
    right: -20px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 2px;
    content: '';
    border-right: 2px dashed rgba(var(--eduact-white-rgb), 0.4);
}

@media (max-width: 767px) {
    .fact-two__item::after {
        right: 0;
        width: 100%;
        height: 2px;
        top: auto;
        border-right: none;
        border-bottom: 2px dashed rgba(var(--eduact-white-rgb), 0.4);
    }
}
.fact-two__item:hover .fact-two__icon span {
    transform: rotateY(360deg);
}

.fact-two__icon {
    position: relative;
    display: block;
    line-height: 70px;
    font-size: 70px;
    color: var(--eduact-secondary);
    margin-bottom: 15px;
}

.fact-two__icon span {
    display: block;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.fact-two__count {
    display: block;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    color: var(--eduact-white);
    margin: 0 0 7px;
    position: relative;
    z-index: 2;
}

@media (max-width: 1199px) {
    .fact-two__count {
        font-size: 50px;
    }
}
.fact-two__count .count-box {
    display: inline-flex;
}

.fact-two__title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    color: var(--eduact-soft4);
    position: relative;
    z-index: 2;
}

/*--------------------------------------------------------------
  # Hero Slider
  --------------------------------------------------------------*/
.main-slider {
    position: relative;
    overflow: hidden;
    display: block;
}

.main-slider__one {
    width: 100%;
    background-color: var(--eduact-black);
}

.main-slider__one.owl-carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    max-width: 1350px;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 119px;
    padding: 0 15px;
    margin: 0 auto;
    margin-top: 0px;
    height: 0;
    line-height: 0;
    z-index: 3;
}

@media (max-width: 767px) {
    .main-slider__one.owl-carousel .owl-dots {
        bottom: 50px;
    }
}
@media (max-width: 991px) {
    .main-slider__one.owl-carousel .owl-dots {
        max-width: 720px;
    }
}
@media (max-width: 1199px) {
    .main-slider__one.owl-carousel .owl-dots {
        max-width: 960px;
        bottom: 50px;
    }
}
.main-slider__one.owl-carousel .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid var(--eduact-secondary);
    margin: 0 13px 0 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.main-slider__one.owl-carousel .owl-dots .owl-dot.active span {
    width: 31px;
    height: 31px;
    border-width: 3px;
}

.main-slider__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.main-slider__item {
    position: relative;
    padding-top: 286px;
    padding-bottom: 198px;
}

@media (max-width: 1199px) {
    .main-slider__item {
        padding-top: 200px;
        padding-bottom: 150px;
    }
}
@media (max-width: 767px) {
    .main-slider__item {
        padding-top: 180px;
        padding-bottom: 198px;
    }
}
.main-slider__content {
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.main-slider__title {
    visibility: hidden;
    opacity: 0;
    text-transform: capitalize;
    font-size: 70px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: -1.4px;
    margin: 0 0 30px;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    transform: translateY(200px);
}

@media (min-width: 1200px) and (max-width: 1499px) {
    .main-slider__title {
        font-size: 62px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .main-slider__title {
        font-size: 55px;
        line-height: 60px;
    }
}
@media (max-width: 767px) {
    .main-slider__title {
        font-size: 42px;
        line-height: 45px;
        letter-spacing: 0;
    }
}
.main-slider__title span {
    color: var(--eduact-secondary);
    font-family: 'Water Brush', cursive;
    font-size: 107px;
    font-weight: 400;
    line-height: 125px;
    letter-spacing: -2.141px;
    margin-bottom: -38px;
    position: relative;
    display: inline-block;
}

@media (min-width: 1200px) and (max-width: 1499px) {
    .main-slider__title span {
        font-size: 90px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .main-slider__title span {
        font-size: 75px;
    }
}
@media (max-width: 767px) {
    .main-slider__title span {
        font-size: 60px;
        line-height: 65px;
        letter-spacing: 0;
    }
}
.main-slider__text {
    visibility: hidden;
    opacity: 0;
    font-size: 20px;
    line-height: 30px;
    color: var(--eduact-black);
    margin: 0 0 31px;
    transition:
        transform 1200ms ease,
        opacity 1200ms ease;
    transform: translateY(150px);
}

@media (min-width: 992px) and (max-width: 1199px) {
    .main-slider__text br {
        display: none;
    }
}
@media (max-width: 767px) {
    .main-slider__text {
        font-size: 16px;
    }
}
.main-slider__btn {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(0deg) translateY(80px);
    -ms-transform: perspective(400px) rotateY(0deg) translateY(80px);
    transform: perspective(400px) rotateY(0deg) translateY(80px);
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transition: all 1500ms ease;
    -moz-transition: all 1500ms ease;
    -ms-transition: all 1500ms ease;
    -o-transition: all 1500ms ease;
    transition: all 1500ms ease;
}

.main-slider .active .main-slider__title {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) translateX(0);
    transition-delay: 1100ms;
}

.main-slider .active .main-slider__text {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) translateX(0);
    transition-delay: 1300ms;
}

.main-slider .active .main-slider__btn {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg) translateY(0px);
    -ms-transform: perspective(400px) rotateY(0deg) translateY(0px);
    transform: perspective(400px) rotateY(0deg) translateY(0px);
    transition-delay: 1500ms;
}

.main-slider .active .main-slider__layer svg {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) translateX(0);
    transition-delay: 1500ms;
}

.main-slider .active .main-slider__layer img {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) translateX(0);
    transition-delay: 1900ms;
}

.main-slider .active .main-slider__shape-one {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) translateX(0);
    transition-delay: 2000ms;
}

.main-slider .active .main-slider__shape-two {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) translateX(0);
    transition-delay: 2050ms;
}

.main-slider .active .main-slider__shape-three {
    visibility: visible;
    opacity: 1;
    transition-delay: 2100ms;
    transform: translateY(0) translateX(0);
}

.main-slider .active .main-slider__shape-four {
    visibility: visible;
    opacity: 1;
    transition-delay: 2150ms;
    transform: translateY(0) translateX(0);
}

.main-slider .active .main-slider__shape-five {
    visibility: visible;
    opacity: 1;
    transition-delay: 2200ms;
    transform: translateY(0) translateX(0);
}

.main-slider .active .main-slider__shape-six {
    visibility: visible;
    opacity: 1;
    transition-delay: 2250ms;
    transform: translateY(0) translateX(0);
}

.main-slider .active .main-slider__shape-seven {
    visibility: visible;
    opacity: 1;
    transition-delay: 2300ms;
    transform: translateY(0) translateX(0);
}

.main-slider .active .main-slider__shape-eight {
    visibility: visible;
    opacity: 1;
    transition-delay: 2350ms;
    transform: translateY(0) translateX(0);
}

.main-slider .active .main-slider__shape-nine {
    visibility: visible;
    opacity: 1;
    transition-delay: 2400ms;
    transform: translateY(0) translateX(0);
}

.main-slider__layer {
    position: absolute;
    bottom: 0;
    z-index: 3;
    margin-left: 46px;
}

@media (max-width: 991px) {
    .main-slider__layer {
        display: none;
    }
}
.main-slider__layer img {
    object-fit: cover;
    width: auto !important;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    visibility: hidden;
    opacity: 0;
    transform: translateX(200px);
}

@media (min-width: 992px) and (max-width: 1199px) {
    .main-slider__layer img {
        max-width: 455px;
    }
}
.main-slider__layer svg {
    width: 884px;
    height: 578px;
    position: absolute;
    fill: var(--eduact-base);
    bottom: 0;
    z-index: -1;
    left: -155px;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100%);
    animation: change-background 8s ease infinite;
}

@media (min-width: 1200px) and (max-width: 1499px) {
    .main-slider__layer svg {
        left: -120px;
    }
}
@media (max-width: 1199px) {
    .main-slider__layer svg {
        left: -55px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .main-slider__layer svg {
        width: 600px;
        height: auto;
    }
}
.main-slider__shape-one {
    position: absolute;
    top: 200px;
    left: 140px;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
}

@media (max-width: 1499px) {
    .main-slider__shape-one {
        display: none;
    }
}
.main-slider__shape-one img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.main-slider__shape-two {
    position: absolute;
    bottom: 270px;
    left: 73px;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100px);
}

@media (max-width: 1499px) {
    .main-slider__shape-two {
        display: none;
    }
}
.main-slider__shape-two img {
    -webkit-animation: rotated 20s infinite linear;
    animation: rotated 20s infinite linear;
}

.main-slider__shape-three {
    position: absolute;
    bottom: 78px;
    z-index: 3;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    margin-left: -142px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(100px);
}

@media (max-width: 1199px) {
    .main-slider__shape-three {
        display: none;
    }
}
.main-slider__shape-three svg {
    width: 152px;
    height: 152px;
}

.main-slider__shape-three svg circle {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    animation: dash 5s infinite linear;
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1200;
        stroke: var(--eduact-secondary);
    }
    50% {
        stroke: var(--eduact-base);
    }
    100% {
        stroke-dashoffset: 0;
        stroke: var(--eduact-secondary);
    }
}
.main-slider__shape-four {
    position: absolute;
    bottom: 388px;
    z-index: 3;
    margin-left: -112px;
    visibility: hidden;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    opacity: 0;
    transform: translateY(200px);
}

@media (max-width: 1199px) {
    .main-slider__shape-four {
        display: none;
    }
}
.main-slider__shape-four svg {
    width: 32px;
    height: 32px;
}

.main-slider__shape-four svg circle {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
    animation: dash4 3s infinite linear;
}

@keyframes dash4 {
    0% {
        stroke-dashoffset: 1200;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
.main-slider__shape-five {
    position: absolute;
    top: 308px;
    z-index: 3;
    margin-left: -112px;
    visibility: hidden;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    opacity: 0;
    transform: translateY(-200px);
}

@media (max-width: 1199px) {
    .main-slider__shape-five {
        display: none;
    }
}
.main-slider__shape-five svg {
    width: 124px;
    height: 101px;
    fill: var(--eduact-secondary);
    animation: zump-one 1s infinite alternate;
}

@keyframes zump-one {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-15px);
    }
}
.main-slider__shape-six {
    position: absolute;
    top: 160px;
    z-index: 3;
    margin-left: -18px;
    visibility: hidden;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    opacity: 0;
    transform: translateY(-200px);
}

@media (max-width: 1199px) {
    .main-slider__shape-six {
        display: none;
    }
}
.main-slider__shape-six img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.main-slider__shape-seven {
    position: absolute;
    top: 190px;
    margin-left: 500px;
    z-index: 3;
    visibility: hidden;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    opacity: 0;
    transform: translateY(-200px);
}

@media (max-width: 1199px) {
    .main-slider__shape-seven {
        display: none;
    }
}
.main-slider__shape-seven img {
    -webkit-animation: rotated 12s infinite linear;
    animation: rotated 12s infinite linear;
}

.main-slider__shape-eight {
    position: absolute;
    bottom: 290px;
    margin-left: 660px;
    width: 132px;
    height: 132px;
    background-color: var(--eduact-secondary);
    border-radius: 50%;
    border: 9px solid #e7e9f4;
    font-size: 65px;
    color: var(--eduact-white);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    visibility: hidden;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    opacity: 0;
    transform: translateY(200px);
}

@media (max-width: 1199px) {
    .main-slider__shape-eight {
        display: none;
    }
}
.main-slider__shape-eight span {
    -webkit-animation: rotated2 6s infinite linear;
    animation: rotated2 6s infinite linear;
}

.main-slider__shape-nine {
    position: absolute;
    bottom: 78px;
    margin-left: 595px;
    z-index: 3;
    visibility: hidden;
    transition:
        transform 1000ms ease,
        opacity 1000ms ease;
    opacity: 0;
    transform: translateY(200px);
}

@media (max-width: 1199px) {
    .main-slider__shape-nine {
        display: none;
    }
}
.main-slider__shape-nine img {
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

/*--------------------------------------------------------------
  # Page Header
  --------------------------------------------------------------*/
.page-header {
    background-color: var(--eduact-black);
    position: relative;
}

.page-header__bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.page-header__overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(var(--eduact-black2-rgb), 0.8);
}

.page-header .container {
    position: relative;
    z-index: 2;
    padding-top: 166px;
    padding-bottom: 151px;
}

@media (max-width: 767px) {
    .page-header .container {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
.page-header__title {
    margin: 0;
    font-size: 50px;
    line-height: 1;
    color: var(--eduact-white);
    text-transform: uppercase;
    margin-bottom: 10px;
}

@media (max-width: 480px) {
    .page-header__title {
        font-size: 36px;
    }
}
.page-header__breadcrumb {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin: 0;
}

.page-header__breadcrumb li {
    font-size: 20px;
    line-height: 1;
    color: var(--eduact-white);
    display: flex;
    align-items: center;
    position: relative;
}

@media (max-width: 480px) {
    .page-header__breadcrumb li {
        font-size: 18px;
    }
}
.page-header__breadcrumb li:not(:last-of-type)::after {
    position: absolute;
    right: -17px;
    bottom: 0;
    content: '/';
    font-size: inherit;
    line-height: 1;
    color: currentColor;
}

.page-header__breadcrumb li:not(:last-of-type) {
    margin-right: 26px;
}

.page-header__breadcrumb li a {
    color: inherit;
}

.page-header__breadcrumb li a:hover {
    color: var(--eduact-base);
}

.page-header__breadcrumb li span {
    display: block;
    line-height: 1;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact-one {
    position: relative;
    padding: 116px 0 120px;
}

@media (max-width: 767px) {
    .contact-one {
        padding: 76px 0 80px;
    }
}
.contact-one .container {
    max-width: 904px;
}

.contact-one .section-title {
    margin-bottom: 51px;
}

.contact-one__form-box {
    position: relative;
    display: block;
    padding: 0;
}

.contact-one__form {
    position: relative;
    display: block;
}

.contact-one__form .row {
    --bs-gutter-x: 20px;
}

.contact-one__input-box {
    position: relative;
    display: block;
    margin-bottom: 20px;
}

.contact-one__input-box input[type='text'],
.contact-one__input-box input[type='email'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    font-family: var(--eduact-font);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.contact-one__input-box textarea {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    height: 176px;
    width: 100%;
    background-color: var(--eduact-soft5);
    font-family: var(--eduact-font);
    padding: 20px 30px 30px;
    border: none;
    outline: none;
    border-radius: 4px;
    margin-bottom: 0px;
}

.contact-one__input-box.text-message-box {
    height: 176px;
    margin-bottom: 40px;
}

.contact-one .eduact-btn {
    text-transform: inherit;
}

.contact-info {
    position: relative;
    padding: 0 0 120px;
}

@media (max-width: 767px) {
    .contact-info {
        padding-bottom: 80px;
    }
}
.contact-info ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.07);
    background-color: var(--eduact-white);
    border-radius: 14px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .contact-info ul {
        display: block;
    }
}
.contact-info ul li {
    list-style: none;
    display: block;
    width: 33.33%;
    text-align: center;
    padding: 47px 20px 44px;
    position: relative;
    overflow: hidden;
    z-index: 2;
}

@media (max-width: 991px) {
    .contact-info ul li {
        width: 100%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .contact-info ul li {
        height: 252px;
    }
}
.contact-info ul li::after {
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    position: absolute;
    width: 100%;
    height: 200%;
    content: '';
    top: 110%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
    background-color: var(--eduact-base);
}

.contact-info ul li.active::after,
.contact-info ul li:hover::after {
    top: -40%;
}

.contact-info ul li.active .contact-info__icon,
.contact-info ul li:hover .contact-info__icon {
    color: var(--eduact-white);
}

.contact-info ul li.active .contact-info__icon span,
.contact-info ul li:hover .contact-info__icon span {
    transform: rotateY(360deg);
}

.contact-info ul li.active .contact-info__title,
.contact-info ul li:hover .contact-info__title {
    color: var(--eduact-white);
}

.contact-info ul li.active .contact-info__text,
.contact-info ul li:hover .contact-info__text {
    color: var(--eduact-white);
}

.contact-info__icon {
    font-size: 45px;
    line-height: 45px;
    color: var(--eduact-base);
    transition: all 500ms ease;
    margin-bottom: 24px;
}

.contact-info__icon span {
    display: block;
    -webkit-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.contact-info__title {
    font-size: 20px;
    font-weight: 500;
    transition: all 500ms ease;
    margin-bottom: 9px;
}

.contact-info__text {
    font-size: 24px;
    transition: all 500ms ease;
    margin-bottom: 0;
}

.contact-info__text a {
    color: inherit;
}

.contact-info__text a:hover {
    color: var(--eduact-secondary);
}

/*--------------------------------------------------------------
  # Google Map
  --------------------------------------------------------------*/
.google-map {
    width: 100%;
}

.google-map iframe {
    display: block;
    border: none;
    outline: none;
    width: 100%;
    height: 772px;
}

@media (max-width: 1199px) {
    .google-map iframe {
        height: 650px;
    }
}
@media (max-width: 991px) {
    .google-map iframe {
        height: 550px;
    }
}
@media (max-width: 767px) {
    .google-map iframe {
        height: 450px;
    }
}
/*--------------------------------------------------------------
  # Gallery
  --------------------------------------------------------------*/
.gallery-page {
    position: relative;
    padding: 120px 0 80px;
}

@media (max-width: 767px) {
    .gallery-page {
        padding: 80px 0 40px;
    }
}
.gallery-page__carousel {
    padding-bottom: 40px;
}

.gallery-page__single {
    position: relative;
    overflow: hidden;
    border-radius: 238px;
    z-index: 1;
    margin-bottom: 40px;
}

.gallery-page__single img {
    width: 100%;
    transition: all 500ms ease;
    transform: scale(1);
}

.gallery-page__single::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    -webkit-transform: perspective(400px) rotateX(-90deg);
    -ms-transform: perspective(400px) rotateX(-90deg);
    transform: perspective(400px) rotateX(-90deg);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    transform-origin: top;
    background-color: rgba(var(--eduact-base-rgb), 0.62);
}

.gallery-page__single:hover::after {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
}

.gallery-page__single:hover img {
    transform: scale(1.04);
}

.gallery-page__single:hover .gallery-page__icon a {
    -webkit-transform: scale(1);
    transform: scale(1);
    transition-delay: 500ms;
    opacity: 1;
}

.gallery-page__icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 2;
}

.gallery-page__icon a {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 2;
}

.gallery-page__icon a::after,
.gallery-page__icon a::before {
    content: '';
    width: 80px;
    height: 6px;
    background-color: var(--eduact-white);
    display: block;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.gallery-page__icon a::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
.pricing-one {
    position: relative;
    background-position: bottom center;
    background-repeat: no-repeat;
    padding: 116px 0 90px;
}

@media (max-width: 767px) {
    .pricing-one {
        padding: 76px 0 50px;
    }
}
.pricing-one .section-title {
    margin-bottom: 31px;
}

.pricing-one .pricing-one__main-tab-box {
    position: relative;
    display: block;
}

.pricing-one .pricing-one__main-tab-box .tab-buttons {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 80px;
}

.pricing-one .pricing-one__main-tab-box .tab-buttons .tab-btn {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 7px;
}

.pricing-one .pricing-one__main-tab-box .tab-buttons .tab-btn span {
    position: relative;
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 16px;
    color: var(--eduact-black);
    background-color: var(--eduact-soft4);
    padding: 12px 30.2px 12px;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    text-transform: capitalize;
    border-radius: 8px;
    transition: all 0.5s linear;
    z-index: 1;
}

.pricing-one .pricing-one__main-tab-box .tab-buttons .tab-btn span::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0%;
    background-color: var(--eduact-secondary);
    transition: all 0.3s ease;
    z-index: -1;
}

.pricing-one .pricing-one__main-tab-box .tab-buttons .tab-btn.active-btn span {
    color: var(--eduact-white);
}

.pricing-one
    .pricing-one__main-tab-box
    .tab-buttons
    .tab-btn.active-btn
    span::before {
    height: 100%;
}

.pricing-one .pricing-one__main-tab-box .tabs-content {
    position: relative;
    display: block;
}

.pricing-one .pricing-one__main-tab-box .tabs-content .tab {
    position: relative;
    display: none;
    -webkit-transition: all 600ms ease;
    -moz-transition: all 600ms ease;
    -ms-transition: all 600ms ease;
    -o-transition: all 600ms ease;
    transition: all 600ms ease;
    z-index: 10;
}

.pricing-one .pricing-one__main-tab-box .tabs-content .tab.active-tab {
    display: block;
    margin-top: 0px;
}

.pricing-one__item {
    position: relative;
    overflow: hidden;
    padding: 46px 25px 30px;
    border-radius: 10px;
    background: var(--eduact-white);
    box-shadow: 0px 0px 60px 0px rgba(var(--eduact-black2-rgb), 0.07);
    margin-bottom: 30px;
    transition: all 0.4s ease;
    text-align: center;
}

.pricing-one__item.active svg,
.pricing-one__item:hover svg {
    fill: var(--eduact-secondary);
}

.pricing-one__item.active .pricing-one__item__price,
.pricing-one__item.active .pricing-one__item__name,
.pricing-one__item:hover .pricing-one__item__price,
.pricing-one__item:hover .pricing-one__item__name {
    color: var(--eduact-white);
}

.pricing-one__item.active .eduact-btn,
.pricing-one__item:hover .eduact-btn {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.pricing-one__item svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 173px;
    fill: var(--eduact-soft5);
    transition: all 0.4s ease;
}

.pricing-one__item__name {
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 500;
    color: var(--eduact-black);
    transition: all 0.4s ease;
    position: relative;
    z-index: 2;
    margin: 0 0 19px;
}

.pricing-one__item__price {
    font-size: 40px;
    line-height: 1;
    position: relative;
    z-index: 2;
    transition: all 0.4s ease;
    margin-bottom: 80px;
}

.pricing-one__item__list-title {
    font-size: 20px;
    margin-bottom: 20px;
}

.pricing-one__item__list {
    margin: 0 0 33px;
    padding: 0;
    list-style: none;
}

.pricing-one__item__list li {
    list-style: none;
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    color: var(--eduact-black2);
    margin: 0 0 19px;
}

.pricing-one__item__list li span {
    display: inline-block;
    color: var(--eduact-secondary);
    font-size: 16px;
    margin-right: 10px;
    position: relative;
    line-height: inherit;
    top: -1px;
}

.pricing-one__item__border {
    width: calc(100% + 70px);
    position: relative;
    left: -35px;
    height: 1px;
    background-color: var(--eduact-soft4);
    margin-bottom: 30px;
}

.pricing-one__item .eduact-btn {
    background-color: var(--eduact-soft4);
    color: var(--eduact-text);
    font-size: 16px;
    border-radius: 8px;
    padding: 12px 48.5px 12px;
}

/*--------------------------------------------------------------
  # Faq
  --------------------------------------------------------------*/
.accrodion-one {
    position: relative;
    padding: 120px 0 100px;
}

@media (max-width: 767px) {
    .accrodion-one {
        padding: 80px 0 60px;
    }
}
.accrodion-one .container {
    max-width: 1130px;
}

.accrodion-one__wrapper {
    position: relative;
}

.accrodion-one__wrapper .accrodion {
    position: relative;
    display: block;
    margin-bottom: 20px;
    padding: 31px 82px 10px 40px;
    border: 1px solid var(--eduact-soft4);
    border-radius: 8px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

@media (max-width: 767px) {
    .accrodion-one__wrapper .accrodion {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.accrodion-one__wrapper .accrodion.active .accrodion__icon::after {
    opacity: 0;
    visibility: hidden;
}

.accrodion-one__wrapper .accrodion.active .accrodion__icon,
.accrodion-one__wrapper .accrodion:hover .accrodion__icon {
    color: var(--eduact-secondary);
}

.accrodion-one__wrapper .accrodion.active .accrodion-title h4,
.accrodion-one__wrapper .accrodion:hover .accrodion-title h4 {
    color: var(--eduact-secondary);
}

.accrodion-one__wrapper .accrodion__icon {
    position: absolute;
    right: 38px;
    top: 34px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    z-index: 2;
    color: var(--eduact-black);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

@media (max-width: 767px) {
    .accrodion-one__wrapper .accrodion__icon {
        right: 25px;
    }
}
.accrodion-one__wrapper .accrodion__icon::after,
.accrodion-one__wrapper .accrodion__icon::before {
    content: '';
    width: 22px;
    height: 2px;
    background-color: currentColor;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.accrodion-one__wrapper .accrodion__icon::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.accrodion-one__wrapper .accrodion-title {
    padding: 0 0 22px;
    position: relative;
    display: block;
    cursor: pointer;
    transition: all 200ms linear;
    transition-delay: 0s;
    transition-delay: 0.1s;
}

@media (max-width: 767px) {
    .accrodion-one__wrapper .accrodion-title {
        padding-right: 30px;
    }
}
.accrodion-one__wrapper .accrodion-title h4 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.16px;
    line-height: 26px;
    position: relative;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.accrodion-one__wrapper .accrodion-content {
    position: relative;
    padding: 0 0 19px;
}

.accrodion-one__wrapper .accrodion-content p {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.16px;
    margin: 0;
}

.cta-faq {
    position: relative;
    padding: 0 0 120px;
}

@media (max-width: 767px) {
    .cta-faq {
        padding: 0 0 80px;
    }
}
.cta-faq .container {
    max-width: 1130px;
}

.cta-faq__help {
    position: relative;
    padding: 53px 30px 49px;
}

.cta-faq__help__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    border-radius: 14px;
    overflow: hidden;
}

.cta-faq__help__bg::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    background: rgba(var(--eduact-base-rgb), 0.8);
    width: 100%;
    height: 100%;
    border-radius: 14px;
    overflow: hidden;
}

.cta-faq__help__icon {
    position: relative;
    z-index: 2;
    width: 50px;
    height: 50px;
    background-color: var(--eduact-white);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: var(--eduact-secondary);
    margin: 0 auto 19px;
}

.cta-faq__help__icon span {
    display: inline-block;
    transition: all 500ms linear;
    transition-delay: 0s;
    transition-delay: 0s;
    transform: scale(1);
}

.cta-faq__help:hover .cta-faq__help__icon span {
    transform: scale(0.9);
}

.cta-faq__help__title {
    position: relative;
    z-index: 2;
    color: var(--eduact-white);
    font-size: 40px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 19px;
}

@media (max-width: 767px) {
    .cta-faq__help__title {
        font-size: 30px;
    }
}
.cta-faq__help__border {
    max-width: 525px;
    height: 1px;
    margin: auto;
    background-color: var(--eduact-secondary);
    margin-bottom: 13px;
}

.cta-faq__help__text {
    position: relative;
    z-index: 2;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: var(--eduact-white);
    margin-bottom: 0;
}

.cta-faq__help__text a {
    display: block;
    color: inherit;
    font-size: 24px;
    font-weight: 700;
}

.cta-faq__help__text a:hover {
    color: var(--eduact-secondary);
}

/*--------------------------------------------------------------
  # Login
  --------------------------------------------------------------*/
.login-page {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .login-page {
        padding: 80px 0;
    }
}
.login-page__info {
    background-color: var(--eduact-soft5);
    padding: 36px 50px 32px;
    border-radius: 14px;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .login-page__info {
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 30px;
    }
}
.login-page__info p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: var(--eduact-black);
}

.login-page__info p span {
    color: var(--eduact-black);
    font-weight: 700;
}

.login-page__info p a {
    color: var(--eduact-secondary);
    display: inline-block;
    font-weight: 700;
}

.login-page__info p a:hover {
    color: var(--eduact-black);
}

.login-page__area {
    border-radius: 14px;
    background: var(--eduact-white);
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    padding: 53px 60px 60px;
    position: relative;
}

@media (max-width: 991px) {
    .login-page__area {
        padding: 0 50px 60px;
    }
}
@media (max-width: 767px) {
    .login-page__area {
        padding: 0 30px 50px;
    }
}
.login-page__area::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 354px;
    border-right: 1px dashed var(--eduact-grey);
}

@media (max-width: 991px) {
    .login-page__area::after {
        border: none;
        width: calc(100% - 100px);
        height: 1px;
        border-top: 1px dashed var(--eduact-grey);
    }
}
@media (max-width: 767px) {
    .login-page__area::after {
        width: calc(100% - 60px);
    }
}
.login-page__wrap {
    position: relative;
    padding-right: 86px;
}

@media (max-width: 1199px) {
    .login-page__wrap {
        padding-right: 20px;
    }
}
@media (max-width: 991px) {
    .login-page__wrap {
        padding-left: 0;
        padding-right: 0;
    }
}
.login-page__wrap--right {
    padding-right: 0;
    padding-left: 86px;
}

@media (max-width: 1199px) {
    .login-page__wrap--right {
        padding-right: 0;
        padding-left: 20px;
    }
}
@media (max-width: 991px) {
    .login-page__wrap--right {
        padding-left: 0;
        padding-right: 0;
    }
}
.login-page__wrap__title {
    font-size: 32px;
    margin-bottom: 32px;
}

@media (max-width: 991px) {
    .login-page__wrap__title {
        margin-top: 50px;
    }
}
.login-page__form {
    position: relative;
    display: block;
}

.login-page__form-input-box {
    position: relative;
    display: block;
    margin-bottom: 20px;
}

.login-page__form-input-box input[type='email'],
.login-page__form-input-box input[type='password'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.login-page__checked-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 38px;
}

.login-page__checked-box label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    margin-right: 0px;
    margin-bottom: 0;
    color: var(--eduact-text);
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    cursor: pointer;
}

.login-page__checked-box label span:before {
    position: absolute;
    top: 0;
    left: 5px;
    line-height: 19px;
    display: inline-block;
    color: var(--eduact-black);
    font-family: 'Font Awesome 5 Free';
    content: '\f00c';
    font-size: 9px;
    font-weight: 900;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    opacity: 0;
}

.login-page__checked-box input[type='checkbox'] {
    display: none;
}

.login-page__checked-box input[type='checkbox'] + label span {
    position: absolute;
    top: 4px;
    left: 0;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: transparent;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    border: 1px solid #d9d9d9;
}

.login-page__checked-box input[type='checkbox']:checked + label span:before {
    opacity: 1;
}

.login-page__forgot-password {
    position: relative;
    margin-left: 20px;
}

.login-page__forgot-password a {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    position: relative;
    display: inline-block;
}

.login-page__forgot-password a:hover {
    color: var(--eduact-secondary);
}

/*--------------------------------------------------------------
  # 404
  --------------------------------------------------------------*/
.error-page {
    position: relative;
    padding: 191px 0 120px;
}

@media (max-width: 767px) {
    .error-page {
        padding: 120px 0 80px;
    }
}
.error-page__content {
    position: relative;
    text-align: center;
}

.error-page__thumb {
    position: relative;
    max-width: 713px;
    margin: 0 auto;
}

.error-page__404 {
    margin: 0;
    padding: 0 0 138px;
}

@media (max-width: 767px) {
    .error-page__404 {
        padding-bottom: 105px;
    }
}
.error-page__404 img {
    max-width: 100%;
}

@media (max-width: 767px) {
    .error-page__404 img {
        max-height: 150px;
    }
}
.error-page__shape1 {
    position: absolute;
    z-index: 2;
    top: -71px;
    text-align: center;
    left: -227px;
    right: 0;
}

.error-page__shape1 img {
    -webkit-animation: rotated 10s infinite linear;
    animation: rotated 10s infinite linear;
}

.error-page__shape2 {
    position: absolute;
    top: -52px;
    text-align: center;
    left: 0;
    right: -232px;
}

.error-page__shape2 img {
    max-width: 100%;
    -webkit-animation: shapeMover2 20s linear infinite alternate;
    animation: shapeMover2 20s linear infinite alternate;
}

.error-page__shape3 {
    position: absolute;
    top: -6px;
    text-align: center;
    left: 0;
    right: 0;
}

.error-page__shape3 img {
    max-width: 100%;
}

@media (max-width: 767px) {
    .error-page__shape3 img {
        max-width: 360px;
        margin: auto;
    }
}
.error-page__shape4 {
    position: absolute;
    top: 91px;
    text-align: center;
    left: -265px;
    right: 0;
}

@media (max-width: 767px) {
    .error-page__shape4 {
        top: 60px;
        left: -200px;
    }
}
.error-page__shape4 img {
    max-width: 100%;
    -webkit-animation: shapeMover2 20s linear infinite alternate;
    animation: shapeMover2 20s linear infinite alternate;
}

.error-page__shape5 {
    position: absolute;
    top: 83px;
    text-align: center;
    left: 0;
    right: -263px;
}

@media (max-width: 767px) {
    .error-page__shape5 {
        top: 60px;
        right: -200px;
    }
}
.error-page__shape5 img {
    max-width: 100%;
    -webkit-animation: shapeMover3 20s linear infinite alternate;
    animation: shapeMover3 20s linear infinite alternate;
}

.error-page__shape6 {
    position: absolute;
    top: 92px;
    left: -19px;
}

@media (max-width: 767px) {
    .error-page__shape6 {
        display: none;
    }
}
.error-page__shape6 img {
    max-width: 100%;
    -webkit-animation: movebounce2 2s linear infinite;
    animation: movebounce2 2s linear infinite;
}

.error-page__shape7 {
    position: absolute;
    top: 145px;
    left: 20px;
}

@media (max-width: 767px) {
    .error-page__shape7 {
        display: none;
    }
}
.error-page__shape7 img {
    max-width: 100%;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.error-page__shape8 {
    position: absolute;
    top: 232px;
    left: 12px;
}

@media (max-width: 767px) {
    .error-page__shape8 {
        display: none;
    }
}
.error-page__shape8 img {
    max-width: 100%;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.error-page__shape9 {
    position: absolute;
    top: 81px;
    right: 24px;
}

@media (max-width: 767px) {
    .error-page__shape9 {
        display: none;
    }
}
.error-page__shape9 img {
    max-width: 100%;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 4s;
    animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.error-page__shape10 {
    position: absolute;
    top: 172px;
    right: 49px;
}

@media (max-width: 767px) {
    .error-page__shape10 {
        display: none;
    }
}
.error-page__shape10 img {
    max-width: 100%;
    -webkit-animation-name: float-bob-y-2;
    animation-name: float-bob-y-2;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.error-page__shape11 {
    position: absolute;
    top: 260px;
    right: 18px;
}

@media (max-width: 767px) {
    .error-page__shape11 {
        display: none;
    }
}
.error-page__shape11 img {
    max-width: 100%;
    -webkit-animation: movebounce2 2s linear infinite;
    animation: movebounce2 2s linear infinite;
}

.error-page__404-title {
    font-size: 145px;
    max-width: 100px;
    line-height: 0.9;
    font-weight: 800;
    margin: -80px auto 25px;
    word-break: break-all;
    color: var(--eduact-black);
    text-shadow:
        1px 1px 0 var(--eduact-secondary),
        -1px -1px 0 var(--eduact-secondary),
        1px -1px 0 var(--eduact-secondary),
        -1px 1px 0 var(--eduact-secondary),
        1px 1px 0 var(--eduact-secondary);
}

.error-page__title {
    font-size: 40px;
    margin-bottom: 3px;
}

@media (max-width: 767px) {
    .error-page__title {
        font-size: 32px;
    }
}
.error-page__text {
    font-weight: 600;
    letter-spacing: 0.16px;
    margin-bottom: 24px;
}

.error-page__form {
    position: relative;
    display: block;
    margin: 0 auto 30px;
}

.error-page__form-input {
    position: relative;
    display: block;
    max-width: 496px;
    width: 100%;
    margin: 0 auto;
}

.error-page__form-input input[type='search'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 69px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.error-page__form-input button {
    background-color: transparent;
    color: var(--eduact-black);
    font-size: 20px;
    position: absolute;
    top: 0px;
    left: 40px;
    bottom: 0px;
    width: auto;
    outline: none;
    border: medium none;
    display: flex;
    align-items: center;
    padding: 0px;
    transition: all 0.4s ease;
    color: var(--eduact-text);
}

.error-page__form-input button:hover {
    color: var(--eduact-secondary);
}

.error-page .eduact-btn {
    text-transform: inherit;
}

/*--------------------------------------------------------------
  # Product Page
  --------------------------------------------------------------*/
.product {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .product {
        padding: 80px 0;
    }
}
.product__sidebar {
    position: relative;
}

@media (max-width: 991px) {
    .product__sidebar {
        margin: 60px 0 0;
    }
    .product__sidebar--left {
        margin: 0 0 60px;
    }
}
.product__sidebar__single {
    position: relative;
    border-radius: 14px;
    background-color: var(--eduact-white);
    box-shadow: 0px 0px 60px 0px rgba(2, 2, 2, 0.07);
    padding: 25px 20px 30px;
}

.product__sidebar__single + .product__sidebar__single {
    margin-top: 36px;
}

.product__sidebar__title {
    position: relative;
    font-size: 24px;
    line-height: 26px;
    text-transform: capitalize;
    border-bottom: 1px dashed var(--eduact-secondary);
    margin: 0 0 30px;
    padding-bottom: 25px;
}

.product__search {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
}

.product__search-form {
    position: relative;
}

.product__search-form input[type='search'] {
    display: block;
    border: none;
    outline: none;
    background-color: var(--eduact-secondary);
    box-shadow: none;
    color: var(--eduact-white);
    font-size: 16px;
    font-weight: 400;
    padding-left: 60px;
    border-radius: 7px;
    height: 80px;
    width: 100%;
}

.product__search-form input[type='search']::-webkit-input-placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.product__search-form input[type='search']:-ms-input-placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.product__search-form input[type='search']::-ms-input-placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.product__search-form input[type='search']::placeholder {
    color: var(--eduact-white);
    opacity: 1;
}

.product__search-form button[type='submit'] {
    background-color: transparent;
    color: var(--eduact-white);
    font-size: 20px;
    position: absolute;
    top: 0;
    left: 30px;
    bottom: 0;
    width: auto;
    outline: none;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.product__search-form button[type='submit']:hover {
    color: var(--eduact-base);
}

.product__price-ranger {
    position: relative;
}

.product__price-ranger .product__sidebar__title {
    border: none;
    padding: 0;
    margin-bottom: 21px;
}

.product__price-ranger #slider-range {
    margin: 0 0 17px;
    background: var(--eduact-soft5);
    border: none;
    height: 8px;
    border-radius: 16px;
    position: relative;
}

.product__price-ranger #slider-range .ui-slider-range {
    height: 100%;
    background: var(--eduact-secondary);
}

.product__price-ranger #slider-range .ui-slider-handle {
    position: absolute;
    top: -5px;
    background: var(--eduact-secondary);
    border: 0;
    height: 18px;
    width: 18px !important;
    border-radius: 50%;
    margin-left: -3px;
    outline: medium none;
    cursor: pointer;
    z-index: 2;
}

.product__price-ranger #slider-range .ui-slider-handle:first-of-type {
    display: none;
}

.product__price-ranger .ranger-min-max-block {
    position: relative;
    display: block;
    margin: 25px 0 0 0;
}

.product__price-ranger .ranger-min-max-block input[type='text'] {
    position: relative;
    display: inline-block;
    color: var(--eduact-text);
    font-size: 16px;
    font-weight: 600;
    width: 40px;
    line-height: 40px;
    border: none;
    outline: none;
    padding: 0;
    text-align: left;
    background-color: transparent;
}

.product__price-ranger .ranger-min-max-block span {
    position: relative;
    display: inline-block;
    color: var(--eduact-text);
    font-size: 14px;
    font-weight: 400;
    line-height: 40px;
    left: -2px;
}

.product__price-ranger .ranger-min-max-block input[type='submit'] {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--eduact-soft5);
    float: right;
    text-align: center;
    border: none;
    color: var(--eduact-text);
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
    padding: 0 16.5px;
    height: 40px;
    border-radius: 5px;
    text-transform: capitalize;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.product__price-ranger .ranger-min-max-block input[type='submit']:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.product__categories {
    position: relative;
    padding-bottom: 1px;
}

.product__categories .product__sidebar__title {
    margin-bottom: 0;
}

.product__categories ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.product__categories ul li {
    position: relative;
    display: block;
}

.product__categories ul li a {
    position: relative;
    z-index: 2;
    display: block;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    color: var(--eduact-text);
    border-bottom: 1px solid var(--eduact-soft4);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    padding: 29px 0 29px 33px;
}

.product__categories ul li a::after {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '\e92b';
    font-size: 22px;
    color: var(--eduact-secondary);
    font-family: 'icomoon' !important;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.product__categories ul li:last-child a {
    border-bottom: none;
}

.product__categories ul li:hover a,
.product__categories ul li.active a {
    color: var(--eduact-secondary);
    padding-left: 40px;
}

.product__info-top {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    margin-bottom: 36px;
}

@media (max-width: 767px) {
    .product__info-top {
        display: block;
    }
}
.product__showing-text {
    margin: 0;
    font-weight: 700;
    font-size: 20px;
}

@media (max-width: 767px) {
    .product__showing-text {
        margin-bottom: 20px;
    }
}
.product__showing-sort {
    margin: 0;
    font-size: 18px;
}

.product__showing-sort
    .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(
        .input-group-btn
    ) {
    position: relative;
    display: block;
    width: 304px !important;
    font-family: var(--eduact-font);
}

@media (max-width: 360px) {
    .product__showing-sort
        .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(
            .input-group-btn
        ) {
        width: 300px !important;
    }
}
.product__showing-sort .bootstrap-select > .dropdown-toggle::after {
    display: none;
}

.product__showing-sort .bootstrap-select .dropdown-menu {
    border: none;
}

.product__showing-sort .bootstrap-select > .dropdown-toggle {
    position: relative;
    height: 80px;
    outline: none !important;
    border-radius: 7px;
    border: 0;
    background-color: var(--eduact-soft5) !important;
    margin: 0;
    padding: 0;
    padding-left: 30px;
    padding-right: 30px;
    color: var(--eduact-text) !important;
    font-size: 16px;
    line-height: 80px;
    font-weight: 600;
    text-transform: capitalize;
    box-shadow: none !important;
    background-repeat: no-repeat;
    background-size: 14px 12px;
    background-position: right 25.75px center;
    z-index: 3;
}

.product__showing-sort .bootstrap-select > .dropdown-toggle:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    font-family: 'Font Awesome 5 Free';
    content: '\f078';
    font-weight: 600;
    font-size: 13px;
    color: var(--eduact-text);
}

.product__showing-sort .bootstrap-select .dropdown-menu > li + li > a {
    border-top: 1px solid var(--eduact-soft4);
}

.product__showing-sort .bootstrap-select .dropdown-menu > li > a {
    font-size: 16px;
    font-weight: 600;
    padding: 13px 30px;
    text-transform: capitalize;
    color: var(--eduact-text);
    background-color: var(--eduact-soft5);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.product__showing-sort .bootstrap-select .dropdown-menu > li:hover > a,
.product__showing-sort .bootstrap-select .dropdown-menu > li.selected > a {
    background: var(--eduact-secondary);
    color: var(--eduact-white);
    border-color: var(--eduact-secondary);
}

.product__item {
    position: relative;
    background-color: var(--eduact-white);
    border: 1px solid var(--eduact-soft4);
    border-radius: 12px;
    transition: all 500ms ease;
    margin: 0 0 36px;
    padding: 14px 15px 0;
}

.product__item:hover {
    box-shadow: 0px 8px 30px 0px rgba(var(--eduact-black2-rgb), 0.08);
}

.product__item__img {
    position: relative;
    max-width: 212px;
    margin: auto;
}

.product__item__img img {
    width: 100%;
    height: auto;
    transition: all 500ms ease;
    transform: scale(1);
}

.product__item__btn {
    position: absolute;
    right: 19px;
    top: 20px;
    z-index: 2;
}

.product__item__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: var(--eduact-soft5);
    border-radius: 50%;
    color: var(--eduact-text);
    font-size: 14px;
    visibility: hidden;
    opacity: 0;
}

.product__item__btn a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.product__item__btn a:nth-child(1) {
    -webkit-transition:
        transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    -moz-transition:
        transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    -ms-transition:
        transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    -o-transition:
        transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    transition:
        transform 350ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    transform: translate3d(30px, 0, 0);
    -moz-transform: translate3d(30px, 0, 0);
    -webkit-transform: translate3d(30px, 0, 0);
    -ms-transform: translate3d(30px, 0, 0);
    -o-transform: translate3d(30px, 0, 0);
}

.product__item__btn a:nth-child(2) {
    -webkit-transition:
        transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    -moz-transition:
        transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    -ms-transition:
        transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    -o-transition:
        transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    transition:
        transform 550ms cubic-bezier(0.445, 0.05, 0.55, 0.95),
        opacity ease 300ms,
        visibility ease 300ms,
        background ease 300ms,
        color ease 300ms,
        border ease 300ms;
    transform: translate3d(30px, 0, 0);
    -moz-transform: translate3d(30px, 0, 0);
    -webkit-transform: translate3d(30px, 0, 0);
    -ms-transform: translate3d(30px, 0, 0);
    -o-transform: translate3d(30px, 0, 0);
}

.product__item__btn a + a {
    margin-top: 12px;
}

.product__item:hover .product__item__img img {
    transform: scale(1.03);
}

.product__item:hover .product__item__btn a {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
}

.product__item__content {
    position: relative;
    text-align: center;
    padding: 24px 20px 32px;
}

.product__item__title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 4px;
}

.product__item__title a {
    color: inherit;
}

.product__item__title a:hover {
    color: var(--eduact-secondary);
}

.product__item__price {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 26px;
    color: var(--eduact-text);
    font-weight: 600;
    margin-bottom: 11px;
}

.product__item__price ins {
    text-decoration: none;
}

.product__item__price del {
    margin-left: 8px;
    font-size: 18px;
}

.product__item__ratings {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--eduact-secondary);
    letter-spacing: 6px;
    margin-bottom: 27px;
}

.product__item .eduact-btn {
    background-color: var(--eduact-soft5);
    color: var(--eduact-text);
    font-size: 16px;
    height: 40px;
    padding: 7px 30px;
}

.product__item .eduact-btn .eduact-btn__curve {
    background-color: var(--eduact-secondary);
    z-index: -1;
    opacity: 0;
}

.product__item .eduact-btn:hover {
    color: var(--eduact-white);
}

.product__item .eduact-btn:hover .eduact-btn__curve {
    opacity: 1;
}

/*--------------------------------------------------------------
  # Product Details
  --------------------------------------------------------------*/
.product-details {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .product-details {
        padding: 80px 0;
    }
}
.product-details__img {
    background-color: var(--eduact-white);
    position: relative;
    border: 1px solid var(--eduact-soft4);
    overflow: hidden;
    border-radius: 14px;
}

@media (min-width: 1400px) {
    .product-details__img {
        margin-right: 31px;
    }
}
.product-details__img img {
    width: 100%;
    height: auto;
}

.product-details__content {
    position: relative;
    margin: -7px 0 0 0;
}

@media (max-width: 991px) {
    .product-details__content {
        margin: 50px 0 0;
    }
}
.product-details__top {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 19px;
}

.product-details__title {
    font-size: 32px;
    margin: 0;
}

.product-details__price {
    font-size: 20px;
    line-height: 26px;
    color: var(--eduact-secondary);
    font-weight: 700;
    margin: 0 0 0 81px;
}

@media (max-width: 767px) {
    .product-details__price {
        margin-left: 40px;
    }
}
.product-details__review {
    position: relative;
    display: flex;
    align-items: center;
    letter-spacing: 6px;
    font-size: 16px;
    color: var(--eduact-secondary);
}

.product-details__review a {
    display: inline-block;
    color: var(--eduact-text);
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 600;
    margin-left: 19px;
}

.product-details__review a:hover {
    color: var(--eduact-secondary);
}

.product-details__divider {
    width: 100%;
    height: 1px;
    background-color: var(--eduact-soft4);
    margin: 37px 0 29px;
}

.product-details__excerpt {
    margin: 0 0 13px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
}

.product-details__excerpt-text1 {
    margin: 0 0 21px;
}

.product-details__excerpt-text2 {
    font-size: 20px;
    margin: 0;
}

.product-details__stock {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    color: var(--eduact-secondary);
    margin-bottom: 34px;
}

.product-details__quantity {
    position: relative;
    display: flex;
    align-items: center;
    margin: 34px 0 40px;
}

.product-details__quantity__title {
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    margin-right: 32px;
}

.product-details__quantity .quantity-box {
    position: relative;
    width: 153px;
    height: 46px;
    border: 1px solid var(--eduact-soft4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-details__quantity .quantity-box input {
    width: 64px;
    height: 46px;
    border: none;
    border-right: 1px solid var(--eduact-soft4);
    border-left: 1px solid var(--eduact-soft4);
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    font-family: var(--eduact-font);
    color: var(--eduact-black);
    padding: 0 10px;
    text-align: center;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;
}

.product-details__quantity .quantity-box button {
    width: 45px;
    height: 46px;
    color: var(--eduact-black);
    font-size: 12px;
    font-weight: normal;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    transition: all 500ms ease;
}

.product-details__quantity .quantity-box button:hover {
    color: var(--eduact-secondary);
}

.product-details__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

@media (max-width: 767px) {
    .product-details__buttons {
        display: block;
    }
}
.product-details__buttons .eduact-btn {
    text-transform: none;
}

.product-details__buttons .wishlist {
    margin-right: 20px;
    background-color: var(--eduact-black);
}

@media (max-width: 767px) {
    .product-details__buttons .wishlist {
        margin: 0 0 20px;
    }
}
.product-details__socials {
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .product-details__socials {
        display: block;
    }
}
.product-details__socials__title {
    font-size: 20px;
    margin: 0 60px 0 0;
}

@media (max-width: 1399px) {
    .product-details__socials__title {
        margin-right: 40px;
    }
}
@media (max-width: 767px) {
    .product-details__socials__title {
        margin: 0 0 20px;
    }
}
.product-details__socials a {
    display: inline-block;
    background-color: var(--eduact-soft5);
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: var(--eduact-black);
    font-size: 16px;
    border-radius: 50%;
    text-align: center;
}

.product-details__socials a:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.product-details__socials a + a {
    margin-left: 22px;
}

.product-details__description {
    position: relative;
    margin: 31px 0 0;
}

.product-details__description__title {
    font-size: 32px;
    margin-bottom: 21px;
}

.product-details__description__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin-bottom: 27px;
}

.product-details__description__lists {
    margin: 0 0 30px;
    padding: 0;
}

.product-details__description__lists li {
    display: block;
    position: relative;
    padding: 0 0 0 38px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: var(--eduact-black);
    margin: 0 0 13px;
}

.product-details__description__lists li span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 24px;
    height: 24px;
    background-color: var(--eduact-secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--eduact-white);
}

.product-details__comment {
    border-top: 1px solid var(--eduact-soft4);
    margin: 56px 0 0;
    padding: 54px 0 0;
    position: relative;
}

.product-details__review-title {
    font-size: 24px;
    margin-bottom: 44px;
}

.product-details__comment-box {
    position: relative;
    padding: 8px 0 61px 178px;
    margin-bottom: 60px;
    min-height: 140px;
    border-bottom: 1px solid var(--eduact-soft4);
}

@media (max-width: 767px) {
    .product-details__comment-box {
        padding-left: 0;
    }
}
.product-details__comment-box__thumb {
    width: 140px;
    height: 140px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    margin: 0;
    border: none;
}

@media (max-width: 767px) {
    .product-details__comment-box__thumb {
        position: relative;
        margin: 0 0 20px;
    }
}
.product-details__comment-box__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.product-details__comment-box__meta {
    font-size: 24px;
    text-transform: capitalize;
    margin: 0 0 5px;
}

.product-details__comment-box__text {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0;
}

.product-details__comment-box__ratings {
    position: absolute;
    right: 0;
    top: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 6px;
    font-size: 16px;
    color: var(--eduact-secondary);
}

@media (max-width: 767px) {
    .product-details__comment-box__ratings {
        position: relative;
        top: 0;
        margin: 12px 0 22px;
    }
}
.product-details__form {
    position: relative;
    margin: 74px 0 0;
}

.product-details__form .row {
    --bs-gutter-x: 20px;
}

.product-details__form-title {
    font-size: 24px;
    margin-bottom: 37px;
}

.product-details__form-ratings {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    letter-spacing: 9px;
    font-size: 24px;
    color: var(--eduact-secondary);
    margin: 0 0 44px;
}

.product-details__form-ratings__label {
    display: inline-block;
    font-size: 20px;
    letter-spacing: 0;
    font-weight: 600;
    color: var(--eduact-text);
    margin: 0 18px 0 0;
}

.product-details .review-form {
    position: relative;
}

.product-details .review-form__input-box {
    position: relative;
    display: block;
    line-height: 1;
    margin-bottom: 20px;
}

.product-details .review-form__input-box input[type='text'],
.product-details .review-form__input-box input[type='email'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.product-details .review-form__input-box textarea {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    height: 211px;
    width: 100%;
    background-color: var(--eduact-soft5);
    font-family: var(--eduact-font);
    padding: 24px 30px 30px;
    border: none;
    outline: none;
    margin-bottom: 0px;
    border-radius: 4px;
}

.product-details .review-form .eduact-btn {
    text-transform: none;
    margin-top: 20px;
}

/*--------------------------------------------------------------
  # Cart
  --------------------------------------------------------------*/
.cart-page {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .cart-page {
        padding: 80px 0;
    }
}
.cart-page .table-responsive {
    position: relative;
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 1199px) {
    .cart-page .table-responsive {
        margin-bottom: 30px;
    }
}
.cart-page__table {
    position: relative;
    width: 100%;
    border: none;
    margin: 0 0 60px;
}

@media (max-width: 1199px) {
    .cart-page__table {
        min-width: 1116px;
    }
}
.cart-page__table thead tr th {
    color: var(--eduact-black);
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    padding: 0 0 21px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid var(--eduact-soft4) !important;
    box-shadow: none;
}

.cart-page__table thead tr th:last-child {
    text-align: right;
}

@media (min-width: 1400px) {
    .cart-page__table thead tr th:first-child {
        min-width: 36%;
    }
    .cart-page__table thead tr th:nth-child(2) {
        min-width: 18%;
    }
}
.cart-page__table tbody tr td {
    font-size: 20px;
    font-weight: 500;
    color: var(--eduact-black);
    vertical-align: middle;
    border: none;
    box-shadow: none;
    background-color: transparent;
    border-top: 1px solid var(--eduact-soft4);
    border-bottom: 1px solid var(--eduact-soft4);
    padding: 30px 0;
    letter-spacing: 0;
}

.cart-page__table tbody tr td:last-child {
    text-align: right;
}

.cart-page__table__meta {
    display: flex;
    align-items: center;
}

.cart-page__table__meta-img {
    width: 110px;
    height: 107px;
    background-color: var(--eduact-white);
    border: 1px solid var(--eduact-soft4);
    border-radius: 6px;
    overflow: hidden;
    margin-right: 40px;
}

.cart-page__table__meta-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cart-page__table__meta-title {
    font-size: 20px;
    margin: 0;
}

.cart-page__table__meta-title a {
    color: inherit;
}

.cart-page__table__meta-title a:hover {
    color: var(--eduact-secondary);
}

.cart-page__table__remove {
    display: block;
    color: var(--eduact-black);
    font-size: 20px;
}

.cart-page__table__remove:hover {
    color: var(--eduact-secondary);
}

.cart-page__coupone-form {
    position: relative;
    display: flex;
}

@media (max-width: 767px) {
    .cart-page__coupone-form {
        display: block;
    }
}
.cart-page__coupone-form input[type='text'] {
    height: 60px;
    width: 303px;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    border-radius: 6px;
    display: block;
    font-weight: 600;
    margin-right: 20px;
}

@media (max-width: 1199px) {
    .cart-page__coupone-form input[type='text'] {
        width: 290px;
    }
}
@media (max-width: 767px) {
    .cart-page__coupone-form input[type='text'] {
        width: 100%;
        margin: 0 0 10px;
    }
}
.cart-page__cart-total {
    position: relative;
    margin: -5px 0 40px;
    padding: 0;
}

@media (max-width: 991px) {
    .cart-page__cart-total {
        margin-top: 45px;
    }
}
.cart-page__cart-total li {
    display: block;
    font-size: 20px;
    color: var(--eduact-black);
    font-weight: 500;
    max-width: 290px;
    margin: 0 0 19px auto;
    text-align: left;
    clear: both;
}

.cart-page__cart-total li:last-child {
    border-top: 1px solid var(--eduact-soft5);
    padding-top: 23px;
    position: relative;
    top: 6px;
}

.cart-page__cart-total-amount {
    float: right;
}

.cart-page__buttons {
    display: flex;
    justify-content: flex-end;
}

.cart-page__buttons .update {
    background-color: var(--eduact-black);
    color: var(--eduact-white);
    margin-right: 20px;
}

/*--------------------------------------------------------------
  # Checkout
  --------------------------------------------------------------*/
.checkout-page {
    position: relative;
    padding: 120px 0;
}

@media (max-width: 767px) {
    .checkout-page {
        padding: 80px 0;
    }
}
.checkout-page .bs-gutter-x-20 {
    --bs-gutter-x: 20px;
}

.checkout-page__notice {
    background-color: var(--eduact-soft5);
    max-width: 643px;
    padding: 33px 50px;
    border-radius: 14px;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--eduact-black);
    margin: 0 0 51px;
}

@media (max-width: 767px) {
    .checkout-page__notice {
        padding-left: 25px;
        padding-right: 25px;
    }
}
.checkout-page__notice a {
    color: var(--eduact-secondary);
    font-weight: 700;
}

.checkout-page__notice a:hover {
    color: var(--eduact-black);
}

.checkout-page__billing-address {
    position: relative;
}

.checkout-page__billing-address__title {
    font-size: 32px;
    margin: 0 0 33px;
}

@media (max-width: 767px) {
    .checkout-page__billing-address__title {
        font-size: 28px;
    }
}
.checkout-page__shipping-address {
    position: relative;
}

@media (max-width: 991px) {
    .checkout-page__shipping-address {
        margin: 50px 0 0;
    }
}
.checkout-page__shipping-address__title {
    font-size: 32px;
    margin: 0 0 33px;
}

@media (max-width: 767px) {
    .checkout-page__shipping-address__title {
        font-size: 28px;
    }
}
.checkout-page__form {
    position: relative;
    margin: 0 0 51px;
}

.checkout-page__input-box {
    position: relative;
    line-height: 1;
    margin: 0 0 20px;
}

.checkout-page__input-box input[type='text'],
.checkout-page__input-box input[type='email'],
.checkout-page__input-box input[type='tel'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.checkout-page__input-box
    .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(
        .input-group-btn
    ) {
    position: relative;
    display: block;
    width: 100% !important;
}

.checkout-page__input-box .bootstrap-select > .dropdown-toggle::after {
    display: none;
}

.checkout-page__input-box .bootstrap-select > .dropdown-toggle {
    position: relative;
    height: 60px;
    outline: none !important;
    border-radius: 0;
    border: 0;
    background-color: var(--eduact-soft5) !important;
    margin: 0;
    padding: 0;
    padding-left: 30px;
    padding-right: 30px;
    color: var(--eduact-text) !important;
    font-size: 16px;
    line-height: 60px;
    font-weight: 600;
    border-radius: 4px;
    box-shadow: none !important;
    background-repeat: no-repeat;
    background-size: 14px 12px;
    background-position: right 25.75px center;
}

.checkout-page__input-box .bootstrap-select > .dropdown-toggle:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 30px;
    font-family: 'Font Awesome 5 Free';
    content: '\f107';
    font-weight: 600;
    font-size: 12px;
    color: var(--eduact-text);
}

.checkout-page__input-box .bootstrap-select .dropdown-menu > li + li > a {
    border-top: 1px solid var(--eduact-soft4);
}

.checkout-page__input-box .bootstrap-select .dropdown-menu {
    border: none;
}

.checkout-page__input-box .bootstrap-select .dropdown-menu > li > a {
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    color: var(--eduact-text);
    background-color: var(--eduact-soft5);
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.checkout-page__input-box .bootstrap-select .dropdown-menu > li:hover > a,
.checkout-page__input-box .bootstrap-select .dropdown-menu > li.selected > a {
    background: var(--eduact-secondary);
    color: var(--eduact-white);
    border-color: var(--eduact-secondary);
}

.checkout-page__input-box textarea {
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-text);
    height: 176px;
    width: 100%;
    background-color: var(--eduact-soft5);
    padding: 24px 30px 30px;
    border: none;
    outline: none;
    margin-bottom: 0px;
    border-radius: 4px;
}

.checkout-page__check-box {
    position: relative;
    display: block;
    margin-top: 1px;
}

.checkout-page__check-box input[type='checkbox'] {
    display: none;
}

.checkout-page__check-box label {
    position: relative;
    display: inline-block;
    padding-left: 40px;
    margin-right: 0px;
    margin-bottom: 0;
    color: var(--eduact-text);
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;
    text-transform: none;
    cursor: pointer;
}

.checkout-page__check-box label span:before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    line-height: 40px;
    color: var(--eduact-white);
    font-family: 'icomoon' !important;
    content: '\e927';
    font-size: 12px;
    font-weight: normal;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    opacity: 0;
}

.checkout-page__check-box input[type='checkbox'] + label span {
    position: absolute;
    top: 1px;
    left: 0;
    width: 24px;
    height: 24px;
    background-color: var(--eduact-secondary);
    border-radius: 3px;
    border: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.checkout-page__check-box input[type='checkbox']:checked + label span:before {
    opacity: 1;
}

.checkout-page__your-order {
    position: relative;
    margin: 110px 0 0;
}

@media (max-width: 767px) {
    .checkout-page__your-order {
        margin: 20px 0 0;
    }
}
.checkout-page__your-order__title {
    font-size: 32px;
    margin-bottom: 31px;
}

.checkout-page__your-order .eduact-btn {
    text-transform: none;
}

.checkout-page__order-table {
    position: relative;
    width: 100%;
    border: none;
    margin: 0 0 0;
}

.checkout-page__order-table thead tr th {
    font-size: 20px;
    color: var(--eduact-black);
    font-weight: 700;
    margin: 0;
    padding: 24px 0;
    border: none;
    border-top: 1px solid var(--eduact-soft4);
    border-bottom: 1px solid var(--eduact-soft4);
}

.checkout-page__order-table thead tr th:last-child {
    text-align: right;
}

.checkout-page__order-table tbody tr td {
    font-size: 20px;
    color: var(--eduact-black);
    font-weight: 500;
    margin: 0;
    padding: 0 0 19px;
    border: none;
}

.checkout-page__order-table tbody tr td:last-child {
    text-align: right;
}

.checkout-page__order-table tbody tr:first-child td {
    padding-top: 23px;
}

.checkout-page__order-table tbody tr:last-child td {
    border-top: 1px solid var(--eduact-soft4);
    padding-top: 15px;
}

.checkout-page__payment {
    background-color: var(--eduact-soft5);
    border-radius: 14px;
    padding: 40px 70px 25px 40px;
    min-height: 277px;
    margin-bottom: 50px;
}

@media (min-width: 992px) and (max-width: 1399px) {
    .checkout-page__payment {
        padding-right: 40px;
    }
}
@media (max-width: 991px) {
    .checkout-page__payment {
        margin-top: 50px;
    }
}
@media (max-width: 767px) {
    .checkout-page__payment {
        padding-left: 25px;
        padding-right: 25px;
    }
}
.checkout-page__payment__item {
    position: relative;
}

.checkout-page__payment__title {
    display: flex;
    font-size: 20px;
    margin: 0;
    align-items: center;
    margin-bottom: 19px;
    cursor: pointer;
}

.checkout-page__payment__title::before {
    content: '';
    width: 24px;
    height: 24px;
    background-color: var(--eduact-white);
    border: none;
    border-radius: 50%;
    margin-right: 10px;
    font-family: 'icomoon' !important;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
    position: relative;
    top: 0;
    transition: all 500ms ease;
}

.checkout-page__payment__item--active .checkout-page__payment__title::before {
    background-color: var(--eduact-secondary);
    content: '\e927';
    color: var(--eduact-white);
}

.checkout-page__payment__content {
    margin-left: 37px;
    margin-bottom: 30px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
}

/*--------------------------------------------------------------
  # Boxed Home
  --------------------------------------------------------------*/
body.boxed-wrapper {
    position: relative;
}

body.boxed-wrapper .page-wrapper {
    max-width: 1530px;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--eduact-white);
    box-shadow: 0px 0px 100px 0px rgba(var(--eduact-black2-rgb), 0.08);
}

body.boxed-wrapper .hero-banner__thumb {
    margin-left: 30px;
}

/*--------------------------------------------------------------
  # Custom Cursor
  --------------------------------------------------------------*/
.custom-cursor__cursor {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    border: 1px solid var(--eduact-base);
    -webkit-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
    position: fixed;
    pointer-events: none;
    left: 0;
    top: 0;
    -webkit-transform: translate(calc(-50% + 5px), -50%);
    transform: translate(calc(-50% + 5px), -50%);
    z-index: 999991;
}

.custom-cursor__cursor-two {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--eduact-base);
    opacity: 0.3;
    position: fixed;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
    -webkit-transition:
        width 0.3s,
        height 0.3s,
        opacity 0.3s;
    transition:
        width 0.3s,
        height 0.3s,
        opacity 0.3s;
    z-index: 999991;
}

.custom-cursor__hover {
    background-color: var(--eduact-base);
    opacity: 0.4;
}

.custom-cursor__innerhover {
    width: 25px;
    height: 25px;
    opacity: 0.4;
}

/* Templates Custom
  --------------------------------------------- */
.ml-0 {
    margin-left: 0;
}

.blog-two__item--list.eduact-no-thumbnail .blog-two__content {
    margin: 0;
}

.eduact-no-thumbnail .blog-two__item--list .blog-two__content {
    margin: 0;
}

.default-header.main-header-two .main-menu__nav {
    margin-right: 0;
}

.default-header .main-menu__nav {
    margin-left: auto;
    margin-right: unset;
}

.blog-details__meta a {
    background-color: transparent;
    color: var(--eduact-text);
    padding: 0;
}

.blog-details__meta a:hover {
    background-color: transparent;
}

.blog-details__meta__date {
    margin-left: 0;
    margin-right: 15px;
}

span.byline,
span.posted-on {
    display: flex;
}

.blog-two__top-meta span.posted-on {
    align-items: center;
}

.blog-two__top-meta span.posted-on a {
    color: var(--eduact-text);
}

i.fas.fa-user-circle {
    font-weight: 400;
}

.blog-details__tags__title {
    color: var(--eduact-black);
    font-weight: 700;
}

.news-details__tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
}

.news-details__tags--tags.tag-links a {
    color: var(--eduact-text);
    margin-right: 5px;
    margin-bottom: 5px;
    font-weight: 500;
}

.news-details__tags--tags.tag-links a:hover {
    color: var(--eduact-secondary);
}

.blog-details__bottom {
    padding: 40px 0 32px;
}

.news-details__tags--tags.tag-links a::after {
    content: ', ';
}

.news-details__tags--tags.tag-links a:last-child:after {
    content: ' ';
}

.blog-details__content p {
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.32px;
    margin: 0 0 30px;
}

.eduact-btn.eduact-btn-second {
    line-height: 27px;
}

.blog-details__tags a,
.blog-details__tags a,
.blog-details__tags__title {
    margin-bottom: 8px;
}

.blog-sidebar__single.tag-sticky-2 .blog-two__content:before,
.blog-sidebar__single.sticky .blog-two__content:before {
    position: absolute;
    right: 0px;
    content: '';
    width: 40px;
    height: 40px;
    background-color: var(--eduact-base, #4f5de4);
    z-index: 10;
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 50%, 0 100%, 0 0);
    top: 0;
}

.wp-block-cover-image .wp-block-cover-image-text,
.blog-details__content .wp-block-cover-image .wp-block-cover-text,
.blog-details__content .wp-block-cover-image h2,
.blog-details__content .wp-block-cover .wp-block-cover-image-text,
.blog-details__content .wp-block-cover .wp-block-cover-text,
.blog-details__content .wp-block-cover h2,
.wp-block-cover p:not(.has-text-color) {
    color: var(--eduact-white, #ffffff);
}

.blog-details__content > .wp-block-cover {
    margin-bottom: 32px;
}

.blog-details__content,
.comments-area {
    font-size: 16px;
    line-height: 2;
    font-weight: 500;
}

.blog-details__content .wp-block-quote.has-text-align-right {
    border-right: 2px solid var(--eduact-black, #2a254d);
    border-left: 0;
}

.blog-details__content .wp-block-quote.has-text-align-right::before {
    right: auto;
    left: 30px;
    transform: rotateX(-180deg);
}

.blog-details__content .blocks-gallery-caption {
    margin-bottom: 24px;
}

.blog-details__content blockquote:not([class*='has-']),
.comment-content blockquote,
.blog-details__content .wp-block-quote {
    background-color: var(--eduact-soft5);
    border-left: 2px solid var(--eduact-black, #2a254d);
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
}

blockquote {
    margin: 0 1.5em;
}

.blog-details__content blockquote:not([class*='has-'])::before,
.comment-content blockquote::before,
.blog-details__content .wp-block-quote::before {
    content: '\f10d';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    bottom: 0;
    transform: rotate(180deg);
    font-size: 100px;
    color: var(--eduact-secondary, #f57005);
    opacity: 0.4;
    right: 30px;
    line-height: 0.9em;
    bottom: -25px;
}

.wp-block-quote.is-large p,
.wp-block-quote.is-style-large p {
    font-style: italic;
}

.comment-content strong,
.blog-details__content strong {
    color: var(--eduact-black, #2a254d);
}

.wp-block-pullquote:not(.is-style-solid-color) {
    background: none;
}

.wp-block-pullquote {
    border-top: 4px solid #555d66;
    border-bottom: 4px solid #555d66;
    color: #40464d;
}

.wp-block-pullquote {
    margin: 0 0 1em;
    padding: 3em 0;
    text-align: center;
}

.post .blog-details__content p.has-large-font-size {
    font-size: 2.25em;
}

.post .blog-details__content p.has-small-font-size {
    font-size: 0.9125em;
}

.wp-block-group.has-background {
    padding: 30px;
}

.wp-block-columns {
    margin: 30px 0;
}

.post-password-form label {
    font-weight: 600;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.blog-details__content .post-password-form label {
    font-size: 18px;
    font-weight: 600;
    color: var(--eduact-black, #2a254d);
}

.comments-area h1,
.blog-details__content h1,
.comment-content h2,
.blog-details__content h2,
.comment-content h3,
.blog-details__content h3,
.comment-content h4,
.blog-details__content h4,
.comment-content h5,
.blog-details__content h5,
.comment-content h6,
.blog-details__content h6 {
    margin-bottom: 1rem;
    color: var(--eduact-black, #2a254d);
}

.blog-details__content .post-password-form label {
    font-size: 18px;
    font-weight: 600;
    color: var(--eduact-black, #2a254d);
}

.blog-details__content .post-password-form input[type='password'] {
    width: 200px;
    height: 50px;
    background-color: var(--eduact-white, #ffffff);
    color: var(--eduact-grey, #b7b0b4);
    padding-left: 30px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 0;
}

.blog-details__content .post-password-form input[type='submit'] {
    width: 120px;
    height: 50px;
    background-color: var(--eduact-black, #2a254d);
    color: var(--eduact-white, #ffffff);
    padding: 0;
    text-align: center;
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    transition: all 500ms ease;
}

.blog-details__content .post-password-form input[type='submit']:hover {
    background-color: var(--eduact-secondary, #f57005);
    color: var(--eduact-white, #ffffff);
}

.post .blog-details__content > p {
    line-height: 2em;
    font-size: 16px;
}

.wp-block-cover-text strong {
    color: inherit;
}

.blog-details__content ul,
.comments-area ul,
.comments-area ol,
.blog-details__content ol {
    margin: 0 0 0.5em 0.5em;
    margin-left: 0;
}

.wp-block-archives-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--eduact-grey, #b7b0b4);
    position: relative;
    line-height: 34px;
    font-size: 16px;
    text-transform: capitalize;
    color: var(--eduact-grey, #b7b0b4);
    transition: all 0.3s ease;
    z-index: 1;
    font-weight: 600;
}

.blog-details__content .wp-block-archives-list li a {
    color: var(--eduact-text);
    transition: all 500ms ease;
}

.wp-block-archives.wp-block-archives-list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 30px;
}

.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
    width: 100%;
    height: 60px;
    background-color: var(--eduact-soft5);
    border: 1px solid var(--eduact-soft5);
    outline: none;
    color: var(--eduact-text, #697585);
    font-size: 16px;
    font-weight: 500;
    padding-left: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path fill="rgb(34, 36, 41)" d="M540.1 776.847l472.32-473.63c15.466-15.518 15.44-40.642-0.080-56.134-15.518-15.48-40.656-15.44-56.134 0.080l-444.208 445.438-444.206-445.454c-15.48-15.518-40.602-15.558-56.122-0.080-7.78 7.766-11.67 17.94-11.67 28.114 0 10.148 3.864 20.282 11.59 28.034l472.308 473.632c7.436 7.474 17.556 11.668 28.1 11.668s20.652-4.206 28.102-11.668z"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 30px top 50%;
    background-size: 0.65em auto;
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
    margin-bottom: 30px;
}

.has-avatars
    .wp-block-latest-comments__comment
    .wp-block-latest-comments__comment-excerpt,
.has-avatars
    .wp-block-latest-comments__comment
    .wp-block-latest-comments__comment-meta {
    margin-left: 3.25em;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
}

.wp-block-latest-comments__comment-meta {
    font-size: 18px;
    line-height: 1.889;
    font-weight: 400;
    color: var(--eduact-black, #2a254d);
}

.wp-block-latest-comments__comment-date {
    font-weight: 500;
    color: var(--eduact-text);
}

.wp-block-latest-comments__comment-date {
    display: block;
    font-size: 0.75em;
}

.wp-block-latest-comments:not(.has-avatar):not(.has-dates) li {
    position: relative;
    padding-left: 68px;
    min-height: 44px;
}

.wp-block-latest-comments {
    padding-left: 0;
    margin-left: 0;
}

.wp-block-latest-comments:not(.has-avatar):not(.has-dates) {
    margin-top: -7px;
}

.wp-block-latest-comments:not(.has-avatar):not(.has-dates) li::before {
    content: '\f075';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--eduact-black, #2a254d);
    width: 44px;
    height: 44px;
    color: var(--eduact-white, #ffffff);
    margin-right: 20px;
    transition: all 500ms ease;
    position: absolute;
    top: 0;
    left: 0;
}

.wp-block-latest-comments:not(.has-avatar):not(.has-dates) li:hover::before {
    background-color: var(--eduact-secondary, #f57005);
    color: var(--eduact-white, #ffffff);
}

.wp-block-latest-posts__list.has-dates li {
    position: relative;
    padding-left: 64px;
    font-size: 16px;
    line-height: 1.889;
    font-weight: 600;
    transition: all 500ms ease;
    color: var(--eduact-grey, #b7b0b4);
}

.wp-block-latest-posts__list.has-dates {
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
}

.wp-block-latest-posts__list.has-dates li::before {
    content: '\f302';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--eduact-black, #2a254d);
    width: 44px;
    height: 44px;
    color: var(--eduact-white, #ffffff);
    transition: all 500ms ease;
    position: absolute;
    top: 5px;
    left: 0;
}

.wp-block-latest-posts__list.is-grid.has-dates li::before {
    position: relative;
    top: auto;
    left: 0;
    margin-bottom: 10px;
}

.wp-block-latest-posts__list.is-grid.has-dates li {
    padding-left: 0;
}

.wp-block-search label {
    font-size: 18px;
    color: var(--eduact-black, #2a254d);
    font-weight: 600;
}

.wp-block-search input[type='search'] {
    display: block;
    border: none;
    outline: none;
    width: 100%;
    max-width: 300px;
    position: relative;
    line-height: 40px;
    padding: 19px 80px 19px 35px;
    background: var(--eduact-soft5);
    color: var(--eduact-grey, #b7b0b4);
    display: block;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
    width: 100%;
    height: 60px;
    border: 1px solid var(--eduact-white, #ffffff);
    border-radius: 0;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.category-6-1 .wp-block-search input[type='search'] {
    max-width: unset;
}

.wp-block-search .wp-block-search__button {
    display: inline-block;
    vertical-align: middle;
    border: none;
    outline: none;
    background-color: var(--eduact-black, #2a254d);
    text-align: center;
    border-radius: 0;
    color: var(--eduact-white, #ffffff);
    padding: 8px 50px;
    font-size: 18px;
    font-weight: 700;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    text-transform: capitalize;
    letter-spacing: 0.01em;
}

.wp-block-search__icon-button.wp-block-search .wp-block-search__button {
    padding: 8px 20px;
}

.wp-block-tag-cloud a {
    font-size: 18px !important;
    color: var(--eduact-grey, #b7b0b4);
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    transition: all 500ms ease;
}

.wp-block-rss li {
    position: relative;
    padding-left: 68px;
    font-size: 16px;
    font-weight: 600;
    color: var(--eduact-black, #2a254d);
    min-height: 44px;
}

.wp-block-rss li + li {
    margin-top: 20px;
}

.wp-block-rss {
    margin: 0;
    padding: 0;
    list-style: none;
}

.wp-block-rss li::before {
    content: '\f09e';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--eduact-black, #2a254d);
    width: 44px;
    height: 44px;
    color: var(--eduact-white, #ffffff);
    transition: all 500ms ease;
    position: absolute;
    top: 30%;
    left: 0;
    transform: translateY(-50%);
}

.blog-details__content .page-links {
    color: var(--eduact-black, #2a254d);
    display: flex;
    align-items: center;
    font-weight: 500;
}

.page-links {
    clear: both;
    margin: 0 0 1.5em;
}

.blog-details__content .page-links span,
.blog-details__content .page-links a {
    display: flex;
    width: 40px;
    height: 40px;
    display: flex;
    color: var(--eduact-black, #2a254d);
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--eduact-soft4);
    border-radius: 5px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    transition: all 500ms ease;
}

.blog-details__content .page-links span {
    background-color: var(--eduact-secondary, #f57005);
    color: var(--eduact-white, #ffffff);
    border-radius: 5px;
}

.blog-details__content .page-links a:hover {
    background-color: var(--eduact-secondary, #f57005);
    color: var(--eduact-white, #ffffff);
}

.news-sidebar__left .blog-pagination {
    margin-top: 30px;
}

.blog-pagination {
    display: flex;
    align-items: center;
    line-height: 1;
    padding-top: 0px;
}

.comments-area .blog-pagination {
    padding-top: 0px;
}

.blog-widget-pagination {
    justify-content: center;
    margin-top: 30px;
}

.blog-pagination a,
.blog-pagination span {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 50px;
    height: 50px;
    font-size: 20px;
    font-weight: 600;
    transition: all 500ms ease;
    border-radius: 10px;
    color: var(--eduact-black);
    border: 1px solid var(--eduact-soft4);
}

.blog-pagination a.current,
.blog-pagination a:hover,
.blog-pagination span.current,
.blog-pagination span:hover {
    background-color: var(--eduact-secondary, #f57005);
    border-color: var(--eduact-secondary, #f57005);
    color: var(--eduact-white, #ffffff);
}

.blog-pagination span + span,
.blog-pagination a + span,
.blog-pagination span + a,
.blog-pagination a + a {
    margin-left: 10px;
}

.comment-content a,
.blog-details__content blockquote:not([class*='has-']) cite {
    color: var(--eduact-black, #2a254d);
}

.wp-block-latest-comments a,
.wp-block-latest-posts__list.has-dates li a,
.wp-block-rss li a {
    color: var(--eduact-black, #2a254d);
    font-weight: 700;
    font-size: 20px;
}

.wp-block-latest-comments a {
    font-weight: 600;
    font-size: 16px;
}

.wp-block-latest-comments:not(.has-avatar):not(.has-dates) li::before {
    content: '\e926';
    font-family: 'icomoon' !important;
    background-color: var(--eduact-soft5);
    color: var(--eduact-black, #2a254d);
}

.sidebar
    .widget
    .wp-block-latest-comments:not(.has-dates):not(.has-author)
    .wp-block-latest-comments__comment-meta
    a {
    color: var(--eduact-text);
}

.sidebar .widget ul.sidebar__post__list .sidebar__post__content__title a {
    font-family: var(--heading-font);
    color: var(--eduact-black);
    font-weight: 700;
}

.blog-details__left a.wp-block-button__link {
    background-color: var(--eduact-black, #2a254d);
    color: var(--eduact-white, #ffffff);
}

.blog-details__content a.wp-block-file__button,
.wp-block-cover strong,
.wp-block-cover__inner-container a,
.blog-details__content .wp-block-cover-text a {
    color: var(--eduact-white, #ffffff);
}

.wp-block-calendar table th {
    font-weight: 400;
    background: var(--eduact-white, #ffffff);
}

.post .blog-details__content > p {
    margin-bottom: 1.5em;
}

.wp-block-gallery:not(.has-nested-images).alignleft {
    margin-right: 30px;
}

.blog-details__content .wp-block-calendar .wp-calendar-nav {
    margin-bottom: 10px;
}

.news-details .comments-area ol ol,
.blog-details__content ol ol,
.blog-details__content ul ol,
.blog-details__content ol ul,
.blog-details__content ul ul,
.news-details .comments-area ul ul {
    margin-bottom: 0;
}

.comment-area .blog-pagination {
    padding-top: 0;
}

.blog-details__content p {
    margin-bottom: 1.5em;
}

.blog-sidebar__single {
    margin-bottom: 62px;
}

.blog-details__content .wp-block-cover {
    margin-bottom: 32px;
}

.blog-details__content .wp-block-calendar .wp-calendar-nav {
    margin-bottom: 20px;
}

.thm-breadcrumb li:not(:last-child)::after {
    content: '/';
    margin-left: 15px;
    margin-right: 10px;
}

.widget .add_to_cart_button:hover {
    color: var(--eduact-white) !important;
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev {
    background-image: none !important;
}

input[type='submit'].contact-form__btn {
    outline: none !important;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0;
    padding: 15px 50px 15px;
    transition: all 0.5s linear;
    line-height: 32px;
}

.elementor-widget.elementor-widget-eduact-shape {
    position: unset;
}

.news-details__tags a::after {
    content: ', ';
}

.news-details__tags a:last-child:after {
    content: ' ';
}

.blog-details__left .wp-block-button a {
    transition: all 500ms ease;
}

.blog-details__left .wp-block-button a.wp-block-button__link:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.blog-details__left
    .wp-block-button.is-style-outline
    a.wp-block-button__link:hover {
    background-color: transparent;
    border-color: var(--eduact-secondary);
    color: var(--eduact-secondary);
}

.page__left .comments-area {
    border: none;
}

.bootstrap-select:not(.input-group-btn),
.bootstrap-select[class*='col-'] {
    width: 100%;
}

.page-header__title,
.blog-two__title a {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

.blog-details__content .wp-block-button.is-style-squared a {
    border-radius: 0;
}

.blog-details__content a,
.comments-area a,
.blog-details__content blockquote:not([class*='has-']) cite {
    color: var(--eduact-black, #2a254d);
}

.blog-details__content a:hover,
.comments-area a:hover,
.blog-details__content blockquote:not([class*='has-']) cite:hover {
    color: var(--eduact-secondary, #f57005);
}

.blog-two__title a:hover b {
    color: var(--eduact-base);
}

.product__item__title {
    text-transform: capitalize;
}

.default .error-page__404-title {
    max-width: unset;
    margin: unset;
}

.default.error-page {
    padding: 108px 0;
}

.page-header__title b {
    color: var(--eduact-white);
}

.sidebar__search-form input[type='search'] {
    background-color: var(--eduact-soft5);
    color: var(--eduact-text);
}

.default-header.main-header-two .main-menu__list > li.current > a,
.default-header.main-header-two .main-menu__list > li:hover > a {
    color: var(--eduact-secondary);
}

.mobile-nav__wrapper {
    z-index: 99999;
}

.logo-box a h1 {
    color: var(--eduact-white);
}

.addon-inactive .blog-two__meta__author a {
    line-height: 1.9;
}

div.about-two__thumb__two img,
div.about-two__thumb__one img {
    border-radius: 500px;
}

div.about-three__author__thumb img {
    border-radius: 50%;
}

div.about-three__thumb__one img {
    border-radius: 33px;
}

.blog-details__tags__title {
    margin-right: 15px;
}

div.team-details__image > img {
    border-radius: 346px;
}

.login-page__form-input-box input[type='text'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    display: block;
    font-weight: 600;
    border-radius: 4px;
}

.login-result.loading,
.registration-result.loading {
    position: relative;
}

.login-result.loading::after,
.registration-result.loading::after {
    left: 0;
    font-family: 'Font Awesome 5 Free';
    content: '\f110';
    vertical-align: top;
    font-weight: 900;
    top: -40px;
    left: -361px;
    position: absolute;
    right: 1em;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.login-result.loading.alert-warning::after,
.registration-result.loading.alert-warning::after {
    top: 15px;
}

.alert.alert-danger a {
    color: var(--nisoz-black);
}

div.blog-one__meta__author img,
div.blog-two__meta__author img,
div.blog-three__meta__author img,
div.course-one__author img,
div.course-two__author img,
div.course-three__author img,
div.course-details__meta__author img {
    border-radius: 50%;
}

.course-details__instructor__thumb img,
.course-details__comment-box__thumb img {
    border-radius: 50% !important;
}

div.course-one__thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.tutor-iconic-btn:hover,
.tutor-iconic-btn:focus,
.tutor-iconic-btn:active,
.tutor-iconic-btn-secondary {
    color: unset;
    background-color: unset;
    border-color: unset;
}

.tutor-iconic-btn {
    border: none;
}

.is-loading i {
    display: none;
}

.course-details {
    padding: 0;
}

div.service-two__rm_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s;
    z-index: -2;
}

.service-two__wrapper:hover div.service-two__rm_bg {
    opacity: 1;
}

.tutor-btn-block {
    max-width: 100%;
    width: unset;
}

.tutor-icon-cart-line:before {
    content: '';
}

.tutor-course-progress-wrapper
    .list-item-progress
    .tutor-fs-6.tutor-color-secondary {
    display: none !important;
}

.tutor-card-body .tutor-color-success {
    color: #f57005;
}

.course-details__sidebar__item .tutor-card-body {
    padding: 20px 0 0 0;
}

.tutor-btn {
    padding: 15px 32px;
    font-size: 18px;
    font-weight: 700;
}

.tutor-wrap.tutor-wrap-parent.tutor-full-width-student-profile,
.tutor-course-details-page {
    margin-top: 120px;
    margin-bottom: 92px;
}

.tutor-dashboard {
    margin-top: 120px;
}

.tutor-course-details-page {
    margin-bottom: 120px;
}

.tutor-ratings-stars {
    color: var(--eduact-secondary);
}

.tutor-single-course-sidebar .tutor-sidebar-card .tutor-card-body {
    color: var(--eduact-text);
}

.course-three__time .tutor-meta-value.tutor-color-secondary,
.course-one__time .tutor-meta-value.tutor-color-secondary,
.course-two__time .tutor-meta-value.tutor-color-secondary {
    color: var(--eduact-white);
}

.cart-required-login.save-bookmark-btn i.tutor-icon-bookmark-line:before,
.tutor-course-wishlist-btn i.tutor-icon-bookmark-line:before {
    content: '\f004';
    font-family: 'Font Awesome 5 Free' !important;
}

.tutor-course-wishlist-btn i.tutor-icon-bookmark-line-bold:before,
.tutor-course-wishlist-btn i.tutor-icon-bookmark-bold:before {
    content: '\f004';
    font-family: 'Font Awesome 5 Free' !important;
    font-weight: 700;
}

/* Popup Styles */
.eduact-popup {
    display: none;
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: var(--eduact-secondary);
    color: #ffffff;
    padding: 14px 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
}
.eduact-popup p {
    margin-bottom: 0;
}

.popup-content {
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
}

.popup-content,
.popup-content a {
    color: var(--eduact-white);
}

.tutor-login-modal .tutor-form-control {
    height: 60px;
    padding: 8px 16px;
}

.total-duration-display {
    float: right;
    font-size: 16px;
    margin-top: 2px;
}

.tutor-accordion-item-header {
    font-weight: 700;
    color: var(--eduact-black);
}

.tutor-course-content-list-item {
    border-bottom: 1px solid var(--eduact-soft4);
}

.tutor-course-content-list-item:last-child {
    border-bottom: none;
}

.tutor-course-content-list-item-title a:hover {
    color: var(--tutor-color-primary);
}

.blog-details__bottom {
    border-bottom: none;
}

.custom-blog-grid .blog-page {
    padding: 0;
}

.course-details__instructor p {
    font-weight: 600;
}

div.course-details__thumb img {
    border-radius: 14px;
}

.sidebar__post__content__meta i {
    font-weight: 400;
}

.sidebar .widget_custom_recent_posts_widget.widget ul li a::after,
.sidebar__single ul li a::after {
    content: '';
}

.course-one__meta__price,
.course-two__meta__price,
.course-three__meta__price {
    font-weight: 700;
}

.main-footer .elementor-widget-footer-shape,
.main-footer .elementor-section,
.main-footer .elementor-column,
.main-footer .elementor-container,
.main-footer .elementor-widget-wrap,
.main-footer-two .elementor-widget-footer-shape,
.main-footer-two .elementor-section,
.main-footer-two .elementor-column,
.main-footer-two .elementor-container,
.main-footer-two .elementor-widget-wrap {
    position: unset !important;
}

.main-footer__widget01 {
    padding-left: 0;
}

.main-footer-two {
    padding: 115px 0 110px;
}

.course-details__sidebar__item {
    margin-left: 15px;
}

.main-menu .main-menu__list > li.menu-item-has-children > span:after,
.main-menu .main-menu__list > li.dropdown > span:after {
    position: relative;
    align-items: center;
    right: 0;
    top: 0;
    border-radius: 0;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    content: '\f0d7';
    color: inherit;
    line-height: 10px;
    margin-left: 10px;
}
.main-menu .main-menu__list > li.menu-item-has-children-close > span:after,
.main-menu .main-menu__list > li.dropdown > span:after {
    position: relative;
    align-items: center;
    right: 0;
    top: 0;
    border-radius: 0;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Font Awesome 5 Free';
    content: '\f0d8';
    color: inherit;
    line-height: 10px;
    margin-left: 10px;
}

.sidebar .sidebar__post__image img {
    height: 100%;
}

.tutor-course-sidebar-card-pricing,
.tutor-course-single-pricing {
    display: none !important;
}

.course-details__sidebar__item .tutor-add-to-cart-button {
    margin-top: 10px;
    width: 100%;
    padding: 14px 16px;
}

.tutor-login-wrap,
#tutor-registration-wrap {
    margin-top: 120px;
    margin-bottom: 120px;
}

#tutor-registration-wrap .tutor-form-group label {
    color: var(--eduact-black);
    font-weight: 700;
}

.blog-one__cta {
    padding: 184px 50px 35px;
}

select.tutor-form-control,
.tutor-form-select,
input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='search'],
input[type='number'],
input[type='tel'],
input[type='select'],
.tutor-option-field textarea,
.tutor-option-field input[type='text'],
.tutor-option-field input[type='number'],
.tutor-option-field input[type='pas.tutor-dashboard-content-innersword'],
.tutor-form-group textarea,
.tutor-form-group input[type='text'],
.tutor-form-group input[type='number'],
.tutor-form-group input[type='password'].tutor-option-field textarea,
.tutor-option-field input[type='text'],
.tutor-option-field input[type='number'],
.tutor-option-field input[type='pas.tutor-dashboard-content-innersword'],
.tutor-form-group textarea,
.tutor-form-group input[type='text'],
.tutor-form-group input[type='date'],
.tutor-form-control,
.tutor-form-group input[type='password'] {
    height: 60px;
    width: 100%;
    border: none;
    background-color: var(--eduact-soft5);
    border: 1px solid var(--eduact-soft5);
    padding-left: 20px;
    padding-right: 30px;
    outline: none;
    font-size: 16px;
    color: var(--eduact-text);
    font-family: var(--eduact-font);
    display: block;
    font-weight: 600;
    border-radius: 4px;
    padding: 16px 16px;
}

.tutor-dashboard-content-inner .tutor-form-control {
    padding: 15px 16px;
}

.eduact-profile .tutor-card {
    border: none;
}

.tutor-instructor-application-process
    a.tutor-bg-primary.tutor-color-white.tutor-py-16.tutor-px-32.tutor-radius-6 {
    display: inline-block;
    padding-top: 0;
}

.tutor-dashboard .tutor-dashboard-content .tutor-capitalize-text,
.popular-courses-heading-dashboard span,
.tutor-user-public-profile .tutor-user-profile-content h3 {
    font-weight: 700;
}

.cta-one__svgshape svg path {
    animation: none;
    fill: var(--eduact-black, #2a254d);
}

.course-details__thumb {
    margin: 0 0 20px;
}

.course-details__sidebar__item .tutor-add-to-cart-button::after {
    content: '\e903';
    font-family: 'icomoon' !important;
    margin-left: 10px;
}

.eduact-logged-in {
    padding: 120px 0;
    text-align: center;
}

div.main-menu__login img {
    border-radius: 50%;
}

.login-user-menu li > a,
.stricky-header .login-user-menu li > a {
    font-size: 16px;
    line-height: 26px;
    color: var(--eduact-text);
    font-weight: 500;
    font-family: var(--heading-font);
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    transition: 400ms;
    margin-bottom: 4px;
}

.login-user-menu li.current > a,
.login-user-menu li:hover > a,
.stricky-header .login-user-menu li.current > a,
.stricky-header .login-user-menu li:hover > a {
    background-color: var(--eduact-black);
    color: var(--eduact-white);
}

.login-user-menu {
    position: absolute;
    top: 100%;
    left: -25px;
    min-width: 210px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transform-origin: top center;
    transform: scaleY(0) translateZ(100px);
    transition:
        opacity 500ms ease,
        visibility 500ms ease,
        transform 700ms ease;
    z-index: 99;
    background-color: var(--eduact-white);
    box-shadow: 0px 10px 60px 0px RGBA(var(--eduact-white-rgb), 0.07);
    padding: 15px 20px 10px;
    border-radius: 14px;
}

.main-menu__login:hover > div ul,
.stricky-header .login-user-menu li:hover > ul {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1) translateZ(0px);
}

.blog-two__item--list .blog-two__date .posted-on {
    display: flex;
    align-items: center;
}

.comments-area .reply a {
    border-radius: 8px;
}

.main-menu .main-menu__list > li.megamenu-clickable--toggler > a:after {
    display: none;
}

/* Templates
  --------------------------------------------- */
/**
   * Star rating
   */
.star-rating {
    overflow: hidden;
    position: relative;
    height: 1.618em;
    line-height: 1.618;
    width: 6.9em;
    font-family: star;
    font-weight: 900;
}

.star-rating::before {
    content: 'SSSSS';
    opacity: 0.25;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
}

.star-rating span {
    overflow: hidden;
    float: left;
    top: 0;
    left: 0;
    position: absolute;
    padding-top: 1.5em;
}

.star-rating span::before {
    content: 'SSSSS';
    top: 0;
    position: absolute;
    left: 0;
    color: var(--eduact-secondary);
}

p.stars a {
    position: relative;
    height: 1em;
    width: 1em;
    text-indent: -999em;
    display: inline-block;
    text-decoration: none;
    margin-right: 1px;
    font-weight: 400;
}

p.stars a::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    height: 1em;
    line-height: 1;
    font-family: star;
    content: 'S';
    color: #404040;
    text-indent: 0;
    opacity: 0.25;
}

p.stars a:hover ~ a::before {
    content: 'S';
    color: #404040;
    opacity: 0.25;
}

p.stars:hover a::before {
    content: 'S';
    color: var(--eduact-secondary);
    opacity: 1;
}

p.stars.selected a.active::before {
    content: 'S';
    color: var(--eduact-secondary);
    opacity: 1;
}

p.stars.selected a.active ~ a::before {
    content: 'S';
    color: #404040;
    opacity: 0.25;
}

p.stars.selected a:not(.active)::before {
    content: 'S';
    color: var(--eduact-secondary);
    opacity: 1;
}

.star-rating span:before,
.star-rating::before,
p.stars a:hover:after,
p.stars a:after {
    color: var(--eduact-secondary);
    letter-spacing: 4px;
}

.sidebar .widget_rating_filter .star-rating {
    display: block;
    color: var(--eduact-primary);
    font-size: 16px;
}

.woocommerce-ordering {
    float: right;
}

.woocommerce-pagination {
    overflow: hidden;
    width: 100%;
}

.woocommerce div.product div.images.woocommerce-product-gallery {
    position: relative;
}

@media screen and (min-width: 992px) {
    .product-content {
        margin-top: 35px;
    }
}
.woocommerce div.product div.images .woocommerce-product-gallery__wrapper {
    -webkit-transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
    transition: all cubic-bezier(0.795, -0.035, 0, 1) 0.5s;
    margin: 0;
    padding: 0;
    border-radius: 14px;
}

.woocommerce div.product div.summary {
    margin-bottom: 2em;
}

a.woocommerce-product-gallery__trigger img {
    display: none !important;
    border-radius: 14px;
}

.woocommerce-product-gallery__trigger::after {
    position: absolute;
    content: '\f1a2';
    font-family: 'Flaticon' !important;
    font-size: 25px;
    color: #222429;
    top: -20px;
    right: 0;
    text-indent: 0;
}

.woocommerce .woocommerce-product-rating .star-rating {
    float: left;
}

.comment-form-rating {
    display: block;
    overflow: hidden;
}

label.rate-this-pro {
    float: left;
    margin-right: 10px;
    font-size: 20px;
    font-weight: 600;
}

.comment-form-rating p.stars {
    padding-top: 5px;
}

.product__card {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 991px) {
    .product__card {
        grid-template-columns: repeat(1, 1fr);
    }
    .single-product div.product .woocommerce-product-gallery {
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
    }
}
.woocommerce-cart .woocommerce {
    padding-top: 70px;
    padding-bottom: 105px;
}

.woocommerce-checkout .woocommerce,
.woocommerce-cart .woocommerce {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.shop_table .product-thumbnail a {
    display: inline-block;
    background-color: var(--eduact-white, #ffffff);
}

.shop_table .product-thumbnail img {
    max-width: 120px;
    mix-blend-mode: darken;
    border: 1px solid #ece9e0;
    border-radius: 6px;
}

.shop_table .product-subtotal .amount,
.shop_table .amount {
    font-family: var(--eduact-font);
    font-size: 16px;
    color: #686a6f;
    font-weight: 400;
}

.shop_table .coupon label,
.summary.entry-summary .woosw-btn-icon {
    display: none;
}

.shop_table tr {
    position: relative;
}

table.shop_table.shop_table_responsive.cart.woocommerce-cart-form__contents
    th:last-child {
    margin-left: 0;
    padding-left: 0;
}

.shop_table tbody tr {
    border: 1px solid #e9ebee;
    border-left: 0;
    border-right: 0;
}

.shop_table td {
    border: 0;
}

.woocommerce-order-details .shop_table td,
.order_details.shop_table tfoot tr th {
    border: 1px solid #e9ebee;
}

.woocommerce-checkout-review-order-table thead tr {
    border-top: 1px solid #e9ebee;
    border-bottom: 1px solid #e9ebee;
}

.woocommerce-cart-form .shop_table tbody tr:last-child,
.woocommerce-checkout-review-order-table tbody tr,
.cart_totals .shop_table tbody tr,
.cart_totals .shop_table tbody th,
.woocommerce-checkout .shop_table tfoot th {
    border: 0;
    font-family: var(--eduact-font-two);
    font-weight: 400;
    font-size: 20px;
}

.cart_totals .shop_table tbody th {
    padding-left: 0;
}

.cart_totals .shop_table tbody th {
    font-family: var(--heading-font);
    font-weight: 600;
}

.woocommerce-checkout-review-order-table tbody td.product-name,
.woocommerce-order-details
    .woocommerce-table--order-details
    tbody
    tr
    td.product-name
    a {
    text-transform: capitalize;
}

.woocommerce-order-details
    .woocommerce-table--order-details
    tbody
    tr
    td.product-name
    a {
    font-size: 16px;
}

.woocommerce-view-order
    .woocommerce-order-details
    .woocommerce-table--order-details
    tbody
    tr
    td {
    padding-left: 10px;
}

.woocommerce-order-details .woocommerce-table--order-details tfoot th {
    color: var(--eduact-black);
    font-size: 20px;
    font-weight: 700;
    font-family: var(--eduact-font);
}

.woocommerce-checkout .shop_table tfoot tr:last-child {
    border-bottom: 1px solid #e9ebee;
}

.woocommerce-checkout .shop_table td {
    padding: 10px;
    padding-left: 20px;
}

.cart_totals .shop_table td,
.cart_totals .shop_table th {
    padding-top: 10px;
    padding-bottom: 10px;
}

form.woocommerce-checkout
    .shop_table.woocommerce-checkout-review-order-table
    .cart_item
    .product-name,
form.woocommerce-checkout .shop_table tfoot tr th {
    font-family: var(--eduact-font);
    color: var(--eduact-black);
    font-size: 20px;
    line-height: 18px;
    font-weight: 500;
}

form.woocommerce-checkout
    .shop_table.woocommerce-checkout-review-order-table
    .cart_item
    .product-name {
    width: 100%;
}

.woocommerce-checkout .shop_table .amount {
    font-family: var(--eduact-font);
    color: var(--eduact-black);
    font-size: 20px;
    line-height: 18px;
    text-align: right;
    font-weight: 500;
    letter-spacing: 0;
    transition: all 500ms ease;
}

/**
   * Checkout
   */
@media screen and (min-width: 768px) {
    .col2-set .form-row-first {
        float: left;
        margin-right: 3.8%;
    }
    .col2-set .form-row-last {
        float: right;
        margin-right: 0;
    }
    .col2-set .form-row-first,
    .col2-set .form-row-last {
        width: 48.1%;
    }
}
/**
   * Password strength meter
   */
.woocommerce-password-strength {
    text-align: right;
}

.woocommerce-password-strength.strong {
    color: #0f834d;
}

.woocommerce-password-strength.short {
    color: #e2401c;
}

.woocommerce-password-strength.bad {
    color: #e2401c;
}

.woocommerce-password-strength.good {
    color: #3d9cd2;
}

/**
   * Forms
   */
.form-row.woocommerce-validated input.input-text {
    box-shadow: inset 2px 0 0 #0f834d;
}

.form-row.woocommerce-invalid input.input-text {
    box-shadow: inset 2px 0 0 #e2401c;
}

.required {
    color: #f00;
}

/**
   * WooCommerce widgets
   */
.sidebar__single.shop-one__sidebar__item {
    background-color: #fff;
}

.sidebar .wc-block-product-search .wc-block-product-search__fields {
    display: inline-flex;
}

.sidebar .wc-block-product-search .wc-block-product-search__field {
    max-width: 170px;
}

/**
   * WooCommerce Price Filter
   */
.widget_price_filter .price_slider {
    margin-bottom: 1.5em;
}

.widget_price_filter .price_slider_amount {
    text-align: right;
    line-height: 2.4;
}

.widget_price_filter .price_slider_amount .button {
    float: left;
}

.widget_price_filter .ui-slider {
    position: relative;
    text-align: left;
}

.widget_price_filter .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1em;
    height: 1em;
    cursor: ew-resize;
    outline: none;
    background: var(--eduact-primary);
    box-sizing: border-box;
    margin-top: -0.25em;
    opacity: 1;
    top: -4px;
}

.widget_price_filter .ui-slider .ui-slider-handle:last-child {
    margin-left: -1em;
}

.widget_price_filter .ui-slider .ui-slider-handle:hover,
.widget_price_filter .ui-slider .ui-slider-handle.ui-state-active {
    box-shadow: 0 0 0 0.25em rgba(0, 0, 0, 0.1);
}

.widget_price_filter .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    border: 0;
    background: var(--eduact-primary);
}

.widget_price_filter .price_slider_wrapper .ui-widget-content {
    background: rgba(0, 0, 0, 0.1);
}

.widget_price_filter .ui-slider-horizontal {
    height: 0.5em;
}

.widget_price_filter .ui-slider-horizontal .ui-slider-range {
    height: 100%;
}

.sidebar .widget_product_search .searchform {
    position: relative;
    margin: 0px;
}

.sidebar .widget_product_search .searchform input[type='text'],
.sidebar .widget_product_search .searchform input[type='search'] {
    position: relative;
    line-height: 40px;
    padding: 19px 80px 19px 35px;
    background: #f4f5f8;
    color: #222429;
    display: block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0px;
    width: 100%;
    height: 60px;
    border: 1px solid #f4f5f8;
    font-family: var(--eduact-font);
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.sidebar .widget_product_search .searchform input:focus {
    border-color: #222429;
}

.sidebar .widget_product_search .searchform button {
    position: absolute;
    right: 10px;
    top: 3px;
    border: none;
    outline: none;
    padding: 0;
    height: 60px;
    width: 60px;
    line-height: 60px;
    display: block;
    font-size: 18px;
    color: #222429;
    background: none;
    font-weight: normal;
}

.sidebar .widget_product_search .searchform input:focus + button,
.sidebar .widget_product_search .searchform button:hover {
    color: #181b1f;
}

.sidebar .wc-block-product-search__button {
    border: 1px solid;
    border-color: var(--eduact-base);
    border-radius: 3px;
    background: var(--eduact-base);
    color: var(--eduact-white);
    line-height: 1;
    padding: 0.6em 1em 0.4em;
}

.wc-block-product-categories__button {
    border-color: var(--eduact-base);
    background: var(--eduact-base);
    color: var(--eduact-white);
}

.woocommerce-MyAccount-navigation ul,
.sidebar .widget_product_categories ul,
.sidebar .woocommerce-widget-layered-nav ul {
    position: relative;
    margin-top: -9px;
}

.woocommerce-MyAccount-navigation ul li,
.sidebar .widget_product_categories ul li,
.sidebar .woocommerce-widget-layered-nav ul li {
    position: relative;
    line-height: 30px;
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: var(--thm-text);
    font-weight: 300;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: var(--eduact-font);
    letter-spacing: 0;
    font-weight: 400;
}

.woocommerce-MyAccount-navigation ul li:last-child,
.sidebar .widget_product_categories ul li:last-child,
.sidebar .woocommerce-widget-layered-nav ul li:last-child {
    margin-bottom: 0;
}

.woocommerce-MyAccount-navigation ul li a,
.sidebar .widget_product_categories ul li a,
.sidebar .woocommerce-widget-layered-nav ul li a {
    position: relative;
    display: inline-block;
    line-height: 30px;
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: capitalize;
    color: var(--thm-text);
    font-weight: 300;
    transition: all 0.3s ease;
    z-index: 1;
    font-size: 16px;
    font-family: var(--eduact-font);
    letter-spacing: 0;
    font-weight: 400;
}

.woocommerce-MyAccount-navigation ul li > a:hover,
.sidebar .widget_product_categories ul li > a:hover,
.sidebar .woocommerce-widget-layered-nav ul li > a:hover,
.sidebar .widget_product_categories ul li.active > a,
.sidebar .woocommerce-widget-layered-nav ul li.active > a {
    color: #222429;
    text-shadow: 0 0 1px #222429;
}

.woocommerce-MyAccount-navigation ul ul,
.sidebar .widget_product_categories ul ul,
.sidebar .woocommerce-widget-layered-nav ul ul {
    margin-top: 15px;
}

.woocommerce-MyAccount-navigation ul li::after,
.sidebar .widget_product_categories ul li:after,
.sidebar .woocommerce-widget-layered-nav ul li:after {
    position: absolute;
    right: 0;
    top: -1px;
    font-family: 'Flaticon';
    content: '\f15f';
    opacity: 1;
    font-size: 10px;
    line-height: 30px;
    font-weight: 700;
    z-index: 1;
}

.sidebar .widget_price_filter .ui-slider-horizontal {
    height: 4px;
}

.sidebar .widget_price_filter .ui-slider .ui-slider-handle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: none;
    margin-top: -1px;
    margin-left: -1.5px;
}

.sidebar .widget_price_filter .ui-slider .ui-slider-handle:last-child {
    margin-left: -13px;
}

.sidebar .widget_price_filter .theme-btn {
    border-radius: 5px;
}

.sidebar .widget_price_filter .theme-btn .btn-title {
    padding: 7px 21px 3px;
    font-size: 16px;
}

.widget_price_filter .price_slider_amount {
    display: flex;
    flex-direction: row-reverse;
    text-align: left;
}

.widget_price_filter .clear {
    display: none;
}

.widget_price_filter .price_label {
    margin-right: auto;
    padding-top: 3px;
}

.sidebar .widget_price_filter .price_slider_amount {
    font-family: var(--eduact-font);
    font-size: 16px;
    font-weight: 400;
    color: var(--thm-text);
}

.sidebar .widget_rating_filter .star-rating {
    display: block;
    color: var(--eduact-base);
    font-size: 16px;
}

.sidebar .widget_rating_filter a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #222429;
}

.sidebar .widget_rating_filter ul {
    margin-top: -10px;
}

.sidebar .widget_recently_viewed_products li + li,
.sidebar .widget_recent_reviews li + li,
.widget_top_rated_products li + li,
.sidebar .widget_products li + li,
.sidebar .wc-block-product-categories li + li {
    margin-top: 20px;
}

.sidebar .widget_recently_viewed_products li .size-woocommerce_thumbnail,
.sidebar .widget_recent_reviews li .size-woocommerce_thumbnail,
.sidebar .widget_products li .size-woocommerce_thumbnail {
    width: 60px;
    float: left;
    margin-right: 30px;
}

.sidebar .widget_recently_viewed_products li .product-title,
.sidebar .widget_recent_reviews li .product-title,
.sidebar .widget_products li .product-title,
.sidebar .widget_top_rated_products .product-title {
    display: block;
    text-transform: capitalize;
    color: #222429;
    font-size: 16px;
    line-height: 1.3em;
    font-weight: 800;
    margin-top: 10px;
}

.sidebar .widget_recently_viewed_products li ins,
.sidebar .widget_recent_reviews li ins,
.sidebar .widget_products li ins {
    background-color: transparent;
}

.sidebar .widget_recently_viewed_products li .star-rating,
.sidebar .widget_recent_reviews li .star-rating {
    font-size: 14px;
}

.woocommerce-active .content-area .site-main::after {
    content: '';
    clear: both;
    display: table;
}

.woocommerce-active .content-area .woocommerce-result-count {
    float: left;
    margin: 0;
    color: var(--thm-text);
    font-size: 18px;
    font-family: var(--eduact-font);
    padding-top: 18px;
}

.woocommerce-active .content-area .woocommerce-ordering {
    float: right;
}

.variations_form.cart select,
.woocommerce-active .content-area .woocommerce-ordering select {
    border: none;
    outline: none;
    display: block;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 80px;
    background-color: var(--eduact-soft5) !important;
    color: var(--thm-text);
    padding: 14px 30px;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--eduact-font);
    border: 1px solid var(--eduact-extra);
    background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path fill="rgb(34, 36, 41)" d="M540.1 776.847l472.32-473.63c15.466-15.518 15.44-40.642-0.080-56.134-15.518-15.48-40.656-15.44-56.134 0.080l-444.208 445.438-444.206-445.454c-15.48-15.518-40.602-15.558-56.122-0.080-7.78 7.766-11.67 17.94-11.67 28.114 0 10.148 3.864 20.282 11.59 28.034l472.308 473.632c7.436 7.474 17.556 11.668 28.1 11.668s20.652-4.206 28.102-11.668z"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 30px top 50%;
    background-size: 0.65em auto;
    margin-bottom: 30px;
    border-radius: var(--eduact-bdr-radius);
    text-transform: capitalize;
}

.product-cart-btn {
    margin-top: 5px;
}

.product-cart-btn .btn-title {
    padding: 7px 21px 3px;
    font-size: 16px;
}

ul.products li.product .button {
    display: inline-block;
}

.shop-one__image {
    text-align: center;
    width: 100%;
}

.product .added_to_cart,
.shop-one__image .added_to_cart {
    border: none;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none !important;
    background-color: var(--eduact-secondary);
    color: var(--eduact-black);
    font-family: var(--heading-font);
    border-radius: 6px;
    transition: all 0.5s linear;
    font-weight: 700;
    z-index: 1;
    color: var(--eduact-white);
    padding: 7px 21px;
    font-size: 16px;
    overflow: hidden;
    text-transform: capitalize;
}

.wc-proceed-to-checkout a:hover {
    background-color: var(--eduact-base);
    color: var(--eduact-white);
}

.product .added_to_cart:hover {
    color: var(--eduact-white);
    background-color: var(--eduact-black);
}

.products .product {
    text-align: center;
}

.products .product .price {
    font-size: 20px;
    font-weight: 600;
    color: var(--eduact-gray);
    font-family: var(--eduact-font);
    letter-spacing: var(--eduact-letter-spacing);
    line-height: 16px;
}

.products .product .shop-one__price ins {
    background-color: transparent;
}

.products .product .star-rating {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    margin-bottom: 0px;
}

.products .product .woocommerce-loop-product__title {
    margin: 0;
    color: #222429;
    font-size: 24px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 5px;
}

ul.products li.product img {
    margin-left: auto;
    margin-right: auto;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    width: 100%;
}

ul.products li.product:hover img {
    filter: grayscale(0);
}

ul.products li.product {
    position: relative;
}

ul.products li.product .onsale {
    background-color: #222429;
    color: #fff;
    padding-top: 5px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: inline-flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 15px;
    line-height: 1;
    position: absolute;
    top: 20px;
    left: 20px;
}

.woocommerce-pagination ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.woocommerce-pagination ul a,
.woocommerce-pagination ul span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 10px;
    color: var(--eduact-text2, #b3ade1);
    background-color: var(--eduact-color3);
    border: 1px solid var(--eduact-soft4);
    font-size: 16px;
    font-weight: 500;
    min-width: 50px;
    min-height: 50px;
    line-height: 1;
    transition: all 500ms ease;
    border-radius: 10px;
}

.woocommerce-pagination ul a:hover,
.woocommerce-pagination ul span {
    background-color: var(--eduact-secondary);
    border-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.woocommerce-pagination ul li a.prev {
    text-indent: -99999px;
}
.woocommerce-pagination ul li a.prev::before {
    content: '\f104';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    text-indent: 0;
}

.woocommerce-pagination ul li a.next {
    text-indent: -99999px;
}
.woocommerce-pagination ul li a.next::before {
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    text-indent: 0;
}

.woocommerce-pagination ul .prev,
.woocommerce-pagination ul .next {
    padding-top: 0;
}

.woocommerce-active .product__card {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 991px) {
    .woocommerce-active .product__card {
        grid-template-columns: repeat(1, 1fr);
    }
    .single-product div.product .woocommerce-product-gallery {
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
    }
}
.woocommerce-active .product__card .onsale {
    background-color: #222429;
    color: #fff;
    padding-top: 5px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: inline-flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 1;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
}

.woocommerce-product-gallery__wrapper {
    margin-top: 0;
    overflow: hidden;
    background-color: var(--eduact-grey, #b7b0b4);
}

.woocommerce-product-gallery__wrapper a img {
    width: 100%;
    border: 1px solid #dedee5;
}

.woocommerce-product-gallery__image {
    border-radius: 0px;
}

.woocommerce-active .product .woocommerce-tabs {
    grid-column-start: 1;
    grid-column-end: 3;
}

.woocommerce-active .product .related.products {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 20px;
}

.product__card .product_title {
    font-size: 45px;
    text-transform: uppercase;
    margin: 0;
    margin-top: -10px;
}

.product__card .entry-summary {
    margin-top: 0;
}

.single-product
    div.product
    .woocommerce-product-gallery
    .woocommerce-product-gallery__trigger {
    top: 20px;
    right: 20px;
}

.single-product .woocommerce-grouped-product-list-item__price,
.product__card .price {
    margin: 0;
    color: var(--eduact-secondary);
    font-size: 20px;
    line-height: 1;
    font-weight: 600;
    margin-left: 70px;
}

.single-product .woocommerce-product-rating {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.single-product .woocommerce-review-link {
    font-family: var(--eduact-font);
    font-size: 20px;
    color: var(--eduact-text);
    position: relative;
    font-weight: 600;
    margin-left: 20px;
    text-transform: capitalize;
}

.woocommerce-variation-description p,
.single-product .woocommerce-product-details__short-description p {
    font-family: var(--eduact-font);
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    font-weight: 600;
}

.product-quantity .quantity,
.single-product .quantity {
    position: relative;
    width: 98px;
    height: 60px;
}

.product-quantity .quantity input,
.single-product .quantity input {
    width: 98px;
    height: 50px;
    border: 1px solid #e9ebee;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    font-family: var(--eduact-font);
    padding-left: 30px;
    background: var(--eduact-extra);
    border-radius: var(--eduact-bdr-radius);
}

.product-quantity .quantity button,
.single-product .quantity button {
    width: 29px;
    height: 22px;
    background-color: transparent;
    color: #222429;
    font-size: 8px;
    position: absolute;
    top: 1px;
    right: 1px;
    background-color: #fff;
    border: 0;
    border-left: 1px solid #e9ebee;
    border-top-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--eduact-extra);
}

.product-quantity .quantity button.sub,
.single-product .quantity button.sub {
    bottom: 13px;
    top: auto;
    border-top: 1px solid #e9ebee;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 8px;
}

.single-product .woocommerce-variation-add-to-cart,
.single-product .cart {
    display: block;
    flex-wrap: wrap;
    align-items: center;
}

.single-product .cart h2 {
    margin: 0;
    color: #222429;
    text-transform: uppercase;
    font-size: 24px;
    margin-right: 20px;
}

.single-product .woocommerce-variation-add-to-cart .cart-button-wrap,
.single-product .cart .cart-button-wrap {
    margin-top: 30px;
    flex: 0 0 100%;
}

.single-product .product_meta {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.single-product .posted_in,
.single-product .sku_wrapper {
    color: var(--eduact-black);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: var(--eduact-letter-spacing);
}

.single-product .posted_in a,
.single-product .sku_wrapper span {
    font-family: var(--eduact-font);
    font-weight: 700;
    color: var(--thm-text);
    font-size: 14px;
    margin-left: 5px;
    position: relative;
    top: -1px;
    text-transform: capitalize;
}

.single-product
    .woocommerce-grouped-product-list
    .woocommerce-grouped-product-list-item__quantity
    h2 {
    display: none;
}

.single-product .woocommerce-grouped-product-list-item__label label {
    margin: 0;
    color: #222429;
    text-transform: capitalize;
    font-size: 24px;
}

.single-product .woocommerce-grouped-product-list-item__label label a {
    color: inherit;
    transition: all 500ms ease;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 20px;
    font-family: var(--heading-font);
}

.single-product .woocommerce-grouped-product-list-item__label label a:hover {
    color: var(--eduact-base);
}

.single-product .woocommerce-grouped-product-list-item__price {
    font-size: 18px;
}

.variations_form.cart label,
.woocommerce-tabs .woocommerce-product-attributes-item__label {
    color: #222429;
    text-transform: capitalize;
    margin: 0;
    font-size: 20px;
    font-weight: 400;
}

.woocommerce-tabs .woocommerce-product-attributes-item__value {
    position: relative;
    top: 3px;
}

.variations_form.cart select {
    width: 250px;
    margin-bottom: 0;
    float: right;
    height: 50px;
}

#review_form .comment-respond {
    margin-top: 30px;
}

#review_form #commentform {
    margin-top: 15px;
}
#review_form #commentform .row {
    --bs-gutter-x: 20px;
}

.woocommerce-checkout .woocommerce,
.woocommerce-cart .woocommerce {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.woocommerce-checkout .woocommerce {
    padding-top: 120px;
    padding-bottom: 120px;
}

.cart_totals h2,
.shop_table thead th {
    color: var(--eduact-black);
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    font-family: var(--heading-font);
    text-transform: capitalize;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #dedee5 !important;
    box-shadow: none;
}

.cart_totals h2 {
    padding: 0 0 24px;
}

.shop_table thead th {
    padding-left: 0;
    padding-right: 0;
}

.woocommerce-checkout-review-order-table thead tr th {
    padding: 20px 0;
}

.shop_table thead th:first-child {
    padding-left: 0;
}

.shop_table .product-remove {
    text-align: right;
}

.shop_table tbody td.actions {
    padding-top: 60px;
    padding-left: 0;
}

.shop_table tbody .cart_item td {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 0px;
}

.cart_totals h2 {
    font-size: 30px;
}

.shop_table .product-remove a {
    font-size: 20px;
    color: var(--eduact-black);
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-weight: 500;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.cart_totals th,
.shop_table .product-name:not(th),
.shop_table .product-name a {
    margin: 0;
    font-size: 20px;
    text-transform: capitalize;
    font-family: var(--heading-font);
    color: var(--eduact-black);
    font-weight: 700;
    transition: all 500ms ease;
}

.shop_table .product-name a:hover {
    color: var(--eduact-base);
}

.shop_table .product-subtotal .amount,
.shop_table .amount {
    font-family: var(--eduact-font);
    font-size: 20px;
    font-weight: 500;
    color: var(--eduact-black);
}

.shop_table .coupon label {
    display: none;
}

.shop_table tr {
    position: relative;
}

.woocommerce .checkout_coupon input[type='text'],
.shop_table .coupon input[type='text'] {
    width: 100%;
    max-width: 370px;
    border-radius: 0;
    background-color: var(--eduact-soft5);
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 20px;
    font-family: var(--eduact-font);
    font-size: 16px;
    color: var(--eduact-text);
    height: 63px;
    margin-bottom: 20px;
    border: none;
    outline: none;
    font-weight: 600;
}

.shop_table .coupon input::placeholder {
    color: var(--eduact-gray);
    opacity: 1;
}

.shop_table button[name='update_cart'] {
    position: absolute;
    top: 60px;
    right: 0px;
    cursor: pointer;
}

.cart_totals td {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.cart_totals td {
    font-family: var(--eduact-font);
    font-size: 18px;
    color: #686a6f;
    font-weight: 400;
}

.cart_totals .woocommerce-shipping-methods {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cart_totals p {
    margin: 0;
}

.cart_totals .shop_table td:last-child {
    text-align: right;
    padding-right: 0px;
}

.shipping-calculator-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.shipping-calculator-form p:last-of-type,
.shipping-calculator-form p:nth-child(1),
.shipping-calculator-form p:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 3;
}

.shipping-calculator-form ::placeholder {
    opacity: 1;
    color: var(--thm-text);
}

.shipping-calculator-form input[type='text'],
.shipping-calculator-form
    .select2-container--default
    .select2-selection--single {
    width: 100%;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    height: 70px;
    background-color: #f4f5f8;
    color: var(--thm-text);
    padding: 14px 30px;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--eduact-font);
    letter-spacing: 0em;
    border: 1px solid #f4f5f8;
    display: flex;
    align-items: center;
}

.shipping-calculator-form p > span {
    display: block;
    width: 100%;
}

.shipping-calculator-form
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    top: 20px;
    right: 20px;
}

.cart-collaterals {
    display: flex;
    justify-content: flex-end;
}

.cart_totals {
    width: 100%;
    max-width: 400px;
}

.woocommerce-cart .woocommerce {
    padding-top: 120px;
    padding-bottom: 120px;
}

.woocommerce-account .news-details .woocommerce,
.woocommerce-checkout .news-details .woocommerce,
.woocommerce-cart .news-details .woocommerce {
    padding-top: 0;
    padding-bottom: 0;
}

.woocommerce-cart .woocommerce-notices-wrapper {
    margin-bottom: 0px;
}

/**
   * Notices
   */
.woocommerce-message,
.woocommerce-info,
.woocommerce-error,
.woocommerce-noreviews,
p.no-comments {
    background-color: #222;
    clear: both;
    color: #fff !important;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: var(--eduact-font);
    font-size: 16px;
    margin-bottom: 30px;
    text-align: center;
    text-align: left;
    display: inline-flex;
}

.woocommerce-error {
    display: inline-block;
}

.woocommerce-error strong {
    color: #fff;
}

.woocommerce-info {
    line-height: 1.7;
}

.woocommerce-message a,
.woocommerce-info a,
.woocommerce-error a,
.woocommerce-noreviews a,
p.no-comments a {
    color: var(--eduact-secondary);
    margin-right: 5px;
    margin-left: 5px;
}

a.woocommerce-privacy-policy-link {
    color: var(--eduact-base);
}

.woocommerce-error {
    list-style-type: none;
    margin-left: 0;
}

.woocommerce-error {
    background-color: #e2401c;
}

.demo_store {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 1em;
    background-color: #3d9cd2;
    z-index: 9999;
}

.woocommerce-cart .wc-proceed-to-checkout {
    text-align: right;
    margin-top: 40px;
}

.woocommerce-checkout .col2-set {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
}

.woocommerce-checkout .col2-set .col-1,
.woocommerce-checkout .col2-set .col-2 {
    padding: 0 !important;
    width: 100%;
    max-width: 100%;
}

#order_review_heading,
.woocommerce-column__title,
.woocommerce-order-details__title,
.woocommerce-Address-title h3,
.woocommerce-EditAccountForm fieldset legend,
.woocommerce-checkout #customer_details h3 {
    margin: 0;
    display: flex;
    align-items: center;
    margin: 0;
    font-family: var(--heading-font);
    color: var(--eduact-black);
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 45px;
    text-transform: capitalize;
}

.woocommerce-checkout #customer_details h3 label {
    margin: 0;
    cursor: pointer;
}

.woocommerce-checkout #customer_details h3 label input {
    display: none;
    visibility: hidden;
}

.woocommerce-checkout #customer_details h3 label span {
    display: flex;
    align-items: center;
}

.woocommerce-checkout #customer_details h3 label span::after {
    content: '';
    width: 19px;
    height: 19px;
    background-color: #f4f5f8;
    border-radius: 50%;
    margin-left: 20px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.woocommerce-checkout #customer_details h3 label input:checked + span::after {
    content: '\f00c';
    background-color: var(--eduact-primary);
}

.woocommerce-checkout .woocommerce-billing-fields__field-wrapper,
.woocommerce-checkout .woocommerce-shipping-fields__field-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.eduact-edit-address h3,
.eduact-edit-address .form-row {
    margin-bottom: 30px;
}

.woocommerce-EditAccountForm label,
.lost_reset_password label,
.woocommerce-checkout .woocommerce-additional-fields__field-wrapper label,
.woocommerce-checkout .woocommerce-billing-fields__field-wrapper label,
.woocommerce-checkout .woocommerce-shipping-fields__field-wrapper label,
.eduact-edit-address label {
    font-family: var(--eduact-font);
    color: var(--eduact-black);
    font-size: 18px;
    margin: 0;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.woocommerce form .form-row .required {
    text-decoration: none;
}

.woocommerce-checkout .woocommerce-billing-fields__field-wrapper p,
.woocommerce-checkout .woocommerce-shipping-fields__field-wrapper p {
    margin: 0;
    float: none;
    width: 100%;
    display: block;
}

.woocommerce-checkout #customer_details {
    position: relative;
    margin-top: 30px;
}

.woocommerce-form-login input[type='text'],
.woocommerce-form-register input[type='text'],
.woocommerce-form-register input[type='email'],
.woocommerce-form-login input[type='password'],
.lost_reset_password input[type='text'],
.eduact-edit-address input[type='text'],
.eduact-edit-address input[type='email'],
.eduact-edit-address input[type='password'],
.eduact-edit-address input[type='tel'],
.eduact-edit-address input[type='url'],
.eduact-edit-address input[type='file'],
.eduact-edit-address input[type='number'],
.eduact-edit-address textarea,
.woocommerce-EditAccountForm input[type='text'],
.woocommerce-EditAccountForm input[type='email'],
.woocommerce-EditAccountForm input[type='password'],
.woocommerce-EditAccountForm input[type='tel'],
.woocommerce-EditAccountForm input[type='url'],
.woocommerce-EditAccountForm input[type='file'],
.woocommerce-EditAccountForm input[type='number'],
.woocommerce-EditAccountForm textarea,
.woocommerce-checkout #customer_details input[type='text'],
.woocommerce-checkout #customer_details input[type='email'],
.woocommerce-checkout #customer_details input[type='password'],
.woocommerce-checkout #customer_details input[type='tel'],
.woocommerce-checkout #customer_details input[type='url'],
.woocommerce-checkout #customer_details input[type='file'],
.woocommerce-checkout #customer_details input[type='number'],
.woocommerce-checkout #customer_details textarea {
    position: relative;
    display: block;
    height: 60px;
    width: 100%;
    font-size: 16px;
    color: var(--eduact-black);
    line-height: 40px;
    font-weight: 500;
    padding: 14px 30px;
    letter-spacing: 0.1em;
    background-color: var(--eduact-soft5);
    border: none;
    transition: all 300ms ease;
    font-family: var(--eduact-font);
    font-size: 14px;
    letter-spacing: 0;
    border-radius: 4px;
    text-transform: capitalize;
}

.woocommerce-EditAccountForm textarea,
.woocommerce-checkout #customer_details textarea {
    height: 220px;
    resize: none;
}

.woocommerce-EditAccountForm ::placeholder,
.woocommerce-checkout #customer_details ::placeholder {
    opacity: 1;
    color: var(--thm-text);
}

.woocommerce-checkout #customer_details ::-webkit-input-placeholder {
    opacity: 1;
    color: var(--thm-text);
}

.woocommerce-checkout #customer_details ::-moz-input-placeholder {
    opacity: 1;
    color: var(--thm-text);
}

.woocommerce-checkout #customer_details ::-ms-input-placeholder {
    opacity: 1;
    color: var(--thm-text);
}

.woocommerce-checkout #customer_details label.error {
    color: #ff0000;
    font-size: 12px;
    text-transform: capitalize;
    text-align: left;
    display: block;
    letter-spacing: 1px;
    padding-top: 7px;
    line-height: 24px;
}

.woocommerce-checkout .woocommerce-input-wrapper {
    display: block;
    width: 100%;
}

.woocommerce-checkout
    #customer_details
    .select2-container--default
    .select2-selection--single,
.eduact-edit-address .select2-selection--single {
    width: 100%;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    height: 60px;
    background-color: var(--eduact-soft5);
    color: var(--thm-text);
    padding: 14px 30px;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--eduact-font);
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.woocommerce-checkout
    #customer_details
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow,
.eduact-edit-address .select2-selection--single .select2-selection__arrow {
    top: 12px;
    right: 20px;
}

.woocommerce-checkout #customer_details #shipping_address_1_field,
.woocommerce-checkout #customer_details #shipping_postcode_field,
.woocommerce-checkout #customer_details #billing_email_field,
.woocommerce-checkout #customer_details #shipping_address_2_field,
.woocommerce-checkout #customer_details #billing_address_2_field,
.woocommerce-checkout #customer_details #billing_address_1_field {
    grid-column-start: 1;
    grid-column-end: 3;
}

.woocommerce-checkout .shipping_address {
    margin-bottom: 30px;
}

.woocommerce-checkout #order_review_heading {
    margin-top: 60px;
}

.return-to-shop {
    margin-top: 30px !important;
    text-align: left !important;
}

.woocommerce-checkout .shop_table ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.woocommerce-checkout .shop_table ul li {
    font-size: 16px;
    color: #686a6f;
    font-weight: 400;
}
.woocommerce-checkout .shop_table ul li label {
    margin-bottom: 0;
}

.woocommerce-checkout .shop_table tfoot td,
.woocommerce-checkout .shop_table tfoot th {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 13px;
    padding-bottom: 10px;
}

form.woocommerce-checkout .shop_table tfoot th {
    margin: 0;
    color: var(--eduact-gray);
    font-size: 24px;
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 10px;
}

.woocommerce-checkout .shop_table {
    margin-bottom: 0;
}

.woocommerce-checkout-payment .place-order,
.woocommerce-checkout-payment .wc_payment_methods {
    background-color: var(--eduact-soft5);
    padding: 30px 30px 18px 30px;
    margin: 0;
    list-style: none;
    line-height: 1;
}

.woocommerce-checkout-payment .place-order {
    padding-left: 30px;
    padding-bottom: 12px;
}

.woocommerce-checkout-payment .place-order {
    padding-top: 0;
    padding-bottom: 30px;
}

.woocommerce-checkout-payment .wc_payment_methods li label {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 20px;
    color: var(--eduact-black);
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    cursor: pointer;
    font-family: var(--heading-font);
    text-transform: capitalize;
}

.woocommerce-checkout-payment .wc_payment_methods li + li {
    margin-top: -10px;
}

.woocommerce-checkout-payment .wc_payment_methods li input {
    display: none;
}

.woocommerce-checkout-payment .wc_payment_methods li label {
    margin-bottom: 0;
}

.woocommerce-checkout-payment .wc_payment_methods li label::before {
    content: '';
    width: 19px;
    height: 19px;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 10px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    position: relative;
    top: 1px;
    transition: all 500ms ease;
}

.woocommerce-checkout-payment
    .wc_payment_methods
    li
    input:checked
    + label::before {
    background-color: var(--eduact-secondary);
    border-color: var(--eduact-secondary);
    content: '\f00c';
}

.woocommerce-checkout-payment .wc_payment_methods li img {
    mix-blend-mode: multiply;
    width: 170px;
    margin-right: 10px;
    margin-left: 10px;
}

.checkout_coupon p,
.woocommerce-checkout-payment .place-order p,
.woocommerce-checkout-payment .wc_payment_methods li p {
    font-size: 16px;
    line-height: 30px;
    color: var(--eduact-text, #697585);
    font-family: var(--eduact-font, 'Urbanist', sans-serif);
    font-weight: 400;
}

.wc_payment_methods .payment_box {
    padding-left: 35px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.woocommerce-ResetPassword .woocommerce-Button {
    margin-top: 20px;
}

.checkout_coupon {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    max-width: 540px;
}

.checkout_coupon p:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
}

.checkout_coupon input[type='text'] {
    border: none;
    width: 100%;
    max-width: 370px;
    background-color: var(--eduact-primary);
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 10px;
    font-family: var(--eduact-font);
    font-size: 14px;
    color: #686a6f;
    height: 64px;
    margin: 0;
}

.woocommerce-account .woocommerce {
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: 30px;
    padding-top: 120px;
    padding-bottom: 100px;
}

.woocommerce-account.woocommerce-lost-password .woocommerce {
    display: block;
}

.woocommerce-order-overview {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 10px;
}

legend {
    float: none;
}

.woocommerce-form-login label,
.woocommerce-form-register label {
    display: block;
}

.woocommerce-MyAccount-content,
.woocommerce-order p,
.woocommerce-order address,
.woocommerce-order-overview li {
    font-family: var(--eduact-font);
    font-weight: 400;
    font-size: 16px;
}

.woocommerce-MyAccount-content a {
    color: var(--eduact-base, #fff);
}

.woocommerce-MyAccount-content .order-again a {
    font-weight: bold;
}

.woocommerce b,
.woocommerce strong {
    color: #222429;
}

.woocommerce-order-details {
    margin-bottom: 60px;
    margin-top: 60px;
}

.woocommerce-Addresses {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 10px;
}

.woocommerce-Addresses .col-2,
.woocommerce-Addresses .col-1 {
    padding: 0;
    width: 100%;
    max-width: 100%;
}

.woocommerce-EditAccountForm .clear {
    display: none;
}

.woocommerce-EditAccountForm {
    margin-bottom: 20px;
}

.woocommerce-EditAccountForm fieldset,
.woocommerce-EditAccountForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    width: 100%;
}

.woocommerce-EditAccountForm p:nth-child(4),
.woocommerce-EditAccountForm p:nth-child(6),
.woocommerce-EditAccountForm fieldset {
    grid-column-start: 1;
    grid-column-end: 3;
    padding: 0;
}

.woocommerce-EditAccountForm p {
    margin: 0;
}

.woocommerce-EditAccountForm label {
    font-size: 20px;
}

.woocommerce-EditAccountForm p > span {
    display: block;
    width: 100%;
}

.woocommerce-EditAccountForm input + span {
    margin-top: 10px;
}

.woocommerce-EditAccountForm fieldset legend {
    margin-top: 20px;
}

.woocommerce-MyAccount-navigation ul {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: block;
    padding: 35px 45px;
    background: var(--eduact-soft5);
}

@media (max-width: 991px) {
    .shop_table button[name='update_cart'] {
        position: relative;
        margin-top: 20px;
        top: auto;
        right: auto;
        transform: translateY(0);
    }
}
@media (max-width: 767px) {
    table.shop_table_responsive .product-thumbnail {
        justify-content: center;
    }
    table.shop_table_responsive .product-thumbnail::before {
        display: none;
    }
    table.shop_table_responsive tr td.actions {
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    table.shop_table_responsive tr td.actions .coupon {
        text-align: left;
    }
    table.shop_table_responsive tr td.actions .coupon button[type='submit'] {
        margin-top: 20px;
    }
    .woocommerce-checkout .woocommerce-billing-fields__field-wrapper p,
    .woocommerce-EditAccountForm p {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .woocommerce-checkout .woocommerce-billing-fields__field-wrapper,
    .woocommerce-checkout .woocommerce-shipping-fields__field-wrapper {
        grid-template-columns: 1fr;
    }
}
.woocommerce-checkout #order_review {
    width: 100%;
    overflow: hidden;
    display: block;
}

.woocommerce-checkout #order_review .woocommerce-checkout-review-order-table {
    float: left;
    width: 49%;
    margin-right: 30px;
    overflow: hidden;
}

.woocommerce-checkout #order_review .woocommerce-checkout-payment {
    float: right;
    width: 48%;
}

@media (max-width: 991px) {
    .woocommerce-checkout #order_review,
    .woocommerce-account .woocommerce,
    .woocommerce-checkout .col2-set {
        grid-template-columns: 1fr;
    }
    .woocommerce-checkout
        #order_review
        .woocommerce-checkout-review-order-table {
        float: none;
        width: 100%;
    }
    .woocommerce-checkout #order_review .woocommerce-checkout-payment {
        width: 100%;
        float: none;
    }
}
.woocommerce-terms-and-conditions-wrapper {
    margin-bottom: 23px;
}

abbr[title] {
    border-bottom: none;
}

/* end woocommerce checkout  */
/**
   * Single product
   */
.single-product div.product {
    position: relative;
}

.single-product div.product .woocommerce-product-gallery {
    position: relative;
}

.single-product
    div.product
    .woocommerce-product-gallery
    .woocommerce-product-gallery__trigger {
    position: absolute;
    top: 45px;
    right: 25px;
    display: block;
    z-index: 99;
    text-indent: -999999px;
}

.single-product div.product .woocommerce-product-gallery .flex-viewport {
    margin-bottom: 1em;
}

.single-product div.product .woocommerce-product-gallery .flex-control-thumbs {
    margin: 0;
    padding: 0;
}

.single-product
    div.product
    .woocommerce-product-gallery
    .flex-control-thumbs
    li {
    list-style: none;
    cursor: pointer;
    float: left;
}

.single-product
    div.product
    .woocommerce-product-gallery
    .flex-control-thumbs
    li
    img {
    opacity: 0.5;
}

.single-product
    div.product
    .woocommerce-product-gallery
    .flex-control-thumbs
    li
    img.flex-active {
    opacity: 1;
}

.single-product
    div.product
    .woocommerce-product-gallery
    .flex-control-thumbs
    li:hover
    img {
    opacity: 1;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-2
    .flex-control-thumbs
    li {
    width: 48.1%;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-2
    .flex-control-thumbs
    li:nth-child(2n) {
    margin-right: 0;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-2
    .flex-control-thumbs
    li:nth-child(2n + 1) {
    clear: both;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-3
    .flex-control-thumbs
    li {
    width: 30.79667%;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-3
    .flex-control-thumbs
    li:nth-child(3n) {
    margin-right: 0;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-3
    .flex-control-thumbs
    li:nth-child(3n + 1) {
    clear: both;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-4
    .flex-control-thumbs
    li {
    width: 22.15%;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-4
    .flex-control-thumbs
    li:nth-child(4n) {
    margin-right: 0;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-4
    .flex-control-thumbs
    li:nth-child(4n + 1) {
    clear: both;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-5
    .flex-control-thumbs
    li {
    width: 16.96%;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-5
    .flex-control-thumbs
    li:nth-child(5n) {
    margin-right: 0;
}

.single-product
    div.product
    .woocommerce-product-gallery.woocommerce-product-gallery--columns-5
    .flex-control-thumbs
    li:nth-child(5n + 1) {
    clear: both;
}

.stock:empty::before {
    display: none;
}

.stock.in-stock {
    color: #0f834d;
}

.stock.out-of-stock {
    color: #e2401c;
}

/**
   * Products
   */
ul.products {
    margin: 0;
    padding: 0;
}

ul.products li.product {
    list-style: none;
    position: relative;
    margin-bottom: 2em;
}

ul.products li.product img {
    display: block;
}

ul.products li.product .button {
    display: inherit;
}

.shop-one__product-count {
    display: inline-block;
    padding-top: 15px;
    font-size: 20px;
    font-weight: 700;
    text-transform: capitalize;
}

.entry-summary .yith-wcwl-add-to-wishlist {
    margin-top: 0;
    margin-bottom: 20px;
    margin-right: 10px;
}

a.thm-btn.wishlist {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.yith-wcwl-add-button > a i {
    margin-right: 0;
}

.checkout-button,
.return-to-shop .button,
.shop_table .button,
.form-row.place-order .button,
.woocommerce-form-coupon .button {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    outline: none !important;
    background-color: var(--eduact-base);
    color: var(--eduact-white);
    font-size: 14px;
    font-weight: 700;
    padding: 12px 50px;
    transition: 500ms;
    line-height: 28px;
    border-radius: 0px;
    z-index: 1;
    overflow: hidden;
}

.widget_price_filter .price_slider_amount .button.thm-btn {
    padding: 3px 10px;
    font-size: 12px;
}

input[type='text']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='search']:focus,
input[type='password']:focus,
input[type='textarea']:focus,
input[type='textarea']:focus-visible,
.product-quantity .quantity input,
#customer_details textarea {
    outline-style: none;
}

mark,
ins {
    background: #fff9c0;
    text-decoration: none;
}

.entry-summary ins {
    background: transparent;
}

.product-details__social + .yith-wcwl-add-to-wishlist {
    display: none !important;
}

.woocommerce-checkout .shop_table .cart_item td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.woocommerce-checkout .shop_table td:last-child {
    padding-right: 0;
}

form.woocommerce-checkout .shop_table tfoot th {
    padding-left: 0;
}

.product-details__content__quantity__text {
    font-size: 14px;
}

.variations_form.cart label,
.woocommerce-tabs .woocommerce-product-attributes-item__label,
.single-product .woocommerce-grouped-product-list-item__label label {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
}
.variations_form.cart label a:hover,
.woocommerce-tabs .woocommerce-product-attributes-item__label a:hover,
.single-product .woocommerce-grouped-product-list-item__label label a:hover {
    color: var(--eduact-base, #4f5de4);
}

.variations_form.cart label {
    font-family: var(--heading-font);
    font-weight: 600;
}

.product-details__content__quantity {
    padding-left: 10px;
}

.shop-one__sidebar .sidebar__single ul li ul {
    margin-top: 0;
}

.sidebar__single.shop-one__sidebar__item.widget
    .wc-block-product-categories-list-item-count::after {
    content: ')';
    position: static;
    font-family: inherit;
    font-weight: inherit;
}

.sidebar__single.shop-one__sidebar__item.widget li::after {
    display: none;
}

.checkout_coupon {
    margin-top: 0;
}

.shop_table tbody .cart_item td:first-child {
    padding-left: 0;
    width: 120px;
    padding-right: 35px;
}

@media screen and (max-width: 992px) {
    .woocommerce-cart-form__contents {
        border: 0;
    }
    .woocommerce-cart-form__contents caption {
        font-size: 1.3em;
    }
    .woocommerce-cart-form__contents thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .woocommerce-cart-form__contents tr {
        border-bottom: 1px solid var(--eduact-extra);
        display: block;
        margin-bottom: 0.625em;
    }
    .woocommerce-cart-form__contents td {
        border-bottom: 1px solid var(--eduact-extra);
        display: block;
        font-size: 0.8em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        color: var(--eduact-black);
        font-size: 20px;
        font-weight: 700;
        font-family: var(--eduact-font);
    }
    .woocommerce-cart-form__contents td::before {
        /*
      * aria-label has no advantage, it won't be read inside a .woocommerce-cart-form__contents
      content: attr(aria-label);
      */
        content: attr(data-title);
        font-weight: bold;
    }
    .woocommerce-cart-form__contents td:last-child {
        border-bottom: 0;
    }
    .shop_table button[name='update_cart'] {
        position: relative;
    }
    .woocommerce-cart-form .actions {
        display: block;
    }
    .shop_table tbody tr + tr {
        border-top: 0;
    }
    .woocommerce-cart-form__contents .product-thumbnail {
        justify-content: center;
    }
}
.product-details__content__rating {
    display: flex;
    align-items: center;
    padding-bottom: 26px;
    margin-bottom: 17px;
    border-bottom: 1px solid #ece9e0;
}

.product-review .comments-area .comment-box .info {
    margin-bottom: 0;
}

.product__all-content ins {
    background-color: transparent;
}

.product-details__title ins span {
    margin-left: 0;
}

.product__all-btn-box .thm-btn {
    padding: 6px 25px;
}

.product__all-btn-box .thm-btn:focus {
    color: #fff;
}

.product-details__title del span {
    text-decoration: line-through;
}

.eduact-overlay {
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    left: 0;
    display: none;
    background: rgba(0, 0, 0, 0.1);
}

.cv-spinner {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px #ddd solid;
    border-top: 4px solid;
    border-radius: 50%;
    animation: sp-anime 0.8s infinite linear;
    border-top-color: var(--eduact-primary);
}

@keyframes sp-anime {
    100% {
        transform: rotate(360deg);
    }
}
.is-hide {
    display: none;
}

.woocommerce-form-register,
.woocommerce-form-login {
    margin-top: 20px;
}

.shop-category.widget_block.widget_categories {
    padding: 30px 20px;
}

.sidebar .shop-category h2 {
    margin-bottom: 23px;
}

.sidebar.shop-one__sidebar .widget .wp-block-categories li,
.sidebar.shop-one__sidebar .widget_categories ul li {
    display: block;
    margin-bottom: 0;
    position: relative;
}

.sidebar.shop-one__sidebar .widget .wp-block-categories li,
.sidebar .widget_categories ul li + li {
    margin-top: 0;
    margin-bottom: 5px;
    z-index: 999;
    padding: 6px 0;
}

.woo-widget .wc-block-components-filter-submit-button {
    font-size: 13px;
    font-weight: 800;
    background-color: var(--eduact-base);
    line-height: 1em;
    padding: 13px 19px 13px;
    transition: all 500ms ease;
    border-radius: 0;
}

.wc-block-components-price-slider__actions
    button[type='submit']:not(
        .wp-block-search__button
    ).wc-block-components-filter-submit-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--eduact-soft5);
    float: right;
    text-align: center;
    border: none;
    color: var(--eduact-text);
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
    padding: 0 16.5px;
    height: 40px;
    border-radius: 5px;
    text-transform: capitalize;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.woo-widget div .wc-block-components-filter-submit-button:hover {
    background-color: var(--eduact-secondary, #1a1414) !important;
    color: var(--eduact-white) !important;
}

.wc-block-components-price-slider__range-input-progress {
    --track-background: linear-gradient(
            90deg,
            transparent var(--low),
            var(--range-color) 0,
            var(--range-color) var(--high),
            transparent 0
        )
        no-repeat 0 100%/100% 100%;
    --range-color: var(--eduact-secondary);
    background: var(--track-background);
    height: 6px;
}

.wc-block-components-price-slider__range-input-wrapper {
    height: 6px;
}

input[type='range' i] {
    appearance: auto;
    cursor: default;
    padding: initial;
    border: initial;
    margin: 0;
    accent-color: var(--eduact-secondary);
}

.wc-block-price-filter__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: var(--eduact-letter-spacing);
    margin: 0;
    margin-bottom: 22px;
}

.shop-one__sidebar.sidebar .widget ul li::after {
    content: '';
}

.shop-one__sidebar.sidebar .widget ul.wp-block-categories li a::after {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    content: '\e92b';
    font-size: 22px;
    color: var(--eduact-secondary);
    font-family: 'icomoon' !important;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.shop-one__sidebar.sidebar .price,
.sidebar .price {
    padding: 0;
}

.shop-one__sidebar.sidebar .wc-block-components-sort-select__label {
    float: left;
}

.shop-one__sidebar.sidebar .wc-block-components-review-list-item__info {
    display: block;
}

.shop-one__sidebar.sidebar .wc-block-components-review-list-item__image {
    width: 100%;
    height: 100%;
}

.wc-block-featured-category__link,
.wc-block-featured-category__title,
.wc-block-featured-product__description,
.wc-block-featured-product__link {
    padding: 0;
}

.sidebar .widget_products li .size-woocommerce_thumbnail,
.sidebar .widget_recent_reviews li .size-woocommerce_thumbnail {
    width: 100%;
    float: unset;
}

.shop-one__sidebar.sidebar .wc-block-stock-filter__title {
    font-size: 16px;
}

.wc-block-product-categories-list--has-images
    .wc-block-product-categories-list-item__image {
    max-width: unset;
    margin-bottom: 5px;
}

.wc-block-product-categories-list--has-images li ul,
.wc-block-product-categories-list--has-images
    li
    ul
    li.wc-block-product-categories-list-item {
    margin-left: 0;
}

.sidebar .select2-container .select2-selection--single {
    height: 60px;
    margin-bottom: 5px;
}

.sidebar
    .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 55px;
    padding: 0 20px;
}

.sidebar
    .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    height: 55px;
    margin-right: 10px;
}

.shop-one__sidebar.sidebar .wc-block-product-categories__dropdown select {
    padding-left: 10px;
    background-image: none;
}

.woocommerce-product-search button,
.woocommerce-widget-layered-nav button {
    background: var(--eduact-base);
    color: #fff;
    line-height: 32px;
    width: 100%;
}

.wc-block-components-review-list-item__item--has-image
    .wc-block-components-review-list-item__meta {
    margin-top: 10px;
}

.wc-block-grid__product-add-to-cart.wp-block-button
    .wp-block-button__link.added:after,
.sidebar .widget ul.wc-block-grid__products li::after,
.sidebar .widget .wc-block-product-categories-list li::after,
.sidebar .widget .widget_shopping_cart_content ul li:after,
.sidebar ul.product_list_widget li:after {
    content: ' ';
}

.wc-block-grid__product-add-to-cart.wp-block-button .wp-block-button__link {
    width: 100%;
}

.wp-block-woocommerce-price-filter > .wc-block-price-filter__title {
    margin-bottom: 30px;
    margin-top: 0;
}

.wc-block-grid__product:last-child {
    border-bottom: 0;
    margin-bottom: -16px;
}

.wc-block-grid__products,
.wc-block-components-review-list-item__item,
.wc-block-product-categories {
    margin-bottom: 0;
}

.wc-block-components-price-slider {
    margin-bottom: 5px;
}

.wc-block-grid__product .wc-block-grid__product-title {
    color: #222429;
    font-weight: 800;
    font-size: 16px;
}

.wc-block-grid__product-onsale {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--eduact-base);
    border-color: var(--eduact-base);
    text-transform: capitalize;
    color: #fff;
}

.woo-widget .select2-container--default .select2-selection--single {
    border: 1px solid #e6e1d8;
}

.select2-container--default .select2-selection--multiple {
    border: none !important;
}

.wc-block-components-price-slider__range-input {
    top: -2px;
}

.wc-blocks-filter-wrapper h3,
.woocommerce-account h3 {
    font-family: var(--heading-font);
    font-size: 24px;
    text-transform: capitalize;
    font-weight: 700;
    margin-bottom: 20px;
}

.sidebar .wc-block-components-price-slider::after {
    content: '';
    display: table;
    clear: both;
}
.sidebar .wc-block-components-price-slider__range-text {
    float: left;
    margin: 0;
    padding-top: 6px;
}
.sidebar .wc-block-components-price-slider__range-text span:first-child::after {
    content: '-';
    margin-left: 5px;
    margin-right: 5px;
}
.sidebar .wc-block-components-price-slider__actions {
    float: right;
}

.product__add-to-cart {
    display: flex;
    justify-content: space-around;
    margin-top: 16px;
}

ul.products .product__add-to-cart .add_to_cart_button.thm-btn,
.product-widget .add_to_cart_button {
    position: relative;
}

.woosw-list .add_to_cart_button.loading::after,
ul.products .product__add-to-cart .add_to_cart_button.thm-btn.loading::after,
.product-widget .add_to_cart_button.loading::after {
    font-family: 'Font Awesome 5 Free';
    content: '\f110';
    vertical-align: top;
    font-weight: 900;
    position: absolute;
    right: 5px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.woosw-list .add_to_cart_button.added,
ul.products .product__add-to-cart .add_to_cart_button.thm-btn.added,
.product-widget .add_to_cart_button.thm-btn.added {
    display: none;
}

.woocommerce-Reviews .comment {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: inherit;
}

.product__items ul.products {
    width: calc(100% + 30px);
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    float: left;
}

.product__items ul.products li {
    width: 100%;
    border: 0;
    margin-bottom: 0;
    float: none;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: none !important;
    padding-bottom: 30px;
    border-radius: 12px;
}
@media (min-width: 768px) {
    .product__items ul.products li {
        width: 50%;
    }
}
@media (min-width: 992px) {
    .product__items ul.products li {
        width: 33.333%;
    }
}

.product__items ul.products li.product__item {
    background-color: transparent;
}

.product__all-img {
    border: 1px solid #dedee5;
    background-color: var(--eduact-white);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom: 0;
}

.product__all-content {
    border: 1px solid #dedee5;
    border-top: 0;
    margin-top: 0;
    padding-top: 26px;
    padding-bottom: 30px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.product__sidebar .widget,
.product__sidebar-single {
    padding: 30px;
}

.product__sidebar .product__sidebar-single .wp-block-categories {
    margin-top: -17px;
}

.cart_totals .woocommerce-shipping-methods label {
    margin-bottom: 0;
}

.woocommerce-checkout-review-order-table th:first-child,
.woocommerce-checkout-review-order-table td:first-child {
    font-family: var(--heading-font);
    font-size: 20px;
    color: var(--eduact-black);
    font-weight: 700;
    margin: 0;
    text-transform: capitalize;
}
.woocommerce-checkout-review-order-table th:last-child,
.woocommerce-checkout-review-order-table td:last-child {
    text-align: right;
}

.woosq-popup .woosw-btn {
    background-color: var(--eduact-base);
}

.woosq-popup .woosw-btn:hover,
.product-details .cart .woosw-btn:hover {
    color: var(--eduact-white);
}

.woosq-popup .woosw-added {
    padding: 15px 45px 13px;
}

.woosw-added:hover {
    background-color: var(--eduact-base);
}

.woosw-list .added_to_cart.wc-forward {
    padding: 15px 45px 14px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.shop-one.product.news-sidebar {
    padding: 120px 0;
}

.shop_table tbody .cart_item td.product-name {
    position: relative;
}

.shop_table.woocommerce-checkout-review-order-table
    tbody
    .cart_item
    td.product-name {
    left: 0;
}

.shop-one__sidebar
    .sidebar__single
    .wp-block-search
    .wp-block-search__button.has-icon {
    color: var(--treck-black, #687469);
    padding: 0;
    background-color: transparent;
    position: absolute;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
}

.wp-block-search__button svg {
    min-height: 2.5em;
    min-width: 2.5em;
}

.woosw-list table.woosw-items .woosw-item .woosw-item--add .add_to_cart_inline {
    text-align: right;
    margin-right: 10px;
}

.woosw-list table.woosw-items .woosw-item .woosw-item--image img {
    border-radius: var(--eduact-bdr-radius);
}

.product .blog-sidebar__meta,
.product .blog-sidebar__btn,
.product .blog-sidebar__date {
    display: none;
}

.woosw-popup-content .product .button,
.product__all-content .thm-btn,
.product__item .thm-btn {
    background-color: var(--eduact-soft5);
    font-family: var(--heading-font);
    font-size: 16px;
    font-weight: 700;
    color: var(--eduact-text);
    padding: 8px 20px;
    text-transform: capitalize;
    border-radius: 6px;
}

.item .thm-btn {
    padding: 10px 20px;
}

.product-widget .added_to_cart {
    padding: 9px 12px;
    margin: -8px 0;
}

.woosw-popup-content .product .button:hover,
.product__all-content .thm-btn:hover,
.product__item .thm-btn:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

p.comment-notes {
    margin-bottom: 10px;
}

.woocommerce-ResetPassword .woocommerce-Button,
.eduact-edit-address button[type='submit'],
.checkout_coupon button[type='submit'],
.woocommerce-EditAccountForm button[type='submit'],
.woocommerce-checkout-payment #place_order,
.woocommerce-form-login .woocommerce-form-login__submit,
.woocommerce-form-register .woocommerce-form-register__submit,
.wc-proceed-to-checkout a,
.shop_table button[name='update_cart'],
.shop_table .coupon .thm-btn,
.entry-summary .thm-btn.cart-btn,
.woosq-popup .woosw-btn,
.product-details .cart .woosw-btn,
.woosw-list .add_to_cart_button {
    font-size: 18px;
    padding: 16px 49.5px 17px;
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
    font-weight: 700;
    font-family: var(--heading-font);
    text-transform: capitalize;
    line-height: 30px;
    transition: all 0.5s linear;
    border-radius: 6px;
}

.checkout_coupon button[type='submit'],
.woocommerce-checkout-payment #place_order {
    line-height: 26px;
}

.woocommerce-checkout-payment #place_order {
    line-height: 19px;
}

.entry-summary .thm-btn.cart-btn,
.product-details .cart .woosw-btn {
    padding: 11px 32px;
    border-radius: 6px;
}

.shop_table .coupon .thm-btn {
    line-height: 27px;
}

.wc-proceed-to-checkout a {
    padding: 23px 50px;
}

.entry-summary .thm-btn.cart-btn {
    margin-right: 10px;
}

.woocommerce-ResetPassword .woocommerce-Button:hover,
.eduact-edit-address button[type='submit']:hover,
.checkout_coupon button[type='submit']:hover,
.woocommerce-EditAccountForm button[type='submit']:hover,
.woocommerce-checkout-payment #place_order:hover,
.woocommerce-form-login .woocommerce-form-login__submit:hover,
.woocommerce-form-register .woocommerce-form-register__submit:hover,
.wc-proceed-to-checkout a:hover,
.shop_table button[name='update_cart']:hover,
.shop_table .coupon .thm-btn:hover,
.entry-summary .thm-btn.cart-btn:hover {
    background-color: var(--eduact-black);
    color: var(--eduact-white);
}

.woosq-popup .woosw-btn,
.product-details .cart .woosw-btn,
.woosw-list .add_to_cart_button {
    background-color: var(--eduact-black);
    color: var(--eduact-white);
}

.woosq-popup .woosw-btn:hover,
.product-details .cart .woosw-btn:hover,
.woosw-list .add_to_cart_button:hover {
    background-color: var(--eduact-secondary);
    color: var(--eduact-white);
}

.product__sidebar-title {
    position: relative;
    font-size: 24px;
    line-height: 26px;
    text-transform: capitalize;
    border-bottom: 1px dashed var(--eduact-secondary);
    margin: 0 0 30px;
    padding-bottom: 25px;
}

.shop-category.product__sidebar-single ul.wp-block-categories li {
    position: relative;
    display: block;
}

.sidebar.shop-one__sidebar .widget .wp-block-categories li {
    padding: 0;
}

.shop-category.product__sidebar-single ul.wp-block-categories li a {
    position: relative;
    z-index: 2;
    display: block;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    color: var(--eduact-text);
    border-bottom: 1px solid var(--eduact-soft4);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    padding: 25px 0 30px 33px;
    text-transform: capitalize;
}

.shop-category.product__sidebar-single ul.wp-block-categories li:last-child a {
    border-bottom: none;
}

.shop-one__sidebar.sidebar .widget ul.wp-block-categories li:hover a {
    color: var(--eduact-secondary);
    padding-left: 40px;
}

.review-form-one__title {
    text-transform: capitalize;
    font-size: 24px;
}

.wc-block-components-price-slider__range-text
    .wc-block-components-formatted-money-amount {
    font-size: 16px;
    font-weight: 600;
}

@media (min-width: 992px) {
    .product__items ul.products.columns-1 li {
        width: calc(100% / 1);
    }
}
@media (min-width: 992px) {
    .product__items ul.products.columns-2 li {
        width: calc(100% / 2);
    }
}
@media (min-width: 992px) {
    .product__items ul.products.columns-3 li {
        width: calc(100% / 3);
    }
}
@media (min-width: 992px) {
    .product__items ul.products.columns-4 li {
        width: calc(100% / 4);
    }
}
@media (min-width: 992px) {
    .product__items ul.products.columns-5 li {
        width: calc(100% / 5);
    }
}
@media (min-width: 992px) {
    .product__items ul.products.columns-6 li {
        width: calc(100% / 6);
    }
}

.product__item__btn .woosw-btn-text-icon .woosw-btn-text {
    display: none;
}

.product-details__buttons .woosw-btn-icon {
    display: none;
}

.blog-sidebar__single.product .blog-one__meta,
.blog-sidebar__single.product .eduact-btn {
    display: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.product__item__btn {
    right: 35px;
    top: 20px;
}

.product-content.product-description p {
    font-weight: 600;
    line-height: 32px;
}

.product-details__quantity-title {
    font-size: 20px;
    margin-right: 30px;
}

.product {
    padding: 0;
}

.woocommerce-ResetPassword .woocommerce-Button::after,
.eduact-edit-address button[type='submit']::after,
.checkout_coupon button[type='submit']::after,
.woocommerce-EditAccountForm button[type='submit']::after,
.woocommerce-checkout-payment #place_order::after,
.woocommerce-form-login .woocommerce-form-login__submit::after,
.woocommerce-form-register .woocommerce-form-register__submit::after,
.wc-proceed-to-checkout a::after,
.shop_table button[name='update_cart']::after,
.shop_table .coupon .thm-btn::after,
.entry-summary .thm-btn.cart-btn::after,
.woosq-popup .woosw-btn::after,
.product-details .cart .woosw-btn::after,
.woosw-list .add_to_cart_button::after {
    content: '\e903';
    font-family: 'icomoon' !important;
    margin-left: 10px;
}

.woocommerce-grouped-product-list .product-details__buttons {
    margin-bottom: -7px;
}

.woosw-items .woosw-item .woosw-item--name a,
.woosw-popup
    .woosw-popup-inner
    .woosw-popup-content
    .woosw-popup-content-bot
    .woosw-popup-content-bot-inner
    a {
    font-weight: 700;
    text-decoration: none;
    color: var(--eduact-black);
}

:root {
    --eduact-text: #f1f2fd;
    --eduact-text-rgb: 241, 242, 253;
    --eduact-black: #fff;
    --eduact-black-rgb: 255, 255, 255;
    --eduact-white: #121534;
    --eduact-white-rgb: 18, 21, 52;
    --eduact-black3: #000324;
    --eduact-black3-rgb: 0, 3, 36;
    --eduact-grey: #f6f6f6;
    --eduact-grey-rgb: 246, 246, 246;
    --eduact-black4: #2a254d;
    --eduact-black4-rgb: 42, 37, 77;
    --eduact-black5: #121745;
    --eduact-black5-rgb: 18, 23, 69;
    --eduact-black6: #282445;
    --eduact-black6-rgb: 40, 36, 69;
    --eduact-soft3: #13194f;
    --eduact-soft3-rgb: 19, 25, 79;
}

.main-menu .main-menu__list > li > a,
.stricky-header .main-menu__list > li > a,
.hero-banner__title,
.service-one__icon,
.category-one__hover__icon,
.category-one__icon,
.home-showcase__title,
.course-one__like,
.course-one__like:hover,
.counter-one__left__title,
.counter-one__left__content,
.course-one__time,
.team-one__title,
.blog-one__cta__icon,
.blog-one__cta__title,
.blog-one__cats a,
.cta-one__left__title,
.main-footer__title,
.main-footer__social a,
.main-footer__social a:hover,
.blog-one__cta__text,
.team-one__designation,
.team-one__social a,
.category-one__hover__title,
.category-one__hover__text,
.main-menu__login,
.main-menu__search,
.hero-banner__cart__title,
.about-one__thumb__box__icon,
.eduact-btn,
.eduact-btn:hover {
    color: var(--eduact-black);
}

.hero-banner__text {
    color: var(--eduact-text);
}

.hero-banner__bg-shape1,
.hero-banner__bg-shape1::after {
    background: linear-gradient(
        180deg,
        rgba(var(--eduact-black-rgb), 0.09) 0%,
        rgba(var(--eduact-black-rgb), 0) 100%
    );
}

.hero-banner__btn .eduact-btn + .eduact-btn {
    border-color: var(--eduact-black);
}

.hero-banner__cart__content::after {
    border-color: rgba(var(--eduact-black-rgb), 0.47);
}

.search-popup__overlay {
    background-color: var(--eduact-white);
}

.counter-one,
.stricky-header,
.hero-banner {
    background-color: var(--eduact-black3);
}

.main-footer,
.hero-banner__video .video-popup {
    background-color: var(--eduact-black4);
}

.blog-one__item,
.course-one__content,
.category-one__item,
.service-one__item {
    box-shadow: 11px 15px 30px 0px #05092d;
}

.testimonial-one__area {
    box-shadow: 11px 15px 30px 0px rgba(2, 2, 2, 0.37);
}

.section-title__tagline svg path,
.service-one__item svg path {
    fill: var(--eduact-soft3);
}

.team-one,
.search-popup__form input[type='search'],
.search-popup__form input[type='text'],
.counter-one__list li,
.counter-one__list::after,
.counter-one__list::before,
.category-one__slider.owl-carousel .owl-dots,
.about-one__box__icon {
    background-color: var(--eduact-soft3);
}

.category-one__hover__icon::after,
.category-one__icon::after {
    background: linear-gradient(
        177deg,
        rgba(var(--eduact-black-rgb), 0.53) 0%,
        rgba(var(--eduact-black-rgb), 0) 100%
    );
}

.category-one__slider.owl-carousel .owl-dots .owl-dot {
    background-color: rgba(var(--eduact-base-rgb), 0.48);
}

.course-one,
.blog-one__meta,
.blog-one__cta__email-input-box input[type='email'],
.course-one__bottom {
    background-color: var(--eduact-black5);
}

.counter-one__list li {
    color: var(--eduact-secondary);
}

.blog-one .section-title__tagline svg path,
.team-one .section-title__tagline svg path,
.testimonial-one .section-title__tagline svg path {
    fill: var(--eduact-black3);
}

.testimonial-one__carousel.owl-carousel .owl-nav button {
    background-color: var(--eduact-black5);
    color: var(--eduact-base);
}

.testimonial-one__carousel.owl-carousel .owl-nav button:hover {
    color: var(--eduact-black);
}

.team-one__image::after {
    background-color: rgba(var(--eduact-black4-rgb), 0.83);
}

.blog-one__image a {
    background-color: rgba(var(--eduact-white-rgb), 0.4);
}

.blog-one__image a::after,
.blog-one__image a::before {
    background-color: var(--eduact-black);
}

.blog-one__cta::after {
    background-color: rgba(var(--eduact-black6-rgb), 0.83);
}

.cta-one__svgshape svg {
    fill: rgba(var(--eduact-black4-rgb), 0.74);
}

.cta-one__thumb__area__dark {
    background-color: rgba(var(--eduact-black4-rgb), 0.46);
}

.course-one__thumb {
    background: linear-gradient(
        147deg,
        var(--eduact-black) 0%,
        rgba(231, 231, 231, 0) 100%
    );
}

.course-one__time .tutor-meta-value.tutor-color-secondary {
    color: #fff;
}
